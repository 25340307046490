import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import React, { useEffect, useRef, useState } from 'react'
import ExportButton from '../../components/mini/ExportButton'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import { getProductPrice } from '../../api'
import { onChangeFilter } from '../../functions/setter'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { useGlobalContext } from '../../context'

const emptyFilterObject = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  isStockOut: { value: 0, matchMode: FilterMatchMode.EQUALS },
  status: { value: 'active', matchMode: FilterMatchMode.EQUALS },
  productName: { value: null, matchMode: FilterMatchMode.CONTAINS },
}

export default function ProductPricePage() {
  const { productNames } = useGlobalContext()
  const dt = useRef(null)
  const [records, setRecords] = useState([])
  const [filters, setFilters] = useState(emptyFilterObject)
  const [loading, setLoading] = useState(false)

  const fetchProductPricing = async () => {
    setLoading(true)
    const res = await getProductPrice()
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      }
    }
  }
  useEffect(() => {
    fetchProductPricing()
  }, [])

  const productPriceFiltersHeader = () => (
    <>
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
        <div>
          <Dropdown
            value={filters.productName.value}
            placeholder="Filter by product"
            options={productNames}
            optionLabel="productName"
            optionValue="productName"
            maxSelectedLabels={3}
            filter
            showClear
            filterBy="productName"
            onChange={(e) => {
              onChangeFilter(setFilters, e.value, 'productName')
            }}
          />
        </div>
        <div>
          <Dropdown
            value={filters.isStockOut.value}
            placeholder="Stock Out"
            options={[
              {
                label: 'Stock Out',
                value: 1,
              },
              {
                label: 'In Stock',
                value: 0,
              },
            ]}
            showClear
            optionLabel="label"
            optionValue="value"
            filter
            filterBy="label"
            onChange={(e) => {
              onChangeFilter(setFilters, e.value, 'isStockOut')
            }}
          />
        </div>
        <div>
          <Dropdown
            value={filters.status.value}
            placeholder="Active/Inactive"
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inactive',
              },
            ]}
            showClear
            optionLabel="label"
            optionValue="value"
            filter
            filterBy="label"
            onChange={(e) => {
              onChangeFilter(setFilters, e.value, 'status')
            }}
          />
        </div>
        <div className="">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'] ? filters['global'].value : '' || ''}
              onChange={(e) => onChangeFilter(setFilters, e.target.value, 'global')}
              placeholder="Search..."
            />
          </span>
        </div>
      </div>
    </>
  )

  return (
    <>
      <div className="card">
        <Toolbar
          className="p-toolbar"
          left={() => (
            <div className="p-d-flex gap-1 p-ai-center">
              <h4 className="p-my-0">Product Price</h4>
            </div>
          )}
          right={() => (
            <div className="p-d-flex p-ai-center gap-1">
              <Button
                onClick={() => fetchProductPricing()}
                icon="pi pi-refresh"
              />
              <ExportButton datatable_ref={dt} />
            </div>
          )}
        ></Toolbar>
        <DataTable
          ref={dt}
          filters={filters}
          columnResizeMode="fit"
          removableSort
          value={records}
          loading={loading}
          showGridlines
          paginator={true}
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          header={productPriceFiltersHeader}
        >
          <Column header="Full Product Name" field="fullProductName" hidden />
          <Column header="Product Name" field="productName" />
          <Column header="Strength Name" field="strengthName" />
          <Column header="Quantity" field="quantity" />

          <Column
            header="Discount Percentage"
            field="discountInPercentage"
            body={(rd) => <label>{rd.discountInPercent}%</label>}
          />
          <Column
            header="Total Price"
            field="totalPrice"
            body={(rd) => <div>${Number(rd.totalPrice).toFixed(2)}</div>}
          />
          <Column
            header="Discounted Price"
            field="discountPrice"
            body={(rd) => <div>${Number(rd.discountPrice).toFixed(2)}</div>}
          />
          <Column
            header="Shipping Charge"
            field="shippingPrice"
            // body={(rd) => <label>{Number(rd.shippingPrice) ? `$${rd.shippingPrice}` : `NA`}</label>}
            body={(rd) => <label>${rd.shippingPrice}</label>}
          />
          <Column
            header="Total Savings"
            field="saving"
            // body={(rd) => <label>{Number(rd.shippingPrice) ? `$${rd.shippingPrice}` : `NA`}</label>}
            body={(rd) => <label>${Number(rd.saving).toFixed(2)}</label>}
          />
          <Column header="Active/InActive" field="status" />
          <Column
            header="Stock"
            field="isStockOut"
            body={(rd) => <label>{rd.isStockOut ? 'Stock Out' : 'In Stock'}</label>}
          />
        </DataTable>
      </div>
    </>
  )
}
