import React, { useEffect, useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import validator from 'validator'
import { loginApi, loginOtpApi } from '../../api'
import { useGlobalContext } from '../../context'
import { Toast } from 'primereact/toast'
import { Password } from 'primereact/password'

export default function LoginPage(props) {
  const { setUser } = useGlobalContext()
  const toast = useRef(null)
  const refOtp = useRef(null)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const emptyError = {
    email: null,
    password: null,
    otp: null,
  }
  const [errors, setErrors] = useState(emptyError)
  const [isLogged, setIsLogged] = useState(false)
  const [otp, setOtp] = useState('')
  const [requesting, setRequesting] = useState(false)
  const [loginLoading, setLoginLoading] = useState(false)
  const [otpLoading, setOtpLoading] = useState(false)
  const [otpExpireTime, setOtpExpireTime] = useState(0)
  const [otpCoolDown, setOtpCoolDown] = useState(0)
  const [resId, setResId] = useState(null)
  const [resRole, setResRole] = useState(null)
  const validateInputs = () => {
    let _errors = {}
    if (!email) {
      _errors.email = 'Email is required'
    } else if (!validator.isEmail(email)) {
      _errors.email = 'Email is not valid'
    } else {
      _errors.email = null
    }

    if (!password) {
      _errors.password = 'Password is required'
    } else {
      _errors.password = null
    }

    if (!otp) {
      _errors.otp = 'OTP is required'
    } else {
      _errors.otp = null
    }
    setErrors(_errors)
    return _errors
  }
  const loginOnSubmitHandler = async () => {
    const err = validateInputs()
    if (err?.email || err?.password) return
    setLoginLoading(true)
    const res = await loginApi(email, password)
    if (res) {
      if (res.status === 404) {
        setErrors({ password: 'email or password does not match' })
        setIsLogged(false)
        setLoginLoading(false)
      } else if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Login success, please provide OTP',
        })
        setRequesting(false)
        setOtpExpireTime(new Date().getTime() + 30 * 1000)
        setResId(res?.data?.user?.id)
        setResRole(res?.data?.user?.role)
        setIsLogged(true)
        setLoginLoading(false)
        setErrors(emptyError)
        refOtp.current.focus()
      } else {
        setIsLogged(false)
        setLoginLoading(false)
        setErrors({ password: res?.data?.error || 'something went wrong' })
      }
    }
  }
  const handleRequestOtp = async () => {
    setRequesting(true)
    const res = await loginApi(email, password)
    if (res) {
      setRequesting(false)
      if (res.status === 200) {
        setOtpExpireTime(new Date().getTime() + 30 * 1000)
      }
    }
  }
  const otpOnSubmitHandler = async () => {
    const err = validateInputs()
    if (err.otp) return
    setOtpLoading(true)
    const res = await loginOtpApi(parseInt(otp), resId, resRole)
    if (res) {
      setOtpLoading(false)
      if (res.status === 200) {
        const data = await res?.data
        toast.current.show({
          severity: 'success',
          summary: 'Verification Successful',
          detail: '',
          life: 3000,
        })
        const { role, id, email, username } = data?.user || {}
        setUser({
          role,
          id,
          email,
          username,
        })
        localStorage.setItem('token', data?.token)
        let redirectUrl = '/'
        if (['operations'].includes(role)) {
          redirectUrl = '/orders'
        }
        setTimeout(() => {
          window.location.href = redirectUrl
        }, 1000)
      }
    }
  }

  useEffect(() => {
    if (otpExpireTime) {
      const interval = setInterval(() => {
        if (new Date().getTime() < otpExpireTime) {
          setOtpCoolDown((p) => p + 1)
        } else {
          setOtpCoolDown(0)
          setOtpExpireTime(null)
          return () => clearInterval(interval)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [otpExpireTime])
  return (
    <div className="login-body">
      <Toast ref={toast} />

      <div className="w-full">
        <div className="p-d-flex h-full p-ai-center p-jc-center">
          <div className="card max-w-30 p-m-4">
            <div className="">
              <div className="p-d-flex p-jc-between p-ai-center">
                <h3 className="p-m-0">Login</h3>
              </div>
              <hr />
              {!isLogged ? (
                <div id="login">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      loginOnSubmitHandler()
                    }}
                  >
                    <div className="p-fluid p-grid">
                      <div className="p-col-12">
                        <label>Email</label>
                        <div className="p-mt-2">
                          <InputText
                            autoFocus
                            value={email}
                            type="text"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => {
                              setEmail(e.target.value)
                              setErrors(emptyError)
                            }}
                            className={classNames('w-full', {
                              'p-invalid': errors?.email,
                            })}
                          />
                          {errors?.email && (
                            <small className="p-error block">{errors?.email}</small>
                          )}
                        </div>
                      </div>
                      <div className="p-col-12">
                        <label className="">Your Password</label>
                        <div className="p-mt-2">
                          <Password
                            toggleMask
                            feedback={false}
                            name="password"
                            value={password}
                            type="password"
                            placeholder="Password"
                            onChange={(e) => {
                              setPassword(e.target.value)
                              setErrors(emptyError)
                            }}
                            className={classNames('', {
                              'p-invalid': errors?.password,
                            })}
                          />
                          {errors?.password && (
                            <small className="p-error block">{errors?.password}</small>
                          )}
                        </div>
                      </div>
                    </div>
                    <Link to="/reset-password" className="p-d-flex p-link p-mb-3">
                      Forgot your password?
                    </Link>
                    <Button
                      type="submit"
                      label={loginLoading ? 'Logging' : 'Login'}
                      loading={loginLoading}
                      disabled={loginLoading}
                      icon="pi pi-sign-in"
                      className="w-full"
                      onClick={() => loginOnSubmitHandler()}
                    />
                  </form>
                </div>
              ) : (
                <div id="otp">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      otpOnSubmitHandler()
                    }}
                  >
                    <div className="p-formgrid">
                      <p className="">
                        Please enter the OTP which was send to your email to continue.
                      </p>
                      <div className="p-field p-fuild">
                        <label>OTP</label>
                        <InputText
                          ref={refOtp}
                          value={otp}
                          type="text"
                          placeholder="Enter the OTP"
                          className="w-full"
                          onChange={(e) => {
                            if (e.target.value.length > 4) return
                            setOtp(e.target.value)
                          }}
                        />
                        {errors?.otp && <small className="p-error block">{errors?.otp}</small>}
                      </div>
                    </div>
                    <div className="p-d-flex p-flex-column-reverse">
                      <Button
                        type="submit"
                        label={otpLoading ? 'Submiting' : 'Submit'}
                        loading={otpLoading}
                        disabled={otpLoading}
                        icon="pi pi-check"
                        className="w-full"
                      />
                      {otpExpireTime ? (
                        <div className="p-field">
                          <Button
                            label={`Request OTP again in ${30 - otpCoolDown} sec`}
                            className="p-button-text"
                            disabled
                          />
                        </div>
                      ) : (
                        <div className="p-field">
                          <Button
                            label={requesting ? 'Requesting' : 'Request OTP'}
                            className="p-button-text"
                            onClick={handleRequestOtp}
                            loading={requesting}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
