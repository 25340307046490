import React, { useCallback, useEffect, useRef, useState } from 'react'

import { format, formatDistanceToNow } from 'date-fns'
import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Sidebar } from 'primereact/sidebar'
import { Timeline } from 'primereact/timeline'
import { Toast } from 'primereact/toast'
import { useHistory, useLocation } from 'react-router-dom'
import { getOrderHistory, updateOrderHistoryReason } from '../../api'
import { useGlobalContext } from '../../context'
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetter'
import useQuery from '../../hooks/useQuery'

export default function ChangeHistorySidebar({
  searchParamsForHistory = 'history',
  setTableLoading,
  historyType,
  position = 'right',
  header,
}) {
  /**
   * @param {string} searchParamsForHistory - The search params for history
   * @param {function} setTableLoading - The function to set the table loading state
   * @param {string} historyType - The type of history to be fetched
   * @param {string} position - The position of the sidebar
   * @param {string} header - The header of the sidebar
   * @param {string} resetPath - The path to reset the sidebar
   * @description - The sidebar to display the change history
   **/

  const history = useHistory()
  const location = useLocation()
  const { user } = useGlobalContext()
  const search = useQuery()
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get(searchParamsForHistory)
  const toast = useRef(null)
  const [visible, setVisible] = useState(false)
  const [records, setRecords] = useState([])
  const [recordsLoading, setRecordsLoading] = useState(false)
  const handleCloseSidebar = () => {
    setVisible(false)
    setRecordsLoading(false)
    setRecords([])
    history.push({
      pathname: location.pathname,
    })
  }
  const toggelReasonTextbox = (index, bool) => {
    if (records.length > 0) {
      const temp = [...records]
      temp[index].textbox = bool
      setRecords(temp)
    }
  }

  const content = (item, index) => {
    let parseData = new Date(item?.timestamp)
    let date = formatDistanceToNow(parseData, { addSuffix: true })
    let date1 = format(parseData, 'PPPPp')

    return (
      <div key={item.id}>
        <h5 className="p-text-bold p-mb-2 p-mt-0">
          <span className="p-d-none">#{item.id + 1}: </span>
          {capitalizeFirstLetter(item?.action)}
        </h5>

        <small>
          <span className=" ">Performed By : </span>
          <label className="p-text-lowercase">
            {item?.master} ({item?.masterRole})
          </label>
          <br />
          <span>
            {capitalizeFirstLetter(date)} - {date1}
          </span>
        </small>
        <div>
          <div>
            {item?.textbox ? (
              <div className="p-d-flex p-ai-center ">
                <InputTextarea
                  placeholder="Enter the reason"
                  autoResize
                  id="name"
                  type="text"
                  value={item?.reason}
                  onChange={(e) => {
                    const temp = [...records]
                    temp[index].reason = e.target.value
                    setRecords(temp)
                  }}
                  style={{ width: '25rem', margin: '5px 0px' }}
                />
                <div>
                  <div>
                    <Button
                      label="Save"
                      className="p-button-text p-button-primary p-ml-2"
                      icon="pi pi-send"
                      onClick={async () => {
                        toggelReasonTextbox(index, false)
                        const res = await updateOrderHistoryReason(item.id, item.reason)
                        if (res && res.status === 200) {
                          toast.current.show({
                            severity: 'success',
                            summary: 'Success',
                            detail: 'Reason Updated',
                          })
                        }
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      label="Cancel"
                      className="p-button-text p-button-danger p-ml-2"
                      icon="pi pi-times"
                      onClick={() => toggelReasonTextbox(index, false)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <small className="p-text-italic">Reason: {item?.reason}</small>
            )}
          </div>
          {item.masterid === user.id && (
            <>
              <small
                id="notToBePrinted"
                onClick={() => toggelReasonTextbox(index, true)}
                className="p-error"
                style={{ cursor: 'pointer' }}
              >
                Update Reason
              </small>
            </>
          )}
        </div>
      </div>
    )
  }

  const handlePrint = () => {
    // display none for all the elements which has id="notToBePrinted"
    const x = document.querySelectorAll('#notToBePrinted')
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = 'none'
    }
    let content = document.getElementById('contentToBePrinted')
    let pri = document.getElementById('ifmForPrint').contentWindow
    pri.document.open()
    pri.document.write(content.innerHTML)
    pri.document.close()
    pri.focus()
    pri.print()
    // display all the elements which has id="notToBePrinted"
    for (let i = 0; i < x.length; i++) {
      x[i].style.display = 'block'
    }
  }

  const fetchRecords = useCallback(async () => {
    if (!id) return
    setTableLoading && setTableLoading(true)
    setRecordsLoading(true)

    setVisible(true)
    const res = await getOrderHistory(id, {
      type: historyType,
    })
    if (res) {
      setTableLoading && setTableLoading(false)
      setRecordsLoading(false)
      if (res.status === 404 || res.status === 400) {
        handleCloseSidebar()
      }
      if (res.data) {
        setRecords(res.data)
      }
    }
  }, [id, historyType])

  useEffect(() => {
    fetchRecords()
  }, [fetchRecords])

  return (
    <div>
      <Toast ref={toast} />
      <Sidebar
        visible={visible}
        onHide={handleCloseSidebar}
        position={position}
        className="p-sidebar-md"
      >
        <div id="contentToBePrinted">
          <header>
            <div className="p-d-flex p-jc-between p-ai-center">
              <h4 className="p-m-0">
                {header} #{id}
              </h4>
              <Button
                id="notToBePrinted"
                label="Print"
                icon="pi pi-print"
                onClick={handlePrint}
                className="p-button-outlined invoice-button"
              />
            </div>
          </header>
          <hr />
          {recordsLoading ? (
            <p className="p-text-center">
              <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
            </p>
          ) : (
            <>
              {records.length > 0 ? (
                <div className="widget-timeline">
                  <div className="timeline-content">
                    <Timeline value={records} content={content} className="custimized-timeline" />
                  </div>
                </div>
              ) : (
                <h5 className="p-text-center">
                  <i className="pi pi-exclamation-circle p-mx-2" />
                  No History Found
                </h5>
              )}
            </>
          )}
        </div>
      </Sidebar>
      <iframe
        title="printFrame"
        id="ifmForPrint"
        style={{ height: '0px', width: '0px', position: 'absolute' }}
      />
    </div>
  )
}
