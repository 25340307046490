import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import { handleObjChange } from '../../../functions/setter'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputNumber } from 'primereact/inputnumber'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { InputSwitch } from 'primereact/inputswitch'
import { Message } from 'primereact/message'
import { Button } from 'primereact/button'
import { useGlobalContext } from '../../../context'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { getCouponById, patchCoupon } from '../../../api'
import ConfirmationDialogWithInput from '../Dialog/ConfirmationDialogWithInput'
import { Password } from 'primereact/password'
import classNames from 'classnames'

export default function EditCoupon({
  visibleEditCouponDialog,
  setVisibleEditCouponDialog,
  setTableLoading,
  setCoupons,
  coupons,
  fetchStrength,
  fetchQuantity,
  discountTypeOptions,
  typeOptions,
  rankOptions,
}) {
  const emptyErr = {
    state: false,
    errors: [],
  }

  const emptyRecord = {
    code: '',
    description: '',
    validFrom: null,
    validTo: null,
    discountType: '',
    discount: null,
    minOrderAmount: 0,
    maxDiscountAmount: 0,
    status: false,
    couponRank: null,
    isShownOnCheckout: false,
    type: null,
    categoryName: null,
    productCode: null,
    paymentMethodName: null,
    quantityCode: null,
    strengthCode: null,
  }
  const [editCouponError, setEditCouponError] = useState(emptyErr)
  const [editCoupon, setEditCoupon] = useState(emptyRecord)
  const { toast, productCategoriesWithIds, activeProductNames, paymentMethods } = useGlobalContext()
  const query = useQuery()
  const history = useHistory()
  const id = parseInt(query.get('id'))
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmationDialog, setPasswordConfirmationDialog] = useState(false)
  const [strengths, setStrengths] = useState([])
  const [quantities, setQuantities] = useState([])

  const getCoupon = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getCouponById(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setEditCoupon(res.data.record)
        setVisibleEditCouponDialog(true)
      }
    }
  }, [id])

  useEffect(() => {
    getCoupon()
  }, [getCoupon])


  const validateCoupon = (coupon) => {
    let err = []
    if (!coupon.code) {
      err.push('Coupon code is required')
    }
    if (!coupon.description) {
      err.push('Description is required')
    }
    if (!coupon.validFrom) {
      err.push('Valid from date required')
    }
    if (!coupon.validTo) {
      err.push('Valid to date required')
    }
    if (!coupon.discount) {
      err.push('Discount is required')
    }
    if (coupon.type !== 'Payment Method' && !coupon?.minOrderAmount) {
      err.push('Minimum order amount is required')
    }
    if (coupon.type !== 'Payment Method' && !coupon?.maxDiscountAmount) {
      err.push('Maximum discount amount is required')
    }
    if (!coupon.discountType) {
      err.push('Discount type is required')
    }
    if (!coupon.couponRank) {
      err.push('Coupon rank is required')
    }
    // Additional validation based on the type
    if (coupon.type === 'Payment Method' && !coupon?.paymentMethodName) {
      err.push('Payment Method is required')
    }
    if (coupon.type === 'Category' && !coupon?.categoryName) {
      err.push('Category is required')
    }
    if (coupon.type === 'Product' && !coupon?.productCode) {
      err.push('Product is required')
    }
    if (coupon.type === 'Strength') {
      if (!coupon?.productCode) err.push('Product is required')
      if (!coupon?.strengthCode) err.push('Strength is required')
    }
    if (coupon.type === 'Quantity') {
      if (!coupon?.productCode) err.push('Product is required')
      if (!coupon?.strengthCode) err.push('Strength is required')
      if (!coupon?.quantityCode) err.push('Quantity is required')
    }
    return err
  }

  const updateCoupon = async () => {
    const err = validateCoupon(editCoupon)
    if (err.length > 0) {
      setEditCouponError({
        state: true,
        errors: err,
      })
      return
    }
    setEditCouponError(emptyErr)
    setPasswordConfirmationDialog(true)
  }

  const ontDialogHide = () => {
    setVisibleEditCouponDialog(false)
    setEditCoupon(emptyRecord)
    setEditCouponError(emptyErr)
    setLoading(false)
    history.push({
      pathname: '/website/coupons',
    })
  }

  const onPasswordSubmitConfirmation = async () => {
    setLoading(true)
    const res = await patchCoupon(id, { password, ...editCoupon })
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: res.data.message,
        })
        const updatedCouponRecords = coupons.map((coupon) =>
          coupon.id === id ? res.data.record : coupon
        )
        setCoupons(updatedCouponRecords)
        ontDialogHide()
        onConfirmDialogHide()
      }
    }
  } 

  const onConfirmDialogHide = () => {
    setPasswordConfirmationDialog(false)
    setPassword('')
    setLoading(false)
  }

  const CategoryDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="category" className="">
          Select Category
        </label>
        <div>
          <Dropdown
            value={editCoupon?.categoryName}
            optionLabel="name"
            optionValue="name"
            filter
            filterBy="name"
            filterPlaceholder="Search..."
            options={productCategoriesWithIds}
            className=""
            placeholder="Select Category"
            onChange={(e) => {
              handleObjChange(setEditCoupon, 'categoryName', e.value)
            }}
          />
        </div>
      </div>
    )
  }

  const ProductDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="product" className="">
          Select product
        </label>
        <div>
          <Dropdown
            optionLabel="productName"
            optionValue="productCode"
            filter
            filterBy="productName"
            id="product"
            placeholder="Select product"
            options={activeProductNames}
            value={editCoupon?.productCode}
            onChange={(e) => {
              if (e.value.isStockOut) return
              setQuantities([])
              handleObjChange(setEditCoupon, 'productCode', e.value)
              if (editCoupon.type !== 'Product') fetchStrength(e.value, setStrengths)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.productName}
              </span>
            )}
          />
        </div>
      </div>
    )
  }

  const StrengthDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="strength" className="">
          Select strength
        </label>
        <div>
          <Dropdown
            id="strength"
            placeholder="Select strength"
            options={strengths}
            optionLabel="name"
            optionValue="code"
            value={editCoupon?.strengthCode}
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setEditCoupon, 'strengthCode', e.value)
              if (editCoupon.type === 'Quantity') fetchQuantity(e.value, setQuantities)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.name}
              </span>
            )}
          />
        </div>
      </div>
    )
  }

  const QuantityDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="quantity" className="">
          Select quantity
        </label>
        <div>
          <Dropdown
            id="quantity"
            placeholder="Select quantity"
            options={quantities}
            optionLabel="name"
            optionValue="code"
            value={editCoupon?.quantityCode}
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setEditCoupon, 'quantityCode', e.value)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.name}
              </span>
            )}
          />
        </div>
      </div>
    )
  }

  const PaymentMethodDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="paymentMethod" className="">
          Select method
        </label>
        <div>
          <Dropdown
            id="quantity"
            placeholder="Select method"
            options={paymentMethods}
            optionLabel="appName"
            optionValue="appName"
            value={editCoupon?.paymentMethodName}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': !option.status,
                })}
              >
                {option.appName}
              </span>
            )}
            onChange={(e) => {
              const item = paymentMethods.find((i) => i.appName === e.value)
              if (!item || !item.status) return
              handleObjChange(setEditCoupon, 'paymentMethodName', e.value)
            }}
          />
        </div>
      </div>
    )
  }

  const Footer = () => {
    return (
      <>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={ontDialogHide}
        />
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button button-primary"
          onClick={() => updateCoupon()}
        />
      </>
    )
  }
  console.log(editCoupon)
  return (
    <>
      <Dialog
        header={'Edit coupon'}
        style={{ width: '50vw' }}
        visible={visibleEditCouponDialog}
        onHide={ontDialogHide}
        footer={Footer}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="type" className="">
              Select type
            </label>
            <div>
              <Dropdown
                id="type"
                value={editCoupon.type}
                placeholder="Select type"
                onChange={(e) => {
                  setEditCoupon((ps) => ({
                    ...ps,
                    categoryName: null,
                    productCode: null,
                    paymentMethodName: null,
                    quantityCode: null,
                    strengthCode: null,
                  }))
                  handleObjChange(setEditCoupon, 'type', e.target.value)
                }}
                options={typeOptions}
              />
            </div>
          </div>
          {editCoupon.type === 'Category' && <CategoryDropDown />}
          {editCoupon.type === 'Product' && <ProductDropDown />}
          {editCoupon.type === 'Strength' && (
            <>
              <ProductDropDown /> <StrengthDropDown />
            </>
          )}
          {editCoupon.type === 'Quantity' && (
            <>
              <ProductDropDown /> <StrengthDropDown />
              <QuantityDropDown />
            </>
          )}
          {editCoupon.type === 'Payment Method' && <PaymentMethodDropDown />}
        </div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="code" className="p-col-fixed">
              Coupon Code
            </label>
            <InputText
              id="code"
              placeholder="Enter Code"
              type="text"
              className="p-text-uppercase"
              keyfilter="alphanum"
              value={editCoupon.code}
              onChange={(e) => handleObjChange(setEditCoupon, 'code', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="description" className="p-col-fixed">
              Description
            </label>
            <InputTextarea
              id="description"
              placeholder="Enter description"
              type="text"
              value={editCoupon.description}
              onChange={(e) => handleObjChange(setEditCoupon, 'description', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="discountType" className="p-col-fixed">
              Select Discount Type
            </label>
            <Dropdown
              placeholder="Select type"
              value={editCoupon.discountType}
              onChange={(e) => handleObjChange(setEditCoupon, 'discountType', e.value)}
              options={discountTypeOptions}
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="discount" className="p-col-fixed">
              Discount
            </label>
            <InputNumber
              id="discount"
              placeholder="Enter Discount"
              type="text"
              value={editCoupon.discount}
              onChange={(e) => handleObjChange(setEditCoupon, 'discount', e.value)}
              minFractionDigits={2}
              maxFractionDigits={2}
              suffix={editCoupon.discountType === 'Percentage' && '%'}
              prefix={editCoupon.discountType === 'Price' && '$'}
            />
          </div>
          {editCoupon.type !== 'Payment Method' && (
            <>
              <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
                <label htmlFor="minOrderAmount" className="p-col-fixed">
                  Minimum Order Amount
                </label>
                <InputNumber
                  id="minOrderAmount"
                  placeholder="Enter Amount"
                  value={editCoupon.minOrderAmount}
                  prefix="$"
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  onValueChange={(e) =>
                    handleObjChange(setEditCoupon, 'minOrderAmount', e.value || 0)
                  }
                />
              </div>
              <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
                <label htmlFor="maxDiscountAmount" className="p-col-fixed">
                  Maximum Discount Amount
                </label>
                <InputNumber
                  id="maxDiscountAmount"
                  placeholder="Enter Amount"
                  value={editCoupon.maxDiscountAmount}
                  prefix="$"
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  onValueChange={(e) =>
                    handleObjChange(setEditCoupon, 'maxDiscountAmount', e.value || 0)
                  }
                />
              </div>
            </>
          )}
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="validFrom" className="p-col-fixed">
              Valid From Date
            </label>
            <Calendar
              id="validFrom"
              placeholder="Select Date"
              value={editCoupon.validFrom ? new Date(editCoupon.validFrom) : ''}
              onChange={(e) => handleObjChange(setEditCoupon, 'validFrom', e.value)}
              showIcon
              showTime
              showSeconds
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="validTo" className="p-col-fixed">
              Valid To Date
            </label>
            <Calendar
              id="validTo"
              placeholder="Select Date"
              value={editCoupon.validTo ? new Date(editCoupon.validTo) : ''}
              onChange={(e) => handleObjChange(setEditCoupon, 'validTo', e.value)}
              showIcon
              showTime
              showSeconds
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="rank" className="">
              Select Rank
            </label>
            <div>
              <Dropdown
                id="rank"
                placeholder="Select Rank"
                value={editCoupon.couponRank}
                onChange={(e) => handleObjChange(setEditCoupon, 'couponRank', e.value)}
                options={rankOptions}
              />
            </div>
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
            <label htmlFor="isShownOnCheckout" className="">
              Show On Checkout
            </label>
            <div>
              <InputSwitch
                id="isShownOnCheckout"
                checked={editCoupon.isShownOnCheckout ? true : false}
                onChange={(e) => {
                  handleObjChange(setEditCoupon, 'isShownOnCheckout', e.value)
                }}
              />
            </div>
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
            <label htmlFor="status" className="">
              Inactive/Active
            </label>
            <div>
              <InputSwitch
                id="status"
                checked={editCoupon.status ? true : false}
                onChange={(e) => handleObjChange(setEditCoupon, 'status', e.value)}
              />
            </div>
          </div>
        </div>
        {editCouponError.state && (
          <>
            {editCouponError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setEditCouponError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
      {/* Confirmation dialog to ask password start*/}
      <ConfirmationDialogWithInput
        onHide={onConfirmDialogHide}
        header={'Confirm Password'}
        onSubmit={onPasswordSubmitConfirmation}
        visible={passwordConfirmationDialog}
        inputLabel={'Password'}
        customInputs={true}
        loading={loading}
      >
        <div className="">
          <label htmlFor="password" className="">
            Password
          </label>
          <Password
            autoComplete="nope"
            toggleMask
            feedback={false}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-mt-1"
            placeholder="Enter your password"
          />
        </div>
      </ConfirmationDialogWithInput>
      {/* Confirmation dialog to ask password end*/}
    </>
  )
}
