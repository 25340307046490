import { Toast } from 'primereact/toast'
import React, { useRef } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

export default function AxiosInterpector({ children }) {
  const toast = useRef(null)
  const history = useHistory()
  axios.interceptors.request.use(
    async function (config) {
      try {
        return config
      } catch (error) {
        console.error({ axiosError: error })
      }
    },
    function (error) {
      // Do something with request error
      console.error({ axiosError: error })
      return Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (res) => {
      // Add configurations here
      return res
    },
    (err) => {
      if (err.response.status === 401) {
        history.push('/access')
        toast.current.show({
          severity: 'warn',
          summary: 'Alert',
          detail: err.response.data.error,
          life: 7000,
        })
      } else if (err.response.status >= 500) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: err.response.data.error,
          life: 7000,
        })
      } else if (err.response.status >= 400) {
        toast.current.show({
          severity: 'warn',
          summary: 'Alert',
          detail: err.response.data.error,
          life: 7000,
        })
      }
      return Promise.reject(err)
    },
  )

  return (
    <div>
      <Toast ref={toast} />
      {children}
    </div>
  )
}
