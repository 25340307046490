import axios from 'axios'
import endpoints from './endpoints'

export const getMyself = async () => {
  try {
    const res = await axios.get(endpoints.master.myself)
    return res
  } catch (error) {
    return error.response
  }
}

export const getUsers = async () => {
  try {
    const res = await axios.get(endpoints.master.index)
    return res
  } catch (error) {
    return error.response
  }
}

export const getUser = async (id) => {
  try {
    return await axios.get(endpoints.master.index + id)
  } catch (error) {
    return error.response
  }
}

export const getAllHistory = async (params) => {
  try {
    let myParams = { ...params } || {}
    const res = await axios.get(endpoints.master.history, {
      params: myParams,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const masterOperaions = async (id, operation, password, params) => {
  try {
    const res = await axios.put(endpoints.master.operations, {
      id,
      operation,
      password,
      params,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const addUser = async (username, email, password, role) => {
  try {
    const res = await axios.post(endpoints.master.index, {
      username,
      email,
      password,
      role,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const requestOtp = async () => {
  try {
    const res = await axios.get(endpoints.master.otp)
    return res
  } catch (error) {
    return error.response
  }
}

export const validateOtp = async (otp) => {
  try {
    const res = await axios.post(endpoints.master.otp, { otp })
    return res
  } catch (error) {
    return error.response
  }
}

export const resetPassword = async (password, otp, newPassword) => {
  try {
    const res = await axios.patch(endpoints.master.resetpass, {
      password,
      otp,
      newPassword,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const postTeam = async (payload) => {
  try {
    return await axios.post(endpoints.master.teams, payload)
  } catch (error) {
    return error.response
  }
}

export const getTeam = async (id) => {
  try {
    return await axios.get(endpoints.master.teams + id)
  } catch (error) {
    return error.response
  }
}

export const getTeams = async (limit) => {
  try {
    return await axios.get(endpoints.master.teams, {
      params: { limit },
    })
  } catch (error) {
    return error.response
  }
}

export const patchTeam = async (id, payload) => {
  try {
    return await axios.patch(endpoints.master.teams + id, payload)
  } catch (error) {
    return error.response
  }
}

export const deleteTeam = async (id, password) => {
  try {
    const data = JSON.stringify({ password })
    return await axios.delete(endpoints.master.teams + id, { data })
  } catch (error) {
    return error.response
  }
}

export const patchAvatar = async ({ avatar }) => {
  try {
    const res = await axios.patch(endpoints.master.index, { avatar })
    return res
  } catch (error) {
    return error.response
  }
}
