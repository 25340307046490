import React, { useEffect, useRef, useState } from 'react'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { getContactsForFix, saveContactFix } from '../../api'
import { Column } from 'primereact/column'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { useGlobalContext } from '../../context'

function OrderContactFixPage() {
  const { toast } = useGlobalContext()
  const dt = useRef(null)

  const [contacts, setContacts] = useState([])
  const [fetching, setFetching] = useState(false)
  const fetchData = async () => {
    setFetching(true)
    const res = await getContactsForFix()
    if (res) {
      setFetching(false)
      if (res.status === 200) {
        setContacts(res.data)
      }
    }
  }
  useEffect(() => fetchData(), [])
  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    )
  }
  const cellEditor = (options) => {
    return textEditor(options)
  }
  const savePhone = async (id, phone, oldPhone) => {
    const res = await saveContactFix(id, phone, oldPhone)
    if (res) {
      if (res.status === 200) {
        setContacts((ps) =>
          ps.map((i) => {
            if (i.id === id) {
              i.status = 1
            }
            return i
          })
        )
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
          life: 3000,
        })
      }
    }
  }
  const onCellEditComplete = async (e) => {
    let { rowData, newValue, field, originalEvent: event } = e
    let id, phone, oldPhone
    id = rowData.id
    phone = newValue
    oldPhone = rowData.phone
    if (phone === oldPhone) return
    if (newValue.trim().length > 0) {
      rowData[field] = newValue
      await savePhone(id, phone, oldPhone)
    } else event.preventDefault()
  }

  return (
    <PageAllowedToRoles allowedRoles={['superadmin']}>
      <Toolbar
        left={
          <div>
            <h4 className="p-m-0">Contact Fix</h4>
          </div>
        }
        right={
          <div>
            <Button
              icon="pi pi-refresh"
              onClick={fetchData}
              loading={fetching}
              disabled={fetching}
            />
          </div>
        }
      />
      <DataTable
        ref={dt}
        value={contacts}
        showGridlines
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} contacts"
        filterDisplay="row"
      >
        <Column
          header="Id"
          sortable
          field="id"
          filter
          showFilterMenu={false}
          filterPlaceholder="Search by Id"
        />
        <Column
          header="Country"
          field="country"
          sortable
          filter
          filterPlaceholder="Search by Country"
        />
        <Column
          header="Phone"
          field="phone"
          sortable
          filter
          filterPlaceholder="Search by Phone"
          editor={(options) => cellEditor(options)}
          onCellEditComplete={onCellEditComplete}
        />
        <Column
          field="status"
          header="Status"
          sortable
          body={(rd) => (rd.status ? 'Reviewed' : 'Not Reviewed')}
        />
      </DataTable>
    </PageAllowedToRoles>
  )
}

export default OrderContactFixPage
