import Numlookupapi from '@everapi/numlookupapi-js'

const apiKey = process.env.REACT_APP_NUMLOOKUP_API_KEY

const numlookupClient = new Numlookupapi(apiKey)

export const numlookupPhoneValidate = async (phone) => {
  try {
    const response = await numlookupClient.validate(phone)
    return response
  } catch (err) {
    throw new Error(err)
  }
}
