import React from 'react'
import { useGlobalContext } from '../../context'
import AdminTargetsPage from './AdminTargetsPage'
import TeamTargetsPage from './TeamTargetsPage'
import AgentTargetsPage from './AgentTargetsPage'
export default function TargetsPage() {
  const { user } = useGlobalContext()
  if (['superadmin', 'admin', 'manager'].includes(user.role)) return <AdminTargetsPage />
  if (['teamlead'].includes(user.role)) return <TeamTargetsPage />
  if (['agent'].includes(user.role)) return <AgentTargetsPage />
  return null
}
