import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import { handleObjChange } from '../../../functions/setter'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import {
  deleteAwsS3Files,
  getWebsiteBannerById,
  putPaymentMethod,
  putWebsiteBanner,
  uploadFileToS3,
} from '../../../api'
import { useGlobalContext } from '../../../context'
import { Message } from 'primereact/message'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import UploadImage from '../../mini/UploadImage'
import generateS3Path from '../../../functions/generateS3Path'
import { Calendar } from 'primereact/calendar'
import { format, subDays } from 'date-fns'
import { Checkbox } from 'primereact/checkbox'

const emptyErr = {
  state: false,
  errors: [],
}

const emptyImageState = {
  url: '',
  file: null,
}

const emptyBannerObj = {
  bannerName: '',
  ctaLink: '',
  validFrom: '',
  validTo: '',
  bannerImage: '',
  active: false,
}

export default function EditBannerDialog({ bannerRecords, setBannerRecords, setTableLoading }) {
  const query = useQuery()
  const history = useHistory()
  const id = parseInt(query.get('id'))
  const { toast } = useGlobalContext()
  const [banner, setBanner] = useState(emptyBannerObj)
  const [updateRecordError, setUpdateRecordError] = useState(emptyErr)
  const [editBannerDialogVisible, setEditBannerDialogVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [bannerImage, setBannerImage] = useState(emptyImageState)
  const [bannerCopy, setBannerCopy] = useState(null)
  // fetch paymentMethod by id
  const fetchBannerById = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getWebsiteBannerById(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setBanner(res.data)
        setBannerCopy(res.data)
        setEditBannerDialogVisible(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchBannerById()
  }, [fetchBannerById])

  const validateUpdateRecord = () => {
    const errs = []
    const { bannerName, ctaLink, validFrom, validTo } = banner
    if (!bannerName) {
      errs.push('Banner name is mandatory')
    }
    if (!ctaLink) {
      errs.push('Please enter a link')
    }
    if (!validFrom) {
      errs.push('Valid from cannot be empty')
    }
    if (!validTo) {
      errs.push('Valid to cannot be empty')
    }
    if (!banner.bannerImage) {
      if (!bannerImage.url) {
        errs.push('Banner image cannot be empty')
      }
    }
    return errs
  }
  const editBanner = async () => {
    try {
      //make sure all the fields does have the values
      const errs = validateUpdateRecord(banner)
      if (errs.length > 0) {
        setLoading(false)
        setUpdateRecordError({ state: true, errors: errs })
        return
      }
      setLoading(true)
      const deletePaths = []
      const uploadPromises = []
      const data = banner
      //check if there are any new files to upload
      if (bannerImage.file) {
        deletePaths.push(bannerCopy.bannerImage)
        uploadPromises.push(
          uploadFileToS3({ file: bannerImage.file }).then(
            (res) => (data.bannerImage = res.data.filePath)
          )
        )
      }
      //upload new files to awsS3
      await Promise.all(uploadPromises)
      // delete old paths from awsS3 if new files are added
      if (deletePaths.length > 0) {
        await deleteAwsS3Files(deletePaths)
      }
      setTableLoading(true)
      //make the api call for creating payment method
      const res = await putWebsiteBanner(data, id)
      if (res) {
        setLoading(false)
        setTableLoading(false)
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
            life: 3000,
          })
          const updatedData = bannerRecords.map((payment) =>
            payment.id === id ? res.data.record : payment
          )
          setBannerRecords(updatedData)
          onHide()
        }
      }
    } catch (error) {
      setLoading(false)
      setUpdateRecordError({ state: true, errors: [error.message] })
    }
  }

  const onHide = () => {
    setEditBannerDialogVisible(false)
    setBanner(emptyBannerObj)
    setBannerCopy(null)
    setUpdateRecordError(emptyErr)
    setBannerImage(emptyImageState)
    history.push({
      pathname: '/website/banners',
    })
  }

  const footer = () => {
    return (
      <div>
        <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-secondary" />
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-primary"
          onClick={editBanner}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <Dialog
      header="Update Banner"
      visible={editBannerDialogVisible}
      style={{ width: '50vw' }}
      onHide={onHide}
      footer={footer}
    >
      <div className="p-grid p-formgrid  p-ai-center p-col-12">
        <div className="p-field p-fluid p-col-12">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            placeholder="Enter Banner Name"
            value={banner.bannerName}
            onChange={(e) => {
              handleObjChange(setBanner, 'bannerName', e.target.value)
            }}
          />
        </div>
        <div className="p-field p-fluid p-col-12">
          <label htmlFor="name">CTA Link</label>
          <InputText
            id="name"
            placeholder="Add Link"
            value={banner.ctaLink}
            onChange={(e) => handleObjChange(setBanner, 'ctaLink', e.target.value)}
          />
        </div>
        <div className="p-field p-fluid p-col-12 p-lg-6">
          <label className="p-d-block" htmlFor="validFrom">
            Valid From
          </label>
          <Calendar
            id="validFrom"
            placeholder="Valid From"
            minDate={subDays(new Date(), 0)}
            value={new Date(banner.validFrom)}
            onChange={(e) => {
              const date = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : ''
              handleObjChange(setBanner, 'validFrom', date)
            }}
          />
        </div>
        <div className="p-field p-fluid p-col-12 p-lg-6 ">
          <label className="p-d-block" htmlFor="ValidTo">
            Valid To
          </label>
          <Calendar
            id="validTo"
            placeholder="Valid To"
            minDate={subDays(new Date(), 0)}
            value={new Date(banner.validTo)}
            onChange={(e) => {
              const date = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : ''
              handleObjChange(setBanner, 'validTo', date)
            }}
          />
        </div>
        {banner?.bannerImage ? (
          <div className="p-fluid p-field p-col-12 p-lg-6">
            <label htmlFor="" className="p-d-block">
              Banner Image
            </label>
            <div className="card p-text-center">
              <img
                className="w-full"
                style={{ borderRadius: '10px 10px 10px 10px' }}
                src={generateS3Path(banner.bannerImage)}
                alt=""
              />
              <Button
                label="Change Image"
                icon="pi pi-pencil"
                onClick={() => handleObjChange(setBanner, 'bannerImage', '')}
              />
            </div>
          </div>
        ) : (
          <div className="p-fluid p-field p-col-12 p-lg-6">
            <label htmlFor="" className="p-col-fixed">
              Banner Image
            </label>
            <UploadImage imageState={bannerImage} setImageState={setBannerImage} />
          </div>
        )}

        <div className="p-field p-fluid p-col-12 p-lg-6">
          <label
            className="p-d-none p-d-lg-inline"
            htmlFor="active"
            style={{ color: 'transparent' }}
          >
            Active
          </label>
          <div className="p-d-flex p-ai-center">
            <label className="p-d-block p-mr-2" htmlFor="active">
              Active :
            </label>
            <Checkbox
              onChange={(e) => handleObjChange(setBanner, 'active', e.checked)}
              checked={banner.active}
            ></Checkbox>
          </div>
        </div>
      </div>
      {updateRecordError.state && (
        <>
          {updateRecordError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setUpdateRecordError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
    </Dialog>
  )
}
