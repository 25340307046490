import React from 'react'
// RequiredMessage component to show the required message for input
export default function RequiredMessage({ value }) {
  /**
   * value @type {any} - value to check
   **/
  let valid
  if (!value) {
    valid = false
  } else {
    if (value.isArray && value.length === 0) {
      valid = false
    } else {
      valid = true
    }
  }

  return (
    <small id="" className="p-error p-inline-message-text">
      {!valid && 'Required'}
    </small>
  )
}
