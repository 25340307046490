import React, { useState, useEffect, useCallback } from 'react'
import ActivitiesDataTable from '../../components/mycomponents/ActivitiesDataTable'
import { getAllHistory, getUsers } from '../../api'
import { useGlobalContext } from '../../context'
import { assignDates, dateFilters } from '../../functions/myDateFns'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'

export default function AllActivities() {
  const { user } = useGlobalContext()
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))

  const [activities, setActivities] = useState([])
  const [agents, setAgents] = useState([])
  const [agentNames, setAgentNames] = useState([])

  /* Loading */
  const [loading, setLoading] = useState(false)

  const pageType = 'AllActivities'

  const getActivities = useCallback(async () => {
    setLoading(true)
    const res = await getAllHistory({ ...dateFilter })
    if (res && res.status === 200) {
      setActivities(res.data)
    } else {
      setActivities([])
    }
    setLoading(false)
  }, [dateFilter])

  useEffect(() => {
    if (!['superadmin', 'admin'].includes(user.role)) return
    const fetchData = async () => {
      const res = await getUsers()
      if (res && res.status === 200) {
        setAgents(res.data)
        setAgentNames(['All', ...res.data.map((item) => item.username)])
      }
    }
    fetchData()
  }, [user.role])

  useEffect(() => {
    getActivities()
  }, [getActivities])

  return (
    <ActivitiesDataTable
      activities={activities}
      setActivities={setActivities}
      getActivities={getActivities}
      agentNames={agentNames}
      agents={agents}
      dateFilter={dateFilter}
      setDateFilter={setDateFilter}
      pageType={pageType}
      loading={loading}
      setLoading={setLoading}
    />
  )
}
