import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useCallback, useEffect, useState } from 'react'
import { CustomerDetails } from './CustomerDetails'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Dropdown } from 'primereact/dropdown'
import { useGlobalContext } from '../../../context'
import { addCustomerEnquireFeedback, getProductNames } from '../../../api'
import { Checkbox } from 'primereact/checkbox'
import { Timeline } from 'primereact/timeline'
import { InputTextarea } from 'primereact/inputtextarea'
import country_name, { objectifiedData } from '../../../data/country_name'
import { InputNumber } from 'primereact/inputnumber'

export const CustomerCaseEditDialog = ({
  id,
  enquiryData,
  customizedMarker,
  customizedContent,
  editable,
  hideEditableDialog,
  updateCustomerEnquiry,
  setEnquiryData,
  acceptabilityOptions,
  toast,
}) => {
  const { user, agents, productTypes, productCategories, productSubCategories } = useGlobalContext()

  const genderOptions = [
    { name: 'Male', value: 'Male' },
    { name: 'Female', value: 'Female' },
  ]

  const leadType = [
    { name: 'Buy Lead', value: 'Buy Lead' },
    { name: 'Direct Lead', value: 'Direct Lead' },
  ]
  const shiftsOptions = [
    { name: 'Day Shift', value: 'Day' },
    { name: 'Night Shift', value: 'Night' },
  ]
  const catelougeOptions = ['Selco', 'Supreme']

  const disposalOptions = [
    'Followup',
    'Not Answer',
    'Not Interested',
    'Quote Shared',
    'High Price',
    'Venmo Dropped',
    'Already Purchased',
    'Do not call',
    'Wrong Contact',
    'Sale Done',
    'Order Cancelled',
    'No Followup',
    'Trust Issues',
    'No Notes',
    'Payment Confirmation',
  ]

  const enquiryScoreOptions = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
  ]

  const ratingOptions = [
    { name: '1 Star', value: 1 },
    { name: '2 Star', value: 2 },
    { name: '3 Star', value: 3 },
    { name: '4 Star', value: 4 },
    { name: '5 Star', value: 5 },
  ]

  const newFeedback_empty = {
    feedback: '',
    visible: false,
    loading: false,
  }

  const [newFeedback, setNewFeedback] = useState(newFeedback_empty)

  const [productNames, setProductNames] = useState([])

  const getProducts = useCallback(async () => {
    const res = await getProductNames()
    if (res && res.status === 200) {
      setProductNames(res.data)
    }
  }, [])

  useEffect(() => {
    getProducts()
  }, [getProducts])

  // add feedback function
  const handleFeedbackSave = async () => {
    onChangeNewFeedback('loading', true)
    const res = await addCustomerEnquireFeedback(id, {
      feedback: newFeedback.feedback,
    })
    if (res) {
      onChangeNewFeedback('loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation succesful',
          detail: res.data.message,
        })
        setNewFeedback(newFeedback_empty)
        setEnquiryData((ps) => ({
          ...ps,
          feedbacks: [res.data.feedback, ...ps.feedbacks],
        }))
      }
    }
  }

  // handle nested object on change
  const handleNestedObject = (setter, key, value) => {
    setter((ps) => ({
      ...ps,
      enquiry: {
        ...ps.enquiry,
        [key]: value,
      },
    }))
  }

  const onChangeNewFeedback = (target, value) => {
    setNewFeedback((ps) => ({ ...ps, [target]: value }))
  }

  return (
    <div>
      <Dialog
        header={`Update Customer Enquiry #${id}`}
        visible={editable}
        onHide={hideEditableDialog}
        style={{ width: '60%' }}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              onClick={hideEditableDialog}
              icon="pi pi-times"
            />
            <Button
              label="Save"
              className=" p-button-primary"
              onClick={updateCustomerEnquiry}
              icon="pi pi-save"
            />
          </div>
        }
      >
        <div>
          {/* customer details  */}
          <CustomerDetails enquiryData={enquiryData} />
          {/* enter customer details  */}
          <div className="enterCustomerDetails p-mt-4">
            <div className="p-grid p-fluid p-mt-2 p-my-3">
              <div className="p-lg-8 p-col-12 p-mb-3">
                <h5 className="p-mt-5 p-mb-4">Enter Details</h5>
                <div className="p-grid p-fluid p-mt-2 p-my-3">
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Customer Name</p>
                    <InputText
                      value={enquiryData?.enquiry?.name}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'name', e.target.value)}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Catelogue</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.catelogue}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'catelogue', e.target.value)
                      }
                      options={catelougeOptions}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Shifts</p>
                    <MultiSelect
                      value={enquiryData.enquiry.shifts}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'shifts', e.target.value)}
                      options={shiftsOptions}
                      optionLabel="name"
                      optionValue="value"
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Lead Type</p>
                    <Dropdown
                      optionLabel="name"
                      optionValue="value"
                      value={enquiryData?.enquiry?.leadType}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'leadType', e.target.value)
                      }
                      options={leadType}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Map Product</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.mapProduct}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'mapProduct', e.target.value)
                      }
                      options={productTypes}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Enquiry Score</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.enquiryScore}
                      optionLabel="name"
                      optionValue="value"
                      options={enquiryScoreOptions}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'enquiryScore', e.target.value)
                      }
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Disposals</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.disposal}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'disposal', e.target.value)
                      }
                      options={disposalOptions}
                    />
                  </div>

                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Tag To</p>
                    <MultiSelect
                      optionValue="id"
                      optionLabel="username"
                      value={enquiryData?.enquiry?.agentIds}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'agentIds', e.target.value)
                      }
                      options={agents}
                      filter
                      filterBy="username"
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Product</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.product}
                      optionLabel="productName"
                      optionValue="productName"
                      options={productNames}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'product', e.target.value)
                      }
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Category</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.category}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'category', e.target.value)
                      }
                      options={productCategories}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Sub category</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.subCategory}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'subCategory', e.target.value)
                      }
                      options={productSubCategories}
                      id="subCategory"
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Country</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.country}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'country', e.target.value)
                      }
                      options={objectifiedData}
                      optionLabel="name"
                      optionValue="name"
                      filter
                      filterBy="name"
                      editable
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Gender</p>
                    <Dropdown
                      optionLabel="name"
                      optionValue="value"
                      value={enquiryData?.enquiry?.gender}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'gender', e.target.value)}
                      options={genderOptions}
                    />
                  </div>
                  <div className="p-lg-3 p-mb-3">
                    <p className="p-mb-2">Age</p>
                    <InputNumber
                      value={enquiryData?.enquiry?.age}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'age', e.value)}
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Budget</p>
                    <InputNumber
                      value={enquiryData?.enquiry?.budget}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'budget', e.value)}
                    />
                  </div>
                  <div className="p-col p-lg-3 p-mb-3">
                    <p className="p-mb-2">Quantity</p>
                    <InputNumber
                      value={enquiryData?.enquiry?.quantity}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'quantity', e.value)}
                    />
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <p className="p-mb-2">Rate</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.rate}
                      onChange={(e) => handleNestedObject(setEnquiryData, 'rate', e.target.value)}
                      options={ratingOptions}
                      optionLabel="name"
                      optionValue="value"
                    />
                  </div>
                  <div className="p-lg-3 p-col p-mb-3">
                    <p className="p-mb-2">Acceptability</p>
                    <Dropdown
                      value={enquiryData?.enquiry?.acceptibility}
                      onChange={(e) =>
                        handleNestedObject(setEnquiryData, 'acceptibility', e.target.value)
                      }
                      options={acceptabilityOptions}
                      optionGroupChildren="items"
                      optionGroupLabel="label"
                    />
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.firstResponse === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'firstResponse', newValue)
                      }}
                      inputId="firstResponse"
                    ></Checkbox>
                    <label htmlFor="firstResponse" className="p-pl-2 p-checkbox-label">
                      First Response
                    </label>
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.followups === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'followups', newValue)
                      }}
                      inputId="followups"
                    ></Checkbox>
                    <label htmlFor="followups" className="p-pl-2 p-checkbox-label">
                      Followups
                    </label>
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.notes === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'notes', newValue)
                      }}
                      inputId="notes"
                    ></Checkbox>
                    <label htmlFor="notes" className="p-pl-2 p-checkbox-label">
                      Notes
                    </label>
                  </div>
                  <div className="p-col-3 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.clientResponse === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'clientResponse', newValue)
                      }}
                      inputId="clientResponse"
                    ></Checkbox>
                    <label htmlFor="clientResponse" className="p-pl-2 p-checkbox-label">
                      Client Response
                    </label>
                  </div>
                  <div className="p-col-6 p-mb-3">
                    <Checkbox
                      className="p-mt-5"
                      checked={enquiryData?.enquiry?.goodConvowithClient === 1}
                      onChange={(e) => {
                        const newValue = e.target.checked ? 1 : 0
                        handleNestedObject(setEnquiryData, 'goodConvowithClient', newValue)
                      }}
                      inputId="goodConvowithClient"
                    ></Checkbox>
                    <label htmlFor="goodConvowithClient" className="p-pl-2 p-checkbox-label">
                      Good conversation with Client
                    </label>
                  </div>
                </div>
              </div>
              {/* feedbacks section  */}
              <div className="p-lg-4 p-col-12 p-mb-3">
                <h5 className="p-mt-5">Feedbacks</h5>
                <InputTextarea
                  className="p-mt-5 p-mb-2"
                  value={newFeedback?.feedback}
                  onChange={(e) => onChangeNewFeedback('feedback', e.target.value)}
                  placeholder="Feedback"
                />
                <Button
                  label={newFeedback?.loading ? 'Adding Feedback' : 'Add Feedback'}
                  icon="pi pi-plus"
                  onClick={handleFeedbackSave}
                  loading={newFeedback?.loading}
                  disabled={newFeedback?.loading || !newFeedback?.feedback}
                />
                <div className="widget-timeline p-mt-5">
                  <div className="timeline-content">
                    <Timeline
                      value={enquiryData?.feedbacks}
                      marker={customizedMarker}
                      content={customizedContent}
                      className="custimized-timeline"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* enter customer details  */}
        </div>
      </Dialog>
    </div>
  )
}
