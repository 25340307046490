import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useGlobalContext } from '../../../context'
import { getPaymentMethods, postCoupon, searchQuantity, searchStrength } from '../../../api'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { handleObjChange } from '../../../functions/setter'
import { InputSwitch } from 'primereact/inputswitch'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { InputNumber } from 'primereact/inputnumber'
import { InputTextarea } from 'primereact/inputtextarea'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import ConfirmationDialogWithInput from '../Dialog/ConfirmationDialogWithInput'
import { Password } from 'primereact/password'
import classNames from 'classnames'

export default function AddCoupon({
  setCoupons,
  visibleCouponDialog,
  setVisibleCouponDialog,
  fetchStrength,
  fetchQuantity,
  discountTypeOptions,
  typeOptions,
  rankOptions,
}) {
  const emptyErr = {
    state: false,
    errors: [],
  }

  const emptyRecord = {
    code: '',
    description: '',
    validFrom: null,
    validTo: null,
    discountType: '',
    discount: null,
    minOrderAmount: 0,
    maxDiscountAmount: 0,
    status: false,
    couponRank: null,
    isShownOnCheckout: false,
    type: 'All',
    categoryName: null,
    productCode: null,
    paymentMethodName: null,
    quantityCode: null,
    strengthCode: null,
  }

  const [newCoupon, setNewCoupon] = useState(emptyRecord)
  const { toast, productCategoriesWithIds, paymentMethods, activeProductNames } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmationDialog, setPasswordConfirmationDialog] = useState(false)
  const [newCouponError, setNewCouponError] = useState(emptyErr)
  const [strengths, setStrengths] = useState([])
  const [quantities, setQuantities] = useState([])


  const validateCoupon = (coupon) => {
    let err = []
    if (!coupon.code) {
      err.push('Coupon code is required')
    }
    if (!coupon.description) {
      err.push('Description is required')
    }
    if (!coupon.validFrom) {
      err.push('Valid from date required')
    }
    if (!coupon.validTo) {
      err.push('Valid to date required')
    }
    if (!coupon.discount) {
      err.push('Discount is required')
    }
    if (coupon.type !== 'Payment Method' && !coupon.minOrderAmount) {
      err.push('Minimum order amount is required')
    }
    if (coupon.type !== 'Payment Method' && !coupon.maxDiscountAmount) {
      err.push('Maximum discount amount is required')
    }
    if (!coupon.discountType) {
      err.push('Discount type is required')
    }
    if (!coupon.couponRank) {
      err.push('Coupon rank is required')
    }
    // Additional validation based on the type
    if (coupon.type === 'Payment Method' && !coupon.paymentMethodName) {
      err.push('Payment Method is required')
    }
    if (coupon.type === 'Category' && !coupon.categoryName) {
      err.push('Category is required')
    }
    if (coupon.type === 'Product' && !coupon.productCode) {
      err.push('Product is required')
    }
    if (coupon.type === 'Strength') {
      if (!coupon.productCode) err.push('Product is required')
      if (!coupon.strengthCode) err.push('Strength is required')
    }
    if (coupon.type === 'Quantity') {
      if (!coupon.productCode) err.push('Product is required')
      if (!coupon.strengthCode) err.push('Strength is required')
      if (!coupon.quantityCode) err.push('Quantity is required')
    }
    return err
  }


  const createCoupon = async () => {
    const err = validateCoupon(newCoupon)
    if (err.length > 0) {
      setNewCouponError({
        state: true,
        errors: err,
      })
      return
    }
    setNewCouponError(emptyErr)
    setPasswordConfirmationDialog(true)
  }

  const onPasswordSubmitConfirmation = async () => {
    setLoading(true)
    const res = await postCoupon({ password, ...newCoupon })
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: res.data.message,
        })
        setVisibleCouponDialog(false)
        setCoupons((ps) => [res.data.record, ...ps])
        setNewCoupon(emptyRecord)
        onConfirmDialogHide()
      }
    }
  }

  const onConfirmDialogHide = () => {
    setPasswordConfirmationDialog(false)
    setPassword('')
    setLoading(false)
  }

  const onHideDialog = () => {
    setVisibleCouponDialog(false)
    setNewCoupon(emptyRecord)
    setNewCouponError(emptyErr)
    setLoading(false)
  }

  const CategoryDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="category" className="">
          Select Category
        </label>
        <div>
          <Dropdown
            value={newCoupon?.categoryName}
            optionLabel="name"
            optionValue="name"
            filter
            filterBy="name"
            filterPlaceholder="Search..."
            options={productCategoriesWithIds}
            className=""
            placeholder="Select Category"
            onChange={(e) => {
              handleObjChange(setNewCoupon, 'categoryName', e.value)
            }}
          />
        </div>
      </div>
    )
  }

  const ProductDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="product" className="">
          Select product
        </label>
        <div>
          <Dropdown
            optionLabel="productName"
            optionValue="productCode"
            filter
            filterBy="productName"
            id="product"
            placeholder="Select product"
            options={activeProductNames}
            value={newCoupon?.productCode}
            onChange={(e) => {
              if (e.value.isStockOut) return
              setQuantities([])
              handleObjChange(setNewCoupon, 'productCode', e.value)
              if (newCoupon.type !== 'Product') fetchStrength(e.value, setStrengths)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.productName}
              </span>
            )}
          />
        </div>
      </div>
    )
  }

  const StrengthDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="strength" className="">
          Select strength
        </label>
        <div>
          <Dropdown
            id="strength"
            placeholder="Select strength"
            options={strengths}
            optionLabel="name"
            optionValue="code"
            value={newCoupon?.strengthCode}
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setNewCoupon, 'strengthCode', e.value)
              if (newCoupon.type === 'Quantity') fetchQuantity(e.value, setQuantities)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.name}
              </span>
            )}
          />
        </div>
      </div>
    )
  }

  const QuantityDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="quantity" className="">
          Select quantity
        </label>
        <div>
          <Dropdown
            id="quantity"
            placeholder="Select quantity"
            options={quantities}
            optionLabel="name"
            optionValue="code"
            value={newCoupon?.quantityCode}
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setNewCoupon, 'quantityCode', e.value)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.name}
              </span>
            )}
          />
        </div>
      </div>
    )
  }

  const PaymentMethodDropDown = () => {
    return (
      <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
        <label htmlFor="paymentMethod" className="">
          Select method
        </label>
        <div>
          <Dropdown
            id="paymentMethod"
            placeholder="Select method"
            options={paymentMethods}
            optionLabel="appName"
            optionValue="appName"
            value={newCoupon?.paymentMethodName}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': !option.status,
                })}
              >
                {option.appName}
              </span>
            )}
            onChange={(e) => {
              const item = paymentMethods.find((i) => i.appName === e.value)
              if (!item || !item.status) return
              handleObjChange(setNewCoupon, 'paymentMethodName', e.value)
            }}
          />
        </div>
      </div>
    )
  }

  const Footer = () => {
    return (
      <>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={onHideDialog}
        />
        <Button
          label="Submit"
          icon="pi pi-save"
          className="p-button button-primary"
          onClick={() => createCoupon()}
        />
      </>
    )
  }

  return (
    <>
      <Dialog
        header={'Add coupon'}
        style={{ maxWidth: '50vw' }}
        visible={visibleCouponDialog}
        onHide={onHideDialog}
        footer={Footer}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="type" className="">
              Select type
            </label>
            <div>
              <Dropdown
                id="type"
                value={newCoupon.type}
                placeholder="Select type"
                onChange={(e) => {
                  setNewCoupon((ps) => ({
                    ...ps,
                    category: null,
                    productCode: null,
                    paymentMethod: null,
                    quantityCode: null,
                    strengthCode: null,
                  }))
                  handleObjChange(setNewCoupon, 'type', e.target.value)
                }}
                options={typeOptions}
              />
            </div>
          </div>
          {newCoupon.type === 'Category' && <CategoryDropDown />}
          {newCoupon.type === 'Product' && <ProductDropDown />}
          {newCoupon.type === 'Strength' && (
            <>
              <ProductDropDown /> <StrengthDropDown />
            </>
          )}
          {newCoupon.type === 'Quantity' && (
            <>
              <ProductDropDown /> <StrengthDropDown />
              <QuantityDropDown />
            </>
          )}
          {newCoupon.type === 'Payment Method' && <PaymentMethodDropDown />}
        </div>
        <div className="p-fluid p-formgrid p-grid p-mt-4">
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="code" className="p-col-fixed">
              Coupon Code
            </label>
            <InputText
              id="code"
              placeholder="Enter Code"
              type="text"
              className="p-text-uppercase"
              keyfilter="alphanum"
              value={newCoupon.code}
              onChange={(e) => handleObjChange(setNewCoupon, 'code', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="description" className="p-col-fixed">
              Description
            </label>
            <InputTextarea
              id="description"
              placeholder="Enter description"
              type="text"
              value={newCoupon.description}
              onChange={(e) => handleObjChange(setNewCoupon, 'description', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="discountType" className="p-col-fixed">
              Select Discount Type
            </label>
            <Dropdown
              placeholder="Select type"
              value={newCoupon.discountType}
              onChange={(e) => handleObjChange(setNewCoupon, 'discountType', e.value)}
              options={discountTypeOptions}
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="discount" className="p-col-fixed">
              Discount
            </label>
            <InputNumber
              id="discount"
              placeholder="Enter Discount"
              type="text"
              value={newCoupon.discount}
              onChange={(e) => handleObjChange(setNewCoupon, 'discount', e.value)}
              minFractionDigits={2}
              maxFractionDigits={2}
              suffix={newCoupon.discountType === 'Percentage' && '%'}
              prefix={newCoupon.discountType === 'Price' && '$'}
            />
          </div>
          {newCoupon.type !== 'Payment Method' && (
            <>
              <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
                <label htmlFor="minOrderAmount" className="p-col-fixed">
                  Minimum Order Amount
                </label>
                <InputNumber
                  id="minOrderAmount"
                  placeholder="Enter Amount"
                  value={newCoupon.minOrderAmount}
                  prefix="$"
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  onValueChange={(e) =>
                    handleObjChange(setNewCoupon, 'minOrderAmount', e.value || 0)
                  }
                />
              </div>
              <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
                <label htmlFor="maxDiscountAmount" className="p-col-fixed">
                  Maximum Discount Amount
                </label>
                <InputNumber
                  id="maxDiscountAmount"
                  placeholder="Enter Amount"
                  value={newCoupon.maxDiscountAmount}
                  prefix="$"
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  onValueChange={(e) =>
                    handleObjChange(setNewCoupon, 'maxDiscountAmount', e.value || 0)
                  }
                />
              </div>
            </>
          )}
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="validFrom" className="p-col-fixed">
              Valid From Date
            </label>
            <Calendar
              id="validFrom"
              placeholder="Select Date"
              value={newCoupon.validFrom}
              onChange={(e) => handleObjChange(setNewCoupon, 'validFrom', e.value)}
              showIcon
              showTime
              showSeconds
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="validTo" className="p-col-fixed">
              Valid To Date
            </label>
            <Calendar
              id="validTo"
              placeholder="Select Date"
              value={newCoupon.validTo}
              onChange={(e) => handleObjChange(setNewCoupon, 'validTo', e.value)}
              showIcon
              showTime
              showSeconds
            />
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-6 p-mb-4">
            <label htmlFor="rank" className="">
              Select Rank
            </label>
            <div>
              <Dropdown
                id="rank"
                value={newCoupon.couponRank}
                placeholder="Select Rank"
                onChange={(e) => handleObjChange(setNewCoupon, 'couponRank', e.value)}
                options={rankOptions}
              />
            </div>
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
            <label htmlFor="isShownOnCheckout" className="">
              Show On Checkout
            </label>
            <div>
              <InputSwitch
                id="isShownOnCheckout"
                checked={newCoupon.isShownOnCheckout}
                onChange={(e) => handleObjChange(setNewCoupon, 'isShownOnCheckout', e.value)}
              />
            </div>
          </div>
          <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
            <label htmlFor="status" className="">
              Inactive/Active
            </label>
            <div>
              <InputSwitch
                id="status"
                checked={newCoupon.status}
                onChange={(e) => handleObjChange(setNewCoupon, 'status', e.value)}
              />
            </div>
          </div>
        </div>
        {newCouponError.state && (
          <>
            {newCouponError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewCouponError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
      {/* Confirmation dialog to ask password start*/}
      <ConfirmationDialogWithInput
        onHide={onConfirmDialogHide}
        header={'Confirm Password'}
        onSubmit={onPasswordSubmitConfirmation}
        visible={passwordConfirmationDialog}
        inputLabel={'Password'}
        customInputs={true}
        loading={loading}
      >
        <div className="">
          <label htmlFor="password" className="">
            Password
          </label>
          <Password
            autoComplete="nope"
            toggleMask
            feedback={false}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-mt-1"
            placeholder="Enter your password"
          />
        </div>
      </ConfirmationDialogWithInput>
      {/* Confirmation dialog to ask password end*/}
    </>
  )
}
