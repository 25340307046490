import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useEffect, useRef, useState } from 'react'
import { addWebsiteIp, deleteWebsiteIp, getWebsiteIps, patchWebsiteIp } from '../../api'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { useGlobalContext } from '../../context'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'
import { Dropdown } from 'primereact/dropdown'
import { handleObjChange } from '../../functions/setter'
import { Checkbox } from 'primereact/checkbox'

const typeOptions = ['country', 'ip']
function LocationWhitelistPage() {
  const { toast } = useGlobalContext()
  const dt = useRef(null)
  const emptyRecord = {
    visible: false,
    loading: false,
    name: '',
    type: '',
    value: '',
    active: false,
    id: 0,
  }
  const [lastViewed, setLastViewed] = useState(null)
  const [newRecord, setNewRecord] = useState(emptyRecord)
  const [editRecord, setEditRecord] = useState(emptyRecord)
  const [deleteRecord, setDeleteRecord] = useState(emptyRecord)
  const [tableLoading, setTableLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [password, setPassword] = useState('')
  const hideNewRecord = () => {
    setNewRecord(emptyRecord)
    setPassword('')
  }
  const hideEditRecord = () => {
    setEditRecord(emptyRecord)
    setPassword('')
  }
  const hideDeleteRecord = () => {
    setDeleteRecord(emptyRecord)
    setPassword('')
  }
  const handleSaveNewRecord = async () => {
    handleObjChange(setNewRecord, 'loading', true)
    const res = await addWebsiteIp({
      name: newRecord.name,
      type: newRecord.type,
      value: newRecord.value,
      active: newRecord.active,
      password,
    })
    if (res) {
      handleObjChange(setNewRecord, 'loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setRecords((ps) => [...ps, res.data.data])
        hideNewRecord()
      }
    }
  }
  const handleSaveEditRecord = async () => {
    handleObjChange(setEditRecord, 'loading', true)
    const res = await patchWebsiteIp(editRecord.id, {
      name: editRecord.name,
      type: editRecord.type,
      value: editRecord.value,
      active: editRecord.active,
      password,
    })
    if (res) {
      handleObjChange(setEditRecord, 'loading', false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        let ps = [...records].map((i) => {
          if (i.id === res.data.id) {
            return res.data.data
          }
          return i
        })
        setRecords(ps)
        hideEditRecord()
      }
    }
  }
  const handleDeleteRecord = async () => {
    setDeleteRecord((ps) => ({ ...ps, loading: true }))
    const res = await deleteWebsiteIp(deleteRecord.id, password)
    if (res) {
      setDeleteRecord((ps) => ({ ...ps, loading: false }))
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setRecords((ps) => ps.filter((i) => i.id !== res.data.id))
        hideDeleteRecord()
      }
    }
  }

  const fetchData = async () => {
    setTableLoading(true)
    const res = await getWebsiteIps()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setRecords(res.data)
      }
    }
  }

  useEffect(() => fetchData(), [])
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'operations', 'manager']}>
      <div>
        <Toast ref={toast} />
        <Toolbar
          className="p-toolbar p-mb-2"
          left={() => (
            <div className="p-d-flex gap-1 p-ai-center">
              <h4 className="p-m-0">Location Whitelist</h4>
              <Button
                label="Add New Location"
                icon="pi pi-plus"
                onClick={() => {
                  handleObjChange(setNewRecord, 'visible', true)
                }}
              />
            </div>
          )}
          right={() => (
            <div className="p-d-flex gap-1">
              <Button icon="pi pi-refresh" className="" onClick={fetchData} />
              <ExportButton datatable_ref={dt} />
            </div>
          )}
        ></Toolbar>
        <DataTable
          exportFilename={`IP_Access_${dtFilenameDate}`}
          value={records}
          ref={dt}
          showGridlines
          selectionMode="single"
          selection={lastViewed}
          onSelectionChange={(e) => setLastViewed(e.value)}
          dataKey="id"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          className="datatable-responsive"
          columnResizeMode="fit"
          emptyMessage="No record found."
          rowHover
          breakpoint="1000px"
          responsiveLayout="stack"
          loading={tableLoading}
        >
          <Column header="ID" field="id" />
          <Column header="Name" field="name" />
          <Column header="Type" field="type" />
          <Column header="Value" field="value" />
          <Column
            // style={{
            //   width: '2%',
            //   textAlign: 'center',
            // }}
            field="status"
            header="Status"
            sortable
            body={(rd) => <div>{rd.active ? 'Active' : 'Inactive'}</div>}
          />
          <Column header="Created By" field="createdBy" />
          <Column
            header="Created At"
            field="createdAt"
            body={(rd) => format(new Date(rd.createdAt), 'PPp')}
          />

          <Column
            header="Action"
            body={(rd) => {
              return (
                <div className="p-d-flex gap-1by2">
                  <Button
                    icon="pi pi-pencil"
                    onClick={() => setEditRecord({ ...rd, visible: true })}
                  />
                  <Button
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={() => {
                      const ps = { ...rd }
                      ps.visible = true
                      setDeleteRecord(ps)
                    }}
                  />
                  <Link to={`?history=${rd.id}`}>
                    <Button
                      tooltip="History"
                      tooltipOptions={{ position: 'bottom' }}
                      icon="pi pi-clock"
                      className="p-button p-button-help"
                    />
                  </Link>
                </div>
              )
            }}
          />
        </DataTable>
        <Dialog
          header="Add Location Whitelist"
          className="w-full max-w-40"
          visible={newRecord.visible}
          onHide={hideNewRecord}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideNewRecord}
              />
              <Button
                label={newRecord.loading ? 'Submitting...' : 'Submit'}
                icon="pi pi-plus"
                disabled={newRecord.loading}
                loading={newRecord.loading}
                onClick={handleSaveNewRecord}
              />
            </div>
          }
        >
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Name
            </label>
            <InputText
              placeholder="Enter name"
              value={newRecord.name}
              onChange={(e) => handleObjChange(setNewRecord, 'name', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Type
            </label>
            <Dropdown
              options={typeOptions}
              placeholder="Enter type"
              value={newRecord.type}
              onChange={(e) => handleObjChange(setNewRecord, 'type', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Value
            </label>
            <InputText
              placeholder="Enter value"
              value={newRecord.value}
              onChange={(e) => handleObjChange(setNewRecord, 'value', e.target.value)}
            />
          </div>

          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Password
            </label>
            <Password
              placeholder="Enter your password"
              toggleMask
              feedback={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-lg-6">
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="active">
                Active :
              </label>
              <Checkbox
                onChange={(e) => handleObjChange(setNewRecord, 'active', e.checked)}
                checked={newRecord.active}
              ></Checkbox>
            </div>
          </div>
        </Dialog>
        <Dialog
          header="Edit Location Whitelist"
          className="w-full max-w-40"
          visible={editRecord.visible}
          onHide={hideEditRecord}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideEditRecord}
              />
              <Button
                label={editRecord.loading ? 'Submitting...' : 'Submit'}
                icon="pi pi-plus"
                disabled={editRecord.loading}
                loading={editRecord.loading}
                onClick={handleSaveEditRecord}
              />
            </div>
          }
        >
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Name
            </label>
            <InputText
              placeholder="Enter name"
              value={editRecord.name}
              onChange={(e) => handleObjChange(setEditRecord, 'name', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Type
            </label>
            <Dropdown
              options={typeOptions}
              placeholder="Enter type"
              value={editRecord.type}
              onChange={(e) => handleObjChange(setEditRecord, 'type', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Value
            </label>
            <InputText
              placeholder="Enter value"
              value={editRecord.value}
              onChange={(e) => handleObjChange(setEditRecord, 'value', e.target.value)}
            />
          </div>
          <div className="p-fluid p-field">
            <label htmlFor="" className="">
              Password
            </label>
            <Password
              placeholder="Enter your password"
              toggleMask
              feedback={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-lg-6">
            <div className="p-d-flex p-ai-center">
              <label className="p-d-block p-mr-2" htmlFor="active">
                Active :
              </label>
              <Checkbox
                onChange={(e) => handleObjChange(setEditRecord, 'active', e.checked)}
                checked={!!editRecord.active}
              ></Checkbox>
            </div>
          </div>
        </Dialog>
        {/* delete */}
        <ConfirmDialogWithPassword
          visible={deleteRecord.visible}
          deleting={deleteRecord.loading}
          headerText="Delete Ip"
          mainText={`Delete Location Whitelist ${deleteRecord.name}`}
          onHide={hideDeleteRecord}
          onDelete={handleDeleteRecord}
          password={password}
          setPassword={setPassword}
        />
        <ChangeHistorySidebar
          setTableLoading={setTableLoading}
          header="Customer Blacklist Change History"
          historyType="website-whitelist"
        />
      </div>
    </PageAllowedToRoles>
  )
}

export default LocationWhitelistPage
