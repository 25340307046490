import React, { useCallback, useEffect, useState, useRef } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { handleObjChange } from '../../../functions/setter'
import classNames from 'classnames'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { MultiSelect } from 'primereact/multiselect'
import { patchTargetById, getPendingTargetById, getTeam } from '../../../api'
import { useHistory } from 'react-router-dom'
import { Toast } from 'primereact/toast'
import useQuery from '../../../hooks/useQuery'
import { useGlobalContext } from '../../../context'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from '@fullcalendar/core'
import { subDays } from 'date-fns'
import { Checkbox } from 'primereact/checkbox'
import TargetBreakdown from './TargetBreakdown'
import { RadioButton } from 'primereact/radiobutton'
// TODO: Work on this component
const TargetTeamAssignBreakdownDialog = ({ callBackFn }) => {
  /**
   * @param {function} fetchPendingTargets - function to fetch the pending targets
   * @returns {JSX} - TargetBreakdownDialog component
   * @description - This component is used to assign a breakdown to the manager's team target
   **/
  const { toast } = useGlobalContext()
  const query = useQuery()
  const id = parseInt(query.get('at_id'))
  const { teams, agents } = useGlobalContext()
  const [managerTeamMembers, setManagerTeamMembers] = useState([])
  const history = useHistory()
  const emptyRecord = {
    visible: false,
    loading: false,
    name: '',
    groupName: '',
    managerReward: null,
    teamLeadReward: null,
    teamId: null,
    members: [],
    levels: null,
    membersLevelsBreakdown: [],
    teamOrGroupLevelsBreakdown: [],
    weekRange: [],
    targetAmountBased: true,
    targetCountBased: false,
    targetConversionRateBased: false,
    settingSaved: false,
    rewardType: null,
    active: true,
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  const level_empty = {
    targetAmount: null,
    targetOrder: null,
    conversionRate: null,
    rewardAmount: null,
    remark: '',
  }
  const hideBreakDownDialog = async () => {
    setRecord(emptyRecord)
    history.push({
      pathname: '/targets',
    })
  }

  const [record, setRecord] = useState(emptyRecord)

  //* Function to fetch the pending target
  const fetchPendingTargetById = useCallback(async () => {
    if (!id) return
    setRecord(emptyRecord)
    const res = await getPendingTargetById(id)
    if (res) {
      if (res.status === 200) {
        const { data } = res
        setRecord((ps) => ({
          ...ps,
          visible: true,
          name: data.targetName,
          active: !!data.active,
          teamId: data.targetTeamId,
          managerReward: data.managerReward,
          teamLeadReward: data.teamLeadReward,
          levels: data.totalLevels,
          weekRange: [new Date(data.dateFrom), new Date(data.dateTo)],
          targetAmountBased: !!data.isAmountBased,
          targetCountBased: !!data.isCountBased,
          targetConversionRateBased: !!data.isConversionRateBased,
          rewardType: data.rewardType,
          teamOrGroupLevelsBreakdown: data.levelDetails
            .sort((a, b) => a.level - b.level)
            .map((i) => {
              return {
                targetAmount: i.orderAmount,
                targetOrder: i.orderCount,
                conversionRate: i.orderConversionRate,
                rewardAmount: i.reward,
                remark: i.remark,
              }
            }),
        }))

        if (data.targetTeamId) {
          const teamRes = await getTeam(data.targetTeamId)
          if (teamRes && teamRes.status === 200) {
            setManagerTeamMembers(teamRes.data.teamMembers)
          }
        }
      }
      if (res.status === 404) {
        history.push({
          pathname: '/targets',
        })
      }
    }
  }, [id])
  useEffect(() => fetchPendingTargetById(), [fetchPendingTargetById])
  const confirmationCallbackFn = async () => {
    callBackFn && callBackFn()
    setRecord(emptyRecord)
  }
  return (
    <div>
      <Dialog
        visible={record.visible}
        header="Assign Breakdown"
        style={{ width: '100%', maxWidth: '1200px' }}
        onHide={() => {
          hideBreakDownDialog()
        }}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => hideBreakDownDialog()}
            />
          </div>
        }
      >
        {record.visible && (
          <>
            {!record.settingSaved && (
              <div className="p-grid">
                {record?.teamOrGroupLevelsBreakdown?.map((data, idx) => {
                  return (
                    <div key={idx} className="p-col-3">
                      <h5>Level {idx + 1}</h5>
                      {record?.targetAmountBased ? (
                        <h6 className="p-mt-1">{`Target Amount: $${Number(
                          data?.targetAmount
                        ).toLocaleString('en-US')}/-`}</h6>
                      ) : (
                        ''
                      )}
                      {record?.targetCountBased ? (
                        <h6 className="p-mt-1">{`Target Orders: ${Number(
                          data?.targetOrder
                        ).toLocaleString('en-US')} `}</h6>
                      ) : (
                        ''
                      )}
                      {record?.targetConversionRateBased ? (
                        <h6 className="p-mt-1">{`Target Conversion Rate: ${Number(
                          data?.conversionRate
                        )}% `}</h6>
                      ) : (
                        ''
                      )}
                      <h6 className="p-mt-1">
                        Reward:{' '}
                        {record?.rewardType === 'Incentive' ? (
                          <>₹{Number(data.rewardAmount)}/$</>
                        ) : (
                          <>₹{Number(data.rewardAmount)}</>
                        )}
                      </h6>
                      {data?.remark && <h6 className="p-mt-1">Remark: {data.remark}</h6>}
                    </div>
                  )
                })}
              </div>
            )}
            <div className="p-grid">
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Target Name</label>
                <InputText value={record.name} disabled />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Team</label>
                <Dropdown
                  options={teams}
                  optionLabel="teamName"
                  optionValue="teamID"
                  value={record.teamId}
                  disabled
                />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Manager Reward</label>
                <InputNumber
                  placeholder="Enter Manager Reward"
                  value={record.managerReward}
                  disabled
                  mode="currency"
                  currency="INR"
                  currencyDisplay="symbol"
                  useGrouping={true}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Team Lead Reward</label>
                <InputNumber
                  placeholder="Enter Team Lead Reward"
                  value={record.teamLeadReward}
                  disabled
                  mode="currency"
                  currency="INR"
                  currencyDisplay="symbol"
                  useGrouping={true}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Levels</label>
                <InputText disabled value={'Level ' + record.levels} />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label htmlFor="" className="p-d-block">
                  Dates
                </label>
                <InputText
                  disabled
                  value={`${record.weekRange[0].toLocaleDateString()} - ${record.weekRange[1].toLocaleDateString()}`}
                />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Reward Type</label>
                <InputText disabled value={record.rewardType} />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Based On</label>
                <div className="p-d-flex gap-1 p-ai-end p-mb-2">
                  <div className="p-d-flex gap-1by2 p-mb-2">
                    <RadioButton checked={record.targetAmountBased} disabled />
                    <label htmlFor="targetAmountBased">Amount</label>
                  </div>
                  <div className="p-d-flex gap-1by2 p-mb-2">
                    <RadioButton checked={record.targetCountBased} disabled />
                    <label htmlFor="targetCountBased">Count</label>
                  </div>
                  {/* <div className="p-d-flex gap-1by2 p-mb-2">
                    <Checkbox
                      checked={record.targetConversionRateBased}
                      disabled
                    />
                    <label htmlFor="targetCountBased">Conversion Rate</label>
                  </div> */}
                </div>
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label className="p-d-block">Members</label>
                <MultiSelect
                  options={agents.filter((agent) => managerTeamMembers.includes(agent.id))}
                  filter
                  optionLabel="username"
                  optionValue="id"
                  placeholder="Select team member(s)"
                  showClear
                  display="chip"
                  value={record?.members}
                  disabled={record?.settingSaved}
                  onChange={(e) => {
                    handleObjChange(setRecord, 'members', e.value)
                  }}
                />
              </div>
              <div className="p-field p-fluid p-col-12 p-md-4">
                <label htmlFor="active" className="p-d-block">
                  {record.active ? 'Active' : 'Inactive'}
                </label>
                <InputSwitch
                  id="active"
                  className="p-mt-1"
                  checked={record.active}
                  disabled={record.settingSaved}
                  onChange={(e) => {
                    handleObjChange(setRecord, 'active', e.value)
                  }}
                />
              </div>
              <div className="p-field p-fluid p-col-12">
                <Button
                  label={record.settingSaved ? 'Reset' : 'Apply'}
                  icon={classNames('pi', {
                    'pi-check': !record.settingSaved,
                    'pi-replay': record.settingSaved,
                  })}
                  className={classNames({
                    'p-button-danger': record.settingSaved,
                  })}
                  disabled={!record.settingSaved && record.members?.length === 0}
                  onClick={() => {
                    const val = !record.settingSaved

                    const _membersLevelsBreakdown = record.members.map(() => {
                      return [...Array(record.levels).keys()].map(() => ({
                        ...level_empty,
                      }))
                    })
                    handleObjChange(setRecord, 'membersLevelsBreakdown', _membersLevelsBreakdown)

                    handleObjChange(setRecord, 'settingSaved', val)
                  }}
                />
              </div>
            </div>
            {record.settingSaved && (
              <TargetBreakdown
                record={record}
                setRecord={setRecord}
                confirmationCallbackFn={confirmationCallbackFn}
                targetType="G"
                toast={toast}
                isTeamPendingBreakdown={true}
                teamPendingBreakdownId={id}
              />
            )}
          </>
        )}
      </Dialog>
    </div>
  )
}

export default TargetTeamAssignBreakdownDialog
