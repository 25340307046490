import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import AppWrapper from './app/wrappers/AppWrapper'

// import * as serviceWorker from './serviceWorker'
import axios from 'axios'
import { AppContextProvider } from './context'
import AxiosInterpector from './app/wrappers/AxiosInterpector'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.patch['Content-Type'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.delete['Content-Type'] = 'application/json'
ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AxiosInterpector>
        <AppWrapper />
      </AxiosInterpector>
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register()
