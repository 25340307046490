import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom'
import { getShippingChargesForProductById, getSingleChargeDetails } from '../../../api'
import useQuery from '../../../hooks/useQuery'
import { format } from 'date-fns'

export default function ViewProductChargesDialog() {
  const history = useHistory()
  const query = useQuery()
  const id = query.get('view_product_code')
  const dt = useRef(null)
  // states
  const [visible, setVisible] = useState(false)
  const [record, setRecord] = useState([])

  //* Function to fetch all charges using it's id
  const fetchShippingChargesForProductById = useCallback(async () => {
    if (!id) return
    // setChargesTableLoading(true)
    setVisible(true)
    const res = await getShippingChargesForProductById(id)
    if (res) {
      //   setChargesTableLoading(false)
      if (res.status === 400) {
        hideDialog()
      }
      if (res.status === 200) {
        setRecord(res.data.record)
        setVisible(true)
      }
    }
  }, [id])

  useEffect(() => {
    fetchShippingChargesForProductById()
  }, [fetchShippingChargesForProductById])

  //Dialog hide function
  const hideDialog = () => {
    setVisible(false)
    setRecord([])
    history.push({
      pathname: '/website/logistics',
    })
  }

  return (
    <div>
      <Dialog
        style={{
          width: '50vw',
          minWidth: '300px',
        }}
        onHide={hideDialog}
        header="Shipping Charges"
        visible={visible}
        footer={() => {
          return (
            <div className="p-d-flex p-jc-end">
              <Button label="Cancel" className="p-button-text" onClick={() => hideDialog()} />
            </div>
          )
        }}
      >
        <div className="p-d-flex gap-1 p-flex-column">
          <h6 className=" p-m-0">Product : {record?.product?.productName}</h6>
          {/* <h5 className=" p-m-0">
            Shipper Tag : {record.provider.companyTag}
          </h5> */}
          <h6 className=" p-m-0">Shipper Name : {record?.product?.shippingCompanyName}</h6>
          <h6 className=" p-m-0">Shipper Provider : {record?.product?.shippingCompanyProvider}</h6>
          <DataTable
            value={record?.chargesByProduct || []}
            header={
              <div className="p-d-flex p-jc-between">
                <span>Shipping charges by product</span>
                {record?.chargesByProduct?.length > 0 && (
                  <Link to={`?edit_sc_id=${record?.chargesByProduct[0]?.uid}`}>Edit</Link>
                )}
              </div>
            }
            showGridlines
            columnResizeMode="fit"
            emptyMessage="No records found."
            rowHover
          >
            <Column header="Quantity" field="quantity" />
            <Column
              header="Shipping Charges"
              field="price"
              body={(rd) => {
                return `$${rd.price}`
              }}
            />
            <Column
              header="Delivery Time"
              body={(rd) => `${rd.deliveryTimeFrom} To ${rd.deliveryTimeTo} Days`}
            />
            <Column
              header="Created at"
              field="dateOfCreation"
              body={(rd) => {
                return format(new Date(rd?.dateOfCreation), 'PPp')
              }}
            />
          </DataTable>

          <DataTable
            value={record?.chargesByShipper || []}
            header={
              <div className="p-d-flex p-jc-between">
                <span>Shipping charges by shipper</span>
                {record?.chargesByShipper?.length > 0 && (
                  <Link to={`?sc_uid=${record?.chargesByShipper[0]?.uid}`}>Edit</Link>
                )}
              </div>
            }
            showGridlines
            columnResizeMode="fit"
            emptyMessage="No records found."
            rowHover
          >
            <Column header="Quantity" field="quantity" />
            <Column
              header="Shipping Charges"
              field="price"
              body={(rd) => {
                return `$${rd.price}`
              }}
            />
            <Column
              header="Delivery Time"
              body={(rd) => `${rd.deliveryTimeFrom} To ${rd.deliveryTimeTo} Days`}
            />
            <Column
              header="Created at"
              field="dateOfCreation"
              body={(rd) => {
                return format(new Date(rd?.dateOfCreation), 'PPp')
              }}
            />
          </DataTable>
          <DataTable
            value={record?.chargesByAll || []}
            header={
              <div className="p-d-flex p-jc-between">
                <span>Shipping charges by all</span>
                {record?.chargesByAll?.length > 0 && (
                  <Link to={`?sc_uid=${record?.chargesByAll[0]?.uid}`}>Edit</Link>
                )}
              </div>
            }
            showGridlines
            columnResizeMode="fit"
            emptyMessage="No records found."
            rowHover
          >
            <Column header="Quantity" field="quantity" />
            <Column
              header="Shipping Charges"
              field="price"
              body={(rd) => {
                return `$${rd.price}`
              }}
            />
            <Column
              header="Delivery Time"
              body={(rd) => `${rd.deliveryTimeFrom} To ${rd.deliveryTimeTo} Days`}
            />
            <Column
              header="Created at"
              field="dateOfCreation"
              body={(rd) => {
                return format(new Date(rd?.dateOfCreation), 'PPp')
              }}
            />
          </DataTable>
        </div>
      </Dialog>
    </div>
  )
}
