import React, { useEffect, useRef, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'

import { InputNumber } from 'primereact/inputnumber'

import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { handleAryChange, handleObjChange } from '../../../functions/setter'
import { useGlobalContext } from '../../../context'
import { addShippingCharges, getTotalShippingChargeMeta } from '../../../api'
import { Toast } from 'primereact/toast'
import { Message } from 'primereact/message'

export default function AddShippingChargeLogisticDialog({
  visible,
  setVisible,
  setChargesRecords,
}) {
  let emptyCharge = {
    quantity: 1,
    price: null,
    deliveryTimeFrom: null,
    deliveryTimeTo: null,
  }
  const emptyErr = {
    state: false,
    errors: [],
  }

  const { shippingTags, productNames } = useGlobalContext()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [productCharges, setProductCharges] = useState([])
  const [shipperCharges, setShipperCharges] = useState([])
  const [allCharges, setAllCharges] = useState([])
  const [selectedShipper, setSelectedShipper] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [productCode, setProductCode] = useState(null)
  const [shipperCode, setShipperCode] = useState(null)

  const [loading, setLoading] = useState(false)
  const toast = useRef(null)

  const validateShippingRecord = (charges) => {
    const err = []
    charges.forEach((element) => {
      if (element.price === null) {
        err.push(`Price for quantity ${element.quantity} cannot be empty`)
      }
    })
    return err
  }

  const postShippingCharges = async (type, targetId, charges) => {
    const errs = validateShippingRecord(charges)

    if (errs.length) {
      setNewRecordError({ state: true, errors: errs })
      return
    }
    setLoading(true)
    const res = await addShippingCharges({
      target: type,
      targetId: targetId,
      charges: charges,
    })
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setChargesRecords((ps) => [res.data.record, ...ps])
        dialogHide()
      }
    }
  }

  const fetchAndSetQuantities = async (type, code) => {
    const res = await getTotalShippingChargeMeta({
      type,
      code,
    })
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        const { totalQuantity, deliveryTimeFrom, deliveryTimeTo } = res.data
        const _charges = Array.from({ length: totalQuantity }, (_, index) => ({
          ...emptyCharge,
          quantity: emptyCharge.quantity + index,
          deliveryTimeFrom,
          deliveryTimeTo,
        }))
        switch (type) {
          case 'all':
            setAllCharges(_charges)
            break
          case 'product':
            setProductCharges(_charges)
            break
          case 'shipper':
            setShipperCharges(_charges)
            break
          default:
            null
        }
      }
    }
  }

  useEffect(() => {
    if (activeTabIndex === 2) {
      fetchAndSetQuantities('all')
    }
  }, [activeTabIndex])

  const dialogHide = () => {
    setVisible(false)
    setNewRecordError(emptyErr)
    setAllCharges([])
    setShipperCharges([])
    setProductCharges([])
    setSelectedProduct(null)
    setSelectedShipper(null)
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Add Shipping Charge"
        visible={visible}
        footer
        className="w-full max-w-60"
        onHide={dialogHide}
      >
        <TabView
          activeIndex={activeTabIndex}
          onTabChange={(e) => {
            setNewRecordError(emptyErr)
            setActiveTabIndex(e.index)
          }}
        >
          <TabPanel header="Product">
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-col-12 p-field p-mb-0 p-mb-0">
                <label htmlFor="">Product</label>
                <Dropdown
                  value={selectedProduct}
                  options={productNames}
                  optionLabel="productName"
                  filter
                  filterBy="productName"
                  filterPlaceholder="Search"
                  onChange={(e) => {
                    const { value } = e
                    setSelectedProduct(value)
                    setProductCode(value.productCode)
                    fetchAndSetQuantities('product', value.productCode)
                  }}
                  placeholder="Select Product"
                />
              </div>
              <div className="p-col-12">
                <ChargesDataTable records={productCharges} setRecords={setProductCharges} />
              </div>
              <div className="p-col-12">
                {productCharges.length > 0 && (
                  <Button
                    onClick={() => postShippingCharges('product', productCode, productCharges)}
                    className="p-mt-5"
                    label={loading ? 'Submitting' : 'Submit'}
                    loading={loading}
                    disabled={loading}
                  />
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Shipper">
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-col-12 p-field p-mb-0">
                <label htmlFor="">Shipper</label>
                <Dropdown
                  value={selectedShipper}
                  options={shippingTags}
                  onChange={(e) => {
                    const { value } = e
                    setShipperCode(value)
                    setSelectedShipper(value)
                    fetchAndSetQuantities('shipper', value)
                  }}
                  placeholder="Select Shipper"
                />
              </div>
              <div className="p-col-12">
                <ChargesDataTable records={shipperCharges} setRecords={setShipperCharges} />
              </div>
              <div className="p-col-12">
                {shipperCharges.length > 0 && (
                  <Button
                    onClick={() => postShippingCharges('shipper', shipperCode, shipperCharges)}
                    className="p-mt-5"
                    label={loading ? 'Submitting' : 'Submit'}
                    loading={loading}
                    disabled={loading}
                  />
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel header="All">
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-col-12">
                <ChargesDataTable records={allCharges} setRecords={setAllCharges} />
              </div>
              <div className="p-col-12">
                {allCharges.length > 0 && (
                  <Button
                    onClick={() => postShippingCharges('all', '', allCharges)}
                    className="p-mt-5"
                    label={loading ? 'Submitting' : 'Submit'}
                    loading={loading}
                    disabled={loading}
                  />
                )}
              </div>
            </div>
          </TabPanel>
        </TabView>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}

const ChargesDataTable = ({ records, setRecords }) => {
  return (
    <DataTable className="p-mt-4" value={records}>
      <Column field="quantity" header="Quantity" />
      <Column
        field="price"
        header="Shipping Charges"
        body={(rd, { rowIndex }) => {
          return (
            <InputNumber
              placeholder="Shipping Charge"
              value={rd.price}
              maxFractionDigits={2}
              minFractionDigits={2}
              onValueChange={(e) => {
                const { value } = e
                const _record = { ...rd }
                _record.price = value
                handleAryChange(setRecords, rowIndex, _record)
              }}
              mode="currency"
              currency="USD"
              locale="en-US"
            />
          )
        }}
      />
      <Column
        field="Actions"
        body={(rd, { rowIndex }) => {
          return (
            <Button
              tabIndex="-1"
              className="p-button-danger"
              icon="pi pi-times"
              onClick={() => {
                const _record = { ...rd }
                _record.price = 0
                handleAryChange(setRecords, rowIndex, _record)
              }}
            />
          )
        }}
      />
    </DataTable>
  )
}
