import { startOfMonth } from 'date-fns'
import { debounce } from 'lodash'
import { Chart } from 'primereact/chart'
import { Dropdown } from 'primereact/dropdown'
import { MultiSelect } from 'primereact/multiselect'
import { SelectButton } from 'primereact/selectbutton'
import React, { useCallback, useEffect, useState } from 'react'
import { getOrderProductSales } from '../../../api/dashboard'
import { useGlobalContext } from '../../../context'
import { dateFilters } from '../../../functions/myDateFns'
import { Checkbox } from 'primereact/checkbox'
import MyDatesPicker from '../../mini/MyDatesPicker'
const date = new Date()
export default function OrderProductSalesDashbaord() {
  const {
    colorScheme,
    user,
    leadSource: ctx_leadSource,
    allStatusGroupsOnly: ctx_allStatusGroupsOnly,
  } = useGlobalContext()
  // STATES
  const [filterDateCode, setFilterDateCode] = useState(dateFilters[2].code)
  const [filterRangeDates, setFilterRangeDates] = useState(null)
  const [filterDates, setLbFilterDates] = useState({
    dateTo: date,
    dateFrom: startOfMonth(date),
  })
  const [filterStatusGroup, setFilterStatusGroup] = useState('Paid')
  const [filterLeads, setFilterLeads] = useState([])
  const [filterUsers, setFilterUsers] = useState([])
  const [filterType, setFilterType] = useState('amount')
  const [filterTopProductLimit, setFilterTopProductLimit] = useState(5)
  const [filterIsIncludeOthers, setFilterIsIncludeOthers] = useState(false)

  const [barChartOptions, setBarChartOptions] = useState({})
  const [pieChartOptions, setPieChartOptions] = useState({})
  const [barChartData, setLineChartData] = useState({})
  const [pieChartData, setPieChartData] = useState({})

  const applyChangeInChart = ({ isAmount, theme }) => {
    const options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          labels: {
            color: theme === 'light' ? '#A4A4A4' : '#E4E1E1',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: theme === 'light' ? '#A4A4A4' : '#E4E1E1',
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: theme === 'light' ? '#A4A4A4' : '#E4E1E1',
            // write relavent code if y-axis is amount in dollars or not
            callback: function (value) {
              return isAmount ? `$${value}` : value
            },
          },
          grid: {
            color: theme === 'light' ? '#F3F1F1' : '#696363',
          },
        },
      },
    }
    return options
  }

  const fetchData = useCallback(async () => {
    const res = await getOrderProductSales({
      dateFrom: filterDates.dateFrom,
      dateTo: filterDates.dateTo,
      orderStatus: filterStatusGroup,
      type: filterType,
      topProductLimit: filterTopProductLimit,
      isIncludeOthers: filterIsIncludeOthers,
      users: filterUsers,
      leads: filterLeads,
      theme: colorScheme,
    })
    if (res && res.data) {
      const { lineChartData, pieChartData } = res.data
      setLineChartData(lineChartData)
      setPieChartData(pieChartData)
    }
  }, [
    filterDates,
    colorScheme,
    filterStatusGroup,
    filterUsers,
    filterType,
    filterTopProductLimit,
    filterLeads,
    filterIsIncludeOthers,
  ])

  // Debounce the fetchData function
  const debouncedFetchData = useCallback(debounce(fetchData, 300), [fetchData])

  useEffect(() => {
    debouncedFetchData()

    // Clean up the debounced function
    return () => {
      debouncedFetchData.cancel()
    }
  }, [debouncedFetchData])

  useEffect(() => {
    setBarChartOptions(applyChangeInChart({ type: filterType, theme: colorScheme }))
    setPieChartOptions(applyChangeInChart({ type: filterType, theme: colorScheme }))
  }, [colorScheme, filterType])
  return (
    <div className="p-grid">
      <div className="p-col-12 w-full p-my-2 p-d-flex gap-1 p-ai-center">
        <h3 className="p-my-0">Product Sales Board</h3>
        <div className="p-d-flex gap-1 p-flex-wrap">
          <Dropdown
            value={filterTopProductLimit}
            onChange={(e) => setFilterTopProductLimit(e.value)}
            placeholder="Top Product Limit"
            options={[5, 10, 15, 20, 25]}
          />
          <div className="">
            <MyDatesPicker
              code={filterDateCode}
              setCode={setFilterDateCode}
              rangeDates={filterRangeDates}
              setRangeDates={setFilterRangeDates}
              setFilterDates={setLbFilterDates}
            />
          </div>
          <Dropdown
            value={filterStatusGroup}
            onChange={(e) => setFilterStatusGroup(e.value)}
            placeholder="Order Status"
            options={ctx_allStatusGroupsOnly}
            optionLabel="name"
            optionValue="value"
          />
          <MultiSelect
            value={filterUsers}
            onChange={(e) => setFilterUsers(e.value)}
            placeholder="Select Agent, Teamlead"
            options={user.members.filter((i) => ['agent', 'teamlead'].includes(i.role))}
            optionLabel="username"
            optionValue="id"
            filter
            filterBy="username,role"
            filterPlaceholder="Search..."
            maxSelectedLabels={2}
          />
          <MultiSelect
            maxSelectedLabels={3}
            value={filterLeads}
            onChange={(e) => setFilterLeads(e.value)}
            placeholder="Lead Source"
            options={ctx_leadSource}
            optionLabel="name"
            optionValue="value"
            showClear
          />
          <SelectButton
            className="p-d-flex"
            value={filterType}
            options={[
              { label: 'Amount', value: 'amount' },
              { label: 'Quanity', value: 'quantity' },
              { label: 'Order', value: 'orderCount' },
            ]}
            onChange={(e) => setFilterType(e.value)}
          />
          <div className="p-d-flex p-ai-center">
            <Checkbox
              checked={filterIsIncludeOthers}
              onChange={(e) => setFilterIsIncludeOthers(e.checked)}
            />
            <span className="p-ml-2">Include Other</span>
          </div>
        </div>
      </div>
      <div className="p-col-12  p-grid">
        <div className="p-col-12 p-lg-9">
          <div className="card">
            <Chart type="line" height={400} data={barChartData} options={barChartOptions} />
          </div>
        </div>
        <div className="p-col-12 p-lg-3">
          <div className="card">
            <Chart type="pie" height={400} data={pieChartData} options={pieChartOptions} />
          </div>
        </div>
      </div>
    </div>
  )
}
