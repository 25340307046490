import axios from 'axios'
import endpoints from './endpoints'

export const aftershipAddressValidationProxy = async ({
  address,
  city,
  state,
  postal_code,
  country,
}) => {
  try {
    const res = await axios.post(endpoints.proxy.aftershipAddressValidation, {
      address,
      city,
      state,
      postal_code,
      country,
    })
    return res
  } catch (error) {
    return error.response
  }
}
