import axios from 'axios'
import endpoints from './endpoints'

export const addIp = async (payload) => {
  try {
    return await axios.post(endpoints.ipaccess.index, payload)
  } catch (error) {
    return error.response
  }
}

export const deleteIp = async (id, password) => {
  try {
    const data = JSON.stringify({ password })
    return await axios.delete(endpoints.ipaccess.index + id, { data })
  } catch (error) {
    return error.response
  }
}

export const getIps = async () => {
  try {
    return await axios.get(endpoints.ipaccess.index)
  } catch (error) {
    return error.response
  }
}

export const patchIp = async (id, payload) => {
  try {
    return await axios.patch(endpoints.ipaccess.index + id, payload)
  } catch (error) {
    return error.response
  }
}

export const addWebsiteIp = async (payload) => {
  try {
    return await axios.post(endpoints.ipaccess.website, payload)
  } catch (error) {
    return error.response
  }
}

export const deleteWebsiteIp = async (id, password) => {
  try {
    const data = JSON.stringify({ password })
    return await axios.delete(endpoints.ipaccess.website + id, { data })
  } catch (error) {
    return error.response
  }
}

export const getWebsiteIps = async () => {
  try {
    return await axios.get(endpoints.ipaccess.website)
  } catch (error) {
    return error.response
  }
}

export const patchWebsiteIp = async (id, payload) => {
  try {
    return await axios.patch(endpoints.ipaccess.website + id, payload)
  } catch (error) {
    return error.response
  }
}
