import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { handleObjChange } from '../../../functions/setter'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { FilterMatchMode } from 'primereact/api'
import { InputText } from 'primereact/inputtext'
import { format } from 'date-fns'
import {
  addShippingCharges,
  deleteShippingCharge,
  getChargesDetailsById,
  updateShippingCharge,
} from '../../../api'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import capitalizeFirstLetter from '../../../functions/capitalizeFirstLetter'
import { Message } from 'primereact/message'
import { Dropdown } from 'primereact/dropdown'
import { useGlobalContext } from '../../../context'
import ConfirmDialogWithPassword from '../Dialog/ConfirmDialogWithPassword'
import AddShippingChargeLogisticDialog from './AddShippingChargeLogisticDialog'

export default function ShippingChargeLogistic({
  chargesTableLoading,
  setChargesTableLoading,
  fetchCharges,
  chargesRecords,
  setChargesRecords,
}) {
  const { shippingTags, productTypes, productNames: ctx_productNames } = useGlobalContext()
  const toast = useRef(null)
  const dt = useRef(null)
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const sc_uid = parseInt(searchParams.get('sc_uid'))
  const newRecordCharges_empty = {
    type: '',
    targetId: '',
    packType: '',

    charges: [],
    loading: false,
    visible: false,
  }
  const editChargeRecord_empty = {
    visible: false,
    general: {},
    charges: [],
    loading: false,
  }
  const quantityCharge_empty = {
    minRange: 0,
    maxRange: 0,
    shippingTime: '15 to 20 Days',
    price: 0,
  }
  const defaultLoader = {
    add: false,
    update: false,
    delete: false,
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [editChargeRecord, setEditChargeRecord] = useState(editChargeRecord_empty)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [newChargesRecord, setNewChargeRecord] = useState(newRecordCharges_empty)

  const [newRecordDialogVisible, setNewRecordDialogVisible] = useState(false)

  const [password, setPassword] = useState('')
  // const [expandedRows, setExpandedRows] = useState(null)
  const [deleteChargeData, setDeleteChargeData] = useState({})
  const [deleteChargeDialog, setDeleteChargeDialog] = useState(false)
  const [loaders, setLoaders] = useState(defaultLoader)
  const [chargesFilter, setChargesFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [selectedCharges, setSelectedCharges] = useState([])

  const validateEditRecord = () => {
    const err = []
    const allValues = editChargeRecord.charges.every((input) => {
      return (
        input.minQty !== '' &&
        input.maxQty !== '' &&
        input.shippingTime !== '' &&
        input.price !== ''
      )
    })
    if (!allValues) {
      err.push('Charges are invalid')
    }
    return err
  }
  const handleChargesUpdate = async () => {
    const valid = validateEditRecord()
    if (valid.length) {
      setNewRecordError({
        state: true,
        errors: valid,
      })
      return
    }
    handleObjChange(setEditChargeRecord, 'loading', true)
    let {
      general: { uid, type, packType, targetId },
      charges,
    } = editChargeRecord
    charges = charges.map((item, idx) => {
      item.lable = `Q${idx + 1}`
      return item
    })
    const res = await updateShippingCharge({
      uid,
      type,
      packType,
      targetId,
      charges,
    })
    if (res) {
      if (res.status === 200) {
        handleObjChange(setEditChargeRecord, 'loading', false)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setChargesRecords(
          chargesRecords.map((record) => {
            if (record.uid === res.data.data[0].uid) {
              return res.data.data[0]
            }
            return record
          })
        )
        hideRecord()
      }
    }
  }
  // Validating input fields before adding them to the table
  const validateNewRecord = () => {
    const err = []
    const { type, packType, charges, targetId } = newChargesRecord

    const allValues = charges.every((input) => {
      return (
        //* This needs to checked for shippingTime
        input.minRange !== '' &&
        input.maxRange !== '' &&
        input.shippingTime !== '' &&
        input.price !== ''
      )
    })
    if (!type) {
      err.push('Type is invalid')
      return err
    }
    if (type === 'all') {
      if (charges.length < 1) {
        err.push('Cannot submit empty')
      }
      if (!allValues) {
        err.push('Quantities are invalid')
      }
      return err
    }
    if (type === 'shipper') {
      if (!targetId) {
        err.push('Shipper is invalid')
      }
      if (!packType) {
        err.push('Packing Type is invalid')
      }
      if (!allValues) {
        err.push('Quantities are invalid')
      }
      return err
    }
    if (type === 'product') {
      if (!targetId) {
        err.push('Select a product')
      }
      if (!allValues) {
        err.push('Quantities are invalid')
      }
      return err
    }
    return err
  }
  // Function to post new shipping charge
  const handleShipperPackingChargesSave = async () => {
    const valid = validateNewRecord()
    if (valid.length) {
      setNewRecordError({
        state: true,
        errors: valid,
      })
      return
    }

    let { type, targetId, packType, charges } = newChargesRecord
    charges = charges.map((item, idx) => {
      return {
        label: `Q${idx + 1}`,
        ...item,
      }
    })
    const res = await addShippingCharges({
      type,
      targetId,
      packType,
      charges,
    })

    if (res) {
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
      }
      // setCharges([...charges, res.data.record])
      fetchCharges()
      hideNewChargeRecord()
    }
  }
  const hideNewChargeRecord = () => {
    setNewChargeRecord(newRecordCharges_empty)
    setNewRecordError(emptyErr)
  }
  const hideDeleteChargeDialog = () => {
    setPassword('')
    setDeleteChargeDialog(false)
    setDeleteChargeData({})
  }
  const hideRecord = () => {
    setEditChargeRecord(editChargeRecord_empty)
    setNewRecordError(emptyErr)
    history.push({
      pathname: '/website/logistics',
    })
  }
  const deleteCharge = async () => {
    setLoaders({ ...loaders, delete: true })
    const res = await deleteShippingCharge(deleteChargeData.uid, password)
    setLoaders(defaultLoader)
    hideDeleteChargeDialog()
    if (res) {
      if (res.status === 200) {
        setChargesRecords(chargesRecords.filter((item) => item.uid !== deleteChargeData.uid))
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Shipping charge deleted successfully',
          life: 3000,
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res.data.error,
          life: 3000,
        })
      }
    }
  }

  // Displaying a single feedback on click
  const fetchSingleCharge = useCallback(async () => {
    if (!sc_uid) return
    setChargesTableLoading(true)
    const res = await getChargesDetailsById(sc_uid)
    if (res) {
      setChargesTableLoading(false)

      if (res.status === 200) {
        setEditChargeRecord((ps) => ({
          ...ps,
          ...res.data.record,
          visible: true,
        }))
      }
    }
  }, [sc_uid])
  useEffect(() => fetchSingleCharge(), [fetchSingleCharge])

  const handleCompareUrl = (e) => {
    const ids = selectedCharges.map((charge) => charge.uid).join('-')
    history.push(`?view_sc_id=${ids}`)
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="p-grid">
        <div className="p-col-12">
          <div className="card p-shadow-3">
            <Toolbar
              className="p-toolbar p-mb-3 p-d-flex p-ai-center"
              left={() => {
                return (
                  <>
                    <div className="p-d-flex p-ai-center p-mr-3">
                      <h4 className="p-m-0">Shipping Charges</h4>
                    </div>
                    <div className="p-mr-3">
                      <Button
                        tooltipOptions={{ position: 'top' }}
                        label="Add Charges"
                        icon="pi pi-plus"
                        onClick={() => setNewRecordDialogVisible(true)}
                      />
                    </div>
                    <div>
                      <div>
                        <Button
                          icon="pi pi-refresh"
                          tooltip="Refresh"
                          tooltipOptions={{ position: 'right' }}
                          onClick={fetchCharges}
                        />
                      </div>
                    </div>
                  </>
                )
              }}
              right={() => {
                return (
                  <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                    {/* <div>
                      <Button
                        icon="pi pi-plus"
                        label="Expand All"
                        onClick={expandAllRows}
                        className="mr-2"
                      />
                    </div>
                    <div>
                      <Button
                        icon="pi pi-minus"
                        label="Collapse All"
                        onClick={collapseAllRows}
                      />
                    </div> */}
                    <div>
                      <Button
                        label="Compare"
                        icon="pi pi-info-circle"
                        tooltipOptions={{
                          position: 'top',
                        }}
                        disabled={selectedCharges.length < 2}
                        className="p-button-info"
                        onClick={handleCompareUrl}
                        // onClick={handelRemovingMultipleShippers}
                      />
                    </div>

                    <div>
                      <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                          type="search"
                          onInput={(e) =>
                            setChargesFilter({
                              ...chargesFilter,
                              global: {
                                value: e.target.value,
                                matchMode: FilterMatchMode.CONTAINS,
                              },
                            })
                          }
                          placeholder="Search..."
                        />
                      </span>
                    </div>
                  </div>
                )
              }}
            ></Toolbar>
            <DataTable
              filters={chargesFilter}
              ref={dt}
              value={chargesRecords}
              loading={chargesTableLoading}
              showGridlines
              paginator
              rows={10}
              rowsPerPageOptions={[10, 50, 100, 500]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} charges"
              emptyMessage="No charges found."
              rowHover
              removableSort
              selectionMode="checkbox"
              responsiveLayout="stack"
              // expandedRows={expandedRows}
              // onRowToggle={(e) => {
              //   setExpandedRows(e.data)
              // }}
              selection={selectedCharges}
              onSelectionChange={(e) => {
                const { value } = e
                setSelectedCharges(value)
              }}
              dataKey="uid"
              // rowExpansionTemplate={rowExpansionTemplate}
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3em' }} onC></Column>
              <Column sortable header="Id" field="uid" />

              {/* <Column expander={true} style={{ width: '3em' }} /> */}

              <Column header="Type" field="target" />
              <Column header="Target Id" field="name" />
              <Column header="Quantity" field="count" />
              <Column
                header="Created At"
                field="createdAt"
                body={(rd) => format(new Date(rd.createdAt), 'PPp')}
              />
              <Column header="Created By" field="createdBy" />

              <Column
                header="Actions"
                style={{ width: '15%' }}
                body={(rowData) => {
                  return (
                    <div className="p-d-flex gap-1by2">
                      <Link to={`?view_sc_id=${rowData.uid}`}>
                        <Button
                          icon="pi pi-eye"
                          tooltip="View Record"
                          tooltipOptions={{
                            position: 'bottom',
                          }}
                        />
                      </Link>
                      <Link to={`?edit_sc_id=${rowData.uid}`}>
                        <Button
                          icon="pi pi-pencil"
                          className="p-button-info"
                          tooltip="Edit Record"
                          tooltipOptions={{
                            position: 'bottom',
                          }}
                        />
                      </Link>
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger"
                        tooltip="Delete"
                        onClick={() => {
                          setDeleteChargeData(rowData)
                          setDeleteChargeDialog(true)
                        }}
                        tooltipOptions={{ position: 'bottom' }}
                      />

                      <Link to={`?history_shipper_charge_id=${rowData.uid}`}>
                        <Button
                          tooltip="History"
                          tooltipOptions={{ position: 'bottom' }}
                          icon="pi pi-clock"
                          className="p-button p-button-help"
                        />
                      </Link>
                    </div>
                  )
                }}
              />
            </DataTable>
          </div>
        </div>
        <Dialog
          header="Add Shipping Charge"
          visible={newChargesRecord.visible}
          modal
          className="p-fluid "
          style={{ width: '90%', maxWidth: '900px' }}
          footer={
            <>
              {
                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  className="p-button-text"
                  onClick={hideNewChargeRecord}
                />
              }
              <Button
                disabled={newChargesRecord.loading}
                loading={newChargesRecord.loading}
                label={newChargesRecord.loading ? 'Submiting' : 'Submit'}
                icon="pi pi-plus"
                className="p-button"
                onClick={handleShipperPackingChargesSave}
                autoFocus
              />
            </>
          }
          onHide={hideNewChargeRecord}
        >
          <>
            <div className="p-fuild">
              <div className="p-field">
                <label className="p-mb-2">Type</label>
                <Dropdown
                  value={newChargesRecord?.type}
                  placeholder="Select Shipping Type"
                  options={[
                    {
                      name: 'All',
                      value: 'all',
                    },
                    {
                      name: 'Product',
                      value: 'product',
                    },
                    {
                      name: 'Shipper',
                      value: 'shipper',
                    },
                  ]}
                  optionLabel="name"
                  optionValue="value"
                  onChange={(e) => {
                    handleObjChange(setNewChargeRecord, 'type', e.value)
                    if (['product', 'all'].includes(e.value)) {
                      handleObjChange(setNewChargeRecord, 'charges', [{ ...quantityCharge_empty }])
                    }
                    if (['shipper'].includes(e.value)) {
                      handleObjChange(setNewChargeRecord, 'charges', [
                        { ...quantityCharge_empty },
                        { ...quantityCharge_empty },
                        { ...quantityCharge_empty },
                        { ...quantityCharge_empty },
                      ])
                    }
                  }}
                />
              </div>
              {newChargesRecord?.type === 'shipper' && (
                <>
                  <div className="p-field">
                    <p className="p-mb-1">Shipper</p>
                    <Dropdown
                      options={shippingTags}
                      placeholder="Select Shipper"
                      value={newChargesRecord.targetId}
                      onChange={(e) => {
                        handleObjChange(setNewChargeRecord, 'targetId', e.value)
                      }}
                    />
                  </div>
                  <div className="p-field">
                    <p className="p-mb-1">Packing Type</p>
                    <Dropdown
                      options={productTypes}
                      placeholder="Select Packing Type"
                      value={newChargesRecord.packType}
                      onChange={(e) => handleObjChange(setNewChargeRecord, 'packType', e.value)}
                    />
                  </div>
                </>
              )}
              {newChargesRecord?.type === 'product' && (
                <div className="p-field">
                  <p className="p-mb-1">Product</p>
                  <Dropdown
                    options={ctx_productNames}
                    optionLabel="productName"
                    optionValue="productCode"
                    filter
                    filterBy="productName"
                    filterPlaceholder="Search..."
                    placeholder="Select Product"
                    value={newChargesRecord.targetId}
                    onChange={(e) => handleObjChange(setNewChargeRecord, 'targetId', e.value)}
                  />
                </div>
              )}
            </div>
            {newChargesRecord.charges.length > 0 && (
              <div className="p-grid p-mx-auto p-mb-2">
                <label className="p-col-1">Label</label>
                <label className="p-col-2">Min Quantity</label>
                <label className="p-col-2">Max Quantity</label>
                <label className="p-col-3">Delivery Time</label>
                <label className="p-col-2">Price</label>
              </div>
            )}
            {newChargesRecord.charges.map((item, idx) => {
              return (
                <div key={idx} className="p-formgrid p-grid p-mx-auto">
                  <div className="p-field p-col-1">
                    <label>{`Q${idx + 1}`}</label>
                  </div>
                  <div className="p-field p-col-2">
                    <InputNumber
                      name="minRange"
                      placeholder="Enter min quantity"
                      value={item.minRange}
                      onChange={(e) => {
                        let _newRecordCharges = [...newChargesRecord.charges]
                        _newRecordCharges[idx].minRange = e.value
                        handleObjChange(setNewChargeRecord, 'charges', _newRecordCharges)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-2">
                    <InputNumber
                      name="maxRange"
                      placeholder="Enter max quantity"
                      value={item.maxRange}
                      onChange={(e) => {
                        let _newRecordCharges = [...newChargesRecord.charges]
                        _newRecordCharges[idx].maxRange = e.value
                        handleObjChange(setNewChargeRecord, 'charges', _newRecordCharges)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-3">
                    <InputText
                      name="deliveryTime"
                      placeholder="Enter delivery time"
                      value={item.shippingTime}
                      onChange={(e) => {
                        let _newRecordCharges = [...newChargesRecord.charges]
                        _newRecordCharges[idx].shippingTime = e.target.value
                        handleObjChange(setNewChargeRecord, 'charges', _newRecordCharges)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-3">
                    <InputNumber
                      mode="currency"
                      currency="USD"
                      name="price"
                      placeholder="Enter price"
                      value={item.price}
                      onChange={(e) => {
                        let _newRecordCharges = [...newChargesRecord.charges]
                        _newRecordCharges[idx].price = e.value
                        handleObjChange(setNewChargeRecord, 'charges', _newRecordCharges)
                      }}
                    />
                  </div>

                  <div className="p-field p-col-1">
                    <Button
                      disabled={newChargesRecord?.type === 'shipper'}
                      onClick={() => {
                        let _newRecordCharges = newChargesRecord.charges.filter(
                          (charge, id) => id !== idx
                        )

                        handleObjChange(setNewChargeRecord, 'charges', [..._newRecordCharges])
                      }}
                      className="p-button-danger"
                      icon="pi pi-trash"
                      label=""
                    />
                  </div>
                </div>
              )
            })}
            {['all', 'product'].includes(newChargesRecord?.type) && (
              <Button
                onClick={() => {
                  handleObjChange(setNewChargeRecord, 'charges', [
                    ...newChargesRecord.charges,
                    quantityCharge_empty,
                  ])
                }}
                icon="pi pi-plus"
                label="Add More Quantity"
              />
            )}
            <div className="p-grid p-mt-3">
              {/* Showing errors that occur if the field isn't filled properly */}
              {newRecordError.state && (
                <>
                  {newRecordError.errors.map((err, index) => (
                    <div className="p-col-12 p-lg-6" key={index}>
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  ))}
                  <div className=" p-col-12">
                    <Button
                      onClick={() => setNewRecordError(emptyErr)}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </Dialog>
        {/* old shipping charges edit dialog  */}
        <Dialog
          header={`Shipping Charges #${editChargeRecord.general?.uid}`}
          visible={editChargeRecord.visible}
          modal
          className="p-fluid"
          style={{ width: '90%', maxWidth: '900px' }}
          footer={
            <>
              <Button
                label="Close"
                icon="pi pi-times"
                className="p-button-text"
                onClick={hideRecord}
              />
              <Button
                label={editChargeRecord.loading ? 'Loading' : 'Submit'}
                icon="pi pi-save"
                loading={editChargeRecord.loading}
                disabled={editChargeRecord.loading}
                onClick={() => handleChargesUpdate()}
              />
            </>
          }
          onHide={hideRecord}
        >
          <>
            <div className="p-grid">
              <div className="p-col-12 p-md-4">
                <h6>Type: {capitalizeFirstLetter(editChargeRecord.general?.type)}</h6>
              </div>
              <div className="p-col-12 p-md-4">
                <h6>Product: {editChargeRecord.general?.targetId}</h6>
              </div>
              <div className="p-col-12 p-md-4">
                <h6>
                  Packing Type:{' '}
                  {editChargeRecord.general?.packType ? editChargeRecord.general?.packType : 'NA'}
                </h6>
              </div>
            </div>
            <div className="p-grid p-mx-auto p-mb-2">
              <label className="p-col-1">Label</label>
              <label className="p-col-2">Min Quantity</label>
              <label className="p-col-2">Max Quantity</label>
              <label className="p-col-3">Delivery Time</label>
              <label className="p-col-3">Price</label>
            </div>
            {editChargeRecord?.charges?.map((charge, idx) => {
              return (
                <div key={idx} className="p-formgrid p-grid p-mx-auto">
                  <div className="p-field p-col-1">
                    <label>{`Q${idx + 1}`}</label>
                  </div>
                  <div className="p-field p-col-2">
                    <InputNumber
                      value={charge.minQty}
                      onChange={(e) => {
                        const _charges = [...editChargeRecord.charges]
                        _charges[idx].minQty = e.value || 0
                        handleObjChange(setEditChargeRecord, 'charges', _charges)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-2">
                    <InputNumber
                      value={charge.maxQty}
                      onChange={(e) => {
                        const _charges = [...editChargeRecord.charges]
                        _charges[idx].maxQty = e.value || 0
                        handleObjChange(setEditChargeRecord, 'charges', _charges)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-3">
                    <InputText
                      value={charge.shippingTime}
                      onChange={(e) => {
                        const _charges = [...editChargeRecord.charges]
                        _charges[idx].shippingTime = e.target.value
                        handleObjChange(setEditChargeRecord, 'charges', _charges)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-3">
                    <InputNumber
                      value={charge.price}
                      mode="currency"
                      currency="USD"
                      onChange={(e) => {
                        const _charges = [...editChargeRecord.charges]
                        _charges[idx].price = e.value || 0
                        handleObjChange(setEditChargeRecord, 'charges', _charges)
                      }}
                    />
                  </div>
                  <div className="p-field p-col-1">
                    <Button
                      disabled={
                        editChargeRecord.general?.type === 'shipper' ||
                        editChargeRecord?.charges?.length <= 1
                      }
                      onClick={() => {
                        let _newEditRecord = editChargeRecord.charges.filter(
                          (charge, id) => id !== idx
                        )

                        handleObjChange(setEditChargeRecord, 'charges', _newEditRecord)
                      }}
                      className="p-button-danger"
                      icon="pi pi-trash"
                      label=""
                    />
                  </div>
                </div>
              )
            })}
            <div className="p-grid p-mt-3">
              {/* Showing errors that occur if the field isn't filled properly */}
              {newRecordError.state && (
                <>
                  {newRecordError.errors.map((err, index) => (
                    <div className="p-col-12 p-lg-6" key={index}>
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  ))}
                  <div className=" p-col-12">
                    <Button
                      onClick={() => setNewRecordError(emptyErr)}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </Dialog>
        <ConfirmDialogWithPassword
          onHide={hideDeleteChargeDialog}
          onDelete={deleteCharge}
          visible={deleteChargeDialog}
          deleting={loaders.delete}
          password={password}
          setPassword={setPassword}
          headerText="Delete Shipping Charge"
          mainText={`Are you sure you want to delete shipping charge for the type (${deleteChargeData?.name})`}
        />
        <AddShippingChargeLogisticDialog
          visible={newRecordDialogVisible}
          setVisible={setNewRecordDialogVisible}
          setChargesRecords={setChargesRecords}
        />
      </div>
    </div>
  )
}
