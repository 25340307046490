import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Timeline } from 'primereact/timeline'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { getTargetById } from '../../../api'
import { handleObjChange } from '../../../functions/setter'
import useQuery from '../../../hooks/useQuery'
import { useGlobalContext } from '../../../context'
import { format } from 'date-fns'
import { paidStatus } from '../../../data/orderStatusValues'
export default function TargetDetailsDialog() {
  /**
   * @param {string} pathname - The pathname of the current page
   **/
  const { user } = useGlobalContext()
  const history = useHistory()
  const query = useQuery()
  const id = parseInt(query.get('id'))
  const record_empty = {
    visible: false,
    loading: false,
    targetType: '',
    targetName: '',
    targetTypeName: '',
    status: '',
    dateFrom: '',
    dateTo: '',
    createdAt: '',
    createdBy: '',
    teamName: '',
    totalLevels: '',
    isAmountBased: '',
    isCountBased: '',
    isConversionRateBased: '',
    rewardType: '',
    managerReward: '',
    teamLeadReward: '',
    assignedTo: '',

    targetIndividualLevels: [],
    targetTeamLevels: [],
    targetMemberLevels: [],
  }
  const [record, setRecord] = useState(record_empty)
  const fetchSingleTarget = useCallback(async () => {
    if (!id) return
    setRecord(record_empty)
    const res = await getTargetById(id)
    if (res) {
      if (res.status === 200) {
        setRecord((ps) => ({
          ...ps,
          ...res.data,
          visible: true,
        }))
      }
    }
  }, [id])

  const hideViewRecordDialog = () => {
    handleObjChange(setRecord, 'visible', false)
    history.push({
      pathname: '/targets',
    })
  }
  useEffect(() => fetchSingleTarget(), [fetchSingleTarget])
  return (
    <div>
      <Dialog
        visible={record.visible}
        header={`Target Details`}
        style={{ width: '100%', maxWidth: '1200px' }}
        onHide={hideViewRecordDialog}
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => hideViewRecordDialog()}
            />
          </div>
        }
      >
        <div className="p-grid" style={{ rowGap: '15px' }}>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Name</label>
            <h6 className="p-m-0 p-pt-3">{record.targetName}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Status</label>
            <h6 className="p-m-0 p-pt-3">{record.status}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Type</label>
            <h6 className="p-m-0 p-pt-3">{record.targetTypeName}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Dates</label>
            <h6 className="p-m-0 p-pt-3">{`${record.dateFrom} To ${record.dateTo}`}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Created At</label>
            <h6 className="p-m-0 p-pt-3">{`${record.createdAt}`}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Created By</label>
            <h6 className="p-m-0 p-pt-3">{`${record.createdBy}`}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Assigned To</label>
            <h6 className="p-m-0 p-pt-3">{`${record.assignedTo}`}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Total Levels</label>
            <h6 className="p-m-0 p-pt-3">{`${record.totalLevels}`}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Order Amount Based</label>
            <h6 className="p-m-0 p-pt-3">{`${record.isAmountBased ? 'Yes' : 'No'}`}</h6>
          </div>
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Order Count Based</label>
            <h6 className="p-m-0 p-pt-3">{`${record.isCountBased ? 'Yes' : 'No'}`}</h6>
          </div>
          {/* <div className="p-col-6 p-lg-2">
            <label htmlFor="">Conversion Rate Based</label>
            <h6 className="p-m-0 p-pt-3">
              {`${record.isConversionRateBased ? 'Yes' : 'No'}`}
            </h6>
          </div> */}
          <div className="p-col-6 p-lg-2">
            <label htmlFor="">Reward Type</label>
            <h6 className="p-m-0 p-pt-3">{record.rewardType}</h6>
          </div>
          {['T', 'TM'].includes(record.targetType) &&
            ['superadmin', 'admin', 'manager', 'teamlead'].includes(user.role) && (
              <>
                <div className="p-col-6 p-lg-2">
                  <label htmlFor="">Manager Reward</label>
                  <h6 className="p-m-0 p-pt-3">₹{record.managerReward}</h6>
                </div>
                <div className="p-col-6 p-lg-2">
                  <label htmlFor="">Team Lead Reward</label>
                  <h6 className="p-m-0 p-pt-3">₹{record.teamLeadReward}</h6>
                </div>
              </>
            )}
        </div>
        {user.role === 'agent' && (
          <>
            {['TM', 'G', 'OT'].includes(record.targetType) && (
              <TargetDataTable
                target={record}
                levels={record.targetTeamLevels || []}
                header={`Agent Levels`}
                targetLevelType={record.targetType}
              />
            )}
            {['I'].includes(record.targetType) && (
              <TargetDataTable
                target={record}
                levels={record.targetIndividualLevels}
                header={`Agent Levels`}
                targetLevelType={record.targetType}
              />
            )}
          </>
        )}
        {['teamlead'].includes(user.role) && (
          <>
            {['TM', 'T', 'OT'].includes(record.targetType) && (
              <>
                <TargetDataTable
                  target={record}
                  levels={record.targetTeamLevels}
                  header={`Target Levels`}
                  targetLevelType={record.targetType}
                />
              </>
            )}
            {['OT'].includes(record.targetType) && (
              <div className="p-mt-3">
                <OTTargetDataTable
                  levels={record.targetMemberLevels}
                  target={record}
                  targetLevelType={record.targetMemberLevels[0].levels[0].levelType}
                />
              </div>
            )}
            {['TM', 'G'].includes(record.targetType) && (
              <>
                <div className="widget-timeline p-mt-4 p-ml-2">
                  <div className="timeline-content">
                    <Timeline
                      value={record.targetMemberLevels}
                      className="custimized-timeline"
                      content={(data, idx) => {
                        return (
                          <TargetDataTable
                            key={idx}
                            target={record}
                            levels={data.levels}
                            header={`${data.username}'s Target Levels`}
                            targetLevelType={data.levels[0].levelType}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {['I'].includes(record.targetType) && (
              <TargetDataTable
                target={record}
                levels={record.targetIndividualLevels}
                header={`Agent Levels`}
                targetLevelType={record.targetType}
              />
            )}
          </>
        )}
        {['superadmin', 'admin', 'manager', 'teamlead'].includes(user.role) && (
          <>
            {['TM', 'T', 'G', 'OT'].includes(record.targetType) && (
              <>
                <TargetDataTable
                  target={record}
                  levels={record.targetTeamLevels}
                  header={`Target Levels`}
                  targetLevelType={record.targetType}
                />
              </>
            )}
            {['OT'].includes(record.targetType) && (
              <div className="p-mt-3">
                <OTTargetDataTable
                  levels={record.targetMemberLevels}
                  target={record}
                  targetLevelType={record.targetMemberLevels[0].levels[0].levelType}
                />
              </div>
            )}
            {['TM', 'G'].includes(record.targetType) && (
              <>
                <div className="widget-timeline p-mt-4 p-ml-2">
                  <div className="timeline-content">
                    <Timeline
                      value={record.targetMemberLevels}
                      className="custimized-timeline"
                      content={(data, idx) => {
                        return (
                          <TargetDataTable
                            key={idx}
                            target={record}
                            levels={data.levels}
                            header={`${data.username}'s Target Levels`}
                            targetLevelType={data.levels[0].levelType}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            {['I'].includes(record.targetType) && (
              <TargetDataTable
                target={record}
                levels={record.targetIndividualLevels}
                header={`Agent Levels`}
                targetLevelType={record.targetType}
              />
            )}
          </>
        )}

        {record.targetType === 'T' &&
          ['superadmin', 'admin', 'manager', 'teamlead'].includes(user.role) && (
            <div className="p-d-flex p-jc-center p-mt-4">
              <Link
                className="p-button p-component p-button-text-icon-left p-button-plain"
                to={{
                  pathname: '/targets',
                  search: `?at_id=${id}`,
                }}
              >
                Assign Target Breakdown
              </Link>
            </div>
          )}
      </Dialog>
    </div>
  )
}

const OTTargetDataTable = ({ levels, target, targetLevelType }) => {
  let dateFrom = target.dateFrom.replaceAll('/', '-'),
    dateTo = target.dateTo.replaceAll('/', '-'),
    masterIDs = []
  // multiple masterids
  if (['OT', 'TM', 'G'].includes(targetLevelType)) {
    if (target.targetMemberLevels && target.targetMemberLevels.length > 0) {
      target.targetMemberLevels.forEach((l) => masterIDs.push(l.userid))
    }
  }
  // // single masterid
  // if (['TML', 'OTML', 'IL', 'GML'].includes(targetLevelType)) {
  //   if (levels[0].userid) {
  //     masterIDs.push(levels[0].userid)
  //   }
  // }

  let orderLink = `/orders?dateFrom=${dateFrom}&dateTo=${dateTo}&orderStatus=${paidStatus.join(
    ' '
  )}`
  if (masterIDs.length > 0) {
    orderLink += `&masterid=${masterIDs.join(' ')}`
  }
  const newArr = levels.map((level, index) => {
    return {
      number: ++index,
      agentName: level.username,
      paidOrderCount: level.levels[0].totalPaidOrderCount,
      paidOrderConversionRate: level.levels[0].totalPaidOrderConversionRate,
      paidOrderAmount: level.levels[0].totalPaidOrderAmount,
      orderLink: `${orderLink}&masterid=${level.userid}`,
    }
  })
  return (
    <DataTable value={newArr} header={'Target Team Members'} showGridlines>
      <Column field="number" header="Sr. No" />
      <Column field="agentName" header="Agent Name" />
      {target.isCountBased === 1 && (
        <Column
          field="totalPaidOrderCount"
          header="Paid Order Count"
          body={(rd) => {
            return (
              <Link target="_blank" to={rd.orderLink}>
                {rd.paidOrderCount}
              </Link>
            )
          }}
        />
      )}
      {target.isConversionRateBased === 1 && (
        <Column
          field="totalPaidOrderConversionRate"
          header="Paid Order Conversion Rate"
          body={(rd) => {
            return (
              <Link target="_blank" to={rd.orderLink}>
                {rd.paidOrderConversionRate}
              </Link>
            )
          }}
        />
      )}
      {target.isAmountBased === 1 && (
        <Column
          field="totalPaidOrderAmount"
          header="Paid Order Amount"
          body={(rd) => {
            return (
              <Link target="_blank" to={rd.orderLink}>
                {rd.paidOrderAmount}
              </Link>
            )
          }}
        />
      )}
    </DataTable>
  )
}

const TargetDataTable = ({ target, levels, header, targetLevelType }) => {
  let dateFrom = target.dateFrom.replaceAll('/', '-'),
    dateTo = target.dateTo.replaceAll('/', '-'),
    masterIDs = []
  // multiple masterids
  if (['OT', 'TM', 'G'].includes(targetLevelType) && header === 'Target Levels') {
    if (target.targetMemberLevels && target.targetMemberLevels.length > 0) {
      target.targetMemberLevels.forEach((l) => masterIDs.push(l.userid))
    }
  }
  // single masterid
  if (['TML', 'OTML', 'IL', 'GML'].includes(targetLevelType)) {
    if (levels[0].userId) {
      masterIDs.push(levels[0].userId)
    }
  }
  let orderLink = `/orders?dateFrom=${dateFrom}&dateTo=${dateTo}&orderStatus=${paidStatus.join(
    ' '
  )}`
  if (masterIDs.length > 0) {
    orderLink += `&masterid=${masterIDs.join(' ')}`
  }
  return (
    <DataTable value={levels} header={header} showGridlines>
      <Column
        field="level"
        header="Level"
        style={{
          width: '1%',
        }}
        bodyClassName="p-text-center"
      />
      <Column
        field="status"
        header="Status"
        style={{
          width: '8%',
        }}
      />
      {target.isAmountBased === 1 && (
        <Column
          field="orderAmount"
          header="Target Amount"
          style={{
            width: '10%',
          }}
          body={(rd) => `$${rd.orderAmount}/-`}
        />
      )}
      {target.isAmountBased === 1 && (
        <Column
          field="totalPaidOrderAmount"
          header="Paid Amount"
          style={{
            width: '10%',
          }}
          body={(rd) => {
            return (
              <Link target="_blank" to={orderLink}>
                {rd.totalPaidOrderAmount}/-
              </Link>
            )
          }}
        />
      )}
      {target.isCountBased === 1 && (
        <Column
          field="orderCount"
          header="Target Order"
          style={{
            width: '8%',
          }}
          body={(rd) => `${rd.orderCount}`}
        />
      )}
      {target.isCountBased === 1 && (
        <Column
          field="totalPaidOrderCount"
          header="Paid Order"
          style={{
            width: '8%',
          }}
          body={(rd) => {
            return (
              <Link target="_blank" to={orderLink}>
                {rd.totalPaidOrderCount}
              </Link>
            )
          }}
        />
      )}
      {target.isConversionRateBased === 1 && (
        <Column
          field="orderConversionRate"
          header="Target C.R."
          style={{
            width: '8%',
          }}
          body={(rd) => `${rd.orderConversionRate}%`}
        />
      )}
      {target.isConversionRateBased === 1 && (
        <Column
          field="totalPaidOrderConversionRate"
          header="Paid C.R."
          style={{
            width: '8%',
          }}
          body={(rd) => {
            return (
              <Link target="_blank" to={orderLink}>
                {rd.totalPaidOrderConversionRate}%
              </Link>
            )
          }}
        />
      )}
      <Column
        field="rewardWithCurrency"
        header="Reward"
        style={{
          width: '8%',
        }}
      />
      <Column
        field="remark"
        header="Remark"
        style={{
          width: '20%',
        }}
      />
    </DataTable>
  )
}
