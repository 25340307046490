import React, { useEffect, useState } from 'react'
import { Route, withRouter, useLocation } from 'react-router-dom'
import App from '../../App'
import LoginPage from '../../pages/OtherPages/LoginPage'
import ErrorPage from '../../pages/OtherPages/ErrorPage'
import NotFoundPage from '../../pages/OtherPages/NotFoundPage'
import AccessPage from '../../pages/OtherPages/AccessPage'
// import Test from '../../pages/OtherPages/Test'

import { useGlobalContext } from '../../context'
import ResetPasswordPage from '../../pages/OtherPages/ResetPasswordPage'

const AppWrapper = (props) => {
  const { colorScheme, setColorScheme } = useGlobalContext()

  const [theme, setTheme] = useState('blue')
  const [componentTheme, setComponentTheme] = useState('blue')

  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const onColorSchemeChange = (scheme) => {
    changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1)
    changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1)
    localStorage.setItem('colorScheme', scheme)
    setColorScheme(scheme)
  }

  useEffect(() => {
    changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1)
    changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1)
  }, [])

  const changeStyleSheetUrl = (id, value, from) => {
    const element = document.getElementById(id)
    const urlTokens = element.getAttribute('href').split('/')

    if (from === 1) {
      // which function invoked this function - change scheme
      urlTokens[urlTokens.length - 1] = value
    } else if (from === 2) {
      // which function invoked this function - change color
      urlTokens[urlTokens.length - 2] = value
    }

    const newURL = urlTokens.join('/')

    replaceLink(element, newURL)
  }

  const onMenuThemeChange = (theme) => {
    const layoutLink = document.getElementById('layout-css')
    const href = 'assets/layout/css/' + theme + '/layout-' + colorScheme + '.css'

    replaceLink(layoutLink, href)
    setTheme(theme)
  }

  const onComponentThemeChange = (theme) => {
    const themeLink = document.getElementById('theme-css')
    const href = 'assets/theme/' + theme + '/theme-' + colorScheme + '.css'

    replaceLink(themeLink, href)
    setComponentTheme(theme)
  }

  const replaceLink = (linkElement, href, callback) => {
    const id = linkElement.getAttribute('id')
    const cloneLinkElement = linkElement.cloneNode(true)

    cloneLinkElement.setAttribute('href', href)
    cloneLinkElement.setAttribute('id', id + '-clone')

    linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling)

    cloneLinkElement.addEventListener('load', () => {
      linkElement.remove()
      cloneLinkElement.setAttribute('id', id)

      if (callback) {
        callback()
      }
    })
  }

  switch (props.location.pathname) {
    case '/login':
      return <Route path="/login" render={() => <LoginPage colorScheme={colorScheme} />} />
    case '/reset-password':
      return (
        <Route
          path="/reset-password"
          render={() => <ResetPasswordPage colorScheme={colorScheme} />}
        />
      )
    // case '/test':
    //   return <Route path="/test" render={() => <Test />} />
    case '/error':
      return <Route path="/error" render={() => <ErrorPage colorScheme={colorScheme} />} />
    case '/notfound':
      return <Route path="/notfound" render={() => <NotFoundPage colorScheme={colorScheme} />} />
    case '/access':
      return <Route path="/access" render={() => <AccessPage colorScheme={colorScheme} />} />
    default:
      return (
        <App
          colorScheme={colorScheme}
          onColorSchemeChange={onColorSchemeChange}
          componentTheme={componentTheme}
          onComponentThemeChange={onComponentThemeChange}
          theme={theme}
          onMenuThemeChange={onMenuThemeChange}
        />
      )
  }
}

export default withRouter(AppWrapper)
