import axios from 'axios'
import endpoints from './endpoints'

export const getPaymentMethodById = async (id) => {
  try {
    return await axios.get(endpoints.paymentMethods.index + id)
  } catch (error) {
    return error.response
  }
}

export const getPaymentMethods = async () => {
  try {
    return await axios.get(endpoints.paymentMethods.index)
  } catch (error) {
    return error.response
  }
}

export const postPaymentMethod = async (paymentData) => {
  try {
    const res = await axios.post(endpoints.paymentMethods.index, paymentData)
    return res
  } catch (error) {
    return error.response
  }
}

export const putPaymentMethod = async (paymentData,id) => {
  try {
    const res = await axios.put(endpoints.paymentMethods.index + id, paymentData)
    return res
  } catch (error) {
    return error.response
  }
}

export const deletePaymentMethod = async (id,password) => {
  try {
    const res = await axios.delete(endpoints.paymentMethods.index + id,  {
      data: { password },
    })
    return res
  } catch (error) {
    return error.response
  }
}