import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import { handleObjChange } from '../../../functions/setter'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import {
  deleteAwsS3Files,
  getPaymentMethodById,
  putPaymentMethod,
  uploadFileToS3,
} from '../../../api'
import { useGlobalContext } from '../../../context'
import { Message } from 'primereact/message'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import UploadImage from '../../mini/UploadImage'
import generateS3Path from '../../../functions/generateS3Path'

const emptyErr = {
  state: false,
  errors: [],
}

const empty_ImageState = {
  url: '',
  file: null,
}

const emptyPaymentMethod = {
  appName: '',
  slug: '',
  appImage: '',
  qrCodeImage: '',
  isQrCodeAvailable: 0,
  additionalDetails: [
    {
      name: '',
      value: '',
    },
  ],
  status: '',
}

export default function EditPaymentMethodDialog({
  paymentMethods,
  setPaymentMethods,
  setTableLoading,
  statusOptions,
}) {
  const query = useQuery()
  const history = useHistory()
  const id = parseInt(query.get('id'))
  const { toast } = useGlobalContext()
  const [paymentMethod, setPaymentMethod] = useState(emptyPaymentMethod)
  const [updateRecordError, setUpdateRecordError] = useState(emptyErr)
  const [visibleEditPaymentDialog, setVisibleEditPaymentDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [appImage, setAppImage] = useState(empty_ImageState)
  const [qrCodeImage, setQrCodeImage] = useState(empty_ImageState)
  const [paymentMethodCopy, setPaymentMethodCopy] = useState(null)
  // fetch paymentMethod by id
  const fetchPaymentMethodById = useCallback(async () => {
    if (!id) return
    setTableLoading(true)
    const res = await getPaymentMethodById(id)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setPaymentMethod(res.data)
        setPaymentMethodCopy(res.data)
        setVisibleEditPaymentDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchPaymentMethodById()
  }, [fetchPaymentMethodById])

  const validatePaymentMethod = (payment) => {
    const err = []
    if (payment.appName === '') err.push('App name cannot be empty')
    if (payment.slug === '') err.push('Slug cannot be empty')
    if (payment.status === '') err.push('Status cannot be empty')
    payment.additionalDetails.forEach((detail) => {
      if (detail.name === '' || detail.value === '') {
        err.push('Additional details name and value cannot be empty')
      }
    })
    return err
  }

  const editPaymentMethod = async () => {
    try {
      //make sure all the fields does have the values
      const errs = validatePaymentMethod(paymentMethod)
      if (errs.length > 0) {
        setLoading(false)
        setUpdateRecordError({ state: true, errors: errs })
        return
      }
      setLoading(true)
      const deletePaths = []
      const uploadPromises = []
      //check if there are any new files to upload
      if (appImage.file) {
        deletePaths.push(paymentMethodCopy.appImage)
        uploadPromises.push(
          uploadFileToS3({ file: appImage.file }).then(
            (res) => (paymentMethod.appImage = res.data.filePath)
          )
        )
      }
      if (qrCodeImage.file) {
        deletePaths.push(paymentMethodCopy.qrCodeImage)
        uploadPromises.push(
          uploadFileToS3({ file: qrCodeImage.file }).then(
            (res) => (paymentMethod.qrCodeImage = res.data.filePath)
          )
        )
      }
      //upload new files to awsS3
      await Promise.all(uploadPromises)
      // delete old paths from awsS3 if new files are added
      if (deletePaths.length > 0) {
        await deleteAwsS3Files(deletePaths)
      }
      setTableLoading(true)
      //make the api call for creating payment method
      const res = await putPaymentMethod(paymentMethod, id)
      if (res) {
        setLoading(false)
        setTableLoading(false)
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
            life: 3000,
          })
          const updatedData = paymentMethods.map((payment) =>
            payment.id === id ? res.data.record : payment
          )
          setPaymentMethods(updatedData)
          onHide()
        }
      }
    } catch (error) {
      setLoading(false)
      setUpdateRecordError({ state: true, errors: [error.message] })
    }
  }

  const onHide = () => {
    setVisibleEditPaymentDialog(false)
    setPaymentMethod(emptyPaymentMethod)
    setPaymentMethodCopy(null)
    setUpdateRecordError(emptyErr)
    setAppImage(empty_ImageState)
    setQrCodeImage(empty_ImageState)
    history.push({
      pathname: '/website/payment-methods',
    })
  }

  const footer = () => {
    return (
      <div>
        <Button label="Cancel" icon="pi pi-times" onClick={onHide} className="p-button-secondary" />
        <Button
          label="Save"
          icon="pi pi-save"
          className="p-button-primary"
          onClick={editPaymentMethod}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <Dialog
      header="Update Method"
      visible={visibleEditPaymentDialog}
      style={{ width: '50vw' }}
      onHide={onHide}
      footer={footer}
    >
      <div className="p-grid p-formgrid">
        <div className="p-col-12 p-lg-4">
          {paymentMethod?.appImage ? (
            <div className="p-fluid p-field p-col-12">
              <label htmlFor="" className="p-col-fixed">
                App Icon
              </label>
              <div className="card p-text-center">
                <img
                  className="w-full"
                  style={{ borderRadius: '10px 10px 10px 10px' }}
                  src={generateS3Path(paymentMethod.appImage)}
                  alt=""
                />
                <Button
                  label="Change Image"
                  icon="pi pi-pencil"
                  onClick={() => handleObjChange(setPaymentMethod, 'appImage', '')}
                />
              </div>
            </div>
          ) : (
            <div className="p-fluid p-field p-col-12">
              <label htmlFor="" className="p-col-fixed">
                App Icon
              </label>
              <UploadImage imageState={appImage} setImageState={setAppImage} />
            </div>
          )}
          {paymentMethod?.qrCodeImage ? (
            <div className="p-fluid p-field p-col-12">
              <label htmlFor="" className="p-col-fixed">
                Qr Code Image
              </label>
              <div className="card p-text-center">
                <img
                  className="w-full"
                  style={{ borderRadius: '10px 10px 10px 10px' }}
                  src={generateS3Path(paymentMethod.qrCodeImage)}
                  alt=""
                />
                <Button
                  label="Change Image"
                  icon="pi pi-pencil"
                  onClick={() => handleObjChange(setPaymentMethod, 'qrCodeImage', '')}
                />
              </div>
            </div>
          ) : (
            <div className="p-fluid p-field p-col-12">
              <label htmlFor="" className="p-col-fixed">
                Qr Code Image
              </label>
              <UploadImage imageState={qrCodeImage} setImageState={setQrCodeImage} />
            </div>
          )}
        </div>
        <div className="p-col-12 p-lg-8">
          <div className="p-grid p-formgrid">
            <div className="p-field p-fluid p-col-12">
              <label htmlFor="name">App name</label>
              <InputText
                id="name"
                placeholder="Enter App Name"
                value={paymentMethod.appName}
                onChange={(e) => {
                  setPaymentMethod((ps) => ({
                    ...ps,
                    appName: e.target.value,
                    slug: e.target.value.replaceAll(' ', '-').toLowerCase(),
                  }))
                  handleObjChange(setPaymentMethod, 'appName', e.target.value)
                }}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <label htmlFor="name">Slug</label>
              <InputText
                id="name"
                placeholder="Enter Slug"
                value={paymentMethod.slug}
                onChange={(e) => handleObjChange(setPaymentMethod, 'slug', e.target.value)}
              />
            </div>
            <div className="p-field p-fluid p-col-12 p-mb-4">
              <label htmlFor="status">Status</label>
              <Dropdown
                id="status"
                placeholder="Select Status"
                options={statusOptions}
                value={paymentMethod.status}
                onChange={(e) => handleObjChange(setPaymentMethod, 'status', e.value)}
              />
            </div>
            <div className="p-field p-fluid p-col-12">
              <h6>Additional Details</h6>
            </div>
          </div>
          {paymentMethod?.additionalDetails?.map((item, idx) => (
            <div className="p-field p-fluid" key={idx}>
              <div className="p-grid p-formgrid">
                <div className="p-col-12 p-xl-5">
                  <div className="">
                    <label htmlFor="name">Name</label>
                    <InputText
                      id="name"
                      placeholder="Enter entity name"
                      value={item.name}
                      onChange={(e) => {
                        let temp = { ...paymentMethod }
                        temp.additionalDetails[idx].name = e.target.value
                        setPaymentMethod(temp)
                      }}
                    />
                  </div>
                </div>
                <div className="p-col-12 p-xl-5">
                  <div className="">
                    <label htmlFor="name">Value</label>
                    <InputText
                      id="name"
                      placeholder="Enter entity value"
                      value={item.value}
                      onChange={(e) => {
                        let temp = { ...paymentMethod }
                        temp.additionalDetails[idx].value = e.target.value
                        setPaymentMethod(temp)
                      }}
                    />
                  </div>
                </div>
                <div className="p-col p-xl-2 p-d-flex gap-1 p-jc-between p-ai-end p-mt-3">
                  <Button
                    icon="pi pi-trash"
                    className="p-button-danger flex-1"
                    disabled={paymentMethod.additionalDetails.length === 1}
                    onClick={() => {
                      let temp = [...paymentMethod.additionalDetails]
                      temp = temp.filter((item, i) => i !== idx)
                      setPaymentMethod({ ...paymentMethod, additionalDetails: temp })
                    }}
                  />
                  <Button
                    icon="pi pi-plus"
                    className="flex-1"
                    onClick={() => {
                      let temp = [...paymentMethod.additionalDetails]
                      temp.push({ name: '', value: '' })
                      setPaymentMethod({ ...paymentMethod, additionalDetails: temp })
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {updateRecordError.state && (
        <>
          {updateRecordError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setUpdateRecordError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
    </Dialog>
  )
}
