import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import BackToHome from '../../components/mini/BackToHome'

export default function NotFoundPage(props) {
  const history = useHistory()

  const goDashboard = () => {
    history.push('/')
  }
  const goBack = () => {
    history.goBack()
  }
  return (
    <div className="exception-body notfound">
      <div className="exception-panel">
        <h1>404</h1>
        <h3>not found</h3>
        <p>The page that you are looking for does not exist</p>
        <div className="p-d-flex gap-1 p-ai-center p-jc-center">
          <Button label="Go back" onClick={goBack} icon="pi pi-arrow-left" />
          <BackToHome />
          {/* <Button label={`Open ${user.role === "operations" ? 'Orders' : 'Dashboard'}`} onClick={user.role === "operations" ? goOrdersPage : goDashboard} icon="pi pi-home" /> */}
        </div>
      </div>
      <div className="exception-footer">
        <img
          src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`}
          className="exception-logo"
          alt="expection-logo"
        />
        <img
          src={`assets/layout/images/appname-${
            props.colorScheme === 'light' ? 'dark' : 'light'
          }.png`}
          className="exception-appname"
          alt="expection-appname"
        />
      </div>
    </div>
  )
}
