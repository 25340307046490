// hide details of a string
function hideDetails(string) {
    if (!string) return
    if(string.length > 3){
        let start = string.slice(0, 3)
        let mid = new Array(string.slice(3, -3).length + 1).join('*')
        let end = string.slice(-3)
        return start + mid + end
    }
  }
  
  export default hideDetails