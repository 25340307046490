import axios from 'axios'
import { format } from 'date-fns'
import endpoints from './endpoints'

export const getOrderSales = async (dateFrom, dateTo, agents, leads, websites) => {
  try {
    return await axios.get(endpoints.dashboard.ordersales, {
      params: {
        dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : null,
        dateTo: dateTo ? format(dateTo, 'yyyy-MM-dd') : null,
        agents,
        leads,
        websites,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const getSalesRankings = async ({ dateFrom, dateTo, status, sort, rankSort }) => {
  try {
    return await axios.get(endpoints.dashboard.salesRanking, {
      params: {
        dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : null,
        dateTo: dateTo ? format(dateTo, 'yyyy-MM-dd') : null,
        status,
        sort,
        rankSort,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const getConversionRateRankings = async (dateFrom, dateTo, sort) => {
  try {
    return await axios.get(endpoints.dashboard.conversionRateRanking, {
      params: {
        dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : null,
        dateTo: dateTo ? format(dateTo, 'yyyy-MM-dd') : null,
        sort,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const getGrowthChartData = async ({
  chartType,
  dateFrom,
  dateTo,
  leads,
  tension,
  isAmount,
  orderStatus,
  isCompare,
  users,
  theme,
}) => {
  try {
    return await axios.get(endpoints.dashboard.growthchart + chartType, {
      params: {
        dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : null,
        dateTo: dateTo ? format(dateTo, 'yyyy-MM-dd') : null,
        leads,
        tension,
        isAmount,
        orderStatus,
        isCompare,
        users,
        theme,
      },
    })
  } catch (error) {
    return error.response
  }
}

export const getDashbaordTargets = async () => {
  try {
    return await axios.get(endpoints.dashboard.targets)
  } catch (error) {
    return error.response
  }
}

export const getOrderProductSales = async ({
  dateFrom,
  dateTo,
  leads,
  tension,
  type,
  orderStatus,
  users,
  theme,
  topProductLimit,
  isIncludeOthers,
}) => {
  try {
    return await axios.get(endpoints.dashboard.orderProductSales, {
      params: {
        dateFrom: dateFrom ? format(dateFrom, 'yyyy-MM-dd') : null,
        dateTo: dateTo ? format(dateTo, 'yyyy-MM-dd') : null,
        leads,
        tension,
        type,
        orderStatus,
        users,
        theme,
        topProductLimit,
        isIncludeOthers,
      },
    })
  } catch (error) {
    return error.response
  }
}
