import classNames from 'classnames'
import { Button } from 'primereact/button'
import React, { useRef } from 'react'
import { useGlobalContext } from '../../context'
import { Toast } from 'primereact/toast'
// UploadImage component to manage the image upload functionality
export default function UploadImage({ imageState, setImageState }) {
  /**
   * imageState @type {object} - object to manage the image state
   * setImageState @type {function} - function to set the image state {url, file}
   **/
  const imageFileRef = React.useRef(null)
  const toast = useRef(null)
  function handleImageChange() {
    imageFileRef.current.click()
  }
  function handelClearImage() {
    setImageState({
      url: '',
      file: null,
    })
  }
  function handelImageSelect(e) {
    const file = e.target.files[0]
    if (!file) return
    let largeFiles = 0
    if (file.size < 1024 * 1024 * 5) {
      setImageState({
        file: file,
        url: URL.createObjectURL(new Blob(e.target.files), {
          type: file.type,
        }),
      })
    } else {
      largeFiles++
    }
    if (largeFiles) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: `${largeFiles} files are larger than 5 MB and will not be uploaded`,
      })
    }
  }

  return (
    <div className="card p-shadow-1 p-d-flex p-ai-center p-jc-center p-mb-0 ">
      <Toast ref={toast} />
      <div>
        <label
          onClick={handleImageChange}
          className={classNames('pointer', { 'p-hidden': imageState?.url })}
        >
          Select Image
        </label>
        <input
          type="file"
          accept="image/*"
          ref={imageFileRef}
          className="p-hidden"
          onChange={handelImageSelect}
        />
      </div>
      {imageState?.url && (
        <div className=" w-full">
          <img
            className="product-image  p-mb-2"
            src={imageState?.url}
            alt={imageState?.file?.name}
          />
          <div className="p-formgrid p-grid">
            <div className="p-col-6">
              <Button
                label="Change Image"
                icon="pi pi-image"
                className="p-button"
                onClick={handleImageChange}
              />
            </div>
            <div className="p-col-6">
              <Button
                label="Clear Image"
                icon="pi pi-times"
                className="p-button-danger"
                onClick={handelClearImage}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
