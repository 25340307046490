import React, { useState, useEffect, useCallback } from 'react'
import ActivitiesDataTable from '../../components/mycomponents/ActivitiesDataTable'
import { getAllHistory } from '../../api'
import { assignDates, dateFilters } from '../../functions/myDateFns'

export default function MyActivitiesPage() {
  const [activities, setActivities] = useState([])
  const pageType = 'MyActivities'
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))

  /* Loading */
  const [loading, setLoading] = useState(false)
  const getActivities = useCallback(async () => {
    setLoading(true)
    const res = await getAllHistory({ ...dateFilter, onlyMy: true })
    if (res && res.status === 200) {
      setActivities(res.data)
    } else {
      setActivities([])
    }
    setLoading(false)
  }, [dateFilter])

  useEffect(() => {
    getActivities()
  }, [getActivities])

  return (
    <>
      <ActivitiesDataTable
        activities={activities}
        setActivities={setActivities}
        getActivities={getActivities}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        pageType={pageType}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  )
}
