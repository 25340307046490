import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { RadioButton } from 'primereact/radiobutton'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { logoutApi, patchAvatar } from '../api'
import { subscribeToNotification, unsubscribeToNotification } from '../api/notification'
import ChangePassword from '../components/mini/ChangePassword'
import { useGlobalContext } from '../context'
import { checkIfSubscriptionExistsSw, registerNotificationSw } from '../functions/notificationUtil'
import MyActivitiesPage from '../pages/MyPages/MyActivitiesPage'
import MyNotificationsPage from '../pages/MyPages/MyNotificationsPage'

// AppProfile component to manage the profile sidebar
const AppProfile = (props) => {
  const { user, setUser, toast } = useGlobalContext()
  const [notification, setNotification] = useState(false)
  useEffect(() => {
    const fn = async () => {
      let res = await checkIfSubscriptionExistsSw()
      setNotification(res)
    }
    fn()
  }, [])

  const onNotificationChange = async (e) => {
    try {
      if (e.value) {
        setNotification(true)
        const serviceWorkerReg = await registerNotificationSw()
        await subscribeToNotification(serviceWorkerReg)
      } else {
        setNotification(false)
        const serviceWorkerReg = await registerNotificationSw()
        await unsubscribeToNotification(serviceWorkerReg)
        toast?.current?.show({
          severity: 'success',
          summary: 'Notifications Disabled',
          detail: `You will not receive notifications from the CRM`,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const avatars = [
    { name: 'Avatar 1', code: 'avatar-1' },
    { name: 'Avatar 2', code: 'avatar-2' },
    { name: 'Avatar 3', code: 'avatar-3' },
    { name: 'Avatar 4', code: 'avatar-4' },
    { name: 'Avatar 5', code: 'avatar-5' },
    { name: 'Avatar 6', code: 'avatar-6' },
    { name: 'Avatar 7', code: 'avatar-7' },
  ]

  const history = useHistory()

  const [editable, setEditable] = useState(false)
  const [logoutLoading, setLogoutLoading] = useState(false)

  //avatar
  const [selectedAvatar, setSelectedAvatar] = useState(user?.avatar)
  useEffect(() => {
    if (user) {
      setSelectedAvatar(user?.avatar)
    }
  }, [user])

  const logoutUser = async () => {
    setLogoutLoading(true)
    localStorage.removeItem('token')
    const res = await logoutApi()
    if (res) {
      setLogoutLoading(false)
      if (res.status === 200) {
        history.push('/login')
      }
    }
  }

  const onAvatarChange = async (e) => {
    const value = e.value
    setSelectedAvatar(value)
    const res = await patchAvatar({ avatar: value })
    if (res) {
      if (res.status === 200) {
        setUser((prev) => ({
          ...prev,
          avatar: res.data.avatar,
        }))
      }
    }
  }

  const avatarOptionTemplate = (option) => {
    return (
      <div className="p-d-flex gap-1 p-ai-center" style={{ borderRadius: '4px' }}>
        <img
          alt={option.name}
          src={`https://globalpharma-s3-bucket.s3.us-east-2.amazonaws.com/Avatars/${option.code}.png`}
          onError={(e) =>
            (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
          }
          className="w-2"
          style={{ borderRadius: '50px' }}
        />
        <div>{option.name}</div>
      </div>
    )
  }

  return (
    <>
      <div id="layout-config">
        <span className="layout-rightmenu-button-desktop">
          {/* button to open profile sidebar */}
          <Button
            label={user.username || 'Profile'}
            icon="pi pi-user"
            className="layout-rightmenu-button"
            onClick={props.onRightMenuButtonClick}
            style={{
              position: 'fixed',
              top: '10px',
              right: '0px',
            }}
          ></Button>
        </span>
      </div>
      <div
        className={classNames('layout-rightmenu p-shadow-3', {
          'layout-rightmenu-active': props.rightMenuActive,
        })}
        onClick={props.onRightMenuClick}
      >
        <Button
          icon="pi pi-times"
          className="layout-rightmenu-close p-link"
          onClick={() => props.onRightMenuActiveChange(false)}
        />
        <TabView className="p-mt-2">
          <TabPanel header="Profile">
            <div className="card p-d-flex p-flex-column gap-1by2">
              {user && (
                <div className="p-d-flex p-ai-center gap-1">
                  <div
                    className="h-5 w-5"
                    style={{
                      borderRadius: '50%',
                    }}
                  >
                    <img
                      alt={user.avatar}
                      src={`https://globalpharma-s3-bucket.s3.us-east-2.amazonaws.com/Avatars/${user.avatar}.png`}
                      onError={(e) =>
                        (e.target.src =
                          'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')
                      }
                      className="w-full"
                      style={{ borderRadius: '50px' }}
                    />
                  </div>

                  <div>
                    <h4 className="user-name p-m-0">{user.username}</h4>
                    <p className="user-number p-m-0 p-text-capitalize">
                      {user.id} | {user.role}
                    </p>
                    <p className="p-m-0 " style={{ color: 'var(--teal-400)' }}>
                      {user.email}
                    </p>
                  </div>
                </div>
              )}
              <div className="">
                <Button
                  icon={!editable ? 'pi pi-pencil' : 'pi pi-arrow-left'}
                  label={!editable ? ' Edit Profile ' : 'Back'}
                  className=" p-button-text"
                  onClick={() => {
                    setEditable(!editable)
                  }}
                />
              </div>
              {editable && (
                <div>
                  <Dropdown
                    value={selectedAvatar}
                    options={avatars}
                    onChange={onAvatarChange}
                    optionValue="code"
                    optionLabel="name"
                    itemTemplate={avatarOptionTemplate}
                    placeholder="Select a Avatar"
                    className="w-full"
                  />
                  <hr />
                  <ChangePassword showToaster={props.showToaster} />
                </div>
              )}
            </div>
            <div className="w-full">
              <Button
                label={logoutLoading ? 'Logging Out...' : 'Logout'}
                icon="pi pi-unlock"
                className="w-full"
                disabled={logoutLoading}
                loading={logoutLoading}
                onClick={logoutUser}
              />
            </div>
            <hr />
            <div className="w-full">
              <h5>Color Scheme</h5>
              <div className="p-field-radiobutton">
                <RadioButton
                  name="colorScheme"
                  value="light"
                  id="theme1"
                  checked={props.colorScheme === 'light'}
                  onChange={() => props.onColorSchemeChange('light')}
                ></RadioButton>
                <label htmlFor="theme1">Light</label>
              </div>
              <div className="p-field-radiobutton">
                <RadioButton
                  name="colorScheme"
                  value="dark"
                  id="theme2"
                  checked={props.colorScheme === 'dark'}
                  onChange={() => props.onColorSchemeChange('dark')}
                />
                <label htmlFor="theme2">Dark</label>
              </div>
            </div>
            <hr />
            <h5>Push Notifications</h5>
            <InputSwitch
              checked={notification}
              onChange={onNotificationChange}
              disabled={!('serviceWorker' in navigator)}
            />
          </TabPanel>
          <TabPanel header="Notifications">
            <MyNotificationsPage />
          </TabPanel>
          <TabPanel header="Activities">
            <MyActivitiesPage />
          </TabPanel>
        </TabView>
      </div>
    </>
  )
}

export default AppProfile
