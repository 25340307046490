import axios from 'axios'
import endpoints from './endpoints'

export const getWebsiteBanners = async () => {
  try {
    return await axios.get(endpoints.websiteBanners.index)
  } catch (error) {
    return error.response
  }
}

export const getWebsiteBannerById = async (id) => {
  try {
    return await axios.get(endpoints.websiteBanners.index + id)
  } catch (error) {
    return error.response
  }
}

export const putWebsiteBanner = async (bannerData, id) => {
  try {
    const res = await axios.put(endpoints.websiteBanners.index + id, bannerData)
    return res
  } catch (error) {
    return error.response
  }
}

export const postWebsiteBanner = async (bannerData) => {
  try {
    const res = await axios.post(endpoints.websiteBanners.index, bannerData)
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteWebsiteBanner = async (id, password) => {
  try {
    const res = await axios.delete(endpoints.websiteBanners.index + id, {
      data: { password },
    })
    return res
  } catch (error) {
    return error.response
  }
}
