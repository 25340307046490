import axios from 'axios'
import endpoints from './endpoints'

export const getAllProductsShipping = async () => {
  try {
    const res = await axios.get(endpoints.shipping.index)
    return res
  } catch (error) {
    return error.response
  }
}

export const addMultipleProductToShipper = async (products, shipper) => {
  try {
    const res = await axios.post(endpoints.shipping.index, {
      products,
      shipper,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const removeMultipleChargesFromProduct = async (ids) => {
  try {
    const data = JSON.stringify({ ids })
    const res = await axios.delete(endpoints.shipping.index, { data })
    return res
  } catch (error) {
    return error.response
  }
}

export const changeMultipleChargesFromProduct = async (ids, company) => {
  try {
    const res = await axios.patch(endpoints.shipping.index, { ids, company })
    return res
  } catch (error) {
    return error.response
  }
}

export const getShippingCompanies = async () => {
  try {
    const res = await axios.get(endpoints.shipping.company.index)
    return res
  } catch (error) {
    return error.response
  }
}

export const addShippingCompany = async (data) => {
  try {
    const res = await axios.post(endpoints.shipping.company.index, { ...data })
    return res
  } catch (error) {
    return error.response
  }
}

export const getShippingCompany = async (code) => {
  try {
    const res = await axios.get(endpoints.shipping.company.index + code)
    return res
  } catch (error) {
    return error.response
  }
}

export const updateShippingCompany = async (data) => {
  try {
    const {
      companyName,
      companyTag,
      companyProvider,
      companyLink,
      expectedMinDays,
      expectedMaxDays,
      arrivalMinDays,
      arrivalMaxDays,
    } = data
    const res = await axios.patch(endpoints.shipping.company.index + companyTag, {
      companyName,
      companyTag,
      companyProvider,
      companyLink,
      expectedMinDays,
      expectedMaxDays,
      arrivalMinDays,
      arrivalMaxDays,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteShippingCompany = async (tag, password) => {
  try {
    var data = JSON.stringify({
      password,
    })
    const res = await axios.delete(endpoints.shipping.company.index + tag, {
      data,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getShippingCompanyTags = async () => {
  try {
    const res = await axios.get(endpoints.shipping.company.tags)
    return res
  } catch (error) {
    return error.reponse
  }
}

export const getShippingCharges = async (type, targetId, packType) => {
  try {
    const res = await axios.get(endpoints.shipping.charges.index, {
      params: {
        type,
        targetId,
        packType,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getChargesDetailsById = async (id) => {
  try {
    const res = await axios.get(endpoints.shipping.charges.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const addShippingCharges = async ({ target, targetId, charges }) => {
  try {
    const res = await axios.post(endpoints.shipping.charges.index, {
      target,
      targetId,
      charges,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteShippingCharge = async (id, password) => {
  try {
    var data = JSON.stringify({
      password,
    })
    const res = await axios.delete(endpoints.shipping.charges.index + id, {
      data,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateShippingCharge = async (id, target, targetId, charges) => {
  try {
    const res = await axios.patch(endpoints.shipping.charges.index + id, {
      target,
      targetId,
      charges,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getTotalShippingChargeMeta = async ({ type, code }) => {
  try {
    const res = await axios.get(endpoints.shipping.shippingChargeMeta, {
      params: {
        type,
        code,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getShippingTimeline = async (shipper) => {
  try {
    return await axios.get(endpoints.shipping.company.timeline + shipper)
  } catch (error) {
    return error.response
  }
}

export const getShippingTimelines = async () => {
  try {
    return await axios.get(endpoints.shipping.company.timeline)
  } catch (error) {
    return error.response
  }
}

export const putShippingTimeline = async (shipper, { records }) => {
  try {
    return await axios.put(endpoints.shipping.company.timeline + shipper, {
      records,
    })
  } catch (error) {
    return error.response
  }
}

export const getShippingChargesForProductById = async (id) => {
  try {
    return await axios.get(endpoints.shipping.charges.product + id)
  } catch (error) {
    return error.response
  }
}
