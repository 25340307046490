import classNames from 'classnames'
import { Button } from 'primereact/button'
import { Chips } from 'primereact/chips'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { addNewProduct } from '../../../api'
import { useGlobalContext } from '../../../context'

import discountFns from '../../../functions/discountFns'
import { handleObjChange } from '../../../functions/setter'
import { Checkbox } from 'primereact/checkbox'
// AddNewProductDialog component to manage the add new product dialog
export default function AddNewProductDialog({
  productDialog,
  setProductDialog,
  handelSyncAddProduct,
}) {
  /**
   * productDialog @type {boolean} - boolean to check if dialog is visible
   * setProductDialog @type {function} - function to set dialog visible
   * handelSyncAddProduct @type {function} - function to sync add product in table
   **/
  const {
    productTypes,
    shippingTags,
    productCategoriesGroups: ctx_productCategoriesGroups,
  } = useGlobalContext()
  const multipleImages = useRef(null)
  const featureImage = useRef(null)

  // Static
  const emptySQ = {
    strength: null,
    quantityNo: null,
    quantityPrice: null,
  }
  // quantityCost: 0,
  // quantityPriceDiscounted: 0,
  const emptyImage = { imagetitle: '', imagealt: '' }
  const emptyProduct = {
    shippingCompany: '',
    productName: '',
    productNameAlt: [],
    productSeoTitle: '',
    productSeoDescription: '',
    productDescription: '',
    productMainCategory: [],
    productCategory: [],
    websites: [],
    productSlug: '',
    productStatus: '',
    isStockOut: false,
    bestseller: '',
    productType: '',
    productDiscount: 0,
    strengths: [
      {
        strength: '',
        cost: 0,
      },
    ],
    quantities: [{ ...emptySQ }],
    ingredients: [],
    manufacturedBy: [],
    featuredImageMeta: { emptyImage },
    howToUseMce: '',
    drugInteractionsMce: '',
    sideEffectsMce: '',
    warningsAndPrecautionsMce: '',
    withdrawalSymptomsMce: '',
    drugAbuseMce: '',
    storageMce: '',
    customMce: '',
    loading: false,
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [featuredImage, setFeaturedImage] = useState({ file: null, url: '' })
  const [product, setProduct] = useState(emptyProduct)
  const [error, setError] = useState(emptyErr)

  const [productImages, setProductImages] = useState([])
  const [subCategoryData, setSubCategoryData] = useState([
    {
      label: 'Active',
      items: [],
    },
    {
      label: 'Inactive',
      items: [],
    },
  ])
  // STAY AWAY
  // const onInputChange = (target, value) => {
  //   let _product = { ...product }
  //   _product[target] = value
  //   if (target === 'productName') {
  //     _product.productSeoTitle = value
  //     _product.productSlug = value.replaceAll(' ', '-').toLowerCase()
  //   }
  //   if (target === 'productDescription') {
  //     _product.productSeoDescription = value
  //   }

  //   setProduct(_product)
  // }

  const structureItems = (items, value = null) => {
    if (!items) return

    const filterItems = (status) =>
      items.filter(
        (item) => item.status === status && (value ? value.includes(item.parentCatId) : true)
      )

    return [
      {
        label: 'Active',
        items: filterItems('active'),
      },
      {
        label: 'Inactive',
        items: filterItems('inactive'),
      },
    ]
  }

  const mainCategoryData = structureItems(ctx_productCategoriesGroups[0]?.items)

  const handleProdImagesUpload = (e) => {
    const files = e.target.files
    const imgs = []
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i)
      imgs.push({
        file: file,
        url: URL.createObjectURL(file),
      })
    }
    let temp = [...productImages].concat(imgs)
    setProductImages(temp)
  }

  const toast = useRef(null)

  const clearFiles = () => {
    setProduct((ps) => ({ ...ps, featuredImageMeta: emptyImage }))
    setFeaturedImage({})
  }

  const hideDialog = () => {
    setProductDialog(false)
    setFeaturedImage({})
    setProductImages([])
    setProduct({ ...emptyProduct })
  }

  const handelSaveEvt = async () => {
    let _productImages = productImages.map((i) => i.file)
    let _product = { ...product }

    _product.strengths = _product.strengths.map((item) => item.strength)
    _product.quantities = _product.quantities.map((item) => {
      item.quantityCost = item.strength.cost
      item.strength = item.strength.strength
      item.quantityPriceDiscounted = discountFns(item.quantityPrice, _product.productDiscount)
      return item
    })
    _product.productCategory = _product.productCategory.join(',')
    _product.productMainCategory = _product.productMainCategory.join(',')

    setProduct((ps) => ({ ...ps, loading: true }))
    const res = await addNewProduct(_product, featuredImage.file, _productImages)

    if (res) {
      setProduct((ps) => ({ ...ps, loading: false }))
      if (res.status === 200) {
        hideDialog()
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Product added successfully',
        })
        handelSyncAddProduct(res.data.productCode)
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res?.data?.error || 'Something went wrong',
        })
      }
    }
  }

  const itemTemplate = (option) => {
    return (
      <div
        className={classNames({
          'opacity-50': option.status === 'inactive' ? true : false,
        })}
      >
        {option.name}
      </div>
    )
  }

  const checkAllFieldValid = () => {
    const errs = []
    const {
      shippingCompany,
      productName,
      productSeoTitle,
      productSeoDescription,
      productDescription,
      productMainCategory,
      productSlug,
      productStatus,
      isStockOut,
      bestseller,
      productType,
      strengths,
      quantities,
      ingredients,
      featuredImageMeta,
    } = product

    if (!featuredImage.file) {
      errs.push('Featured Image Invalid')
    }

    if (!shippingCompany) {
      errs.push('Shipping Company Invalid')
    }
    if (!productName) {
      errs.push('Product Name Invalid')
    }
    if (!productSeoTitle) {
      errs.push('Product Seo-Title Invalid')
    }
    if (!productSeoDescription) {
      errs.push('Product Seo-Description Invalid')
    }
    if (!productDescription) {
      errs.push('Product Description Invalid')
    }
    if (!productMainCategory || productMainCategory.length === 0) {
      errs.push('Product Main Categories Invalid')
    }
    if (!productSlug) {
      errs.push('Product Slug Invalid')
    }
    if (typeof isStockOut !== 'boolean') {
      errs.push('Stock Out Invalid')
    }
    if (!productStatus) {
      errs.push('Product Status Invalid')
    }
    if (!bestseller) {
      errs.push('Bestseller Invalid')
    }
    if (!productType) {
      errs.push('Product-Type Invalid')
    }
    if (!strengths) {
      errs.push('Strengths Invalid')
    }
    if (!quantities || quantities.some((qty) => Object.values(qty).includes(null))) {
      errs.push('Quantities Invalid')
    }
    if (!ingredients || ingredients.length === 0) {
      errs.push('Ingredients Invalid')
    }

    return errs
  }

  const productDialogFooter = (
    <>
      {!product.loading && (
        <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      )}
      <Button
        label={product.loading ? 'Saving...' : 'Save'}
        icon="pi pi-save"
        loading={product.loading}
        disabled={product.loading}
        className="p-button-primary"
        onClick={() => {
          const result = checkAllFieldValid()
          if (result.length > 0) {
            setError({
              state: true,
              errors: result,
            })
          } else {
            setError(emptyErr)
            handelSaveEvt()
          }
        }}
        autoFocus
      />
    </>
  )

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={productDialog}
        style={{ width: '90vw' }}
        header="Add Product"
        modal
        className="p-fluid"
        footer={productDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-grid p-formgrid">
          <div className="p-col-12 p-lg-4">
            <div className="card p-shadow-3">
              <div className="">
                <h4 className="p-p-0">Feature Product Image</h4>
                <div className="p-d-flex p-flex-column p-jc-end">
                  {featuredImage.file ? (
                    <Button
                      icon="pi pi-times"
                      label="Clear Image"
                      className="p-button-danger"
                      tooltipOptions={{ position: 'bottom' }}
                      onClick={() => clearFiles()}
                    />
                  ) : (
                    <>
                      <input
                        type="file"
                        className="p-hidden"
                        accept="image/*"
                        ref={featureImage}
                        onChange={(e) => {
                          const img = {
                            file: e.target.files[0],
                            url: URL.createObjectURL(e.target.files[0]),
                          }
                          setFeaturedImage(img)
                        }}
                      />
                      <Button
                        icon="pi pi-image"
                        label="Choose Image"
                        onClick={() => featureImage.current.click()}
                      />
                    </>
                  )}
                  {/* Err */}
                </div>
              </div>
              <hr />
              <div className="p-p-2">
                <FeaturedImage image={featuredImage} product={product} setProduct={setProduct} />
              </div>
            </div>
            <div className="card p-shadow-3">
              <div className="">
                <h4 className="p-p-0">Product Images</h4>
                <div className="p-d-flex gap-1">
                  <input
                    multiple
                    type="file"
                    name=""
                    className="p-hidden"
                    accept="image/*"
                    ref={multipleImages}
                    onChange={handleProdImagesUpload}
                  />

                  <Button
                    icon="pi pi-plus"
                    label="Add Multiple Images"
                    onClick={() => {
                      multipleImages.current.click()
                    }}
                  />
                </div>
              </div>
              <hr />
              <div className="p-p-2">
                <ProductImages productImages={productImages} setProductImages={setProductImages} />
              </div>
            </div>
          </div>
          <div className="p-col-12 p-lg-8">
            <div className="card p-shadow-3 ">
              <div className="p-grid p-formgrid">
                <div className="p-field p-col-12 p-lg-6">
                  <label>Product Name {!product.productName && <Required />}</label>
                  <InputText
                    id="name"
                    required
                    autoFocus
                    value={product.productName}
                    className={classNames({
                      random: false,
                    })}
                    onChange={(e) => {
                      handleObjChange(setProduct, 'productName', e.target.value)
                      handleObjChange(setProduct, 'productSeoTitle', e.target.value)
                      handleObjChange(
                        setProduct,
                        'productSlug',
                        e.target.value.replaceAll(' ', '-').toLowerCase()
                      )
                    }}
                    placeholder="Enter Product Name"
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label>Product SEO Name {!product.productSeoTitle && <Required />}</label>
                  <InputText
                    id="name"
                    required
                    autoFocus
                    value={product.productSeoTitle}
                    onChange={(e) => {
                      handleObjChange(setProduct, 'productSeoTitle', e.target.value)
                    }}
                    placeholder="Enter Product SEO"
                  />
                </div>

                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="quantity">
                    Product Slug {!product.productSlug && <Required />}
                  </label>
                  <InputText
                    placeholder="Enter Product Slug"
                    value={product.productSlug}
                    onChange={(e) => handleObjChange(setProduct, 'productSlug', e.target.value)}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="price">Discount</label>
                  <InputNumber
                    showButtons
                    buttonLayout="horizontal"
                    incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus"
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    min={0}
                    max={100}
                    suffix="%"
                    placeholder="Enter Discount"
                    value={product.productDiscount}
                    onValueChange={(e) => {
                      handleObjChange(setProduct, 'productDiscount', e.value)
                    }}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="productDescription">
                    Product Description {!product.productDescription && <Required />}
                  </label>
                  <InputTextarea
                    id="productDescription"
                    value={product.productDescription}
                    onChange={(e) => {
                      handleObjChange(setProduct, 'productDescription', e.target.value),
                        handleObjChange(setProduct, 'productSeoDescription', e.target.value)
                    }}
                    required
                    rows={3}
                    cols={20}
                    placeholder="Enter Product Description"
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="productSeoDescription">
                    {' '}
                    Product SEO Description {!product.productSeoDescription && <Required />}
                  </label>
                  <InputTextarea
                    id="productSeoDescription"
                    value={product.productSeoDescription}
                    onChange={(e) =>
                      handleObjChange(setProduct, 'productSeoDescription', e.target.value)
                    }
                    required
                    rows={3}
                    cols={20}
                    placeholder="Enter Product SEO Description"
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="price">
                    Main Categories {!product.productMainCategory.length && <Required />}
                  </label>
                  <MultiSelect
                    options={mainCategoryData}
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionLabel="name"
                    optionValue="name"
                    filter
                    filterBy="name"
                    filterPlaceholder="Search Categories"
                    value={product.productMainCategory}
                    placeholder="Select Category"
                    showClear
                    itemTemplate={itemTemplate}
                    onChange={(e) => {
                      const values = e.value || []
                      if (!values.length) {
                        handleObjChange(setProduct, 'productCategory', [])
                      }
                      const inactiveIdx = mainCategoryData[1]?.items?.filter((elem) =>
                        values.includes(elem.name)
                      )
                      const activeData = mainCategoryData[0]?.items
                        ?.filter((elem) => values.includes(elem.name))
                        .map((elem) => elem.id)
                      if (inactiveIdx?.length > 0) {
                        return
                      }
                      setSubCategoryData(
                        structureItems(ctx_productCategoriesGroups[1]?.items, activeData) || []
                      )
                      handleObjChange(setProduct, 'productMainCategory', values || [])
                    }}
                  />

                  {/* <Dropdown
                    options={
                      ctx_productCategoriesGroups[0]?.items?.filter(
                        (category) => category.status === 'active'
                      ) || []
                    }
                    optionLabel="name"
                    optionValue="name"
                    filter
                    filterBy="name"
                    filterPlaceholder="Search Categories"
                    value={product.productMainCategory}
                    onChange={(e) => {
                      const findRecord = ctx_productCategoriesGroups[0]?.items?.find(
                        (category) => category.name === e.value
                      )

                      handleObjChange(setProduct, 'productMainCategory', e.value || [])
                      setFilteredSubCategory(
                        ctx_productCategoriesGroups[1]?.items?.filter(
                          (category) =>
                            category?.parentCatId === findRecord?.id &&
                            category?.status === 'active'
                        )
                      )
                      handleObjChange(setProduct, 'productCategory', [])
                      // onInputChange('productCategory', '')
                    }}
                    placeholder="Select Category"
                    showClear
                  /> */}
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="price">Sub Categories </label>
                  <MultiSelect
                    options={subCategoryData}
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionLabel="name"
                    optionValue="name"
                    filter
                    filterBy="name"
                    filterPlaceholder="Search Categories"
                    itemTemplate={itemTemplate}
                    value={product.productCategory}
                    onChange={(e) => {
                      const values = e.value || []
                      const inactiveIdx = subCategoryData[1]?.items?.filter((elem) =>
                        values.includes(elem.name)
                      )
                      if (inactiveIdx?.length > 0) {
                        return
                      }
                      handleObjChange(setProduct, 'productCategory', values)
                    }}
                    placeholder="Select Categories"
                    showClear
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="price">Product Type {!product.productType && <Required />}</label>
                  <Dropdown
                    editable
                    options={productTypes}
                    value={product.productType}
                    onChange={(e) => handleObjChange(setProduct, 'productType', e.value)}
                    placeholder="Select Type"
                    filter
                    filterPlaceholder="Search Type"
                    className="multiselect-custom"
                  />
                </div>
                <div className="p-field p-col-12 p-lg-3">
                  <label htmlFor="price">Status {!product.productStatus && <Required />}</label>
                  <Dropdown
                    options={['active', 'inactive']}
                    placeholder="Select Status"
                    value={product.productStatus}
                    onChange={(e) => handleObjChange(setProduct, 'productStatus', e.value)}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-3">
                  <label htmlFor="price">Stock Out</label>
                  <div>
                    <Checkbox
                      checked={!!product?.isStockOut}
                      onChange={(e) => handleObjChange(setProduct, 'isStockOut', e.checked)}
                    />
                  </div>
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="price">Bestseller {!product.bestseller && <Required />}</label>
                  <Dropdown
                    options={['Yes', 'No']}
                    placeholder="Select Bestseller Or Not"
                    value={product.bestseller}
                    onChange={(e) => handleObjChange(setProduct, 'bestseller', e.value)}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label htmlFor="price">
                    Shipping Company {!product.shippingCompany && <Required />}
                  </label>
                  <Dropdown
                    options={shippingTags}
                    placeholder="Select Shipping Company"
                    value={product.shippingCompany}
                    onChange={(e) => handleObjChange(setProduct, 'shippingCompany', e.value)}
                  />
                </div>

                <div className="p-field p-col-12 p-lg-6">
                  <label>Ingredients {product?.ingredients.length === 0 && <Required />}</label>
                  <Chips
                    placeholder="Enter Ingredients"
                    value={product.ingredients}
                    onChange={(e) => handleObjChange(setProduct, 'ingredients', e.value)}
                  />
                </div>
                <div className="p-field p-col-12 p-lg-6">
                  <label>Alternative / Trade Names</label>
                  <Chips
                    placeholder="Enter alternative / trade Names"
                    value={product.productNameAlt}
                    onChange={(e) => handleObjChange(setProduct, 'productNameAlt', e.value)}
                  />
                </div>
                <div className="p-field p-col-12">
                  <label>Manufactured By</label>
                  <Chips
                    placeholder="Enter Manufacturer Names"
                    value={product.manufacturedBy}
                    onChange={(e) => handleObjChange(setProduct, 'manufacturedBy', e.value)}
                  />
                </div>
                <div className="p-mb-2 p-col-6">
                  <label>Strengths</label>
                </div>
                <div className="p-mb-2 p-col-6">
                  <label>Buying Prices</label>
                </div>
                {product.strengths.map((strength, index) => {
                  return (
                    <>
                      <div className="p-field p-col-6">
                        <InputText
                          value={strength.strength}
                          placeholder="Enter Strength"
                          onChange={(e) => {
                            let temp = [...product.strengths]
                            temp = temp.map((item, i) => {
                              if (i === index) {
                                item.strength = e.target.value
                              }
                              return item
                            })
                            handleObjChange(setProduct, 'strengths', temp)
                          }}
                        />
                      </div>
                      <div className="p-field p-col-4">
                        <InputNumber
                          value={strength.cost}
                          currency="USD"
                          mode="currency"
                          placeholder="Enter Buying Amount"
                          step={0.25}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          onValueChange={(e) => {
                            let temp = [...product.strengths]
                            temp = temp.map((item, i) => {
                              if (i === index) {
                                item.cost = e.value
                              }
                              return item
                            })
                            handleObjChange(setProduct, 'strengths', temp)
                          }}
                        />
                      </div>
                      <div className="p-field p-col-2 p-d-flex gap-1 p-jc-center p-ai-center">
                        <Button
                          icon="pi pi-trash"
                          disabled={product.strengths.length === 1}
                          className="p-button-danger"
                          onClick={() => {
                            let temp = [...product.strengths]
                            temp = temp.filter((item, i) => i !== index)
                            handleObjChange(setProduct, 'strengths', temp)
                          }}
                        />
                        <Button
                          icon="pi pi-plus"
                          onClick={() => {
                            let temp = [...product.strengths]
                            temp.push({ strength: '', cost: 0 })
                            setProduct({ ...product, strengths: temp })
                          }}
                        />
                      </div>
                    </>
                  )
                })}
                {product.quantities.length > 0 && (
                  <>
                    <div className="p-mb-2 p-col-2">
                      <label>Strength</label>
                    </div>
                    <div className="p-mb-2 p-col-2">
                      <label>Quantity</label>
                    </div>
                    <div className="p-mb-2 p-col-2">
                      <label>Pill Price</label>
                    </div>
                    <div className="p-mb-2 p-col-2">
                      <label>Total Price</label>
                    </div>
                    <div className="p-mb-2 p-col-4">
                      <label>Discounted Price</label>
                    </div>
                  </>
                )}
                {product.quantities.map((quantity, i) => {
                  return (
                    <>
                      <div className="p-field p-col-2">
                        <Dropdown
                          value={quantity.strength}
                          onChange={(e) => {
                            let temp = { ...product }
                            temp.quantities[i].strength = e.value
                            setProduct(temp)
                          }}
                          optionLabel="strength"
                          options={product.strengths}
                          placeholder="Strength"
                        />
                        {!quantity.strength && <Required />}
                      </div>
                      <div className="p-field p-col-2">
                        <InputNumber
                          step={50}
                          value={quantity.quantityNo}
                          onValueChange={(e) => {
                            let temp = { ...product }
                            temp.quantities[i].quantityNo = e.value
                            setProduct(temp)
                          }}
                          placeholder="Enter Quantity Nos."
                        />
                        {!quantity.quantityNo && <Required />}
                      </div>
                      <div className="p-field p-col-2">
                        <InputNumber
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          step={0.1}
                          onValueChange={(e) => {
                            let temp = { ...product }
                            temp.quantities[i].quantityPrice = e.value
                            setProduct(temp)
                          }}
                          placeholder="Price"
                          value={quantity.quantityPrice}
                        />
                        {!quantity.quantityPrice && <Required />}
                      </div>
                      <div className="p-field p-col-2">
                        <InputNumber
                          disabled
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          value={quantity.quantityPrice * quantity.quantityNo}
                        />
                      </div>
                      <div className="p-field p-col-2">
                        <InputNumber
                          disabled
                          mode="currency"
                          currency="USD"
                          locale="en-US"
                          value={discountFns(
                            quantity.quantityPrice * quantity.quantityNo,
                            product.productDiscount
                          )}
                          step={0.25}
                        />
                      </div>
                      <div className="p-field p-col-2 p-mb-auto">
                        <div className=" p-d-flex p-jc-center" style={{ gap: '1rem' }}>
                          <Button
                            className="p-button-icon p-button-danger"
                            icon="pi pi-trash"
                            tooltip="Delete"
                            disabled={product.quantities.length === 1}
                            onClick={() => {
                              let temp = { ...product }
                              if (temp.quantities.length === 1) return
                              temp.quantities = temp.quantities.filter((_, id) => id !== i)
                              setProduct(temp)
                            }}
                            tooltipOptions={{ position: 'bottom' }}
                          />

                          <Button
                            className="p-button-icon"
                            icon="pi pi-plus"
                            tooltip="Add"
                            onClick={() => {
                              let temp = { ...product }
                              temp.quantities.push({ ...emptySQ })
                              setProduct(temp)
                            }}
                            tooltipOptions={{ position: 'bottom' }}
                          />
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="p-col-12">
            <div className="card p-shadow-3">
              {/* Withdrawal Symptoms */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">Withdrawal Symptoms</h3>
                <div className="">
                  <ReactQuill
                    value={product.withdrawalSymptomsMce}
                    onChange={(val) => handleObjChange(setProduct, 'withdrawalSymptomsMce', val)}
                    placeholder="Withdrawal Symptoms"
                  />
                </div>
              </div>
              {/* Side Effects */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">Side Effects</h3>
                <div className="">
                  <ReactQuill
                    value={product.sideEffectsMce}
                    onChange={(val) => handleObjChange(setProduct, 'sideEffectsMce', val)}
                    placeholder="Side Effects"
                  />
                </div>
              </div>
              {/* Warnings and Precautions */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">Warnings and Precautions</h3>
                <div className="">
                  <ReactQuill
                    value={product.warningsAndPrecautionsMce}
                    onChange={(val) =>
                      handleObjChange(setProduct, 'warningsAndPrecautionsMce', val)
                    }
                    placeholder="Warnings and Precautions"
                  />
                </div>
              </div>
              {/* Storage */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">Storage</h3>
                <div className="">
                  <ReactQuill
                    value={product.storageMce}
                    onChange={(val) => handleObjChange(setProduct, 'storageMce', val)}
                    placeholder="Storage"
                  />
                </div>
              </div>
              {/* How To Use */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">How To Use</h3>
                <div className="">
                  <ReactQuill
                    value={product.howToUseMce}
                    onChange={(val) => handleObjChange(setProduct, 'howToUseMce', val)}
                    placeholder="How To Use"
                  />
                </div>
              </div>
              {/* Drug Abuse */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">Drug Abuse</h3>
                <div className="">
                  <ReactQuill
                    value={product.drugAbuseMce}
                    onChange={(val) => handleObjChange(setProduct, 'drugAbuseMce', val)}
                    placeholder="Drug Abuse"
                  />
                </div>
              </div>
              {/* Drug Interactions */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">Drug Interactions</h3>
                <div className="">
                  <ReactQuill
                    value={product.drugInteractionsMce}
                    onChange={(val) => handleObjChange(setProduct, 'drugInteractionsMce', val)}
                    placeholder="Drug Interactions"
                  />
                </div>
              </div>
              {/* Custom */}
              <div className="p-col-12">
                <h3 className="p-mt-2 p-text-center">Additional Info</h3>
                <div className="">
                  <ReactQuill
                    value={product.customMce}
                    onChange={(val) => handleObjChange(setProduct, 'customMce', val)}
                    placeholder="Custom"
                  />
                </div>
              </div>
            </div>
            {error.state && (
              <div className="card p-shadow-3">
                <h4 className="p-text-center p-mb-4">Warnings</h4>
                <div className="p-grid">
                  {error.errors.map((err, idx) => (
                    <div key={idx} className="p-col-12 p-lg-6 p-mb-3">
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  ))}
                  <div className="p-col-12">
                    <Button
                      onClick={() => setError(emptyErr)}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  )
}

const ProductImages = ({ productImages, setProductImages }) => {
  return (
    <div className="p-grid gap-1">
      {productImages.map((prodImage, idx) => {
        return (
          <div className="w-full" key={idx}>
            <div className="">
              <img className="product-image flex-1" src={prodImage.url} alt={prodImage.file.name} />
            </div>
            <div className="w-full">
              <Button
                icon="pi pi-minus"
                label="Remove"
                className="p-button-danger "
                onClick={() => {
                  setProductImages((ps) => ps.filter((_, i) => i !== idx))
                }}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

const FeaturedImage = ({ image, product, setProduct }) => {
  const handelInputChange = (target, value) => {
    let temp = { ...product }
    temp.featuredImageMeta[target] = value
    setProduct(temp)
  }

  return (
    <div className="p-d-flex p-flex-column">
      {image && image.file && image.url && (
        <div className="p-grid">
          {/* image */}
          <div>
            <img className="product-image p-mb-1" src={image.url} alt={image.file.name} />
          </div>

          {/* name */}
          <div className="w-full">
            {/* meta */}
            <div className="p-text-truncate" style={{ width: '10rem' }}>
              <strong>Name: </strong>
              <span>{image.file.name}</span>
            </div>
            <div className="p-text-truncate" style={{ width: '10rem' }}>
              <strong>Size: </strong>
              <span>{(image.file.size / 1000).toFixed(2)} KB</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const Required = () => <small className="p-error">*Required</small>
