import axios from 'axios'
import endpoints from './endpoints'

const getCart = async (cartId) => {
  try {
    const res = await axios.get(endpoints.cart.index + cartId)
    return res
  } catch (error) {
    return error.response
  }
}

const removeCartItem = async (itemId, password) => {
  try {
    const res = await axios.delete(endpoints.cart.index + itemId, { data: { password } })
    return res
  } catch (error) {
    return error.response
  }
}

const addCartItem = async (password,cartId, updatedCart) => {
  try {
    const res = await axios.put(endpoints.cart.index + cartId, {password, updatedCart: updatedCart })
    return res
  } catch (error) {
    return error.response
  }
}
export { getCart, removeCartItem, addCartItem }
