import React from 'react'
import { AiFillStar } from 'react-icons/ai'
// RatingStars component to show the rating stars
export default function RatingStars({ rating }) {
  /**
   * rating @type {number} - number of stars to show
   **/
  return (
    <div>
      <div className="p-d-flex gap-1by4">
        {Array.apply(null, Array(rating)).map((_, idx) => (
          <AiFillStar key={idx} color="gold" />
        ))}
      </div>
    </div>
  )
}
