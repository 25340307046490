import React, { useRef, useState, useEffect, useCallback } from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import AddDACRecord from '../../components/mycomponents/DisputeAndChargeback/AddDACRecord'
import ViewDACRecordNotes from '../../components/mycomponents/DisputeAndChargeback/ViewDACRecordNotes'
import { Toast } from 'primereact/toast'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '../../context'
import classNames from 'classnames'
import {
  getAllCategoryDetails,
  getAllDACIssues,
  getReopenIssueRequestById,
  patchIssueRequestStatus,
  putDACAgentById,
} from '../../api'
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetter'
import MyDatesPicker from '../../components/mini/MyDatesPicker'
import ViewDACRecord from '../../components/mycomponents/DisputeAndChargeback/ViewDACRecord'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { FaBalanceScaleRight } from 'react-icons/fa'
import { RiArrowTurnBackFill } from 'react-icons/ri'
import ConfirmationDialogWithInput from '../../components/mycomponents/Dialog/ConfirmationDialogWithInput'
import { Password } from 'primereact/password'
import useQuery from '../../hooks/useQuery'
import { Dialog } from 'primereact/dialog'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import { Message } from 'primereact/message'
import { format } from 'date-fns'
import { assignDates, dateFilters, dtFilenameDate } from '../../functions/myDateFns'
import ExportButton from '../../components/mini/ExportButton'
export default function DisputeAndChargebackPage() {
  const { toast } = useGlobalContext()
  const open_dt = useRef(null)
  const close_dt = useRef(null)
  const issueCloserCategories = [
    'Resolved on Call',
    'Resolved on Chat',
    'Resolved via Email',
    'Issue Withdrawn',
    'Order was reshipped',
    'Money was refunded',
    'Other',
  ]

  //complaint statuses
  const complaintStatuses = [
    { label: 'Open', value: 'open' },
    { label: 'Reopen', value: 'reopen' },
  ]

  const emptyReopenDetails = {
    issueId: '',
    agentId: '',
    agent: '',
    reopenReason: '',
  }

  const emptyReopenConfirmation = {
    status: '',
    dialogVisible: false,
  }

  const { user, agents, colorScheme } = useGlobalContext()
  const history = useHistory()
  const query = useQuery()
  const reopenId = parseInt(query.get('reopen_id'))
  //*States start
  const [dateCode, setDateCode] = useState(dateFilters[1].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))
  const [dateCode2, setDateCode2] = useState(dateFilters[1].code)
  const [dateRange2, setDateRange2] = useState(null)
  const [dateFilter2, setDateFilter2] = useState(assignDates(dateFilters[1].code))
  //* States Start
  const [openTableFilter, setOpenTableFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    issueType: { value: null, matchMode: FilterMatchMode.IN },
    issueSub: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [closeTableFilter, setCloseTableFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    assignedToId: { value: null, matchMode: FilterMatchMode.CONTAINS },
    orderBy: { value: null, matchMode: FilterMatchMode.CONTAINS },
    closedById: { value: null, matchMode: FilterMatchMode.CONTAINS },
    closeType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    issueType: { value: null, matchMode: FilterMatchMode.IN },
    issueSub: { value: null, matchMode: FilterMatchMode.IN },
  })
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [allOpenDACIssues, setAllOpenDACIssues] = useState([])
  const [allClosedDACIssues, setAllClosedDACIssues] = useState([])
  const [openIssuesTableLoading, setOpenIssuesTableLoading] = useState(false)
  const [closedIssuesTableLoading, setClosedIssuesTableLoading] = useState(false)

  const [updateAgentDialogVisible, setUpdateAgentDialogVisible] = useState(false)
  const [updateAgentDetails, setUpdateAgentDetails] = useState('')
  const [password, setPassword] = useState('')

  const [loading, setLoading] = useState(false)
  const [issuesCategories, setIssuesCategories] = useState([])

  const [reopenComplaintDialogVisible, setReopenComplaintDialogVisible] = useState(false)
  const [reopenRequestDetails, setReopenRequestDetails] = useState(emptyReopenDetails)
  const [reopenRequestConfirmation, setReopenRequestConfirmation] =
    useState(emptyReopenConfirmation)
  //*States end

  //* Handler to fetch all the issue categories from the database
  const fetchMainCategories = async () => {
    const res = await getAllCategoryDetails()
    if (res && res.status === 200) {
      setIssuesCategories(res.data)
    }
  }
  useEffect(() => {
    fetchMainCategories()
  }, [])

  //onchange for filter
  const onChangeFilter = (setter, value, target) => {
    setter((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }

  const hideTargetUpdateDialog = () => {
    setUpdateAgentDialogVisible(false)
    setPassword('')
  }

  //* Function to handle agent change with password for admin and manager
  const handleAgentChange = async () => {
    setOpenIssuesTableLoading(true)
    setLoading(true)
    const res = await putDACAgentById(updateAgentDetails, password)

    if (res) {
      setOpenIssuesTableLoading(false)
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Agent assigned successfully',
          detail: res.data.message,
        })
        let _filtered = allOpenDACIssues.map((complaint, idx) => {
          if (complaint.id === updateAgentDetails?.complaintId) {
            complaint.assignedToId = updateAgentDetails.agentId
          }
          return complaint
        })

        setAllOpenDACIssues(_filtered)
        hideTargetUpdateDialog()
      }
    }
  }

  //* Handler to fetch all open DAC Issues
  const fetchAllOpenDACIssues = useCallback(async () => {
    let status = ['open', 'reopen']
    status = status.map((stat) => `'${stat}'`).join(',')
    setOpenIssuesTableLoading(true)
    const res = await getAllDACIssues({
      status,
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
    })
    if (res) {
      setOpenIssuesTableLoading(false)
      if (res.status === 200) {
        setAllOpenDACIssues(res.data)
      }
    }
  }, [dateFilter.dateFrom, dateFilter.dateTo])
  useEffect(() => {
    fetchAllOpenDACIssues()
  }, [fetchAllOpenDACIssues])

  //* Handler to fetch all closed DAC Issues
  const fetchAllClosedDACIssues = useCallback(async () => {
    let status = ['close']
    status = status.map((stat) => `'${stat}'`).join(',')
    setClosedIssuesTableLoading(true)
    const res = await getAllDACIssues({
      status,
      dateFrom: dateFilter2.dateFrom,
      dateTo: dateFilter2.dateTo,
    })
    if (res) {
      setClosedIssuesTableLoading(false)
      if (res.status === 200) {
        setAllClosedDACIssues(res.data)
      }
    }
  }, [dateFilter2.dateFrom, dateFilter2.dateTo])
  useEffect(() => {
    fetchAllClosedDACIssues()
  }, [fetchAllClosedDACIssues])

  const closeReopenRequestDialog = () => {
    history.push({
      pathname: '/issues/dac',
    })
    setReopenComplaintDialogVisible(false)
    setReopenRequestDetails(emptyReopenDetails)
  }

  const fetchReopenRequestById = useCallback(async () => {
    if (!reopenId) return
    setClosedIssuesTableLoading(true)
    const res = await getReopenIssueRequestById(reopenId)
    if (res) {
      setClosedIssuesTableLoading(false)
      if (res.status === 200) {
        setReopenRequestDetails(res.data.value)
        setReopenComplaintDialogVisible(true)
      }
    }
  }, [reopenId])
  useEffect(() => {
    fetchReopenRequestById()
  }, [fetchReopenRequestById])

  const handlePatchRequestStatus = async () => {
    const { status } = reopenRequestConfirmation
    const res = await patchIssueRequestStatus(reopenId, status, password)
    if (res) {
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        setReopenRequestConfirmation(emptyReopenConfirmation)
        setPassword('')
        closeReopenRequestDialog()
        //TODO Remove network query and use state
        fetchAllClosedDACIssues()
        fetchAllOpenDACIssues()
      }
    }
  }

  const disputeBadge =
    colorScheme === 'dark'
      ? {
          backgroundColor: '#3A3F6A',
          borderRadius: '20px',
          color: '#7387FF',
        }
      : { backgroundColor: '#B2D2FD', borderRadius: '20px', color: '#2B47EB' }

  const chargebackBadge =
    colorScheme === 'dark'
      ? { backgroundColor: '#563333', borderRadius: '20px', color: '#FF7070' }
      : { backgroundColor: '#FDF0F0', borderRadius: '20px', color: '#DE2222' }

  // global filter search onchange
  const onGlobalFilterChange = (event, filter, setFilter) => {
    const value = event.target.value
    let _filters = { ...filter }

    _filters['global'].value = value

    setFilter(_filters)
  }
  const openDataTableFiltersHeader = () => {
    return (
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
        {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
          <>
            <div>
              <Dropdown
                value={openTableFilter.createdById.value}
                placeholder="Created Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setOpenTableFilters, e.value, 'createdById')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={openTableFilter.orderBy.value}
                placeholder="Order Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setOpenTableFilters, e.value, 'orderBy')
                }}
              ></Dropdown>
            </div>
          </>
        )}
        <MultiSelect
          options={issuesCategories}
          maxSelectedLabels={1}
          optionLabel="mainIssue"
          optionValue="mainIssue"
          filter
          filterBy="mainIssue"
          filterPlaceholder="Search..."
          showClear
          placeholder="Select Issue Category"
          value={openTableFilter.issueType.value}
          onChange={(e) => {
            onChangeFilter(setOpenTableFilters, e.value, 'issueType')
          }}
        />
        <MultiSelect
          options={
            openTableFilter.issueType.value && openTableFilter.issueType.value.length > 0
              ? issuesCategories
                  .filter((issue) => openTableFilter.issueType.value.includes(issue.mainIssue))
                  .map((item) => {
                    return {
                      label: item.mainIssue,
                      value: item.mainIssue,
                      items: item?.subIssues?.map((item2) => {
                        return {
                          label: item2.subIssue,
                          value: item2.subIssue,
                        }
                      }),
                    }
                  })
              : []
          }
          maxSelectedLabels={1}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          filter
          filterBy="label"
          filterPlaceholder="Search..."
          showClear
          placeholder="Select Sub-Issue Category"
          value={openTableFilter.issueSub.value}
          onChange={(e) => {
            onChangeFilter(setOpenTableFilters, e.value, 'issueSub')
          }}
        />
        <div>
          <Dropdown
            value={openTableFilter.status.value}
            placeholder="Complaint Type"
            options={complaintStatuses}
            optionLabel="label"
            optionValue="value"
            showClear
            onChange={(e) => {
              onChangeFilter(setOpenTableFilters, e.value, 'status')
            }}
          ></Dropdown>
        </div>
        <div className="">
          <MyDatesPicker
            code={dateCode}
            setCode={setDateCode}
            rangeDates={dateRange}
            setRangeDates={setDateRange}
            filterDates={dateFilter}
            setFilterDates={setDateFilter}
          />
        </div>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={openTableFilter['global'] ? openTableFilter['global'].value : '' || ''}
              onChange={(e) => onGlobalFilterChange(e, openTableFilter, setOpenTableFilters)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    )
  }
  const closeDataTableFiltersHeader = () => {
    return (
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
        {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
          <>
            <div>
              <Dropdown
                value={closeTableFilter.createdById.value}
                placeholder="Created Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'createdById')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={closeTableFilter.assignedToId.value}
                placeholder="Assigned Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'assignedToId')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={closeTableFilter.orderBy?.value}
                placeholder="Order Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'orderBy')
                }}
              ></Dropdown>
            </div>
            <div>
              <Dropdown
                value={closeTableFilter.closedById?.value}
                placeholder="Closed By Agent"
                options={user.members}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                onChange={(e) => {
                  onChangeFilter(setCloseTableFilters, e.value, 'closedById')
                }}
              ></Dropdown>
            </div>
          </>
        )}
        <Dropdown
          value={closeTableFilter.closeType.value}
          onChange={(e) => {
            onChangeFilter(setCloseTableFilters, e.value, 'closeType')
          }}
          showClear
          placeholder="Select Close Category"
          id="closeType"
          options={issueCloserCategories}
        />
        <MultiSelect
          options={issuesCategories}
          maxSelectedLabels={1}
          optionLabel="mainIssue"
          optionValue="mainIssue"
          filter
          filterBy="mainIssue"
          filterPlaceholder="Search..."
          showClear
          placeholder="Select Issue Category"
          value={closeTableFilter.issueType.value}
          onChange={(e) => {
            onChangeFilter(setCloseTableFilters, e.value, 'issueType')
          }}
        />
        <MultiSelect
          options={
            closeTableFilter.issueType.value && closeTableFilter.issueType.value.length > 0
              ? issuesCategories
                  .filter((issue) => closeTableFilter.issueType.value.includes(issue.mainIssue))
                  .map((item) => {
                    return {
                      label: item.mainIssue,
                      value: item.mainIssue,
                      items: item?.subIssues?.map((item2) => {
                        return {
                          label: item2.subIssue,
                          value: item2.subIssue,
                        }
                      }),
                    }
                  })
              : []
          }
          maxSelectedLabels={1}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          filter
          filterBy="label"
          showClear
          placeholder="Select Sub-Issue Category"
          value={closeTableFilter.issueSub?.value}
          onChange={(e) => {
            onChangeFilter(setCloseTableFilters, e.value, 'issueSub')
          }}
        />

        <div className="">
          <MyDatesPicker
            code={dateCode2}
            setCode={setDateCode2}
            rangeDates={dateRange2}
            setRangeDates={setDateRange2}
            filterDates={dateFilter2}
            setFilterDates={setDateFilter2}
          />
        </div>
        <div className="">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={closeTableFilter['global'] ? closeTableFilter['global'].value : '' || ''}
              onChange={(e) => onGlobalFilterChange(e, closeTableFilter, setCloseTableFilters)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <Toast ref={toast} />
      <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
        <TabPanel header="Open">
          <Toolbar
            className="p-toolbar p-mb-3 p-flex-wrap gap-1"
            left={
              <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                <h4 className="p-m-0">Dispute And Chargeback</h4>

                <Button
                  icon="pi pi-plus"
                  label="Report New Issue"
                  onClick={() => {
                    setDialogVisible(true)
                  }}
                />
              </div>
            }
            right={
              <div>
                <div className="p-d-flex p-flex-wrap gap-1">
                  <Button
                    icon="pi pi-refresh"
                    onClick={() => {
                      fetchAllOpenDACIssues()
                    }}
                  />

                  <ExportButton datatable_ref={open_dt} />
                </div>
              </div>
            }
          ></Toolbar>
          <DataTable
            ref={open_dt}
            exportFilename={`Open_Disputes_${dtFilenameDate}`}
            removableSort
            header={openDataTableFiltersHeader}
            filters={openTableFilter}
            value={allOpenDACIssues}
            showGridlines
            loading={openIssuesTableLoading}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          >
            <Column
              field="id"
              header="Id"
              sortable
              style={{
                width: '5%',
              }}
            />
            <Column
              field="invoiceId"
              header="Invoice Id"
              style={{ width: '13%' }}
              body={(rd) => {
                if (rd.invoiceId === 'NA') {
                  return rd.invoiceId
                } else {
                  return (
                    <Link to={`/orders?invId=${rd.invoiceId}`} target="_blank">
                      {rd.invoiceId}
                    </Link>
                  )
                }
              }}
            />
            <Column
              field="customerName"
              header="Customer Name"
              style={{
                width: '10%',
              }}
            />
            <Column
              field="issueType"
              header="Issue Category"
              style={{
                width: '15%',
              }}
            />
            <Column
              field="description"
              header="Description"
              style={{
                width: '20%',
              }}
            />
            <Column
              field="createdAt"
              header="Open Date"
              body={(rd) => <>{format(new Date(rd.createdAt), 'yyyy-MM-dd')}</>}
              style={{
                width: '8%',
              }}
            />
            {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
              <Column
                header="Agent"
                field="assignedToId"
                style={{
                  width: '9%',
                }}
                body={(rd) => {
                  return (
                    <div className="p-fluid">
                      <Dropdown
                        placeholder="Agent"
                        //* for manager, put his agents in top of the list
                        options={
                          ['teamlead'].includes(user.role)
                            ? [
                                ...user.members,
                                ...agents.filter(
                                  (agent) => !user.members.map((u) => u.id).includes(agent.id)
                                ),
                              ]
                            : agents
                        }
                        disabled={user.role === 'agent'}
                        //* Fade out the agents that do not belong to a team manager
                        itemTemplate={(option) => {
                          return (
                            <div
                              className={classNames({
                                'opacity-50':
                                  ['teamlead'].includes(user.role) &&
                                  !user.members.map((u) => u.id).includes(option.id)
                                    ? true
                                    : false,
                              })}
                            >
                              {option.username}
                            </div>
                          )
                        }}
                        optionLabel="username"
                        optionValue="id"
                        filter
                        filterBy="username"
                        filterPlaceholder="Search by username"
                        value={rd.assignedToId}
                        onChange={(e) => {
                          //* Void selection if a manager clicks any agent other than team members
                          if (
                            ['teamlead'].includes(user.role) &&
                            !user.members.map((u) => u.id).includes(e.value)
                          ) {
                            toast.current.show({
                              severity: 'warn',
                              summary: 'Warning',
                              detail: 'This agent is not in your team',
                            })
                            return
                          }
                          if (
                            ['teamlead'].includes(user.role) &&
                            !user.members.map((u) => u.id).includes(rd.assignedToId)
                          ) {
                            toast.current.show({
                              severity: 'warn',
                              summary: 'Warning',
                              detail:
                                "Since the issue is assigned to someone outside your team, it's view only.",
                            })
                            return
                          }
                          const details = {
                            complaintId: rd.id,
                            agentId: e.value,
                          }
                          setUpdateAgentDetails({
                            ...details,
                          })
                          setUpdateAgentDialogVisible(true)
                        }}
                      />
                    </div>
                  )
                }}
              />
            )}
            {user.role === 'agent' && (
              <Column
                field="agent"
                header="Agent"
                style={{
                  width: '9%',
                }}
              />
            )}
            <Column
              field="type"
              header="Issue Type"
              style={{
                width: '10%',
              }}
              body={(rd) => {
                return (
                  <div
                    className="p-p-1 p-d-flex p-ai-center p-jc-evenly p-text-bold"
                    style={rd.type === 'dispute' ? disputeBadge : chargebackBadge}
                  >
                    <span style={{ fontSize: '16px' }}>
                      {rd.type === 'dispute' ? <FaBalanceScaleRight /> : <RiArrowTurnBackFill />}
                    </span>{' '}
                    {capitalizeFirstLetter(rd.type)}
                  </div>
                )
              }}
            />
            <Column
              field="Action"
              header="Action"
              style={{
                width: '10%',
              }}
              body={(rd) => {
                return (
                  <div className="p-d-flex gap-1by2 p-jc-start">
                    <Link to={`?noteId=${rd?.id}`}>
                      <Button
                        icon="pi pi-copy"
                        tooltip="Open Notes"
                        className="p-button-info"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Link to={`?id=${rd?.id}`}>
                      <Button
                        icon="pi pi-eye"
                        tooltip="View"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Link to={`?history=${rd.id}`}>
                      <Button
                        tooltip="History"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-clock"
                        className="p-button p-button-help"
                      />
                    </Link>
                  </div>
                )
              }}
            />
          </DataTable>
          {/* System issue dialog start*/}
          <AddDACRecord
            dialogVisible={dialogVisible}
            setDialogVisible={setDialogVisible}
            setAllOpenDACIssues={setAllOpenDACIssues}
          />
          {/* System issue dialog end*/}
          {/* View notes for a DAC record start*/}
          <ViewDACRecordNotes
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            pathname={'/issues/dac'}
          />
          {/* View notes for a DAC record end*/}
          {/* View DAC Records start  */}
          <ViewDACRecord
            setLoading={setLoading}
            loading={loading}
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            allOpenDACIssues={allOpenDACIssues}
            allClosedDACIssues={allClosedDACIssues}
            setAllOpenDACIssues={setAllOpenDACIssues}
            setAllClosedDACIssues={setAllClosedDACIssues}
            issueCloserCategories={issueCloserCategories}
          />
          {/* View DAC Records start  */}
          {/* Confirmation dialog to change an assigned agent */}
          <ConfirmationDialogWithInput
            onHide={hideTargetUpdateDialog}
            header={'Change Agent'}
            onSubmit={handleAgentChange}
            visible={updateAgentDialogVisible}
            inputLabel={'Password'}
            customInputs={true}
            loading={loading}
          >
            <div className="">
              <label htmlFor="password" className="">
                Password
              </label>
              <Password
                autoComplete="nope"
                toggleMask
                feedback={false}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-mt-1"
                placeholder="Enter your password"
              />
            </div>
          </ConfirmationDialogWithInput>
          {/* Confirmation dialog to change an assigned agent */}
        </TabPanel>
        <TabPanel header="Close">
          <Toolbar
            className="p-toolbar p-mb-3 p-flex-wrap gap-1"
            left={
              <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                <h4 className="p-m-0">Dispute And Chargeback</h4>
              </div>
            }
            right={
              <div>
                <div className="p-d-flex p-flex-wrap gap-1">
                  <Button
                    icon="pi pi-refresh"
                    onClick={() => {
                      fetchAllClosedDACIssues()
                    }}
                  />

                  <ExportButton datatable_ref={close_dt} />
                </div>
              </div>
            }
          ></Toolbar>
          {/* DataTable to show closed record */}
          <DataTable
            ref={close_dt}
            exportFilename={`Closed_Disputes_${dtFilenameDate}`}
            header={closeDataTableFiltersHeader}
            filters={closeTableFilter}
            value={allClosedDACIssues}
            removableSort
            showGridlines
            loading={closedIssuesTableLoading}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
          >
            <Column
              sortable
              field="id"
              header="Id"
              style={{
                width: '5%',
              }}
            />
            <Column
              field="invoiceId"
              header="Invoice Id"
              style={{ width: '13%' }}
              body={(rd) => {
                if (rd.invoiceId === 'NA') {
                  return rd.invoiceId
                } else {
                  return (
                    <Link to={`/orders?invId=${rd.invoiceId}`} target="_blank">
                      {rd.invoiceId}
                    </Link>
                  )
                }
              }}
            />
            <Column
              field="customerName"
              header="Customer Name"
              style={{
                width: '10%',
              }}
            />
            <Column
              field="issueType"
              header="Issue Category"
              style={{
                width: '15%',
              }}
            />
            <Column
              field="description"
              header="Description"
              style={{
                width: '20%',
              }}
            />
            <Column
              field="createdAt"
              header="Open Date"
              body={(rd) => <>{format(new Date(rd.createdAt), 'yyyy-MM-dd')}</>}
              style={{
                width: '8%',
              }}
            />
            <Column
              field="closedBy"
              header="Closed By"
              style={{
                width: '9%',
              }}
            />
            <Column
              field="type"
              header="Issue Type"
              style={{
                width: '10%',
              }}
              body={(rd) => {
                return (
                  <div
                    className="p-p-1 p-d-flex p-ai-center p-jc-around p-text-bold"
                    style={rd.type === 'dispute' ? disputeBadge : chargebackBadge}
                  >
                    <span style={{ fontSize: '16px' }}>
                      {rd.type === 'dispute' ? <FaBalanceScaleRight /> : <RiArrowTurnBackFill />}
                    </span>{' '}
                    {capitalizeFirstLetter(rd.type)}
                  </div>
                )
              }}
            />
            <Column
              field="Action"
              header="Action"
              style={{
                width: '10%',
              }}
              body={(rd) => {
                return (
                  <div className="p-d-flex gap-1by2 p-jc-start">
                    <Link to={`?noteId=${rd?.id}`}>
                      <Button
                        icon="pi pi-copy"
                        tooltip="Open Notes"
                        className="p-button-info"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    <Link to={`?id=${rd?.id}`}>
                      <Button
                        icon="pi pi-eye"
                        tooltip="View"
                        tooltipOptions={{
                          position: 'bottom',
                        }}
                      />
                    </Link>
                    {rd.reopenApproval === 'pending' && //@Rahul-Webenetic #TODO Please verify mister
                      ((user.role !== 'agent' &&
                        user.members?.some(
                          (u) => u.id === rd.assignedToId && u.id === rd.closedById
                        )) ||
                        [rd.assignedToId, rd.closedById].includes(user.id)) && (
                        <Link to={`?reopen_id=${rd?.id}`}>
                          <div className="relative">
                            <Button className="p-button-danger" icon="pi pi-undo"></Button>
                            <span className="blob bottom-12-px left-12-px"></span>
                          </div>
                        </Link>
                      )}
                    <Link to={`?history=${rd.id}`}>
                      <Button
                        tooltip="History"
                        tooltipOptions={{ position: 'bottom' }}
                        icon="pi pi-clock"
                        className="p-button p-button-help"
                      />
                    </Link>
                  </div>
                )
              }}
            />
          </DataTable>
          <ViewDACRecordNotes
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            pathname={'/issues/dac'}
          />
          {/* View DAC Complaints  */}
          <ViewDACRecord
            setLoading={setLoading}
            loading={loading}
            setOpenIssuesTableLoading={setOpenIssuesTableLoading}
            setClosedIssuesTableLoading={setClosedIssuesTableLoading}
            allOpenDACIssues={allOpenDACIssues}
            allClosedDACIssues={allClosedDACIssues}
            setAllOpenDACIssues={setAllOpenDACIssues}
            setAllClosedDACIssues={setAllClosedDACIssues}
            issueCloserCategories={issueCloserCategories}
          />
          {/* Dialog to reopen start*/}
          <Dialog
            header={
              <div className="text-center">
                <h5 className="p-mb-0">Reopen Complaint {reopenRequestDetails?.id}</h5>
              </div>
            }
            visible={reopenComplaintDialogVisible}
            style={{ width: '40%', minWidth: '400px' }}
            onHide={() => closeReopenRequestDialog()}
            footer={<div></div>}
          >
            <div>
              <h6>Requested By : {reopenRequestDetails?.agent}</h6>
              <h5>Reason</h5>
              <h6 className="p-text-normal" style={{ lineHeight: '130%' }}>
                {reopenRequestDetails?.reopenReason}
              </h6>
            </div>
            {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
              <div className="p-d-flex p-fluid">
                <Button
                  className="p-button-raised p-mr-2 p-mt-5 p-button-success"
                  label="Approve"
                  name="approve"
                  icon="pi pi-check"
                  onClick={(e) => {
                    const _data = {
                      status: e.target.name,
                      dialogVisible: true,
                    }

                    setReopenRequestConfirmation(_data)
                  }}
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                />
                <Button
                  className="p-button-raised  p-mt-5 p-button-danger"
                  label="Reject"
                  name="reject"
                  icon="pi pi-times"
                  onClick={(e) => {
                    const _data = {
                      status: e.target.name,
                      dialogVisible: true,
                    }

                    setReopenRequestConfirmation(_data)
                  }}
                  tooltipOptions={{
                    position: 'bottom',
                  }}
                />
              </div>
            )}
            {user.role === 'agent' && (
              <div className="p-field p-fluid p-col-12 p-mb-0">
                <Message text="Pending for approval" severity="warn" sticky={true} />
              </div>
            )}
          </Dialog>
          {/* Dialog to reopen end*/}
          {/* Confirmation dialog to ask password start*/}
          <ConfirmationDialogWithInput
            onHide={() => {
              setReopenRequestConfirmation(emptyReopenConfirmation)
            }}
            header={'Update Request Status'}
            onSubmit={handlePatchRequestStatus}
            visible={reopenRequestConfirmation.dialogVisible}
            inputLabel={'Password'}
            customInputs={true}
            loading={loading}
          >
            <div className="">
              <label htmlFor="password" className="">
                Password
              </label>
              <Password
                autoComplete="nope"
                toggleMask
                feedback={false}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-mt-1"
                placeholder="Enter your password"
              />
            </div>
          </ConfirmationDialogWithInput>
          {/* Confirmation dialog to ask password end*/}
        </TabPanel>
      </TabView>
      <ChangeHistorySidebar
        setTableLoading={
          activeTabIndex === 0 ? setOpenIssuesTableLoading : setClosedIssuesTableLoading
        }
        header="Dispute Change History"
        historyType="im_dispute"
      />
    </>
  )
}
