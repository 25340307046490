import { AutoComplete } from 'primereact/autocomplete'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FileUpload } from 'primereact/fileupload'
import { getTicketById, putCloseTicket } from '../../../api'
import useQuery from '../../../hooks/useQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useGlobalContext } from '../../../context'
import { Dropdown } from 'primereact/dropdown'
import { handleObjChange } from '../../../functions/setter'
import { Message } from 'primereact/message'
import { format } from 'date-fns'
import { Tag } from 'primereact/tag'
import { Chip } from 'primereact/chip'

const ViewTicket = ({
  setClosedUsersTickets,
  allUsersTickets,
  setAssignedUserTickets,
  closedUsersTickets,
  myTickets,
  setMyTickets,
  assignedUserTickets,
  setAllUsersTickets,
  setLoading,
  loading,
  setAllTicketsTableLoading,
  setMyTicketsTableLoading,
  setAssignedTicketsTableLoading,
}) => {
  let emptyTicketObject = {
    type: '',
    assignedUser: '',
    assignToTeamId: '',
    assignedUserId: [],
    subject: '',
    description: '',
    expectedTAT: '',
    status: '',
    assignedBy: '',
    closingTimestamp: '',
    expectedCompletion: '',
  }

  const emptyCloseTicket = {
    closingNote: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const [newRecordError, setNewRecordError] = useState(emptyErr)

  const [closeTicketData, setCloseTicketData] = useState(emptyCloseTicket)
  const [viewDialog, setViewDialog] = useState(false)
  const [closeTicketDialog, setCloseTicketDialog] = useState(false)
  const [ticketData, setTicketData] = useState(emptyTicketObject)
  const [attachments, setAttachments] = useState([])
  const toast = useRef(null)
  const history = useHistory()
  const { colorScheme, user, agents } = useGlobalContext()

  const query = useQuery()
  const id = parseInt(query.get('viewId'))

  //* Function to fetch all details for complaint using it's id
  const fetchTicketById = useCallback(async () => {
    if (!id) return
    setAllTicketsTableLoading(true)
    setMyTicketsTableLoading(true)
    setAssignedTicketsTableLoading(true)
    const res = await getTicketById({ id, status: 'view' })
    if (res) {
      
      if (res.status === 404 || res.status === 400) {
        onHideViewDialog()
      }
      if (res.status === 200) {
        setAllTicketsTableLoading(false)
        setMyTicketsTableLoading(false)
        setAssignedTicketsTableLoading(false)
        setTicketData(res.data.ticket)
        setAttachments(res.data.ticket.attachments)
        setViewDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchTicketById()
  }, [fetchTicketById])

  const onHideViewDialog = () => {
    setMyTicketsTableLoading(false)
    setAssignedTicketsTableLoading(false)
    setAllTicketsTableLoading(false)
    setViewDialog(false)
    history.push({
      pathname: '/issues/ticket-management',
    })
  }

  const toggleCloseTicket = () => {
    setCloseTicketDialog(true)
    setViewDialog(false)
  }

  //* Function to close a complaint
  const closeTicket = async () => {
    const err = []
    if (!closeTicketData?.closingNote?.trim()) {
      err.push('You need to add a closing note')
    }
    if (err.length) {
      setNewRecordError({
        state: true,
        errors: err,
      })
      return
    }
    setLoading(true)
    const res = await putCloseTicket(id, closeTicketData)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        const itemIndex = allUsersTickets.findIndex((item) => item.id === id)
        const myTicketIndex = myTickets.findIndex((item) => item.id === id)
        const assignedTicketIndex = assignedUserTickets.findIndex((item) => item.id === id)
        // Check if the item exists in the array

        if (itemIndex !== -1) {
          allUsersTickets[itemIndex].status = res.data.ticket.status
          setAllUsersTickets(allUsersTickets)
        }
        if (myTicketIndex !== -1) {
          let _myTickets = [...myTickets]
          _myTickets.splice(myTicketIndex, 1)
          setMyTickets(_myTickets)
        }
        if (assignedTicketIndex !== -1) {
          let _assignTickets = [...assignedUserTickets]
          _assignTickets.splice(assignedTicketIndex, 1)
          setAssignedUserTickets(_assignTickets)
        }
        setClosedUsersTickets((prev) => [res.data.ticket, ...prev])
        setCloseTicketData(emptyCloseTicket)
        setCloseTicketDialog(false)
        onHideViewDialog()
      }
    }
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={viewDialog}
        style={{ width: '100vw', maxWidth: '750px' }}
        closable={false}
        footer={
          <div>
            <Button className="p-px-3 p-button-text" label="Cancel" onClick={onHideViewDialog} />
          </div>
        }
      >
        <div className="p-grid gap-1">
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <div className="p-d-flex p-jc-between p-ai-start p-d-block p-mb-5">
              <Chip
                label={`${
                  ticketData.status === 'Open' ? ticketData.status : ticketData.status + 'd'
                }`}
                className={`${
                  ticketData.status === 'Open' ? 'tm-status-Unachieved' : 'tm-status-Achieved'
                } p-text-bold bg-opacity-50`}
              />
              <h6 className="p-mt-0 p-d-flex gap-1 p-ai-center p-mb-5">
                <small
                  className="p-d-block dot"
                  style={{
                    backgroundColor: '#3B82F6',
                  }}
                ></small>
                <span>Ticket type</span>:<span style={{ color: '#3B82F6' }}>{ticketData.type}</span>
              </h6>
            </div>
            <div className="p-d-flex p-jc-between p-ai-start p-d-block">
              <h6 className="p-mt-0 p-d-flex gap-1 p-ai-center">
                <small
                  className="p-d-block dot"
                  style={{
                    backgroundColor: '#AE5EFF',
                  }}
                ></small>
                <span>Assigned By</span>:<span>{ticketData.assignedBy}</span>
              </h6>
              <h6 className="p-mt-0 p-d-flex gap-1 p-ai-center">
                <small
                  className="p-d-block dot"
                  style={{
                    backgroundColor: '#FC47A9',
                  }}
                ></small>
                <span>Assigned to</span>:<span>{ticketData.assignedUser}</span>
              </h6>
            </div>
            {/* Body Template  */}
            <div className="p-field p-fluid p-col-12 p-mb-0">
              <label className="p-d-block">
                Subject <sup style={{ color: 'red' }}>*</sup>
              </label>
              <InputText
                placeholder="Describe your problem here..."
                value={ticketData.subject}
                disabled
              />
            </div>
            <div className="p-field p-fluid p-col-12 p-mb-0">
              <label className="p-d-block">
                Description <sup style={{ color: 'red' }}>*</sup>
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                placeholder="Describe your problem here..."
                value={ticketData.description}
                readOnly
              />
            </div>
            {attachments.length > 0 && (
              <div className="p-field p-fluid p-grid p-formgrid p-mt-1">
                {attachments?.map((item, idx) => {
                  let { name, path } = item
                  if (name.length > 40) {
                    name = name.substring(0, 15) + '...' + name.substring(name.length - 20)
                  }
                  let myPath = process.env.REACT_APP_S3_BUCKET_URL + '/' + path
                  if (item.new) {
                    myPath = path
                  }
                  return (
                    <div key={idx} className="p-field p-col-6">
                      <a
                        className={`p-button p-button-outlined p-button-secondary`}
                        key={item.name}
                        href={myPath}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {name}
                      </a>
                    </div>
                  )
                })}
              </div>
            )}
            {/* Body Template  */}
            {/* Footer Template  */}
            <div className="p-field p-fluid p-col-12 p-mb-0">
              <div className="p-d-flex p-my-4 p-jc-between p-ai-center">
                <div className="block_part">
                  <h6>Open Date</h6>

                  <Tag
                    value={ticketData?.openDate ? format(new Date(ticketData.openDate), 'PPp') : ''}
                    style={{
                      padding: '8px',
                      borderRadius: '12px',
                      background: '#FE4D34',
                      color: 'white',
                    }}
                  />
                </div>
                {ticketData.expectedTAT && (
                  <div className="block_part">
                    <h6>Expected TAT</h6>

                    <Tag
                      value={`${
                        ticketData?.expectedTAT
                          ? format(new Date(ticketData.expectedTAT), 'PPp')
                          : ''
                      }`}
                      style={{
                        padding: '8px',
                        borderRadius: '12px',
                        background: '#FFD12C',
                        color: 'white',
                      }}
                    />
                  </div>
                )}
                {ticketData.expectedCompletion && (
                  <div className="block_part">
                    <h6>Expected Completion</h6>

                    <Tag
                      value={`${
                        ticketData?.expectedCompletion
                          ? format(new Date(ticketData.expectedCompletion), 'PPp')
                          : ''
                      }`}
                      style={{
                        padding: '8px',
                        borderRadius: '12px',
                      }}
                      severity="info"
                    />
                  </div>
                )}
                {ticketData.status === 'Close' && (
                  <div className="block_part">
                    <h6>Closed Date</h6>
                    <Tag
                      value={`${
                        ticketData?.closingTimestamp
                          ? format(new Date(ticketData.closingTimestamp), 'PPp')
                          : ''
                      }`}
                      style={{
                        padding: '8px',
                        borderRadius: '12px',
                        background: '#54B435',
                        color: 'white',
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {/* Footer Template  */}
          </div>
          {ticketData?.status?.toLowerCase() === 'open' && (
            <Button
              onClick={toggleCloseTicket}
              style={{
                margin: 'auto',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: '-16px',
              }}
            >
              Close Ticket
            </Button>
          )}
        </div>
      </Dialog>
      <Dialog
        visible={closeTicketDialog}
        style={{ width: '100vw', maxWidth: '750px' }}
        closable={false}
        footer={
          <div>
            <Button
              className="p-px-3 p-button-text"
              label="Cancel"
              onClick={() => {
                setCloseTicketDialog(false)
                setViewDialog(true)
              }}
            />
            <Button
              className="p-px-3 p-button"
              label="Submit"
              loading={loading}
              onClick={closeTicket}
            />
          </div>
        }
      >
        <div className="p-fluid">
          <div className="field p-col">
            <label htmlFor="closingNote">
              Closer Note <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputTextarea
              value={closeTicketData.closingNote}
              onChange={(e) => {
                handleObjChange(setCloseTicketData, 'closingNote', e.target.value)
              }}
              className="p-mt-3"
              id="closingNote"
              rows={5}
              cols={30}
            />
          </div>
        </div>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}

export default ViewTicket
