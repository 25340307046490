import axios from 'axios'
import endpoints from './endpoints'

export const uploadFileToS3 = async ({ file }) => {
  const fileName = file?.name
  // get
  const res = await getUploadURL({
    fileName,
  })
  // put
  await fetch(res.data.url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: file,
  })
  return res
}

export const getUploadURL = async ({ fileName }) => {
  try {
    const res = await axios.get(endpoints.awsUploads.uploads, {
      params: { fileName },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteAwsS3Files = async (paths) => {
  try {
    const res = await axios.delete(endpoints.awsUploads.uploads, {
      params: { paths },
    })
    return res
  } catch (error) {
    return error.response
  }
}
