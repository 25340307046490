import axios from 'axios'
import endpoints from './endpoints'

const addPaymentLink = async (orderId, paymentLink, paymentMethod) => {
  try {
    return await axios.post(endpoints.payment.index + orderId, {
      paymentLink,
      paymentMethod,
    })
  } catch (error) {
    return error.response
  }
}

const removePaymentLink = async (orderId) => {
  try {
    const res = await axios.delete(endpoints.payment.index + orderId)
    return res
  } catch (error) {
    return error.response
  }
}

const resendPaymentLink = async (orderId) => {
  try {
    const res = await axios.put(endpoints.payment.index + orderId)
    return res
  } catch (error) {
    return error.response
  }
}

export { addPaymentLink, removePaymentLink, resendPaymentLink }
