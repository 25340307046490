import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getAllProductsShipping, getShippingCharges, getShippingCompanies } from '../../api'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import { FilterMatchMode } from 'primereact/api'
import ProductLogistic from '../../components/mycomponents/Logistic/ProductLogistic'
import ShipperLogistic from '../../components/mycomponents/Logistic/ShipperLogistic'
import ShippingChargeLogistic from '../../components/mycomponents/Logistic/ShippingChargeLogistic'
import ShipperShippingTimeDialog from '../../components/mycomponents/Logistic/ShipperShippingTimeDialog'
import ViewShippingChargesLogisticDialog from '../../components/mycomponents/Logistic/ViewShippingChargesLogisticDialog'
import EditShippingChargesLogisticDialog from '../../components/mycomponents/Logistic/EditShippingChargesLogisticDialog'
import ViewProductChargesDialog from '../../components/mycomponents/Logistic/ViewProductChargesDialog'
import EditShippingCompanyDetailsDialog from '../../components/mycomponents/Logistic/EditShippingCompanyDetailsDialog'
import { useGlobalContext } from '../../context'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'

//* Imports end
export default function LogisticManagement() {
  const { toast } = useGlobalContext()

  //* States
  const [unassignedShippings, setUnassignedShippings] = useState([])
  const [assignedShippings, setAssignedShippings] = useState([])
  const [selectedUnassignedProducts, setSelectedUnassignedProducts] = useState([])

  const [selectedAssignedProducts, setSelectedAssignedProducts] = useState([])
  // Charge related states
  const [chargesTableLoading, setChargesTableLoading] = useState(false)
  const [chargesRecords, setChargesRecords] = useState([])
  //* States End

  const fetchData = useCallback(async () => {
    setTableProductLoading(true)
    const res = await getAllProductsShipping()
    setTableProductLoading(false)
    if (res && res.status === 200) {
      setUnassignedShippings(res.data.filter((shipping) => !shipping.shippingCompany))
      setAssignedShippings(res.data.filter((shipping) => shipping.shippingCompany))
      setSelectedAssignedProducts([])
      setSelectedUnassignedProducts([])
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error || 'Something went wrong',
      })
    }
  }, [])

  useEffect(() => fetchData(), [fetchData])

  const [companies, setShippingCompanies] = useState([])
  const [tableLoading, setShippingCompaniesTableLoading] = useState(false)
  const [tableProductLoading, setTableProductLoading] = useState(false)

  const fetchShippingCompanies = async () => {
    setShippingCompaniesTableLoading(true)
    const res = await getShippingCompanies()
    setShippingCompaniesTableLoading(false)
    if (res) {
      setShippingCompaniesTableLoading(false)
      if (res.status === 200) {
        setShippingCompanies(res.data)
      }
    }
  }

  useEffect(() => fetchShippingCompanies(), [])

  const fetchCharges = async () => {
    setChargesTableLoading(true)
    const res = await getShippingCharges()
    if (res) {
      setChargesTableLoading(false)
      if (res.status === 200) {
        setChargesRecords(res.data)
        setChargesTableLoading(false)
      }
    }
  }

  useEffect(() => fetchCharges(), [])

  //! To be deleted
  // const fetchShipperPackingPrices = async () => {
  //   // const res = await getShippingCharges()
  //   // if (res) {
  //   //   if (res.status === 200) {
  //   //     // setChargeRecords(res.data.records)
  //   //   }
  //   // }
  // }

  // useEffect(() => fetchShipperPackingPrices(), [])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <>
        <Toast ref={toast} />
        <div className="p-grid p-fluid">
          <div className="p-col-12">
            <TabView>
              <TabPanel header="Shipping Charges">
                <ShippingChargeLogistic
                  chargesTableLoading={chargesTableLoading}
                  setChargesTableLoading={setChargesTableLoading}
                  fetchCharges={fetchCharges}
                  chargesRecords={chargesRecords}
                  setChargesRecords={setChargesRecords}
                />
              </TabPanel>
              <TabPanel header="Shippers">
                <ShipperLogistic
                  companies={companies}
                  tableLoading={tableLoading}
                  setShippingCompaniesTableLoading={setShippingCompaniesTableLoading}
                  setShippingCompanies={setShippingCompanies}
                  fetchShippingCompanies={fetchShippingCompanies}
                  chargesRecords={chargesRecords}
                  setChargesRecords={setChargesRecords}
                />
              </TabPanel>
              <TabPanel header="Products">
                <ProductLogistic
                  companies={companies}
                  fetchData={fetchData}
                  unassignedShippings={unassignedShippings}
                  assignedShippings={assignedShippings}
                  selectedUnassignedProducts={selectedUnassignedProducts}
                  setSelectedUnassignedProducts={setSelectedUnassignedProducts}
                  selectedAssignedProducts={selectedAssignedProducts}
                  setSelectedAssignedProducts={setSelectedAssignedProducts}
                  tableProductLoading={tableProductLoading}
                />
              </TabPanel>
            </TabView>
          </div>
        </div>

        {/* SHIPPING CHARGES DIALOGS */}
        <ViewShippingChargesLogisticDialog setChargesTableLoading={setChargesTableLoading} />
        <EditShippingChargesLogisticDialog setChargesTableLoading={setChargesTableLoading} />

        {/* SHIPPING DIALOGS */}
        <ShipperShippingTimeDialog />

        {/* PRODUCT SHIPPING CHARGES DIALOG  */}
        <ViewProductChargesDialog />
        <EditShippingCompanyDetailsDialog fetchRecords={fetchShippingCompanies} />

        {/* HISTORY */}
        <ChangeHistorySidebar
          header="Shipping Company History"
          historyType="logistics_shipper"
          searchParamsForHistory="history_shipper_id"
        />

        <ChangeHistorySidebar
          header="Shipping Charge History"
          historyType="logistics_shipping_charges"
          searchParamsForHistory="history_shipper_charge_id"
        />
      </>
    </PageAllowedToRoles>
  )
}
