import axios from 'axios'
import endpoints from './endpoints'

export const addDiscount = async (
  type,
  code,
  name,
  discount,
  description,
  link,
  eligibility,
  howDoYouGetIt,
  conditions,
  otherTerms,
  password
) => {
  try {
    const res = await axios.post(endpoints.discount.index, {
      type,
      code,
      name,
      discount,
      description,
      link,
      eligibility,
      howDoYouGetIt,
      conditions,
      otherTerms,
      password,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getDiscounts = async () => {
  try {
    const res = await axios.get(endpoints.discount.index)
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteDiscount = async (id, password) => {
  try {
    const data = JSON.stringify({ password })
    const res = await axios.delete(endpoints.discount.index + id, { data })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateDiscount = async (id, discount) => {
  try {
    const res = await axios.patch(endpoints.discount.index + id, { discount })
    return res
  } catch (error) {
    return error.response
  }
}
