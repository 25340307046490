import axios from 'axios'
import endpoints from './endpoints'

export const getAllCategoryDetails = async () => {
  try {
    const res = await axios.get(endpoints.issues.types)
    return res
  } catch (error) {
    return error.response
  }
}

export const postNewComplaintIssue = async (payload) => {
  const {
    type,
    invoiceId,
    issueCategory,
    customerName,
    customerId,
    issueSubCategory,
    openingNote,
    orderAgent,
    description,
    assignTo,
    orderDetails,
    customerEmail,
    customerPhone,
  } = payload
  try {
    const res = await axios.post(endpoints.issues.complaint.index, {
      type,
      invoiceId,
      issueCategory,
      customerName,
      customerId,
      issueSubCategory,
      openingNote,
      orderAgent,
      description,
      assignTo,
      orderDetails,
      customerEmail,
      customerPhone,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getAllComplaintIssues = async ({ dateFrom, dateTo, status }) => {
  try {
    const res = await axios.get(endpoints.issues.complaint.index, {
      params: { status, dateFrom, dateTo },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getComplaintById = async (id) => {
  try {
    const res = await axios.get(endpoints.issues.complaint.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const getComplaintNotesById = async (noteId) => {
  try {
    const res = await axios.get(endpoints.issues.complaint.notes + noteId)
    return res
  } catch (error) {
    return error.response
  }
}

export const patchCloseComplaint = async (complaintId, payload) => {
  try {
    const res = await axios.patch(endpoints.issues.complaint.index + complaintId, payload)
    return res
  } catch (error) {
    return error.response
  }
}

export const postNewComplaintNote = async (generalNote, noteId) => {
  try {
    const res = await axios.post(endpoints.issues.complaint.notes + noteId, {
      generalNote,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const putComplaintAgentById = async (updateAgentDetails, password) => {
  const { complaintId, agentId } = updateAgentDetails

  try {
    const res = await axios.put(endpoints.issues.complaint.index, {
      password,
      complaintId,
      agentId,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getReopenRequestById = async (reopenId) => {
  try {
    const res = await axios.get(endpoints.issues.complaint.reopen + reopenId)
    return res
  } catch (error) {
    return error.response
  }
}

export const postReopenComplaintReq = async (id, reasonToReopen) => {
  const { reopeningNote, assignTo } = reasonToReopen
  try {
    const res = await axios.post(endpoints.issues.complaint.reopen + id, {
      reopeningNote,
      assignTo,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchRequestStatus = async (reopenId, status, password) => {
  try {
    const res = await axios.patch(endpoints.issues.complaint.reopen + reopenId, {
      password,
      status,
    })
    return res
  } catch (error) {
    return error.response
  }
}
