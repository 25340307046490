import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import React, { useRef, useState } from 'react'
import { sendOrderMessage } from '../../api'
// OrderMessageDialog component to manage the order message dialog
function OrderMessageDialog({ visible, setVisible, order }) {
  /**
   * visible @type {boolean} - boolean to check if dialog is visible
   * setVisible @type {function} - function to set dialog visible
   * order @type {object} - object to check order
   **/
  const toast = useRef(null)
  const [message, setMessage] = useState('')
  const [confirm, setConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const hide = () => {
    setVisible(false)
    setMessage('')
    setConfirm(false)
    setLoading(false)
  }
  const handleSendMessage = async () => {
    setLoading(true)
    const res = await sendOrderMessage(order?.orderid, message)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        hide()
      }
    }
  }
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        onHide={hide}
        visible={visible}
        className="w-full"
        style={{ maxWidth: '600px' }}
        header={`Compose message for ${order?.orderid}`}
        footer={
          <div className="p-d-flex p-jc-end ">
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={hide}
            />
            <Button
              label="Send"
              icon="pi pi-send"
              loading={loading}
              disabled={!confirm || !message || loading}
              onClick={handleSendMessage}
            />
          </div>
        }
      >
        <InputTextarea
          className="w-full"
          rows={8}
          autoResize
          placeholder="Type a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="p-col-12">
          <Checkbox checked={confirm} onChange={(e) => setConfirm(e.checked)} />
          <label className="p-checkbox-label p-ml-2">
            I confirm sending this message to the customer via Sms and Whatsapp
          </label>
        </div>
      </Dialog>
    </>
  )
}

export default OrderMessageDialog
