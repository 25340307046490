import { Column } from 'jspdf-autotable'
import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { InputSwitch } from 'primereact/inputswitch'
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { deleteTargetByid, putTargetById } from '../../../api'
import { useGlobalContext } from '../../../context'
import TargetDetailsDialog from './TargetDetailsDialog'
import ConfirmDialogWithPassword from '../Dialog/ConfirmDialogWithPassword'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { FilterMatchMode } from 'primereact/api'
import MyDatesPicker from '../../mini/MyDatesPicker'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import { Chip } from 'primereact/chip'
import { dateFilters } from '../../../functions/myDateFns'
export default function TargetsDataTable({
  records,
  setRecords,
  tableLoading,
  setTableLoading,

  fetchRecords,
  dateFilter,
  setDateFilter,
}) {
  /**
   * @param {array} records - The array of target records to be displayed in the table
   * @param {function} setRecords - The function to set the target records
   * @param {boolean} tableLoading - The boolean to set the table loading state
   * @param {function} setTableLoading - The function to set the table loading state
   * @param {function} fetchRecords - The function to fetch the target records
   * @param {object} dateFilter - The object to filter the date {dateFrom, dateTo}
   * @param {function} setDateFilter - The function to set the date filter {dateFrom, dateTo}
   **/
  const { user, agents, teams } = useGlobalContext()
  const toast = useRef(null)
  const dt = useRef(null)
  const [dateCode, setDateCode] = useState(dateFilters[1].code)
  const [dateRange, setDateRange] = useState(null)
  const levelOptions = ['1 Levels', '2 Levels', '3 Levels', '4 Levels']
  const typeOptions = ['Team', 'Manager', 'Group', 'Agent']
  const statusOptions = [
    'Achieved 1',
    'Achieved 2',
    'Achieved 3',
    'Achieved 4',
    'Pending',
    'Inactive',
    'Upcoming',
    'Unachieved',
    'In-Progress',
  ]
  const assignedToOptionsAdmin = [
    {
      label: 'Teams',
      items: teams.map((item) => ({ label: item.teamName })),
    },
    {
      label: 'Agents',
      items: agents
        .filter((item) => item.role === 'agent')
        .map((item) => ({ label: item.username })),
    },
  ]

  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    totalLevels: { value: [], matchMode: FilterMatchMode.IN },
    targetType: { value: [], matchMode: FilterMatchMode.IN },
    status: { value: [], matchMode: FilterMatchMode.IN },
    assignedTo: { value: [], matchMode: FilterMatchMode.IN },
  }
  const [filters, setFilters] = useState(emptyFilterObject)
  const [password, setPassword] = useState('')
  const [deleteData, setDeleteData] = useState({})
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false)
  //* Function to hide the delete dialog
  const hideTargetDeleteDialog = () => {
    setDeleteDialogVisible(false)
    setPassword('')
  }

  //* Handler to delete a target
  const handleRecordDelete = async () => {
    setTableLoading(true)
    const res = await deleteTargetByid(deleteData.id, { password })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        hideTargetDeleteDialog()
        setRecords((ps) => [...ps].filter((item) => item.id !== deleteData.id))
      }
    }
  }
  const onChangeFilter = (value, target) => {
    setFilters((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
  }
  return (
    <div>
      <Toast ref={toast} />
      <Toolbar
        className="p-toolbar p-mb-3 p-flex-wrap gap-1"
        left={
          <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
            <h4 className="p-m-0">All Targets</h4>
          </div>
        }
        right={
          <div>
            <div className="p-d-flex p-flex-wrap gap-1by2">
              {['superadmin', 'admin', 'manager'].includes(user.role) && (
                <div className="">
                  <MultiSelect
                    options={assignedToOptionsAdmin}
                    value={filters.assignedTo.value}
                    onChange={(e) => onChangeFilter(e.value, 'assignedTo')}
                    placeholder="Select assigned to"
                    showClear
                    optionLabel="label"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    optionValue="label"
                  />
                </div>
              )}
              {['teamlead'].includes(user.role) && (
                <div className="">
                  <MultiSelect
                    options={user.members || []}
                    value={filters.assignedTo.value}
                    onChange={(e) => onChangeFilter(e.value, 'assignedTo')}
                    placeholder="Select agent"
                    showClear
                    optionLabel="username"
                    optionValue="username"
                  />
                </div>
              )}

              <div className="">
                <MultiSelect
                  options={statusOptions}
                  value={filters.status.value}
                  onChange={(e) => onChangeFilter(e.value, 'status')}
                  placeholder="Select status"
                  showClear
                />
              </div>
              <div className="">
                <MultiSelect
                  options={typeOptions}
                  value={filters.targetType.value}
                  onChange={(e) => onChangeFilter(e.value, 'targetType')}
                  placeholder="Select target type"
                  showClear
                />
              </div>
              <div className="">
                <MultiSelect
                  options={levelOptions}
                  value={filters.totalLevels.value}
                  onChange={(e) => onChangeFilter(e.value, 'totalLevels')}
                  placeholder="Select Levels"
                  showClear
                />
              </div>
              <div className="">
                <MyDatesPicker
                  code={dateCode}
                  setCode={setDateCode}
                  rangeDates={dateRange}
                  setRangeDates={setDateRange}
                  filterDates={dateFilter}
                  setFilterDates={setDateFilter}
                />
              </div>

              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  value={filters.global.value}
                  onChange={(e) => onChangeFilter(e.target.value, 'global')}
                  placeholder="Search..."
                />
              </span>
              <div>
                <Button icon="pi pi-refresh" onClick={fetchRecords} />
              </div>
            </div>
          </div>
        }
      ></Toolbar>
      <DataTable
        filters={filters}
        loading={tableLoading}
        removableSort
        className="datatable-responsive"
        value={records}
        showGridlines
        emptyMessage="No targets found."
        rowHover
        autoLayout="false"
        ref={dt}
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      >
        <Column field="id" header="ID" sortable />
        <Column field="targetType" header="Type" />
        <Column field="assignedTo" header="Assigned To" />
        <Column field="targetName" header="Name" />
        <Column field="duration" header="Duration" />
        <Column field="totalLevels" header="Levels" />
        <Column
          field="status"
          header="Status"
          body={(rd) => {
            return (
              <Chip
                label={rd.status}
                className={`tm-status-${rd.status} p-text-bold bg-opacity-50`}
              />
            )
          }}
        />
        {['superadmin', 'admin', 'manager', 'teamlead'].includes(user.role) && (
          <Column
            header="Active"
            body={(rd) => (
              <InputSwitch
                disabled={rd.targetType === 'Manager'}
                checked={rd.active === 1 ? true : false}
                onChange={async (e) => {
                  const val = e.value ? 1 : 0
                  setRecords((ps) => {
                    const index = ps.findIndex((item) => item.id === rd.id)
                    ps[index].active = val
                    return [...ps]
                  })
                  const res = await putTargetById(rd.id, {
                    active: val,
                  })
                  if (res) {
                    if (res.status === 200) {
                      toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: res.data.message,
                      })
                      const { record } = res.data

                      setRecords((ps) => {
                        const _ps = [...ps]
                        const idx = _ps.findIndex((i) => i.id === record.id)
                        _ps[idx] = record
                        return _ps
                      })
                    }
                  }
                }}
              />
            )}
          />
        )}
        <Column
          header="Actions"
          body={(rd) => {
            return (
              <div className="p-d-flex gap-1by2 p-jc-start">
                {['superadmin', 'admin'].includes(user.role) && (
                  <>
                    <Button
                      icon="pi pi-trash"
                      className="p-button-danger"
                      tooltip="Delete Record"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                      onClick={() => {
                        setDeleteData(rd)
                        setDeleteDialogVisible(true)
                      }}
                    />
                  </>
                )}
                <Link to={`?${rd.type === 'T' ? 'at_id' : 'id'}=${rd.id}`}>
                  <Button icon="pi pi-eye" className="p-button-primary" />
                </Link>
              </div>
            )
          }}
        />
      </DataTable>

      <ConfirmDialogWithPassword
        onHide={hideTargetDeleteDialog}
        onDelete={handleRecordDelete}
        visible={deleteDialogVisible}
        password={password}
        setPassword={setPassword}
        headerText={'Delete Target'}
        mainText={`Are you sure you want to delete the target with the id ${deleteData.id}`}
      />
    </div>
  )
}
