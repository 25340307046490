import axios from 'axios'
import endpoints from './endpoints'

export const getJson = async (name) => {
  try {
    return await axios.get(endpoints.json.index, { params:{
      name
    } })
  } catch (error) {
    return error.response
  }
}
