import axios from 'axios'
import endpoints from './endpoints'

export const getUsersTickets = async ({ dateFrom, dateTo, status = '' }) => {
  try {
    const res = await axios.get(endpoints.ticketManagement.index, {
      params: { status, dateFrom, dateTo },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getTicketNotesById = async (id) => {
  try {
    const res = await axios.get(endpoints.ticketManagement.notes + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const postNewTicketNote = async (note, noteId) => {
  try {
    const res = await axios.post(endpoints.ticketManagement.notes + noteId, {
      note,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getTicketById = async ({id, status}) => {
  try {
    const res = await axios.get(endpoints.ticketManagement.index + id,{
      params: {status}
    },)
    return res
  } catch (error) {
    return error.response
  }
}

export const postUserTicket = async (newTicketRecord, arrayOfFiles) => {
  const {
    ticketType,
    assignToUsers,
    assignToTeam,
    subject,
    description,
    expectedTAT,
  } = newTicketRecord
  arrayOfFiles = JSON.stringify(arrayOfFiles)
  try {
    const res = await axios.post(endpoints.ticketManagement.index, {
      ticketType,
      assignToUsers,
      assignToTeam,
      subject,
      description,
      expectedTAT,
      arrayOfFiles,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchUserTicket = async (
  updatedTicketRecord,
  id,
  arrayOfFiles
) => {
  const { description, expectedTAT, expectedCompletion } =
    updatedTicketRecord
  try {
    const res = await axios.patch(endpoints.ticketManagement.index + id, {
      description,
      expectedTAT,
      arrayOfFiles,
      expectedCompletion,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const putCloseTicket = async (TicketId, payload) => {
  try {
    const res = await axios.put(
      endpoints.ticketManagement.index + TicketId,
      payload
    )
    return res
  } catch (error) {
    return error.response
  }
}
