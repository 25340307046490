import axios from 'axios'
import endpoints from './endpoints'

export const getProductNames = async () => {
  try {
    const res = await axios.get(endpoints.product.names)
    return res
  } catch (error) {
    return error.response
  }
}
export const getProductStrengths = async (data) => {
  const productCode = data?.productCode || null
  try {
    const res = await axios.get(endpoints.product.strengths, {
      params: {
        productCode,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getProductQuantities = async ({ productCode, strengthCode }) => {
  try {
    const res = await axios.get(endpoints.product.quantities, {
      params: {
        productCode,
        strengthCode,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const addNewProduct = async (product, featuredImage, productImages) => {
  try {
    product = JSON.stringify(product)
    let mydata = new FormData()
    mydata.append('product', product)
    mydata.append('featuredImage', featuredImage)
    productImages.forEach((img) => mydata.append('productImage', img))
    const res = await axios.post(endpoints.product.index, mydata)
    return res
  } catch (error) {
    return error.response
  }
}

export const getProducts = async (status, category) => {
  try {
    const myParams = {
      status,
      category,
    }
    const res = await axios.get(endpoints.product.index, { params: myParams })
    return res
  } catch (error) {
    return error.response
  }
}

export const getProduct = async (productCode) => {
  try {
    const res = await axios.get(endpoints.product.index + productCode)
    return res
  } catch (error) {
    return error.response
  }
}

export const updateStoreProduct = async (code, product) => {
  try {
    const res = await axios.patch(endpoints.product.index + code, {
      ...product,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateStoreProductImage = async (
  productCode,
  images,
  productImagesMeta,
  changeReason
) => {
  try {
    const fromData = new FormData()
    images.forEach((img) => fromData.append('images', img))
    fromData.append('data', JSON.stringify({ productImagesMeta, changeReason }))
    const res = await axios.patch(endpoints.product.image + productCode, fromData)
    return res
  } catch (error) {
    return error.response
  }
}

export const updateStoreProductStrengthAndQuantity = async ({
  productCode,
  strengthCode,
  strengthName,
  strengthRank,
  isStockOut,
  quantities,
  strengthStatus,
  changeReason,
}) => {
  try {
    const res = await axios.patch(endpoints.product.strengthandquantity + productCode, {
      strengthCode,
      strengthName,
      strengthRank,
      isStockOut,
      quantities,
      strengthStatus,
      changeReason,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const addStoreProductStrengthAndQuantity = async ({
  productCode,
  strengthName,
  strengthRank,
  isStockOut,
  quantities,
  changeReason,
  strengthStatus,
}) => {
  try {
    const res = await axios.post(endpoints.product.strengthandquantity + productCode, {
      strengthName,
      strengthRank,
      isStockOut,
      quantities,
      changeReason,
      strengthStatus,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteStoreProductStrengthAndQuantity = async (
  productCode,
  strengthCode,
  changeReason,
  password
) => {
  try {
    const data = JSON.stringify({ strengthCode, changeReason, password })
    const res = await axios.delete(endpoints.product.strengthandquantity + productCode, { data })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateStoreProductIngredients = async (productCode, ingredients, changeReason) => {
  try {
    const res = await axios.patch(endpoints.product.ingredients + productCode, {
      ingredients,
      changeReason,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateStoreProductManufactureNames = async (
  productCode,
  manufactureNames,
  changeReason
) => {
  try {
    const res = await axios.patch(endpoints.product.manufactureNames + productCode, {
      manufactureNames,
      changeReason,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateStoreProductAltNames = async (productCode, names, changeReason) => {
  try {
    const res = await axios.patch(endpoints.product.altnames + productCode, {
      names,
      changeReason,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const updateStoreProductMce = async (productCode, table, data, changeReason) => {
  try {
    const res = await axios.patch(endpoints.product.mce + productCode, {
      table,
      data,
      changeReason,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const bulkActionProduct = async (ids, input, password) => {
  try {
    const res = await axios.patch(endpoints.product.bulkAction, {
      ids,
      input,
      password,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteStoreProduct = async (productCode, password) => {
  try {
    var data = JSON.stringify({
      password,
    })
    const res = await axios.delete(endpoints.product.index + productCode, {
      data,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const syncStoreProduct = async (productCode) => {
  try {
    const res = await axios.get(endpoints.product.sync + productCode)
    return res
  } catch (error) {
    return error.response
  }
}

export const addProductQna = async (productCode, question, answer) => {
  try {
    return await axios.post(endpoints.product.qna + productCode, {
      question,
      answer,
    })
  } catch (error) {
    return error.response
  }
}

export const patchProductQna = async (id, question, answer) => {
  try {
    return await axios.patch(endpoints.product.qna + id, {
      question,
      answer,
    })
  } catch (error) {
    return error.response
  }
}

export const deleteProductQna = async (id) => {
  try {
    return await axios.delete(endpoints.product.qna + id)
  } catch (error) {
    return error.response
  }
}

export const deleteStoreProductImage = async (id) => {
  try {
    const res = await axios.delete(endpoints.product.image + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const addStoreProductImages = async (productCode, images, changeReason) => {
  try {
    const fromData = new FormData()
    images.forEach((img) => fromData.append('productImages', img))
    fromData.append('data', JSON.stringify({ changeReason }))
    const res = await axios.post(endpoints.product.image + productCode, fromData)
    return res
  } catch (error) {
    return error.response
  }
}

export const getProductPrice = async (productCode, strengthCode, quantityCode) => {
  try {
    const res = await axios.get(endpoints.product.util, {
      params: {
        productCode,
        strengthCode,
        quantityCode,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}
