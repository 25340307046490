import { nanoid } from 'nanoid'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import React, { useState } from 'react'
import { useGlobalContext } from '../../context'
import { handleObjChange } from '../../functions/setter'
import { Message } from 'primereact/message'
import classNames from 'classnames'
import { fetchQuantity, fetchStrength } from '../../functions/utilityFunctions'
export default function AddNewProductInOrder({
  visible,
  setVisible,
  newProduct,
  setNewProduct,
  products,
  setProducts,
}) {
  /**
   * @param {boolean} visible - The boolean to set the add new product dialog visible
   * @param {function} setVisible - The function to set the add new product dialog visible
  //  * @param {object} newProduct - The object to store the new product data
  //  * @param {function} setNewProduct - The function to set the new product data
   * @param {array} products - The array of products in the order
   * @param {function} setProducts - The function to set the array of products in the order
   * @description - The dialog to add new product in the order
   * **/

  const emptyErr = {
    state: false,
    errors: [],
  }
  const { activeProductNames } = useGlobalContext()
  const [newNewProductError, setNewProductError] = useState(emptyErr)
  const [strengths, setStrengths] = useState([])
  const [quantities, setQuantities] = useState([])

  const validateNewProduct = () => {
    const errors = []
    if (!newProduct?.product) errors.push('Product Name is required')
    if (!newProduct?.strength) errors.push('Strength is required')
    if (!newProduct?.quantity) errors.push('Quantity is required')
    if (!newProduct?.totalQuantity && newProduct?.totalQuantity <= 0)
      errors.push('Total quantity must be 1 or greater')
    if (!newProduct?.quantityPrice && newProduct?.quantityPrice <= 0)
      errors.push('Please enter a valid price')
    if (!newProduct?.quantityCost && newProduct?.quantityCost <= 0)
      errors.push('Please enter a valid cost')
    return errors
  }

  const onProductAddToLocalOrder = () => {
    const errors = validateNewProduct()
    if (errors.length > 0) {
      setNewProductError({
        state: true,
        errors: errors,
      })
      return
    }
    const id = products[products?.length - 1]?.id + 1
    const _newProduct = {
      id,
      productName: newProduct.product.productName,
      productCode: newProduct.product.productCode,
      productCategory: newProduct.product.productCategory,
      shippingCompany: newProduct.product.shippingCompany,
      strength: newProduct.strength.strengthName,
      strengthCode: newProduct.strength.strengthCode,
      _strength: newProduct.strength.strengthName,
      quantity: newProduct.quantity.quantity,
      quantityCode: newProduct.quantity.quantityCode,
      _quantity: newProduct.quantity.quantity,
      quantityPrice: newProduct.quantityPrice,
      quantityCost: newProduct.quantityCost,
      status: 'Pending',
      totalQuantity: newProduct.totalQuantity || 1,
      nanoid: nanoid(),
      unlinked: true,
      totalPrice: parseFloat(newProduct.totalPrice).toFixed(2),
      total: parseFloat(newProduct.total).toFixed(2),
    }
    setProducts([...products, _newProduct])
    onHideDialog()
  }

  const onHideDialog = async () => {
    setVisible(false)
    setNewProduct({})
    setNewProductError(emptyErr)
    setStrengths([])
    setQuantities([])
  }

  const productDialogFooter = (
    <>
      <Button label="Submit" icon="pi pi-save" onClick={() => onProductAddToLocalOrder()} />
      <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={onHideDialog} />
    </>
  )

  return (
    <Dialog
      visible={visible}
      header="Select Product"
      modal
      className="p-fluid"
      style={{ width: '1000px' }}
      footer={productDialogFooter}
      onHide={onHideDialog}
    >
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="productName">
            Product Name
          </p>
          <Dropdown
            value={newProduct?.product}
            options={activeProductNames}
            optionLabel="productName"
            name="product"
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setNewProduct, 'product', e.value)
              fetchStrength(e.value.productCode, setStrengths)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.productName}
              </span>
            )}
            filter
            filterBy="productName,altNames,ingredients,productCategory,shippingCompany"
            placeholder="Select Product"
            emptyMessage="Loading Products"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Strength
          </p>
          <Dropdown
            value={newProduct?.strength}
            options={strengths}
            optionLabel="strengthName"
            name="strength"
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setNewProduct, 'strength', e.value)
              fetchQuantity(e.value.strengthCode, setQuantities)
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.strengthName}
              </span>
            )}
            filter
            filterBy="strengthName"
            placeholder="Select Strength"
            emptyMessage="No Strength found"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            No. Pills
          </p>
          <Dropdown
            value={newProduct?.quantity}
            options={quantities}
            onChange={(e) => {
              if (e.value.isStockOut) return
              handleObjChange(setNewProduct, 'quantity', e.target.value)
              handleObjChange(setNewProduct, 'quantityCost', e.value.cost)
              handleObjChange(setNewProduct, 'quantityPrice', e.value.price)
              handleObjChange(setNewProduct, 'totalPrice', e.value.price * e.value.quantity)
              handleObjChange(
                setNewProduct,
                'total',
                e.value.price * e.value.quantity * (newProduct?.totalQuantity || 1)
              )
            }}
            itemTemplate={(option) => (
              <span
                className={classNames({
                  'opacity-50': option.isStockOut,
                })}
              >
                {option.quantity}
              </span>
            )}
            optionLabel="quantity"
            name="quantity"
            filter
            filterBy="quantity"
            placeholder="Select Quantity"
            emptyMessage="No Quantity found"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Pill Price
          </p>
          <InputNumber
            step={0.25}
            id="price"
            optionLabel="quantityPrice"
            name="quantityPrice"
            prefix="$"
            disabled={
              !newProduct?.quantity?.quantity ||
              !newProduct?.strength?.strengthName ||
              !newProduct?.product?.productName
            }
            mode="decimal"
            locale="en-US"
            maxFractionDigits={2}
            minFractionDigits={2}
            value={newProduct?.quantityPrice}
            onValueChange={(e) => handleObjChange(setNewProduct, 'quantityPrice', e.value)}
            style={{ width: '100%' }}
          />
        </div>

        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Quantity
          </p>
          <InputNumber
            id="price"
            optionLabel="totalQuantity"
            name="totalQuantity"
            disabled={
              !newProduct?.quantity?.quantity ||
              !newProduct?.strength?.strengthName ||
              !newProduct?.product?.productName ||
              !newProduct?.quantityPrice
            }
            value={newProduct?.totalQuantity || 1}
            onChange={(e) => {
              const total = newProduct?.quantityPrice * newProduct?.quantity?.quantity * e.value
              handleObjChange(setNewProduct, 'totalQuantity', e.value)
              handleObjChange(setNewProduct, 'total', total)
            }}
            style={{ width: '100%' }}
            placeholder="Total Quantity"
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Total Price
          </p>
          <InputNumber
            id="price"
            optionLabel="total"
            mode="decimal"
            locale="en-US"
            maxFractionDigits={2}
            minFractionDigits={2}
            name="total"
            disabled
            prefix="$"
            value={newProduct?.total || 0}
            style={{ width: '100%' }}
          />
        </div>
        <div className="p-fluid p-field p-col-12 p-lg-3 p-mb-4">
          <p className="p-mb-2" htmlFor="state">
            Pill Cost
          </p>
          <InputNumber
            step={0.25}
            id="cost"
            optionLabel="quantityCost"
            name="quantityCost"
            prefix="$"
            disabled={
              !newProduct?.quantity?.quantity ||
              !newProduct?.strength?.strengthName ||
              !newProduct?.product?.productName
            }
            mode="decimal"
            locale="en-US"
            maxFractionDigits={2}
            minFractionDigits={2}
            value={newProduct?.quantityCost}
            onValueChange={(e) => handleObjChange(setNewProduct, 'quantityCost', e.value)}
            style={{ width: '100%' }}
          />
        </div>
      </div>
      {newNewProductError.state && (
        <>
          {newNewProductError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setNewProductError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
    </Dialog>
  )
}
