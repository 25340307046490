import {
  addDays,
  endOfDay,
  endOfWeek,
  format,
  lastDayOfMonth,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns'
// CRM Date Filters utils

// Date Filters options with code and name
export const dateFilters = [
  { name: 'Today', code: 'today' },

  { name: 'This Week', code: 'thisweek' },
  { name: 'This Month', code: 'thismonth' },

  { name: 'Last 7 Days', code: 'last7days' },
  { name: 'Last 30 Days', code: 'last30days' },
  { name: 'Last 60 Days', code: 'last60days' },
  { name: 'Last 90 Days', code: 'last90days' },

  { name: 'Prev. 1 Month', code: 'prev1month' },
  { name: 'Prev. 3 Months', code: 'prev3months' },
  { name: 'Prev. 6 Months', code: 'prev6months' },

  { name: 'Date Range', code: 'daterange' },
  { name: 'Month And Year', code: 'monthyear' },
  { name: 'All', code: 'all' },
]

//& Without using the array
// export const getFiltnerCodeObject = {
//   today: 'today',
//   thisweek: 'thisweek',
//   thismonth: 'thismonth',
//   last7days: 'last7days',
//   last30days: 'last30days',
//   last60days: 'last60days',
//   last90days: 'last90days',
//   prev1month: 'prev1month',
//   prev3months: 'prev3months',
//   prev6months: 'prev6months',
//   daterange: 'daterange',
//   monthyear: 'monthyear',
//   all: 'all',
// }

// Assign dates to date filters based on code
export const assignDates = (code) => {
  let codes = dateFilters.map((i) => i.code)
  if (!codes.includes(code)) return null
  let date = new Date(),
    dateFrom = null,
    dateTo = new Date()
  switch (code) {
    case 'today':
      dateFrom = date
      break
    case 'last7days':
      dateFrom = subDays(date, 7)
      break
    case 'last30days':
      dateFrom = subDays(date, 30)
      break
    case 'last60days':
      dateFrom = subDays(date, 60)
      break
    case 'last90days':
      dateFrom = subDays(date, 90)
      break
    case 'thisweek':
      dateFrom = startOfWeek(date, {
        weekStartsOn: 1,
      })
      dateTo = endOfWeek(date, {
        weekStartsOn: 1,
      })
      break
    case 'thismonth':
      dateFrom = startOfMonth(date)
      dateTo = lastDayOfMonth(date)
      break
    case 'prev1month':
      dateFrom = startOfMonth(subMonths(date, 1))
      dateTo = lastDayOfMonth(subMonths(date, 1))
      break
    case 'prev3months':
      dateFrom = startOfMonth(subMonths(date, 3))
      dateTo = lastDayOfMonth(subMonths(date, 1))
      break
    case 'prev6months':
      dateFrom = startOfMonth(subMonths(date, 6))
      dateTo = lastDayOfMonth(subMonths(date, 1))
      break
    case 'all':
      dateFrom = new Date(2019, 0, 1)
      break
    default:
      return null
  }
  dateFrom = startOfDay(dateFrom)
  dateTo = endOfDay(dateTo)
  return { dateFrom, dateTo }
}

export const dtFilenameDate = format(new Date(), 'yyyy_MM_dd_HH_mm_ss')
