import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

export default function validatePhoneNumber(phoneNumber) {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber)
    const isValid = phoneUtil.isValidNumber(number)

    if (!isValid) {
      throw new Error('Invalid phone number')
    }

    return {
      valid: true,
      countryCode: number.getCountryCode(),
      nationalNumber: number.getNationalNumber(),
    }
  } catch (error) {
    return {
      valid: false,
      error: error.message,
    }
  }
}
