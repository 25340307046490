import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { getShippingTimeline, putShippingTimeline } from '../../../api'
import { handleAryChange } from '../../../functions/setter'
import useQuery from '../../../hooks/useQuery'
import { useGlobalContext } from '../../../context'
import { ScrollPanel } from 'primereact/scrollpanel'

const arrOfValues = ['Shipped', 'Tracking', 'Tracking-Live', 'Delivered']
const defaultPathname = '/website/logistics'
export default function ShipperShippingTimeDialog({ pathname = defaultPathname }) {
  const { toast, user } = useGlobalContext()
  // Used in multiple places
  const query = useQuery()
  const code = query.get('edit_st_code')
  const history = useHistory()
  // Used in multiple places

  // empty objects for state
  const emptyTime = {
    id: null,
    rowIdx: null,
    shipper: '',
    orderStatus: '',
    minDays: null,
    maxDays: null,
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  // empty objects for state

  // various states
  const [records, setRecords] = useState(
    arrOfValues.map((os, idx) => {
      return {
        ...emptyTime,
        rowIdx: idx,
        orderStatus: os,
        shipper: code,
      }
    })
  )
  const [tempRecords, setTempRecords] = useState(records)
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [edit, setEdit] = useState(false)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  // various states

  // Function to get the data for a timeline
  const fetchRecords = useCallback(async () => {
    if (!code) return
    const res = await getShippingTimeline(code)

    if (res) {
      if (res.status === 200) {
        if (res.data.length) {
          setRecords(res.data)
          setTempRecords(res.data)
        }
        setVisible(true)
      }
    }
  }, [code])
  useEffect(() => fetchRecords(), [fetchRecords])

  // Calcualte total on runtime for min and max values
  const calculateSum = (idx, key) => {
    let result = records.slice(0, idx + 1).reduce((sum, ele) => sum + ele[key] || 0, 0)
    return result
  }

  // Function to validate a record before adding it to the db
  const validateNewRecord = () => {
    const err = []

    if (calculateSum(records.length - 1, 'minDays') > calculateSum(records.length - 1, 'maxDays')) {
      err.push('Minimum total cannot be greater than maximum')
    }
    records.forEach((elem, idx) => {
      if (elem.minDays === null) {
        err.push(`Minimum ${elem.orderStatus} time cannot be empty`)
      }
      if (elem.maxDays === null) {
        err.push(`Maximum ${elem.orderStatus} time cannot be empty`)
      }
    })
    return err
  }

  // Function to add shipper
  const saveShipperTimeline = async () => {
    const errs = validateNewRecord()
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading(true)
    const res = await putShippingTimeline(code, { records })
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Timeline Updated',
          detail: res.data.message,
        })
        hideDialog()
      }
    }
  }

  // Function to close dialog
  const hideDialog = () => {
    setRecords(
      arrOfValues.map((os, idx) => {
        return { ...emptyTime, rowIdx: idx, orderStatus: os }
      })
    )
    setTempRecords(
      arrOfValues.map((os, idx) => {
        return { ...emptyTime, rowIdx: idx, orderStatus: os }
      })
    )
    setEdit(false)
    setVisible(false)
    setNewRecordError(emptyErr)
    history.push(pathname)
  }

  return (
    <Dialog
      header={
        <div>
          <h style={{ color: '#4B8AF4' }}>{'Shipping timeline for ' + code}</h>
        </div>
      }
      visible={visible}
      onHide={hideDialog}
      className="w-full max-w-60"
      footer={
        <div>
          {edit ? (
            <>
              <Button
                label="Discard"
                className="p-button-text p-button-danger"
                icon="pi pi-trash"
                disabled={loading}
                onClick={() => {
                  setNewRecordError(emptyErr)
                  setRecords(tempRecords)
                  setEdit(false)
                }}
              />
              <Button
                label={loading ? 'Saving' : 'Save'}
                icon="pi pi-save"
                loading={loading}
                disabled={loading}
                onClick={() => {
                  saveShipperTimeline()
                }}
              />
            </>
          ) : (
            <>
              <Button
                label="Cancel"
                className="p-button-text"
                icon="pi pi-times"
                onClick={() => hideDialog()}
              />
              {pathname === defaultPathname &&
                ['superadmin', 'admin', 'operations'].includes(user.role) && (
                  <Button
                    label="Edit"
                    icon="pi pi-pencil"
                    onClick={() => {
                      setEdit(true)
                    }}
                  />
                )}
            </>
          )}
        </div>
      }
    >
      {/* <ScrollPanel style={{ width: '100%', height: '310px' }}> */}
      <div className="p-grid">
        <div className="p-fluid p-col-12 p-md-3"></div>
        <div className="p-fluid p-col-12 p-md-2">
          <h5>Min Time</h5>
        </div>
        <div className="p-fluid p-col-12 p-md-2 p-mr-6">
          <h5>Min Total</h5>
        </div>
        <div className="p-fluid p-col-12 p-md-2">
          <h5>Max Time</h5>
        </div>
        <div className="p-fluid p-col-12 p-md-2">
          <h5>Max Total</h5>
        </div>
        {records.map((record, idx) => {
          return (
            <>
              <div className="p-fluid p-col-12 p-md-3" key={idx}>
                <h5>{record.orderStatus}</h5>
              </div>
              <div className="p-fluid p-col-12 p-md-2">
                <InputNumber
                  id={`minDays-${idx}`}
                  placeholder="Min Days"
                  disabled={!edit}
                  value={record.minDays}
                  onChange={(e) => {
                    const { value } = e
                    const _record = { ...record }
                    _record.minDays = value
                    _record.rowIdx = idx
                    handleAryChange(setRecords, idx, _record)
                  }}
                />
              </div>
              <div className="p-fluid p-col-12 p-md-2 p-mr-6">
                <InputNumber
                  placeholder="Min Total"
                  disabled
                  value={calculateSum(idx, 'minDays')}
                />
              </div>

              <div className="p-fluid p-col-12 p-md-2">
                <InputNumber
                  placeholder="Max Days"
                  disabled={!edit}
                  value={record.maxDays}
                  onChange={(e) => {
                    const { value } = e
                    const _record = { ...record }
                    _record.maxDays = value
                    handleAryChange(setRecords, idx, _record)
                  }}
                />
              </div>
              <div className="p-fluid p-col-12 p-md-2">
                <InputNumber
                  placeholder="Max Total"
                  disabled
                  value={calculateSum(idx, 'maxDays')}
                />
              </div>
            </>
          )
        })}
        <div className="p-fluid p-col-12 p-md-3">
          <h5 style={{ color: '#4B8AF4' }}>Total Time</h5>
        </div>

        <div className="p-fluid p-col-12 p-md-4 p-mr-6">
          <InputText
            placeholder="Min Total"
            readOnly
            className="p-text-center"
            value={`Minimum ${calculateSum(records.length - 1, 'minDays')} days`}
          />
        </div>

        <div className="p-fluid p-col-12 p-md-4">
          <InputText
            placeholder="Max Total"
            readOnly
            className="p-text-center"
            value={`Maximum ${calculateSum(records.length - 1, 'maxDays')} days`}
          />
        </div>
        {newRecordError.state && (
          <>
            {newRecordError.errors.map((err, idx) => {
              return (
                <div key={idx} className="p-fluid p-filed p-col-12">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              )
            })}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => {
                  setNewRecordError(emptyErr)
                }}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              ></Button>
            </div>
          </>
        )}
      </div>
      {/* </ScrollPanel> */}
    </Dialog>
  )
}
