import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown } from 'primereact/dropdown'
import { endOfToday, format, subDays } from 'date-fns'
import { assignOrdersTo, getAssignOrders } from '../../api'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import { useGlobalContext } from '../../context'
import { InputText } from 'primereact/inputtext'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import MyDatesPicker from '../../components/mini/MyDatesPicker'
import { assignDates, dateFilters } from '../../functions/myDateFns'
export default function AssignOrderPage() {
  const { websites, orderStatus, user, toast } = useGlobalContext()
  const history = useHistory()
  const dt = useRef(null)

  const [dateCode, setDateCode] = useState(dateFilters[4].code)
  const [dateRange, setDateRange] = useState(null)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[4].code))

  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    website: { value: null, matchMode: FilterMatchMode.EQUALS },
    orderStatus: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    username: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [filters, setFilters] = useState(emptyFilterObject)

  const [orders, setOrders] = useState([])
  const [assignTo, setAssignTo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [orderFetching, setOrderFetching] = useState(false)

  const [selectedOrders, setSelectedOrder] = useState([])

  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }

  const assignOrders = async () => {
    setLoading(true)
    const orderDetails = selectedOrders.map((item) => {
      return { orderid: item.orderid, username: item.username }
    })
    const res = await assignOrdersTo({
      userid: assignTo,
      orderDetails,
    })

    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setSelectedOrder([])
        setAssignTo(null)
        fetchOrder()
      }
    }
  }

  const fetchOrder = useCallback(async () => {
    setOrderFetching(true)
    const res = await getAssignOrders({ ...dateFilter })
    if (res) {
      setOrderFetching(false)
      if (res.status === 200) {
        setOrders(res.data)
      }
    }
  }, [dateFilter])

  useEffect(() => fetchOrder(), [fetchOrder])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'teamlead']}>
      <div className="card">
        <Toolbar
          className="p-toolbar p-flex-wrap gap-1"
          left={() => (
            <div className="p-d-flex p-ai-center gap-1">
              <h5 className="p-my-0">Assign Orders</h5>
              <Button
                label="Manual Orders"
                className=""
                onClick={() => history.push('/orders/manual')}
                icon="pi pi-external-link"
              />
              <Button
                label="All Orders"
                className=""
                onClick={() => history.push('/orders')}
                icon="pi pi-external-link"
              />
            </div>
          )}
          right={() => (
            <div className="p-d-flex p-ai-center gap-1">
              <Dropdown
                options={user?.members?.filter((m) => m.role === 'agent' || m.role === 'teamlead')}
                optionLabel="username"
                optionValue="id"
                value={assignTo}
                disabled={selectedOrders.length === 0}
                onChange={(e) => setAssignTo(e.value)}
                placeholder="Assign to"
                showClear
              />
              <Button
                icon="pi pi-user-plus"
                disabled={!assignTo || loading}
                loading={loading}
                label={loading ? 'Assigning' : 'Assign'}
                onClick={assignOrders}
              />
              <Button icon="pi pi-refresh" onClick={fetchOrder} />
            </div>
          )}
        ></Toolbar>

        <DataTable
          header={() => (
            <div className="p-d-flex p-jc-end gap-1by2">
              <div>
                <Dropdown
                  options={websites}
                  value={filters.website.value}
                  optionLabel="name"
                  onChange={(e) => onChangeFilter(e.value, 'website')}
                  placeholder="Website"
                  showClear
                />
              </div>

              <div>
                <Dropdown
                  options={orderStatus}
                  value={filters.orderStatus.value}
                  optionLabel="name"
                  onChange={(e) => onChangeFilter(e.value, 'orderStatus')}
                  placeholder="Status"
                  showClear
                />
              </div>
              <div>
                <Dropdown
                  options={user?.members?.filter((m) => m.role === 'agent')}
                  optionLabel="username"
                  optionValue="username"
                  value={filters.username.value}
                  onChange={(e) => onChangeFilter(e.value, 'username')}
                  placeholder="User"
                  showClear
                />
              </div>
              <div>
                <MyDatesPicker
                  code={dateCode}
                  setCode={setDateCode}
                  rangeDates={dateRange}
                  setRangeDates={setDateRange}
                  filterDates={dateFilter}
                  setFilterDates={setDateFilter}
                />
              </div>
              <div className="">
                <InputText
                  value={filters.global.value}
                  onChange={(e) => onChangeFilter(e.target.value, 'global')}
                  placeholder="Search"
                />
              </div>
            </div>
          )}
          ref={dt}
          loading={orderFetching}
          value={orders}
          rowHover
          breakpoint="1000px"
          responsiveLayout="stack"
          emptyMessage="No orders found."
          paginator
          rows={10}
          filters={filters}
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
          className="datatable-responsive"
          columnResizeMode="fit"
          showGridlines
          dataKey="orderid"
          selection={selectedOrders}
          onSelectionChange={(e) => setSelectedOrder(e.value)}
        >
          <Column selectionMode="multiple" selectionAriaLabel="orderid" />
          <Column
            sortable
            field="orderid"
            header="Invoice Id"
            body={(rd) => (
              <Link to={`/orders?invId=${rd.orderid}`} target="_blank">
                {rd.orderid}
              </Link>
            )}
          />
          <Column sortable field="name" header="Name" />
          <Column sortable field="orderStatus" header="Status" />
          <Column sortable field="total" header="Amount" />
          <Column sortable field="website" header="Website" />
          <Column sortable field="username" header="User" />
          <Column
            sortable
            field="orderDate"
            header="Date"
            body={(rd) => {
              let date = rd.orderDate || ''
              if (rd.orderDate) {
                date = format(new Date(rd.orderDate), 'PP')
              }
              return <div>{date}</div>
            }}
          />
        </DataTable>
      </div>
    </PageAllowedToRoles>
  )
}
