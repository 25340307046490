import { format, formatDistanceToNow, isValid } from 'date-fns'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Timeline } from 'primereact/timeline'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getOrderHistory, updateOrderHistoryReason } from '../../api'
import { useGlobalContext } from '../../context'
import capitalizeFirstLetter from '../../functions/capitalizeFirstLetter'
import HideDetails from '../mini/HideDetails'
export default function BasicOrderDetailsDialog({
  resetUrl,
  discountOptions,
  orderDialogVisible,
  setBasicOrderDialogVisible,
  orderProductDetails,
  orderDetails,
  setEnableFields,
  addressDetails,
  enableFields,
}) {
  /**
   * @param {function} resetUrl - The function to reset the url
   * @param {array} discountOptions - The array of discount options to be displayed in the dropdown (percent, amount)
   * @param {boolean} orderDialogVisible - The boolean to set the order dialog visible
   * @param {function} setBasicOrderDialogVisible - The function to set the order dialog visible
   * @param {array} orderProductDetails - The array of order product details
   * @param {object} orderDetails - The object of order details
   * @param {function} setEnableFields - The function to set the enable fields
   * @param {object} addressDetails - The object of address details
   * @param {boolean} enableFields - The boolean to set the enable fields
   * @description - The dialog to display the basic order details
   **/
  const { user, getActionBtn } = useGlobalContext()
  const toast = useRef(null)
  const [orderHistory, setOrderHistory] = useState([])
  const showSuccess = (
    summary = 'Success Message',
    detail = 'Message Content'
  ) => {
    toast.current.show({
      severity: 'success',
      summary,
      detail,
      life: 3000,
    })
  }
  const showError = (summary = 'Error Message', detail = 'Message Content') => {
    toast.current.show({
      severity: 'error',
      summary,
      detail,
      life: 4000,
    })
  }
  const toggelReasonTextbox = (index, bool) => {
    if (orderHistory.length > 0) {
      const temp = [...orderHistory]
      temp[index].textbox = bool
      setOrderHistory(temp)
    }
  }
  const content = (item, index) => {
    let parseData = new Date(item?.timestamp)
    let datesDistance = formatDistanceToNow(parseData, { addSuffix: true })
    let formatDate = format(parseData, 'PPPPp')

    return (
      <div key={item.id}>
        <h6 className=" p-text-bold">{capitalizeFirstLetter(item?.action)}</h6>

        <div>
          <p>
            <span className=" ">Performed By : </span>
            <label className="p-text-lowercase">
              {item?.master} ({item?.masterRole})
            </label>
          </p>
          <span>
            {datesDistance} - {formatDate}
          </span>
        </div>
        {item.masterid === user.id && (
          <p
            onClick={() => toggelReasonTextbox(index, true)}
            className="p-my-2 p-error block"
            style={{ cursor: 'pointer' }}
          >
            Reason for Action ?
          </p>
        )}
        {item?.textbox && (
          <div className="p-d-flex p-ai-center ">
            <InputTextarea
              placeholder="Enter the reason"
              autoResize
              id="name"
              type="text"
              value={item?.reason}
              onChange={(e) => {
                const temp = [...orderHistory]
                temp[index].reason = e.target.value
                setOrderHistory(temp)
              }}
              style={{ width: '25rem', margin: '5px 0px' }}
            />
            <div>
              <div>
                <Button
                  label="Save"
                  className="p-button-text p-button-primary p-ml-2"
                  icon="pi pi-send"
                  onClick={async () => {
                    toggelReasonTextbox(index, false)
                    const res = await updateOrderHistoryReason(
                      item.id,
                      item.reason
                    )
                    if (res && res.status === 200) {
                      showSuccess(
                        'Success',
                        'Order History Updated Successfully'
                      )
                    } else {
                      showError('Error', 'Something went wrong')
                    }
                  }}
                />
              </div>
              <div>
                <Button
                  label="Cancel"
                  className="p-button-text p-button-danger p-ml-2"
                  icon="pi pi-times"
                  onClick={() => toggelReasonTextbox(index, false)}
                />
              </div>
            </div>
          </div>
        )}
        {!item?.textbox && (
          <h6 className="p-d-flex p-ai-center p-text-italic p-mb-2">
            {item?.reason}
          </h6>
        )}
      </div>
    )
  }
  /* FUNCTIONS */

  const hideDialog = () => {
    resetUrl()
    setBasicOrderDialogVisible(false)
    setEnableFields(false)
  }
  const orderDialogFooter = (
    <div className="p-d-flex p-jc-between p-ai-center">
      <div>
        <p className="opacity-75">
          Status: {orderDetails.orderStatus},{' '}
          {orderDetails.orderDate && isValid(new Date(orderDetails.orderDate))
            ? 'Order-Date: ' + format(new Date(orderDetails.orderDate), 'PPp')
            : ''}
        </p>
      </div>
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          className="p-button-text"
          onClick={hideDialog}
        />
      </div>
    </div>
  )
  const marker = (item) => {
    return getActionBtn(item.actionType)
  }

  const getOrderTimeline = useCallback(async () => {
    if (!orderDialogVisible) return
    const res = await getOrderHistory(orderDetails.orderid, {
      type: 'order',
    })
    if (res && res.status === 200) {
      setOrderHistory(
        res.data.map((item) => {
          item.textbox = false
          return item
        })
      )
    }
  }, [orderDetails.orderid, orderDialogVisible])

  useEffect(() => {
    getOrderTimeline()
  }, [getOrderTimeline])
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        visible={orderDialogVisible}
        style={{ width: '1200px' }}
        header={`${orderDetails.orderLeadSource} Order Details`}
        modal
        className="p-fluid"
        footer={orderDialogFooter}
        onHide={hideDialog}
      >
        <div className=" p-formgrid p-grid">
          <div className="p-col-12 dg-header p-dialog-content">
            <div className="p-grid">
              <h4 className="p-col-12 p-md-4 p-m-0 p-py-2 p-text-center">
                Invoice-ID: {orderDetails?.orderid}
                {orderDetails?.blacklistedCustomer ? (
                  <>
                    <Tooltip target=".custom-target-icon" />
                    <span
                      className="custom-target-icon p-ml-2"
                      data-pr-tooltip="Blacklisted Order"
                    >
                      <i
                        className="pi pi-ban"
                        style={{ fontSize: '1em', color: 'red' }}
                      />
                    </span>
                  </>
                ) : (
                  ''
                )}
              </h4>
              <h4 className="p-col-12 p-md-4 p-m-0 p-py-2 p-text-center">
                Name: {addressDetails?.fname} {addressDetails?.lname}
              </h4>
              <h4 className="p-col-12 p-md-4 p-m-0 p-py-2 p-text-center">
                {orderDetails?.website}
              </h4>
            </div>
          </div>
          {/* x-datatable */}
          <div className="p-col-12">
            <div className="card p-shadow-3">
              <DataTable
                value={orderProductDetails}
                rows={5}
                editMode="row"
                dataKey="id"
                showGridlines
                breakpoint="1000px"
                responsiveLayout="stack"
              >
                {/* <Column field="quantity" header="Quantity" editor={(options) => textEditor(options)} body={dateBodyTemplate} style={{ width: '20%' }}></Column> */}
                <Column header="ID" style={{ width: '7%' }} field="id"></Column>

                <Column
                  header="Product Name"
                  style={{ width: '18%' }}
                  field="productName"
                ></Column>
                <Column
                  header="Strength"
                  style={{ width: '8%' }}
                  field="strength"
                ></Column>
                <Column
                  header="No. Pills"
                  style={{ width: '8%' }}
                  field="quantity"
                ></Column>
                <Column
                  header="Pill Price"
                  style={{ width: '8%' }}
                  field="quantityPrice"
                  body={(rd) => '$' + rd.quantityPrice}
                ></Column>

                <Column
                  header="No. Qty"
                  style={{ width: '8%' }}
                  field="totalQuantity"
                ></Column>
                <Column
                  header="Total Price"
                  style={{ width: '8%' }}
                  field="total"
                  body={(rd) => '$' + rd.total}
                ></Column>
                <Column
                  header="Status"
                  style={{ width: '8%' }}
                  field="status"
                ></Column>
                <Column
                  header="SC"
                  style={{ width: '4%' }}
                  field="shippingCompany"
                ></Column>
              </DataTable>
            </div>
          </div>
          {/* x-total  */}
          <div className="p-col-12 p-md-6">
            <div className="p-shadow-3 card">
              <div className="p-field p-col-12">
                <h4 className="">Invoice Details</h4>
                <hr />
              </div>
              <div className="p-formgrid p-grid ">
                <div className="p-fluid p-field p-col-4">
                  <label className="p-col-fixed p-text-bold">
                    Discount Type
                  </label>
                  <Dropdown
                    disabled={true}
                    options={discountOptions}
                    value={orderDetails.isDiscountPercent}
                  />
                </div>
                <div className="p-fluid p-field p-col-4">
                  <label className="p-col-fixed p-text-bold">Discount</label>
                  <InputNumber
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled={true}
                    value={orderDetails.discountValue}
                    prefix={
                      orderDetails.isDiscountPercent === discountOptions[1] &&
                      '$'
                    }
                    suffix={
                      orderDetails.isDiscountPercent === discountOptions[0] &&
                      '%'
                    }
                  />
                </div>
                <div className=" p-fluid p-field p-col-4">
                  <label className="p-col-fixed p-text-bold">Shipping</label>
                  <InputNumber
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    disabled={true}
                    value={orderDetails.dcharge}
                    prefix="$"
                  />
                </div>
                <div className="p-col-12">
                  <DataTable
                    showGridlines
                    value={[
                      {
                        name: 'Subtotal',
                        value: orderDetails?.subtotal,
                      },
                      {
                        name: 'Shipping',
                        value: orderDetails?.dcharge,
                      },
                      {
                        name: 'Discount',
                        value: orderDetails?.discount,
                      },
                      {
                        name: 'Payment Discount',
                        value: orderDetails?.paymentDiscount,
                      },
                      { name: 'Total', value: orderDetails?.total },
                    ]}
                    responsiveLayout="scroll"
                  >
                    <Column
                      field="name"
                      body={(rd) => {
                        return rd.name === 'Total' ? (
                          <h5 className="p-m-0">{rd.name}</h5>
                        ) : (
                          <div>{rd.name}</div>
                        )
                      }}
                      header=""
                      style={{ width: '50%' }}
                    ></Column>
                    <Column
                      field="value"
                      body={(rd) => {
                        let val = rd.value ? rd.value : '0'
                        return rd.name === 'Total' ? (
                          <h5 className="p-m-0">${val}</h5>
                        ) : (
                          <div>${val}</div>
                        )
                      }}
                      header="Amount"
                      style={{ width: '50%' }}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
          {/* x-address */}
          <div className="p-col-12 p-md-6 ">
            <div className="card p-shadow-3">
              <div className="p-field p-col-12">
                <h4 className="">Shipping Details</h4>
                <hr />
              </div>
              <div className="p-grid p-mt-1">
                <div className="p-field p-col-6">
                  <label htmlFor="fname" className="p-col-12 p-text-bold">
                    First Name
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="fname"
                      type="text"
                      value={addressDetails?.fname}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="lname" className="p-col-12 p-text-bold">
                    Last Name
                  </label>
                  <div className="p-col-12">
                    <InputText
                      id="lname"
                      value={addressDetails?.lname}
                      disabled={!enableFields}
                    />
                  </div>
                </div>

                <div className="p-field p-col-12">
                  <label
                    htmlFor="addressline1"
                    className="p-col-12 p-text-bold"
                  >
                    Address
                  </label>
                  <div className="p-col-12">
                    <InputTextarea
                      id="addressline1"
                      disabled={!enableFields}
                      value={addressDetails?.addressline1}
                      rows={2}
                      cols={30}
                      placeholder="Enter Address"
                    />
                  </div>
                </div>

                <div className="p-field p-col-6">
                  <label htmlFor="city" className="p-col-12 p-text-bold">
                    City
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="city"
                      type="text"
                      value={addressDetails?.city}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="state" className="p-col-12 p-text-bold">
                    State
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="state"
                      type="state"
                      value={addressDetails?.state}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="postalcode" className="p-col-12 p-text-bold">
                    Zip
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="postalcode"
                      type="text"
                      value={addressDetails?.postalcode}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="country" className="p-col-12 p-text-bold">
                    Country
                  </label>
                  <div className="p-col-12">
                    <InputText
                      disabled={!enableFields}
                      id="country"
                      type="text"
                      value={addressDetails?.country}
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="email" className="p-col-12 p-text-bold">
                    Email
                  </label>
                  <div className="p-col-12">
                    {/* <InputText
                      id="email"
                      value={addressDetails?.email}
                      disabled
                    /> */}
                    <HideDetails
                      id={'email'}
                      value={addressDetails?.email}
                      type="input"
                    />
                  </div>
                </div>
                <div className="p-field p-col-6">
                  <label htmlFor="phone" className="p-col-12 p-text-bold">
                    Phone
                  </label>
                  <div className="p-col-12">
                    {/* <InputText
                      id="phone"
                      disabled
                      value={addressDetails?.phone}
                    /> */}
                    <HideDetails
                      id={'phone'}
                      value={addressDetails?.phone}
                      type="input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* x-timeline */}
          <div className="p-col-12">
            <div className="card p-shadow-3">
              <h4 className="p-d-flex p-jc-between p-ai-center">
                <span>Order Timeline</span>
              </h4>
              <hr />
              {orderHistory.length > 0 ? (
                <div className="widget-timeline">
                  <div className="timeline-header p-d-flex p-jc-between p-ai-center"></div>
                  <div className="timeline-content">
                    <Timeline
                      value={orderHistory}
                      marker={marker}
                      content={content}
                      className="custimized-timeline"
                    />
                  </div>
                </div>
              ) : (
                <h5 className="p-text-center">
                  <i className="pi pi-exclamation-circle p-mx-2" />
                  No Order History
                </h5>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
