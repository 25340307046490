


import React from 'react'

export default function EmptyPage() {
  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <h5>Empty Page</h5>
          <p>This is your empty page template to start building beautiful applications.</p>
        </div>
      </div>
    </div>
  )
}
