const notificationTopics = [
  {
    label: 'Order',
    topic: 'order',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'order_note_added', // Done for admin
        label: 'Order note added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_address_updated', // Done for admin
        label: 'Order address updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_updated', // Done for admin
        label: 'Order updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  {
    label: 'Order Status Change',
    topic: 'order_statuschanged',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'order_statuschanged_pending', // Done for admin
        label: 'Order pending',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_processed', // Done for admin
        label: 'Order processed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_shipped', // Done for admin
        label: 'Order shipped',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_partial',
        label: 'Order partial',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_tracking', // Done for admin
        label: 'Order tracking',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_trackinglive', // Done for admin
        label: 'Order tracking live',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_delivered', // Done for admin
        label: 'Order delivered',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_reshipmentinitiated', // Done for admin
        label: 'Order reshipment initiated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_reshipmentcompleted', // Done for admin
        label: 'Order reshipment completed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_refundinitiated', // Done for admin
        label: 'Order refund initiated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_refundcompleted', // Done for admin
        label: 'Order refund completed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_draft',
        label: 'Order draft',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_cancelled', // Done for admin
        label: 'Order cancelled',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_ineligicancel', // Done for admin
        label: 'Order ineligi cancel',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_ineligible', // Done for admin
        label: 'Order ineligible',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_statuschanged_bin', // Done for admin
        label: 'Order bin',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  {
    label: 'Order Product Status Change',
    topic: 'order_product_statuschanged',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'order_product_statuschanged_pending',
        label: 'Order product pending',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_product_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_product_statuschanged_processed', // Done for admin
        label: 'Order product processed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_product_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_product_statuschanged_shipped', // Done for admin
        label: 'Order product shipped',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_product_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_product_statuschanged_tracking', // Done for admin
        label: 'Order product tracking',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_product_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_product_statuschanged_trackinglive', // Done for admin
        label: 'Order product tracking-live',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_product_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_product_statuschanged_delivered', // Done for admin
        label: 'Order product delivered',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_product_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_product_statuschanged_cancelled', // Done for admin
        label: 'Order product cancelled',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_product_statuschanged',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  {
    label: 'Order Processed Request',
    topic: 'order_processedrequest',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'order_paymentrequest_added',
        label: 'Order Payment Request Added', // Done for admin
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_processedrequest',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_paymentrequest_accepted', // Done for admin
        label: 'Order Payment Request Accepted',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_processedrequest',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },

      {
        topic: 'order_paymentrequest_rejected', // Done for admin
        label: 'Order Payment Request Rejected',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'order_processedrequest',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  // *** Website Management ***

  {
    label: 'Product',
    topic: 'website_product',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'operations'],
    items: [
      {
        topic: 'website_product_added', // Done for admin
        label: 'Product added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_product',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_product_updated', // Done for admin
        label: 'Product updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_product',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_product_deleted', // Done for admin
        label: 'Product deleted',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_product',
        roles: ['superadmin', 'admin', 'operations'],
      },
    ],
  },

  {
    label: 'Category',
    topic: 'website_category',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'operations'],
    items: [
      {
        topic: 'website_category_added', // Done for admin
        label: 'Category added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_category',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_category_updated', // Done for admin
        label: 'Category updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_category',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_category_deleted', // Done for admin
        label: 'Category deleted',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_category',
        roles: ['superadmin', 'admin', 'operations'],
      },
    ],
  },

  {
    label: 'Discount',
    topic: 'website_discount',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'operations'],
    items: [
      {
        topic: 'website_discount_added', // Done for admin
        label: 'Discount added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_discount',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_discount_updated', // Done for admin
        label: 'Discount updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_discount',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_discount_deleted', // Done for admin
        label: 'Discount deleted',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_discount',
        roles: ['superadmin', 'admin', 'operations'],
      },
    ],
  },

  {
    label: 'Logistics',
    topic: 'website_logistics',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'operations'],
    items: [
      {
        topic: 'website_logistics_shipper_added', // Done for admin
        label: 'Shipper added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_logistics',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_logistics_shipper_updated', // Done for admin
        label: 'Shipper updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_logistics',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_logistics_shipper_deleted', // Done for admin
        label: 'Shipper deleted',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_logistics',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_logistics_shippingcharge_added', // Done for admin
        label: 'Shipping charge added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_logistics',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_logistics_shippingcharge_updated', // Done for admin
        label: 'Shipping charge updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_logistics',
        roles: ['superadmin', 'admin', 'operations'],
      },
      {
        topic: 'website_logistics_shippingcharge_deleted', // Done for admin
        label: 'Shipping charge deleted',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'website_logistics',
        roles: ['superadmin', 'admin', 'operations'],
      },
    ],
  },

  // *** Issue Management ***
  {
    label: 'Shipment Delay',
    topic: 'issue_shipmentdelay',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'order_product_shipmentdelay_followup', // Done for admin
        label: 'Order product shipment delay follow-up',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_shipmentdelay',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'order_product_shipmentdelay_note_added', // Done for admin
        label: 'Order product shipment delay note added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_shipmentdelay',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  {
    label: 'Complaint And Cases',
    topic: 'issue_complaintandcases',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'issue_complaint_added', // Done for admin
        label: 'Complaint added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_complaintandcases',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_complaint_updated',
        label: 'Complaint updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_complaintandcases',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_complaint_closed', // Done for admin
        label: 'Complaint closed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_complaintandcases',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_complaint_reopen', // Done for admin
        label: 'Complaint reopen',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_complaintandcases',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_complaint_note_added', // Done for admin
        label: 'Complaint note added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_complaintandcases',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  {
    label: 'Dispute And Chargebacks',
    topic: 'issue_disputeandchargebacks',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'issue_dispute_added', // Done for admin
        label: 'Dispute added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_disputeandchargebacks',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_dispute_updated',
        label: 'Dispute updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_disputeandchargebacks',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_dispute_closed', // Done for admin
        label: 'Dispute closed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_disputeandchargebacks',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_dispute_reopen', // Done for admin
        label: 'Dispute reopen',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_disputeandchargebacks',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_dispute_note_added', // Done for admin
        label: 'Dispute note added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_disputeandchargebacks',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  {
    label: 'Tickets',
    topic: 'issue_userTickets',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
    items: [
      {
        topic: 'issue_ticket_added', // Done for admin
        label: 'Ticket added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_userTickets',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_ticket_updated', // Done for admin
        label: 'Ticket updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_userTickets',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_ticket_closed', // Done for admin
        label: 'Ticket closed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_userTickets',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
      {
        topic: 'issue_ticket_note_added', // Done for admin
        label: 'Ticket note added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'issue_userTickets',
        roles: ['superadmin', 'admin', 'manager', 'operations', 'teamlead', 'agent'],
      },
    ],
  },

  // *** User Management ***
  {
    label: 'User',
    topic: 'user',
    isPushNotification: false,
    isEmailNotification: false,
    isHeader: true,
    roles: ['superadmin', 'admin', 'manager'],
    items: [
      {
        topic: 'user_added',
        label: 'User added',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin', 'admin', 'manager'],
      },

      {
        topic: 'user_updated',
        label: 'User updated',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin', 'admin', 'manager'],
      },

      {
        topic: 'user_deleted',
        label: 'User deleted',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin', 'admin', 'manager'],
      },

      {
        topic: 'user_blocked',
        label: 'User blocked',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin', 'admin', 'manager'],
      },

      {
        topic: 'user_unblocked',
        label: 'User unblocked',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin', 'admin', 'manager'],
      },

      {
        topic: 'user_rolechanged',
        label: 'User role changed',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin', 'admin', 'manager'],
      },
      {
        topic: 'user_access_iprestricted',
        label: 'IP restriction updates',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin'],
      },
      {
        topic: 'user_access_allorder',
        label: 'All order access updates',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin'],
      },
      {
        topic: 'user_acesss_allunassignedorder',
        label: 'All unassigned order access updates',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin'],
      },
      {
        topic: 'user_access_contactcopy',
        label: 'Customer contact copy access updates',
        isPushNotification: false,
        isEmailNotification: false,
        isHeader: false,
        group: 'user',
        roles: ['superadmin'],
      },
    ],
  },
]

export default notificationTopics
