import { Button } from 'primereact/button'
import { ProgressBar } from 'primereact/progressbar'
import React, { useEffect, useState } from 'react'
import { FiCheckCircle, FiClock, FiLoader, FiToggleLeft, FiZapOff } from 'react-icons/fi'
import { IoMdCloudy } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { getDashbaordTargets } from '../../../api'
import { useGlobalContext } from '../../../context'
import moneyFormatter from '../../../functions/moneyFormatter'
const targetsLink = '/targets'
export default function TargetDashbaord() {
  /**
   *	@description - TargetDashbaord component is used to display the target dashboard of the user
   **/

  const [records, setRecords] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchRecords = async () => {
    setLoading(true)
    const res = await getDashbaordTargets()
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        setRecords(res.data.targets)
      }
    }
  }

  useEffect(() => {
    fetchRecords()
  }, [])

  return (
    <>
      <div className="p-d-flex p-ai-center p-jc-between">
        <h3 className="p-mb-0 p-d-flex p-ai-center gap-1">
          {loading ? 'Fetching Targets...' : 'Target Board'}
        </h3>
        {/* <Button
          label="Refresh"
          icon="pi pi-refresh"
          className="p-button-rounded p-button-text"
          onClick={fetchRecords}
        /> */}
        <Link to={targetsLink} className="p-text-bold p-mr-2">
          View All
        </Link>
      </div>

      <div className="p-grid p-my-3">
        {records.length === 0 ? (
          <h5 className="p-col-12 p-text-center">
            {loading ? (
              <>
                <i className="pi pi-spin pi-spinner p-mr-2" />
                <span>Fetching Targets</span>
              </>
            ) : (
              <>
                <i className="pi pi-calendar p-mr-2" />
                <span>No Target Found</span>
              </>
            )}
          </h5>
        ) : (
          <>
            {records.map((record, idx) => (
              <TargetCard
                key={idx}
                record={record}
                idx={idx}
                records={records}
                setRecords={setRecords}
                allTargetsLink={targetsLink}
              />
            ))}
          </>
        )}
      </div>
    </>
  )
}
const types = {
  T: 'Manager',
  OT: 'Team',
  TM: 'Team',
  G: 'Group',
  I: 'Agent',
}

const TargetCard = ({ record, idx, records, setRecords, allTargetsLink }) => {
  return (
    <div className="p-col-12 p-lg-6 p-xl-4 p-mb-3">
      <div className="card target-card relative">
        <h4 className="header-bg">{record.targetName}</h4>
        <div className="p-d-flex p-jc-between gap-1 p-ai-end p-my-3 p-px-2">
          <h6 className="primary-text-color p-m-0">
            {types[record.type]} | {record?.assignedTo}
          </h6>
          <h5 className="p-text-bold primary-text-color p-m-0">
            Level {record.levels[record.activeLevel]?.level}
          </h5>
        </div>
        <div className="p-d-flex p-flex-wrap gap-1">
          {record.isAmountBased === 1 && (
            <div className="flex-1">
              <div className="card p-px-1 target-stats-card p-text-center">
                <h6>Target Amount</h6>
                <h5 className="p-mt-0 p-text-bold" style={{ color: 'rgba(243, 209, 21, 1)' }}>
                  - ${moneyFormatter(record.levels[record.activeLevel]?.orderAmount)} -
                </h5>
              </div>
            </div>
          )}
          {record.isConversionRateBased === 1 && (
            <div className="flex-1">
              <div className="card p-px-1 target-stats-card p-text-center">
                <h6>Target Conversion Rate</h6>
                <h5 className="p-mt-0 p-text-bold" style={{ color: 'rgba(153, 255, 50, 1)' }}>
                  - {record.levels[record.activeLevel]?.orderConversionRate}% -
                </h5>
              </div>
            </div>
          )}
          {record.isCountBased === 1 && (
            <div className="flex-1">
              <div className="card p-px-1 target-stats-card p-text-center">
                <h6>Target Order</h6>
                <h5 className="p-mt-0 p-text-bold" style={{ color: 'rgba(167, 172, 217, 1)' }}>
                  - {moneyFormatter(record.levels[record.activeLevel]?.orderCount)} -
                </h5>
              </div>
            </div>
          )}
        </div>
        <div className="p-d-flex p-jc-between gap-1 p-ai-center p-mb-3">
          <Button
            icon="pi pi-chevron-left"
            className="p-button-rounded"
            disabled={record.activeLevel === 0}
            onClick={() => {
              const newRecords = [...records]
              newRecords[idx].activeLevel = newRecords[idx].activeLevel - 1
              setRecords(newRecords)
            }}
          />
          <div className="flex-1 card p-mb-0 target-stats-card p-d-flex p-jc-evenly gap-1 p-ai-end">
            <div className="p-text-center">
              {record.levels.length === 0 && <FiZapOff className="h-2 w-auto" />}
              {record.levels[record.activeLevel]?.status === 'Achieved' && (
                <FiCheckCircle className="h-2 w-auto" />
              )}
              {record.levels[record.activeLevel]?.status === 'In-Progress' && (
                <FiLoader className="h-2 w-auto" />
              )}
              {record.levels[record.activeLevel]?.status === 'Pending' && (
                <FiClock className="h-2 w-auto" />
              )}
              {record.levels[record.activeLevel]?.status === 'Inactive' && (
                <FiToggleLeft className="h-2 w-auto" />
              )}

              <h6 className="p-m-0">Status</h6>
            </div>
            <div className="p-text-center">
              <IoMdCloudy className="h-2 w-auto" />
              <h6 className="p-m-0">{record.daysRemaining} Days Left</h6>
            </div>
            <div className="p-text-center">
              <h5 className="p-m-0 p-pb-2 p-text-bold">₹{moneyFormatter(record?.rewardsEarned)}</h5>
              <h6 className="p-m-0">Reward</h6>
            </div>
          </div>
          <Button
            icon="pi pi-chevron-right"
            className="p-button-rounded"
            disabled={record.activeLevel === record.levels.length - 1}
            onClick={() => {
              const newRecords = [...records]
              newRecords[idx].activeLevel = newRecords[idx].activeLevel + 1
              setRecords(newRecords)
            }}
          />
        </div>

        <div className="p-mx-2 p-mb-2 p-d-flex gap-1 p-flex-column">
          {record.isAmountBased === 1 && (
            <div className="">
              <div className="p-d-flex p-ai-end p-jc-between p-mb-1 p-mx-1">
                <h6 className="p-my-0">Paid Amount</h6>
                <h5 className="p-my-0  p-text-bold" style={{ color: 'rgba(243, 209, 21, 1)' }}>
                  ${moneyFormatter(record.levels[record.activeLevel]?.totalPaidOrderAmount)}
                </h5>
              </div>
              <ProgressBar
                value={record.levels[record.activeLevel]?.orderAmountCompletedPercentage}
                className="h-1"
                showValue={false}
                color="rgba(243, 209, 21, 1)"
                style={{
                  background: 'rgba(243, 209, 21, 0.4)',
                }}
              />
            </div>
          )}
          {record.isConversionRateBased === 1 && (
            <div className="">
              <div className="p-d-flex p-ai-end p-jc-between p-mb-1 p-mx-1">
                <h6 className="p-my-0">Paid Conversion Rate</h6>
                <h5 className="p-my-0 p-text-bold" style={{ color: 'rgba(153, 255, 50, 1)' }}>
                  {moneyFormatter(record.levels[record.activeLevel]?.totalPaidOrderConversionRate)}%
                </h5>
              </div>
              <ProgressBar
                value={record.levels[record.activeLevel]?.totalPaidOrderConversionRate}
                className="h-1"
                showValue={false}
                color="rgba(153, 255, 50, 1)"
                style={{ background: 'rgba(153, 255, 50, 0.2)' }}
              />
            </div>
          )}
          {record.isCountBased === 1 && (
            <div className="">
              <div className="p-d-flex p-ai-end p-jc-between p-mb-1 p-mx-1">
                <h6 className="p-my-0">Paid Order</h6>
                <h5 className="p-my-0 p-text-bold" style={{ color: 'rgba(167, 172, 217, 1)' }}>
                  {moneyFormatter(record.levels[record.activeLevel]?.totalPaidOrderCount)}
                </h5>
              </div>
              <ProgressBar
                value={record.levels[record.activeLevel]?.orderCountCompletedPercentage}
                className="h-1"
                showValue={false}
                color="rgba(167, 172, 217, 1)"
                style={{ background: 'rgba(167, 172, 217, 0.4)' }}
              />
            </div>
          )}
        </div>
        <div className="p-m-4" />
        <div className="collapsed-btn w-full p-d-flex p-jc-center" style={{ bottom: '-20px' }}>
          <Link
            to={`${allTargetsLink}?${record.type === 'T' ? 'at_id' : 'id'}=${record.id}`}
            target="_blank"
          >
            <Button label="View Details" />
          </Link>
        </div>
      </div>
    </div>
  )
}
