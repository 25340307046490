import axios from 'axios'
import endpoints from './endpoints'

export const searchProduct = async (keyword) => {
  try {
    const res = await axios.get(endpoints.search.product + keyword)
    return res
  } catch (error) {
    return error.response
  }
}

export const searchStrength = async (keyword) => {
  try {
    const res = await axios.get(endpoints.search.strength + keyword)
    return res
  } catch (error) {
    return error.response
  }
}

export const searchQuantity = async (keyword) => {
  try {
    const res = await axios.get(endpoints.search.quantity + keyword)
    return res
  } catch (error) {
    return error.response
  }
}

export const searchEmail = async (keyword) => {
  try {
    const res = await axios.get(endpoints.search.email + keyword)
    return res
  } catch (error) {
    return error.response
  }
}

export const searchByOrderOrName = async (keyword) => {
  try {
    const res = await axios.get(endpoints.search.orderOrName + keyword)
    return res
  } catch (error) {
    return error.response
  }
}

export const searchPhone = async (keyword) => {
  try {
    const res = await axios.get(endpoints.search.phone + keyword)
    return res
  } catch (error) {
    return error.response
  }
}
