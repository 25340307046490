import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { TabView, TabPanel } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { FilterMatchMode } from 'primereact/api'
import { InputTextarea } from 'primereact/inputtextarea'
import {
  addFaq,
  addFaqCategory,
  deleteFaq,
  deleteFaqCategory,
  getAllFaqCategories,
  getFaqCategoriesById,
  getFaqs,
} from '../../api'
import { Column } from 'primereact/column'
import { format } from 'date-fns'
import { useGlobalContext } from '../../context'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { onChangeFilter } from '../../functions/setter'
import { Toolbar } from 'primereact/toolbar'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { Message } from 'primereact/message'
import useQuery from '../../hooks/useQuery'
import { Dialog } from 'primereact/dialog'
import { de } from 'date-fns/locale'
import { set } from 'lodash'

export default function FaqsPage() {
  const dt = useRef(null)
  const faqMainCategorydt = useRef(null)
  const faqSubCategorydt = useRef(null)
  const defaultPathname = '/website/faqs'
  const { toast } = useGlobalContext()
  // const query = useQuery()
  // const id = query.get('edit_faq_id')
  const history = useHistory()
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  const newEmptyCategory = {
    name: '',
    slug: '',
    subcategories: [
      {
        name: '',
        slug: '',
      },
    ],
  }
  const newEmptyFaq = {
    question: '',
    answer: '',
    category: '',
    subcategory: '',
  }
  const [faqFilter, setFaqFilter] = useState(emptyFilterObject)
  const [faqMainCategoryFilter, setFaqMainCategoryFilter] = useState(emptyFilterObject)
  const [faqSubCategoryFilter, setFaqSubCategoryFilter] = useState(emptyFilterObject)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [newCategory, setNewCategory] = useState(newEmptyCategory)
  const [newCategoryAdding, setNewCategoryAdding] = useState(false)
  const [newFaqLoading, setNewFaqLoading] = useState(false)
  const [faqs, setFaqs] = useState([])
  const [newFaq, setNewFaq] = useState(newEmptyFaq)
  const [deleteFaqDialog, setDeleteFaqDialog] = useState(false)
  const [deleteFaqData, setDeleteFaqData] = useState({})
  const [subcategoryOptions, setSubcategoryOptions] = useState([])
  const [newFaqRecordError, setNewFaqRecordError] = useState(emptyErr)
  const [newFaqCategoryRecordError, setNewFaqCategoryRecordError] = useState(emptyErr)
  const [mainCategoryData, setMainCategoryData] = useState([])
  const [subCategoryData, setSubCategoryData] = useState([])
  const [deleteFaqCategoryDialog, setDeleteFaqCategoryDialog] = useState(false)
  const [deleteFaqCategoryData, setDeleteFaqCategoryData] = useState({})

  const fetchAllFaqMainCategories = async () => {
    setLoading(true)
    const flag = 'main'
    const res = await getAllFaqCategories(flag)
    setLoading(false)
    setMainCategoryData(res.data.data)
  }
  useEffect(() => {
    fetchAllFaqMainCategories()
  }, [])

  const fetchAllFaqSubCategories = async () => {
    setLoading(true)
    const flag = 'sub'
    const res = await getAllFaqCategories(flag)
    setLoading(false)
    setSubCategoryData(res.data.data)
  }

  useEffect(() => {
    fetchAllFaqSubCategories()
  }, [])

  const fetchFaqs = async () => {
    setLoading(true)
    const res = await getFaqs()
    setLoading(false)
    setFaqs(res.data)
  }

  useEffect(() => {
    fetchFaqs()
  }, [])

  const fetchFaqSubcategories = useCallback(async () => {
    if (newFaq.category) {
      const res = await getFaqCategoriesById(newFaq.category)
      setSubcategoryOptions(res.data)
    }
  }, [newFaq.category])
  useEffect(() => fetchFaqSubcategories(), [fetchFaqSubcategories])

  const handleNewFaqChange = (target, value) => {
    setNewFaq((ps) => ({ ...ps, [target]: value }))
  }

  const validateNewFaq = () => {
    const err = []
    if (!newFaq.question || !newFaq.question.trim()) {
      err.push('Question is required')
    }
    if (!newFaq.answer || !newFaq.answer.trim()) {
      err.push('Answer is required')
    }
    if (!newFaq.category) {
      err.push('Main category is required')
    }
    // if (!newFaq.subcategory) {
    //   err.push('Sub category is required')
    // }
    return err
  }

  const saveFaq = async () => {
    const errors = validateNewFaq()
    if (errors.length > 0) {
      setNewFaqRecordError({
        state: true,
        errors,
      })
      return
    }
    setNewFaqLoading(true)
    const res = await addFaq(newFaq)
    if (res) {
      setNewFaqLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successfull',
          detail: res.data.message,
        })
        fetchFaqs()
        setNewFaq(newEmptyFaq)

        setNewFaqRecordError(emptyErr)
      }
    }
  }

  const validateNewFaqCategory = () => {
    const err = []
    if (!newCategory.name || !newCategory.name.trim()) {
      err.push('Name is required')
    }
    if (!newCategory.slug || !newCategory.slug.trim()) {
      err.push('Slug is required')
    }
    return err
  }

  const saveNewCategory = async () => {
    const errors = validateNewFaqCategory()
    if (errors.length > 0) {
      setNewFaqCategoryRecordError({
        state: true,
        errors,
      })
      return
    }
    setNewCategoryAdding(true)
    const res = await addFaqCategory(newCategory)
    if (res) {
      setNewCategoryAdding(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successfull',
          detail: res.data.message,
        })
        setNewCategory(newEmptyCategory)
        fetchAllFaqMainCategories()
        fetchAllFaqSubCategories()
      }
    }
  }

  const handelEditNewSubcategory = (index, value, field) => {
    let copy = { ...newCategory }
    copy.subcategories[index][field] = value
    setNewCategory(copy)
  }
  const handelAddNewSubcategory = () => {
    setNewCategory((ps) => ({
      ...ps,
      subcategories: [
        ...ps.subcategories,
        {
          name: '',
          slug: '',
        },
      ],
    }))
  }
  const handelDeleteNewSubcategory = (index) => {
    setNewCategory((ps) => ({
      ...ps,
      subcategories: ps.subcategories.filter((val, i) => i !== index),
    }))
  }

  const deleteCharge = async () => {
    setLoading(true)
    const res = await deleteFaq(deleteFaqData.id, password)

    if (res) {
      setLoading(false)
      if (res.status === 200) {
        setFaqs(faqs.filter((item) => item.id !== deleteFaqData.id))
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Shipping charge deleted successfully',
          life: 3000,
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res.data.error,
          life: 3000,
        })
      }
    }
    hideDeleteFaqDialog()
  }

  const removeFaqCategory = async () => {
    setLoading(true)
    const { id, primacy } = deleteFaqCategoryData
    const res = await deleteFaqCategory(id, password)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        if (primacy === 0) {
          setMainCategoryData(mainCategoryData.filter((item) => item.id !== id))
          setSubCategoryData(subCategoryData.filter((item) => item.primacy !== id))
          setFaqs(faqs.filter((item) => item.main_category_id !== id))
        }
        if (primacy !== 0) {
          setSubCategoryData(subCategoryData.filter((item) => item.id !== id))
          setFaqs(faqs.filter((item) => item.category_id !== id))
        }
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Category deleted successfully',
          life: 3000,
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: res.data.error,
          life: 3000,
        })
      }
    }
    hideDeleteCategoryDialog()
  }

  const hideDeleteCategoryDialog = () => {
    setPassword('')
    setDeleteFaqCategoryDialog(false)
    setDeleteFaqCategoryData({})
  }

  const hideDeleteFaqDialog = () => {
    setPassword('')
    setDeleteFaqDialog(false)
    setDeleteFaqData({})
  }
  /* BODIES */
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <>
        <Toast ref={toast} />

        <div className="card ">
          <TabView>
            <TabPanel header="FAQs">
              <Toolbar
                className="p-toolbar p-flex-wrap gap-1"
                left={
                  <div>
                    <h4 className="p-m-0">FAQs</h4>
                  </div>
                }
                right={
                  <div className="p-d-flex p-jc-end gap-1">
                    <Button icon="pi pi-refresh" onClick={fetchFaqs} />
                    <ExportButton datatable_ref={dt} />
                  </div>
                }
              />
              <DataTable
                exportFilename={`FAQs_${dtFilenameDate}`}
                ref={dt}
                value={faqs}
                showGridlines
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                className="datatable-responsive"
                columnResizeMode="fit"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} FAQs"
                emptyMessage="No FAQs found."
                loading={loading}
                rowHover
                filters={faqFilter}
                header={
                  <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        value={faqFilter.global.value}
                        onChange={(e) => {
                          setFaqFilter({
                            ...faqFilter,
                            global: {
                              value: e.target.value,
                              matchMode: FilterMatchMode.CONTAINS,
                            },
                          })
                        }}
                        placeholder="Search..."
                      />
                    </span>
                  </div>
                }
              >
                <Column field="id" header="Id" />
                <Column field="question" header="Question" />
                <Column field="answer" header="Answer" />
                <Column field="createdBy" header="Created By" />
                <Column
                  field="createdAt"
                  header="Created At"
                  body={(rd) => {
                    return <div>{format(new Date(rd.createdAt), 'PPp')}</div>
                  }}
                />
                <Column
                  field="Action"
                  header="Action"
                  body={(rd) => {
                    return (
                      <div className="p-d-flex gap-1by2">
                        <Button
                          icon="pi pi-trash"
                          className="p-button-danger"
                          tooltip="Delete"
                          onClick={() => {
                            setDeleteFaqData(rd)
                            setDeleteFaqDialog(true)
                          }}
                          tooltipOptions={{ position: 'bottom' }}
                        />
                      </div>
                    )
                  }}
                />
              </DataTable>
            </TabPanel>
            <TabPanel header="Add FAQs">
              <div className="p-fluid p-formgrid p-grid p-mb-2">
                <h4 className="p-col-12">Add FAQ</h4>

                <div className="p-col-12 p-md-6">
                  <div className="p-fluid p-field ">
                    <label className="p-col-fixed">Question</label>
                    <InputTextarea
                      placeholder="Enter question"
                      rows={7}
                      value={newFaq.question}
                      onChange={(e) => handleNewFaqChange('question', e.target.value)}
                    />
                  </div>
                </div>

                <div className="p-col-12 p-md-6">
                  <div className="p-fluid p-field ">
                    <label className="p-col-fixed">Answer</label>
                    <InputTextarea
                      placeholder="Enter answer"
                      rows={10}
                      value={newFaq.answer}
                      onChange={(e) => handleNewFaqChange('answer', e.target.value)}
                    />
                  </div>
                </div>
                <div className="p-col-12 p-md-6">
                  <div className="p-fluid p-field ">
                    <label className="p-col-fixed">Main Category</label>
                    <Dropdown
                      placeholder="Select main category"
                      options={mainCategoryData}
                      optionLabel="name"
                      optionValue="id"
                      value={newFaq.category}
                      onChange={(e) => {
                        handleNewFaqChange('category', e.value)
                      }}
                    />
                  </div>
                </div>
                <div className="p-col-12 p-md-6">
                  <div className="p-fluid p-field ">
                    <label className="p-col-fixed">Sub Category</label>
                    <Dropdown
                      placeholder="Select sub category"
                      value={newFaq.subcategory}
                      options={subcategoryOptions}
                      optionLabel="name"
                      optionValue="id"
                      onChange={(e) => {
                        handleNewFaqChange('subcategory', e.value)
                      }}
                    />
                  </div>
                </div>

                <div className="p-col-12">
                  <Button
                    loading={newFaqLoading}
                    disabled={newFaqLoading}
                    onClick={() => saveFaq()}
                    label={newFaqLoading ? 'Saving' : 'Save FAQ'}
                    icon="pi pi-save"
                  />
                </div>

                {/* Showing errors that occur if the field isn't filled properly */}
              </div>
              {newFaqRecordError.state && (
                <>
                  {newFaqRecordError.errors.map((err, idx) => {
                    return (
                      <div key={idx} className="p-fluid  p-col-12 p-lg-12">
                        <Message text={err} severity="warn" sticky={true} />
                      </div>
                    )
                  })}
                  <div className="p-fluid p-field p-col-12">
                    <Button
                      type="button"
                      onClick={() => {
                        setNewFaqRecordError(emptyErr)
                      }}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    ></Button>
                  </div>
                </>
              )}
            </TabPanel>
            <TabPanel header="FAQ Categories">
              <TabView>
                <TabPanel header="Main Category">
                  <Toolbar
                    className="p-toolbar p-flex-wrap gap-1"
                    left={
                      <div>
                        <h4 className="p-m-0">FAQ Main Categories</h4>
                      </div>
                    }
                    right={
                      <div className="p-d-flex p-jc-end gap-1">
                        <Button icon="pi pi-refresh" onClick={fetchAllFaqMainCategories} />
                        <ExportButton datatable_ref={faqMainCategorydt} />
                      </div>
                    }
                  />
                  <DataTable
                    exportFilename={`FAQs_Main_Category_${dtFilenameDate}`}
                    ref={faqMainCategorydt}
                    value={mainCategoryData}
                    showGridlines
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    className="datatable-responsive"
                    columnResizeMode="fit"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} FAQs"
                    emptyMessage="No FAQ main categories found."
                    loading={loading}
                    rowHover
                    filters={faqMainCategoryFilter}
                    header={
                      <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText
                            type="search"
                            value={faqFilter.global.value}
                            onChange={(e) => {
                              setFaqMainCategoryFilter({
                                ...faqMainCategoryFilter,
                                global: {
                                  value: e.target.value,
                                  matchMode: FilterMatchMode.CONTAINS,
                                },
                              })
                            }}
                            placeholder="Search..."
                          />
                        </span>
                      </div>
                    }
                  >
                    <Column field="id" header="Id" />
                    <Column field="name" header="Name" />
                    <Column field="slug" header="Slug" />
                    <Column field="createdBy" header="Created By" />
                    <Column
                      field="createdAt"
                      header="Created At"
                      body={(rd) => {
                        return <div>{format(new Date(rd.createdAt), 'PPp')}</div>
                      }}
                    />
                    <Column
                      field="Action"
                      header="Action"
                      body={(rd) => {
                        return (
                          <div className="p-d-flex gap-1by2">
                            <Button
                              icon="pi pi-trash"
                              className="p-button-danger"
                              tooltip="Delete"
                              onClick={() => {
                                setDeleteFaqCategoryData(rd)
                                setDeleteFaqCategoryDialog(true)
                              }}
                              tooltipOptions={{ position: 'bottom' }}
                            />
                          </div>
                        )
                      }}
                    />
                  </DataTable>
                </TabPanel>
                <TabPanel header="Sub Category">
                  <Toolbar
                    className="p-toolbar p-flex-wrap gap-1"
                    left={
                      <div>
                        <h4 className="p-m-0">FAQ Sub Categories</h4>
                      </div>
                    }
                    right={
                      <div className="p-d-flex p-jc-end gap-1">
                        <Button icon="pi pi-refresh" onClick={fetchAllFaqSubCategories} />
                        <ExportButton datatable_ref={faqSubCategorydt} />
                      </div>
                    }
                  />
                  <DataTable
                    exportFilename={`FAQs_Sub_Category_${dtFilenameDate}`}
                    ref={faqSubCategorydt}
                    value={subCategoryData}
                    showGridlines
                    dataKey="id"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    className="datatable-responsive"
                    columnResizeMode="fit"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} FAQs"
                    emptyMessage="No FAQ sub categories found."
                    loading={loading}
                    rowHover
                    filters={faqSubCategoryFilter}
                    header={
                      <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText
                            type="search"
                            value={faqFilter.global.value}
                            onChange={(e) => {
                              setFaqSubCategoryFilter({
                                ...faqSubCategoryFilter,
                                global: {
                                  value: e.target.value,
                                  matchMode: FilterMatchMode.CONTAINS,
                                },
                              })
                            }}
                            placeholder="Search..."
                          />
                        </span>
                      </div>
                    }
                  >
                    <Column field="id" header="Id" />
                    <Column field="name" header="Name" />
                    <Column field="slug" header="Slug" />
                    <Column field="createdBy" header="Created By" />
                    <Column
                      field="createdAt"
                      header="Created At"
                      body={(rd) => {
                        return <div>{format(new Date(rd.createdAt), 'PPp')}</div>
                      }}
                    />
                    <Column
                      field="Action"
                      header="Action"
                      body={(rd) => {
                        return (
                          <div className="p-d-flex gap-1by2">
                            <Button
                              icon="pi pi-trash"
                              className="p-button-danger"
                              tooltip="Delete"
                              onClick={() => {
                                setDeleteFaqCategoryData(rd)
                                setDeleteFaqCategoryDialog(true)
                              }}
                              tooltipOptions={{ position: 'bottom' }}
                            />
                          </div>
                        )
                      }}
                    />
                  </DataTable>
                </TabPanel>
              </TabView>
            </TabPanel>

            <TabPanel header="Add FAQ Category">
              <div className="p-fluid p-formgrid p-grid p-mb-2">
                <h4 className="p-col-12">Add FAQ Category</h4>
                <div className="p-col-12 p-md-4">
                  <div className="p-fluid p-field ">
                    <label className="p-col-fixed">Main category name</label>
                    <InputText
                      placeholder="Enter main category name"
                      value={newCategory.name}
                      onChange={(e) => {
                        setNewCategory((ps) => ({
                          ...ps,
                          name: e.target.value,
                        }))
                        setNewCategory((ps) => ({
                          ...ps,
                          slug: e.target.value.toLowerCase().replaceAll(' ', '-'),
                        }))
                      }}
                    />
                  </div>
                  <div className="p-fluid p-field ">
                    <label className="p-col-fixed">Main category slug</label>
                    <InputText
                      placeholder="Enter main category slug"
                      value={newCategory.slug}
                      onChange={(e) =>
                        setNewCategory((ps) => ({
                          ...ps,
                          slug: e.target.value.toLowerCase().replaceAll(' ', '-'),
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="p-col-12 p-md-8">
                  <div className="p-fluid p-field ">
                    <label className="p-col-fixed">Sub-categories name</label>
                    {newCategory.subcategories.map((val, idx) => {
                      return (
                        <div key={idx} className="p-fluid p-field ">
                          <div className="p-d-flex gap-1">
                            <InputText
                              placeholder="Enter sub-category name"
                              value={val.name}
                              onChange={(e) => {
                                handelEditNewSubcategory(idx, e.target.value, 'name')
                                handelEditNewSubcategory(
                                  idx,
                                  e.target.value.toLowerCase().replaceAll(' ', '-'),
                                  'slug'
                                )
                              }}
                            />
                            <InputText
                              placeholder="Enter sub-category slug"
                              value={val.slug}
                              onChange={(e) =>
                                handelEditNewSubcategory(
                                  idx,
                                  e.target.value.toLowerCase().replaceAll(' ', '-'),
                                  'slug'
                                )
                              }
                            />
                            <Button
                              style={{ width: '80px' }}
                              icon="pi pi-trash"
                              className="p-button-danger"
                              onClick={() => handelDeleteNewSubcategory(idx)}
                            />
                          </div>
                        </div>
                      )
                    })}
                    <Button
                      className=""
                      icon="pi pi-plus"
                      label="Add sub-category"
                      onClick={() => handelAddNewSubcategory()}
                    />
                  </div>
                </div>
                <div className="p-col-12">
                  <Button
                    loading={newCategoryAdding}
                    disabled={newCategoryAdding}
                    onClick={() => saveNewCategory()}
                    label={newCategoryAdding ? 'Saving' : 'Save Category'}
                    icon="pi pi-save"
                  />
                </div>
              </div>
              {newFaqCategoryRecordError.state && (
                <>
                  {newFaqCategoryRecordError.errors.map((err, idx) => {
                    return (
                      <div key={idx} className="p-fluid p-filed p-col-12">
                        <Message text={err} severity="warn" sticky={true} />
                      </div>
                    )
                  })}
                  <div className="p-fluid p-field p-col-12">
                    <Button
                      type="button"
                      onClick={() => {
                        setNewFaqCategoryRecordError(emptyErr)
                      }}
                      icon="pi pi-times"
                      label="Clear Warnings"
                      className="p-button-secondary"
                    ></Button>
                  </div>
                </>
              )}
            </TabPanel>
          </TabView>
          <ConfirmDialogWithPassword
            onHide={hideDeleteFaqDialog}
            onDelete={deleteCharge}
            visible={deleteFaqDialog}
            deleting={loading}
            password={password}
            setPassword={setPassword}
            headerText="Delete Faq"
            mainText={`Are you sure you want to delete Faq with the Id: ${deleteFaqData?.id}`}
          />
          <ConfirmDialogWithPassword
            onHide={hideDeleteCategoryDialog}
            onDelete={removeFaqCategory}
            visible={deleteFaqCategoryDialog}
            deleting={loading}
            password={password}
            setPassword={setPassword}
            headerText="Delete Faq"
            mainText={` Warning: This is an irreversible action. Are you sure you want to delete Faq category with the Id: ${deleteFaqCategoryData?.id}`}
          />
        </div>
      </>
    </PageAllowedToRoles>
  )
}

// const [editFaqDialogVisible, setEditFaqDialogVisible] = useState(false)
// const [editData, setEditData] = useState({})

// const hideDialog = () => {
//   setEditFaqDialogVisible(false)
//   setEditData({})
//   setNewFaqRecordError(emptyErr)
//   // clear the link
//   history.push(defaultPathname)
// }

{
  /* <Link to={`?edit_faq_id=${rd.id}`}>
                          <Button
                            icon="pi pi-pencil"
                            className="p-button-info"
                            tooltip="Edit Record"
                            tooltipOptions={{
                              position: 'bottom',
                            }}
                          />
                        </Link> */
}
// useEffect(() => {
//   if (id) {
//     const editData = faqs.find((item) => item.id === Number(id))
//     if (!editData) {
//       toast.current.show({
//         severity: 'error',
//         summary: 'Error',
//         detail: 'FAQ not found',
//       })
//       return
//     }
//     setEditData(editData)
//     setEditFaqDialogVisible(true)
//   }
// }, [id])
// <Dialog
//           header={
//             <div>
//               <h style={{ color: '#4B8AF4' }}>{'Edit Faq' + id}</h>
//             </div>
//           }
//           visible={editFaqDialogVisible}
//           onHide={hideDialog}
//           className="w-full max-w-60"
//           footer={
//             <div>
//               {/* {edit ? (
//             <>
//               <Button
//                 label="Discard"
//                 className="p-button-text p-button-danger"
//                 icon="pi pi-trash"
//                 disabled={loading}
//                 onClick={() => {
//                   setNewRecordError(emptyErr)
//                   setRecords(tempRecords)
//                   setEdit(false)
//                 }}
//               />
//               <Button
//                 label={loading ? 'Saving' : 'Save'}
//                 icon="pi pi-save"
//                 loading={loading}
//                 disabled={loading}
//                 onClick={() => {
//                   saveShipperTimeline()
//                 }}
//               />
//             </>
//           ) : (
//             <>
//               <Button
//                 label="Cancel"
//                 className="p-button-text"
//                 icon="pi pi-times"
//                 onClick={() => hideDialog()}
//               />
//               {pathname === defaultPathname &&
//                 ['superadmin', 'admin', 'operations'].includes(user.role) && (
//                   <Button
//                     label="Edit"
//                     icon="pi pi-pencil"
//                     onClick={() => {
//                       setEdit(true)
//                     }}
//                   />
//                 )}
//             </>
//           )} */}
//             </div>
//           }
//         >
//           {/* <ScrollPanel style={{ width: '100%', height: '310px' }}> */}
//           <div className="p-grid">
//             <div className="p-field p-fluid p-col-12 p-mb-0">
//               <label className="p-d-block">
//                 Question <sup style={{ color: 'red' }}>*</sup>
//               </label>
//               <InputTextarea
//                 placeholder="Question"
//                 value={editData?.question}
//                 cols={30}
//                 rows={3}
//                 onChange={(e) => {}}
//               />
//             </div>
//             <div className="p-field p-fluid p-col-12 p-mb-0">
//               <label className="p-d-block">
//                 Answer <sup style={{ color: 'red' }}>*</sup>
//               </label>
//               <InputTextarea
//                 placeholder="Answer"
//                 value={editData?.answer}
//                 cols={30}
//                 rows={5}
//                 onChange={(e) => {}}
//               />
//             </div>
//             <div className="p-field p-fluid p-col-12 p-mb-0">
//               <label className="p-d-block">
//                 Answer <sup style={{ color: 'red' }}>*</sup>
//               </label>
//               <InputTextarea
//                 placeholder="Answer"
//                 value={editData?.answer}
//                 cols={30}
//                 rows={5}
//                 onChange={(e) => {}}
//               />
//             </div>
//             <div className="p-col-12 p-md-6">
//               <div className="p-fluid p-field ">
//                 <label className="p-col-fixed">Main Category</label>
//                 <Dropdown
//                   placeholder="Select main category"
//                   options={categoryOptions}
//                   optionValue="id"
//                   optionLabel="name"
//                   value={editData?.main_category_id}
//                   onChange={(e) => {
//                     setMainCategoryShow(e.value)
//                     handleNewFaqChange('category', e.value.id)
//                   }}
//                 />
//               </div>
//             </div>
//             <div className="p-field p-fluid p-col-12 p-mb-0">
//               <label className="p-d-block">
//                 Sub Category <sup style={{ color: 'red' }}>*</sup>
//               </label>
//               <Dropdown
//                 placeholder="Select sub category"
//                 value={subCategoryShow}
//                 options={subcategoryOptions}
//                 optionLabel="name"
//                 onChange={(e) => {
//                   setSubCategoryShow(e.value)
//                   handleNewFaqChange('subcategory', e.value.id)
//                 }}
//               />
//             </div>

//             {newFaqRecordError.state && (
//               <>
//                 {newFaqRecordError.errors.map((err, idx) => {
//                   return (
//                     <div key={idx} className="p-fluid p-filed p-col-12">
//                       <Message text={err} severity="warn" sticky={true} />
//                     </div>
//                   )
//                 })}
//                 <div className="p-fluid p-field p-col-12">
//                   <Button
//                     type="button"
//                     onClick={() => {
//                       setNewFaqRecordError(emptyErr)
//                     }}
//                     icon="pi pi-times"
//                     label="Clear Warnings"
//                     className="p-button-secondary"
//                   ></Button>
//                 </div>
//               </>
//             )}
//           </div>
//           {/* </ScrollPanel> */}
//         </Dialog>
