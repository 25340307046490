import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { Toast } from 'primereact/toast'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useQuery from '../../../hooks/useQuery'
import { useGlobalContext } from '../../../context'
import { getDACIssueById, patchDACCloseIssue } from '../../../api'
import { handleObjChange } from '../../../functions/setter'
import { Message } from 'primereact/message'
import ConfirmationDialogWithInput from '../Dialog/ConfirmationDialogWithInput'
import { postReopenDACIssueReq } from '../../../api'

export default function ViewDACRecord({
  setLoading,
  loading,
  setOpenIssuesTableLoading,
  setClosedIssuesTableLoading,
  allOpenDACIssues,
  allClosedDACIssues,
  setAllOpenDACIssues,
  setAllClosedDACIssues,
  issueCloserCategories,
}) {
  //* Used in multiple places
  const history = useHistory()
  const toast = useRef(null)
  const query = useQuery()
  const id = parseInt(query.get('id'))
  const { colorScheme, user, agents } = useGlobalContext()

  //* Empty objects
  const emptyDacIssue = {
    orderDetails: '',
    customerName: '',
    issueCategory: '',
    issueSubCategory: '',
    openingNote: '',
    assignedToId: '',
    reopenApproval: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const emptyCloseDacIssue = {
    closingCategory: '',
    closingNote: '',
  }

  const emptyReopenReason = {
    reopeningNote: '',
    assignTo: '',
  }

  //* States start
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [dacIssueData, setDacIssueData] = useState(emptyDacIssue)
  const [closeDacIssueToggle, setCloseDacIssueToggle] = useState(false)
  const [closeDacIssueData, setCloseDacIssueData] = useState(emptyCloseDacIssue)
  const [detailsDialog, setDetailsDialog] = useState(false)
  const [reasonToReopen, setReasonToReopen] = useState(emptyReopenReason)
  const [reopenComplaintDialogVisible, setReopenComplaintDialogVisible] = useState(false)
  //* States end

  //* Function to fetch all details for DAC Issue using it's id
  const fetchDacIssueById = useCallback(async () => {
    if (!id) return
    setOpenIssuesTableLoading(true)
    setClosedIssuesTableLoading(true)
    const res = await getDACIssueById(id)

    if (res) {
      if (res.status === 404) {
        hideDACIssueDialog()
      }
      if (res.status === 200) {
        setOpenIssuesTableLoading(false)
        setClosedIssuesTableLoading(false)
        setDacIssueData(res.data[0])
        setDetailsDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchDacIssueById()
  }, [fetchDacIssueById])

  //* Function to toggle the DAC Issue details dialog off
  const hideDACIssueDialog = () => {
    setLoading(false)
    setDacIssueData(emptyDacIssue)
    setDetailsDialog(false)
    setCloseDacIssueToggle(false)
    setCloseDacIssueData(emptyCloseDacIssue)
    setNewRecordError(emptyErr)
    history.push({
      pathname: '/issues/dac',
    })
  }

  //* Function to close a DAC Issue
  const closeDacIssue = async () => {
    const err = []
    if (!closeDacIssueData.closingCategory) {
      err.push('You need to give complaint closing category')
    }
    if (!closeDacIssueData?.closingNote?.trim()) {
      err.push('You need to add a closing note')
    }
    if (err.length) {
      setNewRecordError({
        state: true,
        errors: err,
      })
      return
    }
    setLoading(true)
    const res = await patchDACCloseIssue(id, closeDacIssueData)
    if (res) {
      if (res.status === 200) {
        setLoading(false)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        let filteredClosedDACIssues = allOpenDACIssues.filter((issue) => {
          if (issue.id === id) {
            issue.closedBy = res.data.closedByName
            return issue
          }
        })
        let filteredOpenDACIssues = allOpenDACIssues.filter((issue) => issue.id !== id)
        setAllOpenDACIssues(filteredOpenDACIssues)
        setAllClosedDACIssues((prevSate) => [...filteredClosedDACIssues, ...prevSate])
        setCloseDacIssueData(emptyCloseDacIssue)
        hideDACIssueDialog()
      }
    }
  }

  const hideReopenDialog = () => {
    setReasonToReopen(emptyReopenReason)
    setReopenComplaintDialogVisible(false)
  }

  const reopenComplaintRequest = async () => {
    const err = []
    if (!reasonToReopen?.reopeningNote?.trim()) {
      err.push('Reason cannot be empty')
    }
    if (!reasonToReopen?.assignTo) {
      err.push('You need to assign the issue to an agent')
    }
    if (err.length) {
      setNewRecordError({
        state: true,
        errors: err,
      })
      return
    }
    setLoading(true)
    const res = await postReopenDACIssueReq(id, reasonToReopen)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        let filterCloseIssues = allClosedDACIssues.filter((complaint) => {
          if (complaint.id === id) {
            complaint.reopenApproval = 'pending'
          }
          return complaint
        })
        setAllClosedDACIssues(filterCloseIssues)
        hideReopenDialog()
        hideDACIssueDialog()
      }
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })

        let filterCloseComplaint = allClosedDACIssues.filter((complaint) => complaint.id === id)
        let filterCloseComplaints = allClosedDACIssues.filter((complaint) => complaint.id !== id)
        filterCloseComplaint[0].assignedToId = reasonToReopen.assignTo
        let moveToOpen = [...filterCloseComplaint, ...allOpenDACIssues]
        // moveToOpen.push(...filterCloseComplaint)
        setAllClosedDACIssues(filterCloseComplaints)
        setAllOpenDACIssues(moveToOpen)
        hideReopenDialog()
        hideDACIssueDialog()
      }
    }
  }

  //* Toggle between the color of open and close badge
  const badgeRed =
    colorScheme === 'dark'
      ? { color: `#FF7070`, backgroundColor: 'rgba(86, 51, 51, 1)' }
      : { color: `#b91c1c`, backgroundColor: '#FEE2E0' }
  const badgeGreen =
    colorScheme === 'dark'
      ? { color: `#06CF51`, backgroundColor: 'rgba(58, 106, 69, 0.6)' }
      : { color: `rgb(48 175 95)`, backgroundColor: 'rgb(204 237 216 / 100%)' }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        style={{ width: '90%', maxWidth: '800px' }}
        visible={detailsDialog}
        footer={
          dacIssueData.status === 'close' ? (
            <div>
              <Button
                label="Reopen"
                disabled={
                  dacIssueData.reopenApproval === 'pending' ||
                  (['teamlead'].includes(user.role) &&
                    !user?.members?.map((u) => u.id).includes(dacIssueData.assignedToId) &&
                    !user.members?.map((u) => u.id).includes(dacIssueData.closedById)) ||
                  (user.role === 'agent' &&
                    dacIssueData.assignedToId !== user.id &&
                    dacIssueData.closedById !== user.id &&
                    user.id !== 150)
                }
                className="p-button-text p-button-danger"
                icon="pi pi-undo"
                onClick={() => {
                  if (user.role === 'agent') {
                    handleObjChange(setReasonToReopen, 'assignTo', user.id)
                  }
                  setReopenComplaintDialogVisible(true)
                }}
              />
            </div>
          ) : (
            <div></div>
          )
        }
        onHide={hideDACIssueDialog}
        header={
          <div className="p-text-capitalize">
            <Badge
              className="p-text-bold"
              value={
                dacIssueData.status === 'close' ? dacIssueData.status + 'd' : dacIssueData.status
              }
              style={dacIssueData.status === 'close' ? badgeGreen : badgeRed}
            ></Badge>
          </div>
        }
      >
        {!closeDacIssueToggle ? (
          dacIssueData && (
            <div className="p-grid">
              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Case ID
                </span>
                <h6 className="p-mt-2">{dacIssueData.id}</h6>
              </div>
              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Invoice ID
                </span>
                <h6 className="p-mt-2">
                  {dacIssueData.invoiceId != 'NA' ? (
                    <Link target={'_blank'} to={`/orders?invId=${dacIssueData.invoiceId}`}>
                      {dacIssueData.invoiceId}
                    </Link>
                  ) : (
                    'NA'
                  )}
                </h6>
              </div>

              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Order Agent
                </span>
                <h6 className="p-mt-2">{dacIssueData?.username ? dacIssueData.username : 'NA'}</h6>
              </div>

              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Handling By
                </span>
                <h6 className="p-mt-2">{dacIssueData.agent}</h6>
              </div>
              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Customer Name
                </span>
                <h6 className="p-mt-2">{dacIssueData.customerName}</h6>
              </div>
              <div className="p-col-12 p-lg-4">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Issue Category
                </span>
                <h6 className="p-mt-2">{dacIssueData.issueType}</h6>
              </div>
              <div className="p-col-12 p-lg-5">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Issue Sub-Category
                </span>
                <h6 className="p-mt-2">{dacIssueData.issueSub}</h6>
              </div>

              <div
                className="p-d-flex gap-1 p-col-12 p-flex-col p-pt-3"
                style={{ borderTop: '1px dashed #0066FF' }}
              >
                <h6
                  style={{
                    color: '#0066FF',
                    fontWeight: 500,
                    fontSize: '16px',
                  }}
                >
                  <strong>Q : </strong>
                  {dacIssueData.note}
                </h6>
              </div>
              {(dacIssueData?.assignedToId === user.id ||
                ['superadmin', 'admin', 'manager', 'operations'].includes(user.role) ||
                (['teamlead'].includes(user.role) &&
                  user.members.map((u) => u.id).includes(dacIssueData?.assignedToId)) ||
                user.id === 150) &&
                (dacIssueData.status === 'open' || dacIssueData.status === 'reopen') && (
                  <Button
                    onClick={() => setCloseDacIssueToggle(true)}
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      bottom: '-16px',
                    }}
                  >
                    Close Issue
                  </Button>
                )}
            </div>
          )
        ) : (
          <div>
            <div className="p-fluid">
              <div className="field p-col-4">
                <label htmlFor="closingCategory">
                  Issue Closer Category <sup style={{ color: 'red' }}>*</sup>
                </label>
                <Dropdown
                  value={closeDacIssueData.closingCategory}
                  onChange={(e) => {
                    handleObjChange(setCloseDacIssueData, 'closingCategory', e.target.value)
                  }}
                  className="p-mt-3"
                  id="closingCategory"
                  options={issueCloserCategories}
                />
              </div>
              <div className="field p-col">
                <label htmlFor="closingNote">
                  Closer Note <sup style={{ color: 'red' }}>*</sup>
                </label>
                <InputTextarea
                  value={closeDacIssueData.closingNote}
                  onChange={(e) => {
                    handleObjChange(setCloseDacIssueData, 'closingNote', e.target.value)
                  }}
                  className="p-mt-3"
                  id="closingNote"
                  rows={5}
                  cols={30}
                />
              </div>
            </div>
            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => {
                  return (
                    <div key={idx} className="p-fluid p-filed p-col-12">
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  )
                })}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => {
                      setNewRecordError(emptyErr)
                    }}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  ></Button>
                </div>
              </>
            )}
            <div className="p-col field">
              <Button loading={loading} label="Submit" onClick={closeDacIssue}></Button>
            </div>
          </div>
        )}
        <ConfirmationDialogWithInput
          onHide={() => {
            hideReopenDialog()
          }}
          header={'Reopen complaint'}
          onSubmit={reopenComplaintRequest}
          visible={reopenComplaintDialogVisible}
          inputLabel={'Enter Reason...'}
          customInputs={true}
          // loading={loading}
        >
          <div className="p-field">
            <label htmlFor="password" className="">
              Reason to reopen <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputTextarea
              rows={4}
              cols={30}
              value={reasonToReopen.reopeningNote}
              onChange={(e) => handleObjChange(setReasonToReopen, 'reopeningNote', e.target.value)}
              className="w-full p-mt-1"
              placeholder="Start typing here..."
            />
          </div>
          {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
            <div className="p-field p-fluid p-col-12 p-lg-6">
              <label className="p-d-block">
                Assign To <sup style={{ color: 'red' }}>*</sup>
              </label>
              <Dropdown
                placeholder="Agent"
                options={['teamlead'].includes(user.role) ? user.members : agents}
                disabled={user.role === 'agent'}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                value={reasonToReopen.assignTo}
                onChange={(e) => {
                  handleObjChange(setReasonToReopen, 'assignTo', e.value)
                }}
              />
            </div>
          )}
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </ConfirmationDialogWithInput>
      </Dialog>
    </div>
  )
}
