// react setter function for state type object
export const handleObjChange = (setter, key, value) => {
  if (typeof setter !== 'function' || !key) return
  setter((ps) => ({ ...ps, [key]: value }))
}

export const onChangeFilter = (setter, value, target) => {
  setter((ps) => ({ ...ps, [target]: { ...ps[target], value } }))
}

export const handleAryChange = (setter, index, value) => {
  if (typeof setter !== 'function') return
  setter((ps) =>
    ps.map((item, idx) => {
      if (idx === index) {
        return value
      }
      return item
    })
  )
}
