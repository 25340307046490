import React, { useState, useRef, useEffect, useCallback } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { Button } from 'primereact/button'
import UploadImage from '../../components/mini/UploadImage'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'

import { Toast } from 'primereact/toast'
import {
  addCategory,
  getCategories,
  patchCategory,
  deleteCategory,
  categoryNames,
  getCategory,
} from '../../api'
import RequiredMessage from '../../components/mini/RequiredMessage'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { useGlobalContext } from '../../context'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import dateFormat from 'date-fns/format'
import { Dialog } from 'primereact/dialog'
import { Toolbar } from 'primereact/toolbar'
import { FilterMatchMode } from 'primereact/api'
import { Link } from 'react-router-dom'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'
import { handleObjChange, onChangeFilter } from '../../functions/setter'
import { Message } from 'primereact/message'

export default function CategoryPage() {
  const dt = useRef(null)
  const { fetchCategories, user, toast } = useGlobalContext()
  const empty_ImageState = {
    url: '',
    file: null,
  }
  const emptyNewCategory = {
    ogCategoryName: '',
    ogCategoryDescription: '',
    ogCategorySlug: '',
    ogCategoryImage: '',
    ogCategoryStatus: 'active',
    ogParentCategory: null,
    categoryType: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    ogCategoryStatus: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const [mainCategoryRecords, setMainCategoryRecords] = useState([])
  const [mainCategoryFilters, setMainCategoryFilters] = useState(emptyFilterObject)
  const [mainCategoryTableLoading, setMainCategoryTableLoading] = useState(false)

  const [subCategoryRecords, setSubCategoryRecords] = useState([])
  const [subCategoryFilters, setSubCategoryFilters] = useState(emptyFilterObject)
  const [subCategoryTableLoading, setSubCategoryTableLoading] = useState(false)

  const [parentCategories, setParentCategories] = useState([])
  const [imageState, setImageState] = useState(empty_ImageState)

  const [addCategoryDialogVisible, setAddCategoryDialogVisisble] = useState(false)
  const [editCategoryDialogVisisble, setEditCategoryDialogVisisble] = useState(false)
  const [deleteCategoryDialogVisible, setDeleteCategoryDialogVisisble] = useState(false)

  const [newCategory, setNewCategory] = useState({ ...emptyNewCategory })
  const [editCategory, setEditCategory] = useState({ ...emptyNewCategory })
  const [deleteCategoryState, setDeleteCategoryState] = useState({
    ...emptyNewCategory,
  })
  const [editImageState, setEditImageState] = useState(empty_ImageState)
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [newRecordError, setNewRecordError] = useState(emptyErr)

  const fetchParentCategories = useCallback(async () => {
    setMainCategoryTableLoading(true)
    setSubCategoryTableLoading(true)

    const res = await categoryNames({
      parent: true,
    })
    if (res) {
      setMainCategoryTableLoading(false)
      setSubCategoryTableLoading(false)
      if (res.status === 200) {
        setParentCategories(res.data)
      }
    }
  }, [])
  useEffect(() => fetchParentCategories(), [fetchParentCategories])

  //* Fetching the data to populate the datatables start*/
  const fetchMainCategoryRecords = useCallback(async () => {
    let type = 'main'
    setMainCategoryTableLoading(true)
    const res = await getCategories({ type })
    if (res && res.status === 200) {
      setMainCategoryTableLoading(false)
      setMainCategoryRecords(res.data)
    }
  }, [])
  useEffect(() => fetchMainCategoryRecords(), [fetchMainCategoryRecords])

  const fetchSubCategoryRecords = useCallback(async () => {
    setSubCategoryTableLoading(true)
    let type = 'sub'
    const res = await getCategories({ type })
    if (res && res.status === 200) {
      setSubCategoryTableLoading(false)
      setSubCategoryRecords(res.data)
    }
  }, [])
  useEffect(() => fetchSubCategoryRecords(), [fetchSubCategoryRecords])
  //* Fetching the data to populate the datatables end*/

  //* Handlers add new category start*/
  const validateNewRecord = () => {
    const err = []
    const { ogCategoryName, ogCategorySlug, categoryType, ogParentCategory } = newCategory
    if (!ogCategoryName) err.push('Please enter a category name')

    if (!ogCategorySlug) err.push('Category slug cannot be empty')

    if (categoryType === 'sub') {
      if (!ogParentCategory) err.push('Please select a parent category')
    }
    return err
  }

  const handelNewCategorySave = async () => {
    const errors = validateNewRecord()
    if (errors.length) {
      return setNewRecordError({
        state: true,
        errors,
      })
    }
    setLoading(true)
    const res = await addCategory(newCategory, imageState.file)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
          life: 3000,
        })
        if (newCategory.categoryType === 'main') {
          setMainCategoryRecords((ps) => [res.data.record, ...ps])
        }
        if (newCategory.categoryType === 'sub') {
          setSubCategoryRecords((ps) => [res.data.record, ...ps])
        }
        hideNewCategoryDialog()
        fetchParentCategories()
        await fetchCategories()
      }
    }
  }
  //* Function to add new category end */

  const handleCategoryDelete = async () => {
    setLoading(true)
    const res = await deleteCategory(deleteCategoryState.ogCategoryId, password)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Category deleted',
          detail: res.data.message,
          life: 3000,
        })
      }
      if (deleteCategoryState.categoryType === 'main') {
        const newRecordset = mainCategoryRecords.filter(
          (categoryRecord) => categoryRecord.ogCategoryId !== deleteCategoryState.ogCategoryId
        )
        setMainCategoryRecords(newRecordset)
      }
      if (deleteCategoryState.categoryType === 'sub') {
        const newRecordset = subCategoryRecords.filter(
          (categoryRecord) => categoryRecord.ogCategoryId !== deleteCategoryState.ogCategoryId
        )
        setSubCategoryRecords(newRecordset)
      }
      hideDeleteDialog()
    }
  }

  const validateEditRecord = () => {
    const err = []
    const { ogCategoryName, ogCategorySlug, categoryType, ogParentCategory } = editCategory
    if (!ogCategoryName) err.push('Please enter a category name')

    if (!ogCategorySlug) err.push('Category slug cannot be empty')
    return err
  }

  const handleEditCategory = async () => {
    const errors = validateEditRecord()
    if (errors.length) {
      return setNewRecordError({
        state: true,
        errors,
      })
    }
    setLoading(true)
    let file = null
    if (editImageState.url) {
      file = editImageState.file
    }
    const category = { ...editCategory }
    const id = category.ogCategoryId
    const res = await patchCategory(id, category, file)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        const { message, record } = res.data
        toast.current.show({
          severity: 'success',
          summary: 'Category saved',
          detail: message,
          life: 3000,
        })

        if (category.ogParentCategory === 0) {
          const newRecordSet = mainCategoryRecords.map((categoryRecord) => {
            if (categoryRecord.ogCategoryId === record.ogCategoryId) {
              return record
            }
            return categoryRecord
          })

          setMainCategoryRecords(newRecordSet)
        } else {
          const newRecordSet = subCategoryRecords.map((categoryRecord) => {
            if (categoryRecord.ogCategoryId === record.ogCategoryId) {
              return record
            }
            return categoryRecord
          })
          setSubCategoryRecords(newRecordSet)
        }

        hideEditeDialog()
        fetchParentCategories()
      }
    }
  }

  const hideDeleteDialog = () => {
    setPassword('')
    setDeleteCategoryDialogVisisble(false)
    setDeleteCategoryState({ ...emptyNewCategory })
  }

  const hideNewCategoryDialog = () => {
    setNewRecordError(emptyErr)
    setImageState(empty_ImageState)
    setAddCategoryDialogVisisble(false)
    setNewCategory({ ...emptyNewCategory })
  }

  const hideEditeDialog = () => {
    setEditCategoryDialogVisisble(false)
    setEditCategory({ ...emptyNewCategory })
    setEditImageState(empty_ImageState)
  }
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <div className="card">
        <ConfirmDialogWithPassword
          onHide={hideDeleteDialog}
          onDelete={handleCategoryDelete}
          visible={deleteCategoryDialogVisible}
          deleting={loading}
          password={password}
          setPassword={setPassword}
          headerText="Delete Category"
          mainText={`Delete Category (${deleteCategoryState.ogCategoryName})`}
        />

        <Toast ref={toast} />
        <TabView>
          <TabPanel header="Main Category">
            <Toolbar
              className="p-toolbar"
              left={
                <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                  <h5 className="p-m-0">Total Categories: {mainCategoryRecords?.length || 0}</h5>
                  <Button
                    icon="pi pi-plus"
                    label="Add Category"
                    onClick={() => {
                      setAddCategoryDialogVisisble(true)
                      handleObjChange(setNewCategory, 'categoryType', 'main')
                      handleObjChange(setNewCategory, 'ogParentCategory', 0)
                    }}
                  />
                </div>
              }
              right={
                <div className="p-d-flex p-fluid p-ai-center p-flex-column p-flex-md-row gap-1">
                  <Button icon="pi pi-refresh" onClick={() => fetchMainCategoryRecords()} />
                  <ExportButton datatable_ref={dt} />
                </div>
              }
            ></Toolbar>
            <DataTable
              exportFilename={`Categories-${dtFilenameDate}`}
              ref={dt}
              value={mainCategoryRecords}
              showGridlines
              filters={mainCategoryFilters}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} categories"
              emptyMessage="No category found."
              loading={mainCategoryTableLoading}
              rowHover
              removableSort
              header={
                <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                  <div className="">
                    <Dropdown
                      options={[
                        {
                          name: 'Active',
                          value: 'active',
                        },
                        {
                          name: 'Inactive',
                          value: 'inactive',
                        },
                      ]}
                      optionLabel="name"
                      optionValue="value"
                      value={mainCategoryFilters.ogCategoryStatus.value}
                      onChange={(e) =>
                        onChangeFilter(setMainCategoryFilters, e.value, 'ogCategoryStatus')
                      }
                      placeholder="Status"
                      showClear
                    />
                  </div>
                  <div className="">
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        value={mainCategoryFilters.global.value}
                        onChange={(e) => {
                          // setGlobalFilterValue(e.target.value)
                          onChangeFilter(setMainCategoryFilters, e.target.value, 'global')
                        }}
                        placeholder="Search..."
                      />
                    </span>
                  </div>
                </div>
              }
            >
              <Column field="ogCategoryId" header="Id" sortable style={{ width: '1%' }} />
              <Column field="prodCount" header="P. Count" sortable style={{ width: '1%' }} />
              {/* <Column
                field="ogParentCategoryName"
                header="Main Category"
                sortable
                style={{ width: '8%' }}
              /> */}
              <Column field="ogCategoryName" header="Name" style={{ width: '8%' }} />
              <Column field="ogCategorySlug" header="Slug" style={{ width: '8%' }} />
              <Column field="ogCategoryDescription" header="Description" style={{ width: '15%' }} />
              <Column field="ogCategoryStatus" header="Status" style={{ width: '2%' }} />
              <Column field="ogCategoryCreatedBy" header="Created By" style={{ width: '5%' }} />
              <Column
                field="ogCategoryCreatedDate"
                header="Created At"
                body={(rd) => dateFormat(new Date(rd?.ogCategoryCreatedDate), 'PP')}
                sortable
                style={{ width: '2%' }}
              />
              <Column
                hidden={!['superadmin', 'admin', 'manager', 'operations'].includes(user?.role)}
                body={(rd) => {
                  return (
                    <div className="p-d-flex gap-1">
                      <Button
                        className="p-button"
                        icon="pi pi-pencil"
                        tooltip="Edit Category"
                        tooltipOptions={{ position: 'bottom' }}
                        onClick={() => {
                          setEditCategoryDialogVisisble(true)
                          setEditCategory({ ...rd, categoryType: 'main' })
                        }}
                      />
                      <Button
                        className="p-button-danger"
                        icon="pi pi-trash"
                        tooltip="Delete Category"
                        tooltipOptions={{ position: 'bottom' }}
                        onClick={() => {
                          setDeleteCategoryDialogVisisble(true)
                          setDeleteCategoryState({ ...rd, categoryType: 'main' })
                        }}
                      />
                      {/* TODO: To be fixed */}
                      {'' && (
                        <Link to={`/website/category/arrange/${rd.ogCategorySlug}`}>
                          <Button
                            icon="pi pi-sort"
                            tooltip="Arrange Groups and Products"
                            tooltipOptions={{ position: 'bottom' }}
                          />
                        </Link>
                      )}
                      <Link to={`?history=${rd.ogCategoryId}`}>
                        <Button
                          icon="pi pi-clock"
                          className="p-button-help"
                          tooltip="History"
                          tooltipOptions={{ position: 'bottom' }}
                        />
                      </Link>
                    </div>
                  )
                }}
                header="Action"
                sortable
                style={{ width: '2%' }}
              />
            </DataTable>
          </TabPanel>
          <TabPanel header="Sub Category">
            <Toolbar
              className="p-toolbar"
              left={
                <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                  <h5 className="p-m-0">Total Categories: {subCategoryRecords?.length || 0}</h5>
                  <Button
                    icon="pi pi-plus"
                    label="Add Sub Category"
                    onClick={() => {
                      setAddCategoryDialogVisisble(true)
                      handleObjChange(setNewCategory, 'categoryType', 'sub')
                      setParentCategories(parentCategories.filter((parent) => parent.id !== 0))

                      // handleObjChange(setNewCategory, 'ogParentCategory', 0)
                    }}
                  />
                </div>
              }
              right={
                <div className="p-d-flex p-fluid p-ai-center p-flex-column p-flex-md-row gap-1">
                  <Button icon="pi pi-refresh" onClick={() => fetchSubCategoryRecords()} />
                  <ExportButton datatable_ref={dt} />
                </div>
              }
            ></Toolbar>
            <DataTable
              exportFilename={`Categories-${dtFilenameDate}`}
              ref={dt}
              value={subCategoryRecords}
              showGridlines
              filters={subCategoryFilters}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} categories"
              emptyMessage="No category found."
              loading={subCategoryTableLoading}
              rowHover
              removableSort
              header={
                <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                  <div className="">
                    <Dropdown
                      options={[
                        {
                          name: 'Active',
                          value: 'active',
                        },
                        {
                          name: 'Inactive',
                          value: 'inactive',
                        },
                      ]}
                      optionLabel="name"
                      optionValue="value"
                      value={subCategoryFilters.ogCategoryStatus.value}
                      onChange={(e) =>
                        onChangeFilter(setSubCategoryFilters, e.value, 'ogCategoryStatus')
                      }
                      placeholder="Status"
                      showClear
                    />
                  </div>
                  <div className="">
                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        value={subCategoryFilters.global.value}
                        onChange={(e) => {
                          // setGlobalFilterValue(e.target.value)
                          onChangeFilter(setSubCategoryFilters, e.target.value, 'global')
                        }}
                        placeholder="Search..."
                      />
                    </span>
                  </div>
                </div>
              }
            >
              <Column field="ogCategoryId" header="Id" sortable style={{ width: '1%' }} />
              <Column field="prodCount" header="P. Count" sortable style={{ width: '1%' }} />
              {/* <Column
                field="ogParentCategoryName"
                header="Main Category"
                sortable
                style={{ width: '8%' }}
              /> */}
              <Column
                field="ogParentCategoryName"
                header="Parent Category"
                style={{ width: '8%' }}
              />
              <Column field="ogCategoryName" header="Name" style={{ width: '8%' }} />
              <Column field="ogCategorySlug" header="Slug" style={{ width: '8%' }} />
              <Column field="ogCategoryDescription" header="Description" style={{ width: '15%' }} />
              <Column field="ogCategoryStatus" header="Status" style={{ width: '2%' }} />
              <Column field="ogCategoryCreatedBy" header="Created By" style={{ width: '5%' }} />
              <Column
                field="ogCategoryCreatedDate"
                header="Created At"
                body={(rd) => dateFormat(new Date(rd?.ogCategoryCreatedDate), 'PP')}
                sortable
                style={{ width: '2%' }}
              />
              <Column
                hidden={!['superadmin', 'admin', 'manager','operations'].includes(user?.role)}
                body={(rd) => {
                  return (
                    <div className="p-d-flex gap-1">
                      <Button
                        className="p-button"
                        icon="pi pi-pencil"
                        tooltip="Edit Category"
                        tooltipOptions={{ position: 'bottom' }}
                        onClick={() => {
                          setEditCategoryDialogVisisble(true)
                          setEditCategory({ ...rd, categoryType: 'sub' })
                        }}
                      />
                      <Button
                        className="p-button-danger"
                        icon="pi pi-trash"
                        tooltip="Delete Category"
                        tooltipOptions={{ position: 'bottom' }}
                        onClick={() => {
                          setDeleteCategoryDialogVisisble(true)
                          setDeleteCategoryState({ ...rd, categoryType: 'sub' })
                        }}
                      />
                      {/* TODO: To be fixed */}
                      {'' && (
                        <Link to={`/website/category/arrange/${rd.ogCategorySlug}`}>
                          <Button
                            icon="pi pi-sort"
                            tooltip="Arrange Groups and Products"
                            tooltipOptions={{ position: 'bottom' }}
                          />
                        </Link>
                      )}
                      <Link to={`?history=${rd.ogCategoryId}`}>
                        <Button
                          icon="pi pi-clock"
                          className="p-button-help"
                          tooltip="History"
                          tooltipOptions={{ position: 'bottom' }}
                        />
                      </Link>
                    </div>
                  )
                }}
                header="Action"
                sortable
                style={{ width: '2%' }}
              />
            </DataTable>
          </TabPanel>
          {/* {['superadmin', 'admin', 'operations'].includes(user?.role) && (
         
          )} */}
        </TabView>
        {/* Dialog to add new category start */}
        <Dialog
          header={`Add ${newCategory?.categoryType} category`}
          visible={addCategoryDialogVisible}
          style={{ width: '50vw' }}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={hideNewCategoryDialog}
                className="p-button-text"
              />
              <Button
                disabled={loading}
                loading={loading}
                label={loading ? 'Submitting' : 'Submit'}
                icon="pi pi-save"
                onClick={handelNewCategorySave}
                autoFocus
              />
            </div>
          }
          onHide={hideNewCategoryDialog}
        >
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-fluid p-field p-col-12 p-lg-4 p-formgrid">
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Category Name <span style={{ color: 'red' }}>*</span>
                </label>
                <InputText
                  placeholder="Enter category name"
                  value={newCategory.ogCategoryName}
                  onChange={(e) => {
                    setNewCategory((ps) => ({
                      ...ps,
                      ogCategoryName: e.target.value,
                      ogCategorySlug: e.target.value.replaceAll(' ', '-').toLowerCase(),
                    }))
                  }}
                />
              </div>
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Slug <span style={{ color: 'red' }}>*</span>
                </label>
                <InputText
                  placeholder="Enter category slug"
                  value={newCategory.ogCategorySlug}
                  onChange={(e) =>
                    handleObjChange(setNewCategory, 'ogCategorySlug', e.target.value)
                  }
                />
              </div>
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Status
                </label>
                <Dropdown
                  placeholder="Select status"
                  value={newCategory.ogCategoryStatus}
                  options={['inactive', 'active']}
                  onChange={(e) => handleObjChange(setNewCategory, 'ogCategoryStatus', e.value)}
                />
              </div>
            </div>

            <div className="p-fluid p-field p-col-12 p-lg-4">
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Main Category{' '}
                  {newCategory.categoryType === 'sub' && <span style={{ color: 'red' }}>*</span>}
                </label>
                <Dropdown
                  disabled={newCategory?.categoryType === 'main'}
                  placeholder="Select status"
                  optionLabel="name"
                  optionValue="id"
                  filter
                  filterBy="name"
                  filterPlaceholder="Search..."
                  value={newCategory.ogParentCategory}
                  options={parentCategories}
                  onChange={(e) => handleObjChange(setNewCategory, 'ogParentCategory', e.value)}
                />
              </div>
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Description{' '}
                </label>
                <InputTextarea
                  placeholder="Enter category description"
                  value={newCategory.ogCategoryDescription}
                  onChange={(e) =>
                    handleObjChange(setNewCategory, 'ogCategoryDescription', e.target.value)
                  }
                  rows={5}
                />
              </div>
            </div>
            <div className="p-fluid p-field p-col-12 p-lg-4">
              <label htmlFor="" className="p-col-fixed">
                Image
              </label>
              <UploadImage imageState={imageState} setImageState={setImageState} />
            </div>
          </div>
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </Dialog>
        {/* Dialog to add new category end */}
        <Dialog
          header={`Edit category`}
          visible={editCategoryDialogVisisble}
          style={{ width: '50vw' }}
          footer={
            <div>
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => hideEditeDialog()}
                className="p-button-text"
              />
              <Button
                loading={loading}
                disabled={loading}
                label={loading ? 'Saving' : 'Save'}
                icon="pi pi-save"
                onClick={() => handleEditCategory()}
                autoFocus
              />
            </div>
          }
          onHide={() => hideEditeDialog('displayBasic')}
        >
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-fluid p-field p-col-12 p-lg-6 p-formgrid">
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Category Name <span style={{ color: 'red' }}>*</span>
                </label>
                <InputText
                  placeholder="Enter category name"
                  value={editCategory.ogCategoryName}
                  onChange={(e) =>
                    setEditCategory((ps) => ({
                      ...ps,
                      ogCategoryName: e.target.value,
                      ogCategorySlug: e.target.value.replaceAll(' ', '-').toLowerCase(),
                    }))
                  }
                />
              </div>
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Slug <span style={{ color: 'red' }}>*</span>
                </label>
                <InputText
                  placeholder="Enter category slug"
                  value={editCategory.ogCategorySlug}
                  onChange={(e) =>
                    handleObjChange(setEditCategory, 'ogCategorySlug', e.target.value)
                  }
                />
              </div>
              {editCategory?.ogCategoryImage ? (
                <div className="p-fluid p-field p-col-12">
                  <label htmlFor="" className="p-col-fixed">
                    Image
                  </label>
                  <div className="card p-text-center">
                    <img
                      className="w-full"
                      style={{ borderRadius: '10px 10px 10px 10px' }}
                      src={process.env.REACT_APP_IMAGE_BASE + editCategory.ogCategoryImage}
                      alt=""
                    />
                    <Button
                      label="Change Image"
                      icon="pi pi-pencil"
                      onClick={() => handleObjChange(setEditCategory, 'ogCategoryImage', '')}
                    />
                  </div>
                </div>
              ) : (
                <div className="p-fluid p-field p-col-12">
                  <label htmlFor="" className="p-col-fixed">
                    Image
                  </label>
                  <UploadImage imageState={editImageState} setImageState={setEditImageState} />
                </div>
              )}
            </div>

            <div className="p-fluid p-field p-col-12 p-lg-6">
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Status
                </label>
                <Dropdown
                  placeholder="Select status"
                  value={editCategory.ogCategoryStatus}
                  options={['inactive', 'active']}
                  onChange={(e) => handleObjChange(setEditCategory, 'ogCategoryStatus', e.value)}
                />
              </div>
              <div className="p-fluid p-field p-col-12">
                <label htmlFor="" className="p-col-fixed">
                  Main Category
                </label>
                <Dropdown
                  placeholder="Select status"
                  optionLabel="name"
                  optionValue="id"
                  filter
                  filterBy="name"
                  filterPlaceholder="Search..."
                  value={editCategory.ogParentCategory}
                  options={parentCategories}
                  onChange={(e) => handleObjChange(setEditCategory, 'ogParentCategory', e.value)}
                />
              </div>
              <label htmlFor="" className="p-col-fixed">
                Description{' '}
              </label>
              <InputTextarea
                placeholder="Enter category description"
                value={editCategory.ogCategoryDescription}
                onChange={(e) =>
                  handleObjChange(setEditCategory, 'ogCategoryDescription', e.target.value)
                }
                rows={9}
              />
            </div>
          </div>
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </Dialog>
        <ChangeHistorySidebar
          setTableLoading={setMainCategoryTableLoading && setSubCategoryTableLoading}
          header="Category Change History"
          historyType="category"
        />
      </div>
    </PageAllowedToRoles>
  )
}
