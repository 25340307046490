import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Timeline } from 'primereact/timeline'
import React, { useRef, useState } from 'react'
import { useGlobalContext } from '../../../context'
import { format } from 'date-fns'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { patchTargetById, postTarget } from '../../../api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function TargetConfirmationDialog({
  pathname,
  targetType,
  confirmationDialogVisible,
  setConfirmationDialogVisible,
  name = '',
  active = true,
  teamId = null,
  groupName = '',
  managerReward = 0,
  teamLeadReward = 0,
  weekRange = [],
  targetAmountBased = 0,
  targetCountBased = 0,
  targetConversionRateBased = 0,
  rewardType = 'Fixed',
  members = [],
  membersLevelsBreakdown = [],
  teamOrGroupLevelsBreakdown = [],
  callBackFn,
  toast,
  isTeamPendingBreakdown = false,
  teamPendingBreakdownId = null,
}) {
  const history = useHistory()
  const { agents, teams } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const handleConfirmation = async () => {
    setLoading(true)

    const postFn = async () => {
      if (isTeamPendingBreakdown && teamPendingBreakdownId) {
        return await patchTargetById(teamPendingBreakdownId, {
          breakDown: membersLevelsBreakdown,
          members: members,
          active: active,
        })
      } else {
        return await postTarget({
          targetType: targetType,
          name: name,
          teamId: teamId,
          groupName: groupName,
          managerReward: managerReward,
          teamLeadReward: teamLeadReward,
          dateFrom: weekRange[0],
          dateTo: weekRange[1],
          rewardType: rewardType,
          targetAmountBased: targetAmountBased,
          targetCountBased: targetCountBased,
          targetConversionRateBased: targetConversionRateBased,
          active: active,
          teamOrGroupLevelsBreakdown: teamOrGroupLevelsBreakdown,
          members: members,
          membersLevelsBreakdown: membersLevelsBreakdown,
        })
      }
    }
    const res = await postFn()
    if (res) {
      setLoading(false)
      if (res.status === 201 || res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res?.data?.message,
        })
        setConfirmationDialogVisible(false)
        callBackFn && callBackFn()
        if (isTeamPendingBreakdown) {
          history.push({
            pathname: pathname,
          })
        }
      }
    }
  }
  return (
    <>
      <Dialog
        header="Target Confirmation"
        visible={confirmationDialogVisible}
        style={{ width: '100%', maxWidth: '1200px' }}
        onHide={() => {
          setConfirmationDialogVisible(false)
          setLoading(false)
        }}
        footer={
          <div className="p-d-flex p-jc-center">
            <Button
              loading={loading}
              disabled={loading}
              label={loading ? 'Submitting' : 'Confirm'}
              onClick={handleConfirmation}
            />
          </div>
        }
      >
        {confirmationDialogVisible && (
          <>
            <div className="p-grid" style={{ rowGap: '15px' }}>
              <div className="p-col-6 p-lg-3">
                <label htmlFor="">Name</label>
                <h6 className="p-m-0 p-pt-3">{name}</h6>
              </div>
              <div className="p-col-6 p-lg-3">
                <label htmlFor="">Status</label>
                <h6 className="p-m-0 p-pt-3">{active ? 'Active' : 'Inactive'}</h6>
              </div>
              <div className="p-col-6 p-lg-3">
                <label htmlFor="">Reward</label>
                <h6 className="p-m-0 p-pt-3">{rewardType}</h6>
              </div>
              <div className="p-col-6 p-lg-3">
                <label htmlFor="">Dates</label>
                <h6 className="p-m-0 p-pt-3">
                  {`${format(weekRange[0], 'dd MMM yyyy')} To ${format(
                    weekRange[1],
                    'dd MMM yyyy'
                  )}`}
                </h6>
              </div>
              {['T', 'TM'].includes(targetType) && (
                <>
                  <div className="p-col-6 p-lg-3">
                    <label htmlFor="">Manager Reward</label>
                    <h6 className="p-m-0 p-pt-3">₹{managerReward}</h6>
                  </div>
                  <div className="p-col-6 p-lg-3">
                    <label htmlFor="">Team Lead Reward</label>
                    <h6 className="p-m-0 p-pt-3">₹{teamLeadReward}</h6>
                  </div>
                </>
              )}
            </div>
            <hr />
            {/* TEAM OR GROUP TARGETS */}
            {['TM', 'T', 'G', 'OT'].includes(targetType) && (
              <div className="p-my-2">
                {targetType === 'G' && <h6 className="p-ml-2"> {groupName}</h6>}
                {targetType === 'TM' && (
                  <h6 className="p-ml-2">
                    {teams[teams.findIndex((team) => team.teamID === teamId)]?.teamName}
                  </h6>
                )}
                {teamOrGroupLevelsBreakdown.map((level, idx) => {
                  return (
                    <div
                      key={idx}
                      className="p-formgrid p-grid p-mx-auto p-text-center p-ai-center gap-1by2"
                    >
                      <div
                        className="p-field p-col-12 p-lg-1 p-p-2 p-shadow-2"
                        style={{
                          border: '1px solid #1F6AE3',
                          borderRadius: '10px',
                        }}
                      >
                        <label className="p-m-0">{`Level ${idx + 1}`}</label>
                      </div>
                      {targetAmountBased && (
                        <div className="p-field p-fluid p-col-12 p-lg-2">
                          <InputText
                            readOnly
                            value={`Amount: $${level.targetAmount}`}
                            placeholder="Amount"
                          />
                        </div>
                      )}
                      {targetCountBased && (
                        <div className="p-field p-fluid p-col-12 p-lg-2">
                          <InputText
                            readOnly
                            value={`Orders:  ${level.targetOrder}`}
                            placeholder="Order"
                          />
                        </div>
                      )}
                      {targetConversionRateBased && (
                        <div className="p-field p-fluid p-col-12 p-lg-2">
                          <InputText
                            readOnly
                            value={`Conversion: ${level.conversionRate}%`}
                            placeholder="Conversion rate"
                          />
                        </div>
                      )}
                      {rewardType === 'Incentive' && (
                        <div className="p-field p-fluid p-col-12 p-lg-2">
                          <InputText
                            readOnly
                            value={`Reward: ₹${level.rewardAmount}/$`}
                            placeholder="Reward"
                          />
                        </div>
                      )}
                      {rewardType === 'Fixed' && (
                        <div className="p-field p-fluid p-col-12 p-lg-2">
                          <InputText
                            readOnly
                            value={`Reward: ₹${level.rewardAmount}`}
                            placeholder="Reward"
                          />
                        </div>
                      )}
                      <div className="p-field p-fluid p-col">
                        <InputText name="Remarks" value={level.remark} placeholder="Remarks" />
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {/* MEMBERS TARGETS */}
            {['TM', 'G', 'I'].includes(targetType) && (
              <div className="widget-timeline">
                <div className="timeline-content">
                  <Timeline
                    value={membersLevelsBreakdown}
                    className="custimized-timeline"
                    content={(targetsArr, idx) => {
                      return (
                        <>
                          <div className="p-mb-3">
                            <h6>{agents.find((agent) => agent.id === members[idx])?.username}</h6>
                          </div>
                          {targetsArr.map((target, id) => {
                            return (
                              <div
                                key={id}
                                className="p-formgrid p-grid p-mx-auto p-text-center p-ai-center gap-1by2"
                              >
                                <div
                                  className="p-field p-col-12 p-lg-1 p-p-2 p-shadow-2"
                                  style={{
                                    border: '1px solid #1F6AE3',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <label className="p-m-0">{`Level ${id + 1}`}</label>
                                </div>
                                {targetAmountBased && (
                                  <div className="p-field p-fluid p-col-12 p-lg-2">
                                    <InputText
                                      readOnly
                                      value={`Amount: $${target.targetAmount}`}
                                      placeholder="Amount"
                                    />
                                  </div>
                                )}
                                {targetCountBased && (
                                  <div className="p-field p-fluid p-col-12 p-lg-2">
                                    <InputText
                                      readOnly
                                      value={`Orders:  ${target.targetOrder}`}
                                      placeholder="Order"
                                    />
                                  </div>
                                )}
                                {targetConversionRateBased && (
                                  <div className="p-field p-fluid p-col-12 p-lg-2">
                                    <InputText
                                      readOnly
                                      value={`Conversion: ${target.conversionRate}%`}
                                      placeholder="Conversion rate"
                                    />
                                  </div>
                                )}
                                {rewardType === 'Incentive' && (
                                  <div className="p-field p-fluid p-col-12 p-lg-2">
                                    <InputText
                                      readOnly
                                      value={`Reward: ₹${target.rewardAmount}/$`}
                                      placeholder="Reward"
                                    />
                                  </div>
                                )}
                                {rewardType === 'Fixed' && (
                                  <div className="p-field p-fluid p-col-12 p-lg-2">
                                    <InputText
                                      readOnly
                                      value={`Reward: ₹${target.rewardAmount}`}
                                      placeholder="Reward"
                                    />
                                  </div>
                                )}
                                <div className="p-field p-fluid p-col">
                                  <InputText readOnly value={target.remark} placeholder="Remarks" />
                                </div>
                              </div>
                            )
                          })}
                        </>
                      )
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Dialog>
    </>
  )
}
