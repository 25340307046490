import { Toast } from 'primereact/toast'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Dialog } from 'primereact/dialog'
import { Timeline } from 'primereact/timeline'
import { Button } from 'primereact/button'
import { Message } from 'primereact/message'
import { InputTextarea } from 'primereact/inputtextarea'
import { Divider } from 'primereact/divider'
import useQuery from '../../../hooks/useQuery'
import { getComplaintNotesById, postNewComplaintNote } from '../../../api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { format } from 'date-fns'
import { useGlobalContext } from '../../../context'
export default function ComplaintNotesDialog({
  setLoading,
  loading,
  setOpenIssuesTableLoading,
  setClosedIssuesTableLoading,
  pathname,
}) {
  //* Used in multiple places
  const newNoteRef = useRef(null)
  const toast = useRef(null)
  const history = useHistory()
  const query = useQuery()
  const noteId = parseInt(query.get('noteId'))

  const { user } = useGlobalContext()

  //* Empty objects
  const emptyErr = {
    state: false,
    errors: [],
  }

  //* State start
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [notesDialogVisible, setNotesDialogVisible] = useState(false)
  const [complaintNotes, setComplaintNotes] = useState([])
  const [generalNote, setGeneralNote] = useState('')
  const [addNewNoteBtn, setAddNewNoteBtn] = useState(false)
  //* State end

  //* Function to fetch all notes for a complaint using it's id
  const fetchComplaintNotesById = useCallback(async () => {
    if (!noteId) return
    setOpenIssuesTableLoading(true)
    setClosedIssuesTableLoading(true)
    const res = await getComplaintNotesById(noteId)
    if (res) {
      setOpenIssuesTableLoading(false)
      setClosedIssuesTableLoading(false)
      if (res.status === 400) {
        hideComplaintNotes()
      }
      if (res.status === 200) {
        setComplaintNotes(res.data.notes)
        setNotesDialogVisible(true)
      }
    }
  }, [noteId])
  useEffect(() => {
    fetchComplaintNotesById()
  }, [fetchComplaintNotesById])

  //* Code for coloring the timeline
  const setMarkerAndBorderColor = (px, item, idx) => {
    let setColor = `${px}px solid ${
      (item.noteType === 'open' && 'rgba(223, 141, 95, 1)') ||
      (item.noteType === 'close' && 'rgba(167, 223, 95, 1)') ||
      (item.noteType === 'general' && idx % 2 === 0
        ? 'rgba(151, 95, 223, 1)'
        : 'rgba(95, 123, 223, 1)')
    }`
    return setColor
  }

  //* Function to customize the marker for the timeline
  const customizedMarker = (item, idx) => {
    return (
      <span
        className="p-p-2"
        style={{
          border: `${setMarkerAndBorderColor(3, item, idx)}`,
          borderRadius: '10px',
        }}
      ></span>
    )
  }

  //* Function to customize the content that appears on the timeline
  const customizedContent = (item, idx) => {
    const resultDate = format(new Date(item?.createdAt), 'PPpp')
    return (
      <div
        className="p-p-3 card "
        style={{
          borderLeft: `${setMarkerAndBorderColor(5, item, idx)}`,
          borderRadius: '10px',
          textAlign: 'start',
        }}
      >
        <div className="p-d-flex p-jc-between">
          <p className="p-text-bold">{item?.createdBy}</p>
          <p style={{ color: 'grey' }}>{resultDate}</p>
        </div>
        <Divider type="dashed" />
        <p>{item.note}</p>
      </div>
    )
  }

  //* Function to add a new note to complaint
  const addNewNoteToComplaint = async () => {
    const errs = []
    setNewRecordError(emptyErr)
    if (!generalNote?.trim()) {
      errs.push('Note is invalid')
    }
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading(true)
    const res = await postNewComplaintNote(generalNote, noteId)

    if (res) {
      if (res.status === 201) {
        setLoading(false)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })

        setComplaintNotes((ps) => [...ps, res.data.note])
        setGeneralNote('')

        setAddNewNoteBtn(false)
      }
    }
  }

  //* Function to hide the section to add a new note
  const hideComplaintNotes = () => {
    setLoading(false)
    setNotesDialogVisible(false)
    setComplaintNotes([])
    setAddNewNoteBtn(false)
    history.push({
      pathname: pathname,
    })
  }

  return (
    <div>
      <Toast ref={toast} />

      <Dialog
        visible={notesDialogVisible}
        header={`Notes for complaint #${noteId}`}
        style={{
          width: '60%',
          minWidth: '400px',
        }}
        onHide={() => {
          hideComplaintNotes()
        }}
        footer={
          <Button
            label="Cancel"
            className="p-button-text"
            icon="pi pi-times"
            onClick={() => {
              hideComplaintNotes()
            }}
          />
        }
      >
        <div>
          <Timeline
            value={complaintNotes}
            align="alternate"
            className="customized-timeline"
            marker={customizedMarker}
            content={customizedContent}
            style={{ zIndex: '999' }}
          />
          {(complaintNotes[0]?.assignedToId === user.id ||
            complaintNotes[0]?.orderAgentId === user.id ||
            ['supreadmin', 'admin', 'manager', 'operations'].includes(user.role) ||
            (['teamlead'].includes(user.role) &&
              user.members.map((u) => u.id).includes(complaintNotes[0]?.assignedToId)) ||
            user.id === 150) &&
            complaintNotes[0]?.complaintStatus !== 'close' && (
              <div className="p-d-flex p-flex-column p-jc-end p-ai-end">
                {addNewNoteBtn && (
                  <>
                    <div className="p-field p-fluid p-col-6 p-mb-0 p-px-0">
                      <label className="p-d-block">Add another note</label>
                      <InputTextarea
                        style={{ borderRadius: '15px 15px 0 15px' }}
                        ref={newNoteRef}
                        autoResize
                        rows={3}
                        cols={30}
                        placeholder="Type Here..."
                        value={generalNote}
                        onChange={(e) => {
                          setGeneralNote(e.target.value)
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        loading={loading}
                        label="Submit"
                        icon="pi pi-check"
                        className="p-mx-2 p-button-rounded"
                        onClick={() => {
                          addNewNoteToComplaint()
                        }}
                      />
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger"
                        disabled={loading}
                        onClick={() => {
                          setAddNewNoteBtn(false)
                          setGeneralNote('')
                          setNewRecordError(emptyErr)
                        }}
                      />
                    </div>
                  </>
                )}
                {!addNewNoteBtn && (
                  <div>
                    <Button
                      label="Add Note"
                      icon="pi pi-plus "
                      className="p-button-rounded"
                      onClick={() => {
                        setAddNewNoteBtn(true)
                        const tm = setTimeout(() => {
                          newNoteRef?.current?.focus()
                        }, 100)
                        return () => clearTimeout(tm)
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  )
}
