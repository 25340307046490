import { Button } from 'primereact/button'
import { DataView } from 'primereact/dataview'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import React, { useCallback, useEffect, useState } from 'react'
import {
  addCartItem,
  getCart,
  getProductNames,
  getProductPrice,
  removeCartItem,
  searchQuantity,
  searchStrength,
} from '../../api'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import generateS3Path from '../../functions/generateS3Path'
import ConfirmationDialogWithInput from '../../components/mycomponents/Dialog/ConfirmationDialogWithInput'
import { Password } from 'primereact/password'
import { useGlobalContext } from '../../context'
import { handleObjChange } from '../../functions/setter'
import { Message } from 'primereact/message'
import { Toolbar } from 'primereact/toolbar'

const emptyProduct = {
  productCode: null,
  strengthCode: null,
  strengths: [],
  quantities: [],
  quantityCode: null,
  totalQuantity: 1,
}

const emptyErr = {
  state: false,
  errors: [],
}

export default function AddToCartPage() {
  const { toast } = useGlobalContext()
  const [updateCart, setUpdateCart] = useState([])
  const [cartData, setCartData] = useState(null)
  const [cartId, setCartId] = useState('')
  const [productNames, setProductNames] = useState([])
  const [password, setPassword] = useState('')
  const [passwordConfirmationDialog, setPasswordConfirmationDialog] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [addItemDialogVisible, setAddItemDialogVisible] = useState(false)
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [cartValue, setCartValue] = useState({})
  const [updateCartValue, setUpdateCartValue] = useState({})

  const fetchCart = useCallback(async () => {
    const res = await getCart(cartId?.trim())
    if (res && res.status === 200) {
      setCartData(res.data)
    }
  }, [cartId])

  const validateCart = () => {
    const err = []
    updateCart.forEach((cartItem, idx) => {
      if (!cartItem.productCode) {
        err.push(`Product on row ${idx + 1} cannot be empty`)
      }
      if (!cartItem.strengthCode) {
        err.push(`Please select a valid quantity on row ${idx + 1}`)
      }
      if (!cartItem.quantityCode) {
        err.push(`Quantity on row ${idx + 1} cannot be empty`)
      }
      if (!cartItem.totalQuantity) {
        err.push(`Invalid total quantity on row ${idx + 1}`)
      }
    })
    return err
  }

  const handleCartUpdate = async () => {
    setLoading(true)
    const res = await addCartItem(password, cartId, updateCart)
    if (res) {
      setLoading(false)
      {
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
            life: 3000,
          })
          await fetchCart()
          hideAddProductDialog()
          onConfirmDialogHide()
        }
      }
    }
  }

  // Function to get/set totals
  const calculateTotals = (products, setter) => {
    let cartTotal = 0
    let cartDiscountedTotal = 0
    let cartTotalSavings = 0
    let cartItemsCount = 0
    let cartShippingCharges = 0
    products.forEach((item) => {
      cartTotal += item.perPillPrice * item.quantity * item.totalQuantity
      cartDiscountedTotal += item.discountedPerPillPrice * item.quantity * item.totalQuantity
      cartShippingCharges += Number(item.shippingCharge) * item.totalQuantity
      cartTotalSavings +=
        item.perPillPrice * item.quantity * item.totalQuantity -
        item.discountedPerPillPrice * item.quantity * item.totalQuantity
      cartItemsCount++
    })
    const result = {
      cartTotal,
      cartDiscountedTotal,
      cartTotalSavings,
      cartItemsCount,
      cartShippingCharges,
    }
    setter(result)
  }

  useEffect(() => {
    if (cartData && cartData.length) {
      calculateTotals(cartData, setCartValue)
    }
  }, [cartData])

  const onConfirmDialogHide = () => {
    setPasswordConfirmationDialog(false)
    setPassword('')
    setLoading(false)
  }

  const setProduct = async () => {
    setLoading(true)
    let temp = [...cartData]
    await Promise.all(
      temp.map(async (item) => {
        const { data: strengthsData } = await searchStrength(item.productCode)
        const { data: quantitiesData } = await searchQuantity(item.strengthCode)
        item.strengths = strengthsData
        item.quantities = quantitiesData
        return item
      })
    )
    setUpdateCart([...temp])
    setAddItemDialogVisible(true)
    calculateTotals(temp, setUpdateCartValue)
    const res = await getProductNames()
    setProductNames(res.data)
    setLoading(false)
  }

  const hideAddProductDialog = () => {
    setAddItemDialogVisible(false)
    setUpdateCart([])
    setUpdateCartValue({})
    setLoading(false)
  }

  const dataTableHeader = (
    <div className="p-col-1">
      <Button
        label="Update Cart"
        icon="pi pi-pencil"
        onClick={() => {
          setProduct()
        }}
      />
    </div>
  )

  const DisplayTotals = ({ data }) => {
    return (
      <div className="p-d-flex p-flex-column gap-1">
        <div>
          <label className="p-m-0">Subtotal: ${data?.cartTotal?.toFixed(2)}</label>
        </div>
        <div>
          <label className="p-m-0">Discount: -${data?.cartTotalSavings?.toFixed(2)}</label>
        </div>
        <div>
          <label className="p-m-0">
            Discounted Price: ${data?.cartDiscountedTotal?.toFixed(2)}
          </label>
        </div>
        <div>
          <label className="p-m-0">
            Shipping Charges: ${data?.cartShippingCharges?.toFixed(2)}
          </label>
        </div>
        <div>
          <label className="p-m-0">
            Total: ${(data?.cartDiscountedTotal + data?.cartShippingCharges)?.toFixed(2)}
          </label>
        </div>
      </div>
    )
  }
  const updateCartFooter = (
    <div className="p-d-flex p-flex-column gap-1">
      <div>
        <label className="p-m-0">Subtotal: ${updateCartValue?.cartTotal?.toFixed(2)}</label>
      </div>
      <div>
        <label className="p-m-0">Discount: -${updateCartValue?.cartTotalSavings?.toFixed(2)}</label>
      </div>
      <div>
        <label className="p-m-0">
          Discounted Price: ${updateCartValue?.cartDiscountedTotal?.toFixed(2)}
        </label>
      </div>
      <div>
        <label className="p-m-0">
          Shipping Charges: ${updateCartValue?.cartShippingCharges?.toFixed(2)}
        </label>
      </div>
      <div>
        <label className="p-m-0">
          Total: $
          {(updateCartValue?.cartDiscountedTotal + updateCartValue?.cartShippingCharges)?.toFixed(
            2
          )}
        </label>
      </div>
    </div>
  )

  return (
    <PageAllowedToRoles
      allowedRoles={['superadmin', 'admin', 'operations', 'manager', 'teamlead', 'agent']}
    >
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <div className="card p-shadow-3">
            <Toolbar
              className="p-toolbar p-mb-3 p-d-flex p-ai-center"
              left={() => {
                return (
                  <>
                    <div className="p-d-flex p-ai-center p-mr-3">
                      <h4 className="p-m-0">Cart</h4>
                    </div>
                    <div className="p-d-flex p-jc-center p-ai-center">
                      <div className="p-col">
                        <InputText
                          placeholder="Enter cart id"
                          id="userid"
                          type="text"
                          style={{ width: '100%', letterSpacing: '1.5px' }}
                          value={cartId}
                          onChange={(e) => {
                            setCartId(e.target.value)
                          }}
                        />
                      </div>
                      <div>
                        <Button
                          icon="pi pi-shopping-cart"
                          label="Get Cart"
                          onClick={() => {
                            if (!cartId || !cartId.trim())
                              return toast.current.show({
                                severity: 'warn',
                                summary: 'Warning',
                                detail: 'Please enter a cart Id',
                                life: 3000,
                              })
                            fetchCart()
                          }}
                        ></Button>
                      </div>
                    </div>
                  </>
                )
              }}
            ></Toolbar>

            {cartData && cartData.length > 0 && (
              <div className="p-col-12">
                <DataTable
                  value={cartData}
                  showGridlines
                  removableSort
                  loading={loading}
                  header={dataTableHeader}
                  className="p-mb-2"
                >
                  <Column
                    field="productName"
                    header="Name"
                    style={{
                      width: '10%',
                    }}
                  />
                  <Column
                    field="strength"
                    header="Strength"
                    style={{
                      width: '10%',
                    }}
                  />
                  <Column
                    field="quantity"
                    header="Quantity"
                    style={{
                      width: '10%',
                    }}
                  />
                  <Column
                    field="totalQuantity"
                    header="Total Quantity"
                    style={{
                      width: '10%',
                    }}
                  />
                  <Column
                    field="discountedPrice"
                    header="Discounted Price"
                    style={{
                      width: '10%',
                    }}
                    body={(rd) => `$${(rd.discountedPerPillPrice * rd.quantity).toFixed(2)}`}
                  />
                  <Column
                    field="actualPrice"
                    header="Actual Price"
                    style={{
                      width: '10%',
                    }}
                    body={(rd) => `$${(rd.perPillPrice * rd.quantity).toFixed(2)}`}
                  />
                  <Column
                    field="discountInPercentage"
                    header="Discount Percentage"
                    style={{
                      width: '10%',
                    }}
                    body={(rd) => `${rd.discountInPercentage}%`}
                  />
                  <Column
                    field="productType"
                    header="Type"
                    style={{
                      width: '10%',
                    }}
                  />
                </DataTable>
                <DisplayTotals data={cartValue} />
              </div>
            )}
            {cartData && !cartData.length && (
              <div className="p-p-3">
                <h5>Cart does not exist.</h5>
              </div>
            )}
          </div>
        </div>
        {/* Message Box Modal */}
        <Dialog
          visible={addItemDialogVisible}
          header="Update Cart Dialog"
          modal
          className="p-fluid"
          style={{ width: '1200px' }}
          footer={
            <div>
              <Button
                label="Submit"
                loading={loading}
                icon="pi pi-shopping-cart"
                onClick={() => {
                  const errs = validateCart()
                  if (errs.length > 0) {
                    setLoading(false)
                    setNewRecordError({ state: true, errors: errs })
                    return
                  }
                  setPasswordConfirmationDialog(true)
                }}
                autoFocus
              />
              <Button
                label="Cancel"
                icon="pi pi-times"
                onClick={() => hideAddProductDialog()}
                className="p-button-text"
              />
            </div>
          }
          onHide={hideAddProductDialog}
        >
          <DataTable
            value={updateCart}
            stripedRows
            removableSort
            loading={loading}
            className="p-mb-2"
          >
            <Column
              field="productName"
              header="Row"
              style={{
                width: '1%',
              }}
              body={(rd, idx) => <label>{idx.rowIndex + 1}</label>}
            />
            <Column
              field="productName"
              header="Name"
              style={{
                width: '10%',
              }}
              body={(rd, idx) => (
                <Dropdown
                  value={rd.productCode}
                  options={productNames.filter(
                    (product) => product.isStockOut !== 1 && product.productStatus !== 'inactive'
                  )}
                  onChange={async (e) => {
                    const { rowIndex } = idx
                    // setUpdateCart(emptyProduct)
                    // handleObjChange(setUpdateCart, 'productCode', e.value || null)
                    // setProductStrengths([])
                    // setProductQuantitys([])
                    // if (e.value) {
                    //   const res = await searchStrength(e.value)
                    //   setProductStrengths(res.data)
                    // }
                    let temp = [...updateCart]
                    temp[rowIndex].productCode = e.value || null
                    const { data: strengthsData } = await searchStrength(e.value)
                    temp[rowIndex].strengthCode = null
                    temp[rowIndex].strengths = strengthsData
                    temp[rowIndex].quantities = []
                    temp[rowIndex].quantityCode = null
                    setUpdateCart([...temp])
                  }}
                  optionLabel="productName"
                  optionValue="productCode"
                  filter
                  filterBy="productName"
                  placeholder="Select Product"
                  showClear
                  emptyMessage="Loading Products"
                />
              )}
            />

            <Column
              field="strength"
              header="Strength"
              style={{
                width: '10%',
              }}
              body={(rd, idx) => (
                <Dropdown
                  value={rd.strengthCode}
                  options={rd.strengths.filter(
                    (strength) =>
                      strength.isStockOut !== 1 && strength.strengthStatus !== 'inactive'
                  )}
                  onChange={async (e) => {
                    const { rowIndex } = idx
                    let temp = [...updateCart]
                    temp[rowIndex].strengthCode = e.value || null
                    const { data: quantitiesData } = await searchQuantity(e.value)
                    temp[rowIndex].quantities = quantitiesData
                    temp[rowIndex].quantityCode = null
                    setUpdateCart([...temp])
                  }}
                  optionLabel="strengthName"
                  optionValue="strengthCode"
                  filter
                  filterBy="strengthName"
                  placeholder="Select Strength"
                  showClear
                  emptyMessage="No Strength found"
                />
              )}
            />
            <Column
              field="quantity"
              header="Quantity"
              style={{
                width: '10%',
              }}
              body={(rd, idx) => (
                <Dropdown
                  value={rd.quantityCode}
                  options={rd.quantities.filter(
                    (quantity) => quantity.isStockOut !== 1 && quantity.qtyStatus !== 'inactive'
                  )}
                  onChange={async (e) => {
                    const quantity = rd.quantities.find((i) => i.quantityCode === e.value)
                    const { rowIndex } = idx
                    let temp = [...updateCart]
                    temp[rowIndex].quantityCode = e.value || null
                    temp[rowIndex].quantity = quantity.quantity || 0
                    const getSelectedQuantity = await getProductPrice(
                      rd.productCode,
                      rd.strengthCode,
                      e.value
                    )
                    const { data } = getSelectedQuantity
                    temp[rowIndex].perPillPrice = data[0].perUnitPrice
                    temp[rowIndex].discountedPerPillPrice = data[0].discountedPerPillPrice
                    temp[rowIndex].shippingCharge = data[0].shippingPrice
                    // temp[rowIndex].shippingCharge = data[0].shippingPrice
                    setUpdateCart([...temp])
                    calculateTotals(updateCart, setUpdateCartValue)
                  }}
                  optionLabel="stringQty"
                  optionValue="quantityCode"
                  filter
                  filterBy="stringQty"
                  placeholder="Select Quantity"
                  showClear
                  emptyMessage="No Quantity Found"
                />
              )}
            />
            <Column
              field="totalQuantity"
              header="Total Quantity"
              style={{
                width: '10%',
              }}
              body={(rd, idx) => (
                <InputNumber
                  id="price"
                  value={rd.totalQuantity}
                  onChange={(e) => {
                    let temp = [...updateCart]
                    temp[idx.rowIndex].totalQuantity = e.value
                    setUpdateCart([...temp])
                    calculateTotals(updateCart, setUpdateCartValue)
                  }}
                  style={{ width: '100%' }}
                />
              )}
            />
            <Column
              field="Action"
              header="Action"
              style={{
                width: '2%',
              }}
              body={(rd, idx) => {
                return (
                  <div className="p-d-flex gap-1">
                    <Button
                      icon="pi pi-trash"
                      className="p-button p-button-danger"
                      tooltip="Delete"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                      onClick={() => {
                        let temp = [...updateCart]
                        temp = temp.filter((item, itemIdx) => itemIdx !== idx.rowIndex)
                        setUpdateCart([...temp])
                        calculateTotals(temp, setUpdateCartValue)
                      }}
                    />
                    <Button
                      icon="pi pi-plus"
                      className="p-button"
                      tooltip="Add"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                      onClick={() => {
                        let temp = [...updateCart]
                        temp.push({ ...emptyProduct })
                        setUpdateCart([...temp])
                      }}
                    />
                  </div>
                )
              }}
            />
          </DataTable>
          <DisplayTotals data={updateCartValue} />
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </Dialog>
        {/* Confirmation dialog to ask password start*/}
        <ConfirmationDialogWithInput
          onHide={onConfirmDialogHide}
          header={'Confirm password'}
          onSubmit={handleCartUpdate}
          visible={passwordConfirmationDialog}
          inputLabel={'Password'}
          customInputs={true}
          loading={loading}
        >
          <div className="p-grid p-formgrid p-px-2">
            <label htmlFor="password" className="p-d-block">
              Password <sup style={{ color: 'red' }}>*</sup>
            </label>
            <Password
              autoComplete="nope"
              toggleMask
              feedback={false}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-mt-1"
              placeholder="Enter your password"
            />
          </div>
        </ConfirmationDialogWithInput>
        {/* Confirmation dialog to ask password end*/}
      </div>
    </PageAllowedToRoles>
  )
}

// Relevant values: {productCode,productName,strengths,strengthCode,quantitys,quantityCode,totalQuantity}
// 1. Copy all the relveant values from the existing cart -> updateCart
// 2. Loop over each element,
// 3. Fetch strength using productCode, fetch quantitys using strengthCode and productCode
// (First part done)

// Update
// 1. When select product set strengths from productStrengths(api) and quantitys to emptyArray[], also set strengthCode and quantityCode to undefined
// 2. When select strength set quantitys via api call using parameter strengthCode and quantityCode and set quantityCode to undefined

// Add new
// 1. Initialize empty relevant values.
