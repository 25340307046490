import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom'
import { getChargesDetailsById } from '../../../api'
import useQuery from '../../../hooks/useQuery'
import { format } from 'date-fns'
import classNames from 'classnames'

function ViewShippingChargesLogisticDialog({ setChargesTableLoading }) {
  const history = useHistory()
  const query = useQuery()
  const id = query.get('view_sc_id')
  const dt = useRef(null)
  // states
  const [viewDialog, setViewDialog] = useState(false)
  const [records, setRecords] = useState([])

  //* Function to fetch all charges using it's id
  const fetchShippingChargesById = useCallback(async () => {
    if (!id) return
    setChargesTableLoading(true)
    const res = await getChargesDetailsById(id)
    if (res) {
      setChargesTableLoading(false)
      if (res.status === 400) {
        onHideDialog()
      }
      if (res.status === 200) {
        setRecords(res.data.records)
        setViewDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchShippingChargesById()
  }, [fetchShippingChargesById])

  //Dialog hide function
  const onHideDialog = () => {
    setViewDialog(false)
    setRecords([])
    history.push({
      pathname: '/website/logistics',
    })
  }

  return (
    <div>
      <Dialog
        visible={viewDialog}
        className={`w-full ${records?.length > 1 ? 'max-w-80' : 'max-w-60'}`}
        closable={false}
        header={
          <div className="p-text-center">
            <h5 style={{ color: '#4B8AF4' }}>More Details</h5>
          </div>
        }
        footer={
          <div>
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => {
                onHideDialog()
              }}
            />
          </div>
        }
        onHide={onHideDialog}
      >
        <div className="p-grid">
          {records.map((record) => {
            return (
              <div
                className={classNames('p-field p-col-12', {
                  'p-md-6': records?.length > 1,
                })}
                key={record}
              >
                <h5>Charge Id - {record[0]?.uid || 'NA'}</h5>
                <DataTable ref={dt} value={record} showGridlines>
                  <Column header="Quantity" field="quantity" />
                  <Column
                    header="Shipping Charges"
                    field="price"
                    body={(rd) => {
                      return `$${rd.price}`
                    }}
                  />
                  <Column
                    header="Created at"
                    field="createdAt"
                    body={(rd) => {
                      return format(new Date(rd.createdAt), 'PPp')
                    }}
                  />
                </DataTable>
              </div>
            )
          })}
        </div>
      </Dialog>
    </div>
  )
}

export default ViewShippingChargesLogisticDialog
