import axios from 'axios'
import endpoints from './endpoints'

export const addFaqCategory = async (payload) => {
  try {
    return await axios.post(endpoints.faq.category, payload)
  } catch (error) {
    return error.response
  }
}

export const getFaqCategoriesById = async (id) => {
  try {
    return await axios.get(endpoints.faq.categoryFind + id)
  } catch (error) {
    return error.response
  }
}

export const addFaq = async (payload) => {
  try {
    return await axios.post(endpoints.faq.index, payload)
  } catch (error) {
    return error.response
  }
}

export const getFaqs = async () => {
  try {
    return await axios.get(endpoints.faq.index)
  } catch (error) {
    return error.response
  }
}

export const deleteFaq = async (id, password) => {
  const data = JSON.stringify({
    password,
  })
  try {
    return await axios.delete(endpoints.faq.index + id, { data })
  } catch (error) {
    return error.response
  }
}

export const getAllFaqCategories = async (flag) => {
  try {
    return await axios.get(endpoints.faq.category, { params: { flag } })
  } catch (error) {
    return error.response
  }
}

export const deleteFaqCategory = async (id, password) => {
  const data = JSON.stringify({
    password,
  })
  try {
    return await axios.delete(endpoints.faq.category + id, { data })
  } catch (error) {
    return error.response
  }
}
