import classNames from 'classnames'
import React from 'react'
import { useHistory } from 'react-router-dom'
import AppMenu from './AppMenu'
import { useGlobalContext } from '../context'
// AppTopbar component to manage the topbar items
const AppTopbar = (props) => {
  /**
   * items @type {array} - array of menu items
   * menuMode @type {string} - string to check menu mode
   * colorScheme @type {string} - string to check color scheme
   * menuActive @type {boolean} - boolean to check if menu is active
   * topbarMenuActive @type {boolean} - boolean to check if topbar menu is active
   * activeInlineProfile @type {boolean} - boolean to check if inline profile is active
   * onMenuButtonClick @type {function} - function to handle menu button click
   * onTopbarItemClick @type {function} - function to handle topbar item click
   * onSidebarMouseOver @type {function} - function to handle mouse over on sidebar
   * onSidebarMouseLeave @type {function} - function to handle mouse leave on sidebar
   * onToggleMenu @type {function} - function to handle menu toggle
   * onChangeActiveInlineMenu @type {function} - function to handle inline menu change
   * onMenuClick @type {function} - function to handle menu click
   * onRootMenuItemClick @type {function} - function to handle root menu item click
   * onMenuItemClick @type {function} - function to handle menu item click
   */
  const { user } = useGlobalContext()
  const onTopbarSubItemClick = (event) => {
    event.preventDefault()
  }

  const history = useHistory()

  return (
    <>
      <div className="layout-topbar">
        <div className="layout-topbar-left">
          <button
            className="topbar-menu-button p-link"
            onClick={props.onMenuButtonClick}
          >
            <i className="pi pi-bars"></i>
          </button>

          <button className="logo p-link" onClick={() => history.push('/')}>
            <img
              src={`/assets/layout/images/logo-${
                props.colorScheme === 'light' ? 'dark' : 'light'
              }.png`}
              alt="logo"
            />
          </button>
          <h6 className="p-ml-2 p-my-0">{user?.globalp_name} CRM</h6>

          {/* <button className="p-link" onClick={() => history.push('/')}>
            <img
              src={`/assets/layout/images/appname-${
                props.colorScheme === 'light' ? 'dark' : 'light'
              }.png`}
              className="app-name"
              alt="app-name"
            />
          </button> */}
        </div>

        <AppMenu
          model={props.items}
          menuMode={props.menuMode}
          colorScheme={props.colorScheme}
          menuActive={props.menuActive}
          activeInlineProfile={props.activeInlineProfile}
          onSidebarMouseOver={props.onSidebarMouseOver}
          onSidebarMouseLeave={props.onSidebarMouseLeave}
          toggleMenu={props.onToggleMenu}
          onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
          onMenuClick={props.onMenuClick}
          onRootMenuItemClick={props.onRootMenuItemClick}
          onMenuItemClick={props.onMenuItemClick}
        />

        <div className="layout-topbar-right">
          <ul className="layout-topbar-right-items">
            <li
              id="profile"
              className={classNames('profile-item', {
                'active-topmenuitem': props.topbarMenuActive,
              })}
            >
              <button className="p-link" onClick={props.onTopbarItemClick}>
                <img
                  src="/assets/layout/images/profile-image.png"
                  alt="profile"
                />
              </button>

              <ul className="fadeInDown">
                <li role="menuitem">
                  <button className="p-link" onClick={onTopbarSubItemClick}>
                    <i className="pi pi-fw pi-user"></i>
                    <span>Profile</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button className="p-link" onClick={onTopbarSubItemClick}>
                    <i className="pi pi-fw pi-cog"></i>
                    <span>Settings</span>
                  </button>
                </li>
                <li role="menuitem">
                  <button className="p-link" onClick={onTopbarSubItemClick}>
                    <i className="pi pi-fw pi-sign-out"></i>
                    <span>Logout</span>
                  </button>
                </li>
              </ul>
            </li>
            <li>
              <button className="p-link">
                <i className="topbar-icon pi pi-fw pi-bell"></i>
                <span className="topbar-badge">2</span>
                <span className="topbar-item-name">Notifications</span>
              </button>
            </li>
            <li>
              <button className="p-link">
                <i className="topbar-icon pi pi-fw pi-comment"></i>
                <span className="topbar-badge">5</span>
                <span className="topbar-item-name">Messages</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default AppTopbar
