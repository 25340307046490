import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { TabView, TabPanel } from 'primereact/tabview'
import { FilterMatchMode } from 'primereact/api'
import ReactQuill from 'react-quill'

import { Toast } from 'primereact/toast'
import {
  getProductNames,
  searchStrength,
  searchQuantity,
  addDiscount,
  getDiscounts,
  deleteDiscount,
  updateDiscount,
} from '../../api'
import { useGlobalContext } from '../../context'
import { InputNumber } from 'primereact/inputnumber'
import classNames from 'classnames'
import ConfirmDialogWithPassword from '../../components/mycomponents/Dialog/ConfirmDialogWithPassword'
import { InputTextarea } from 'primereact/inputtextarea'
import RequiredMessage from '../../components/mini/RequiredMessage'
import { Toolbar } from 'primereact/toolbar'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import ExportButton from '../../components/mini/ExportButton'
import { dtFilenameDate } from '../../functions/myDateFns'

export default function DiscountPage() {
  const dt = useRef(null)
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  }
  const { productCategoriesWithIds, user, toast } = useGlobalContext()
  const [loader, setLoader] = useState({
    new: false,
    update: false,
    delete: false,
  })
  const [tableLoading, setTableLoading] = useState(false)
  /* delete */
  const [password, setPassword] = useState('')
  const [addPassword, setAddPassword] = useState('')

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [deleteData, setDeleteData] = useState({})
  const [editData, setEditData] = useState(null)
  const [type, setType] = useState(null)
  const [value, setValue] = useState(null)
  const [products, setProducts] = useState([])
  const [strength, setStrength] = useState([])
  const [quantity, setQuantity] = useState([])
  const [valid, setValid] = useState(false)
  const [discount, setDiscount] = useState(0)
  const [discounts, setDiscounts] = useState([])
  const [filters, setFilters] = useState(emptyFilterObject)

  const [eligibility, setEligibility] = useState('')
  const [howDoYouGetIt, setHowDoYouGetIT] = useState('')
  const [conditions, setConditions] = useState('')
  const [otherTerms, setOtherTerms] = useState('')
  const [description, setDescription] = useState('')
  const [link, setLink] = useState('')

  const fetchDiscounts = useCallback(async () => {
    setTableLoading(true)
    const res = await getDiscounts()
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setDiscounts(
          res.data.map((val) => {
            if (val.type === 'ALL') {
              val.type = 'All'
            }
            if (val.type === 'CAT') {
              val.type = 'Category'
            }
            if (val.type === 'PROD') {
              val.type = 'Product'
            }
            if (val.type === 'STRN') {
              val.type = 'Strength'
            }
            if (val.type === 'QTY') {
              val.type = 'Quantity'
            }
            return val
          })
        )
      }
    }
  }, [])

  useEffect(() => fetchDiscounts(), [fetchDiscounts])
  const saveDiscount = async () => {
    setLoader({ ...loader, new: true })
    let code
    let name
    if (type === 'All') {
      name = 'NaN'
      code = 'NaN'
    }
    if (type === 'Category') {
      name = value.name
      code = value?.name
    }
    if (type === 'Product') {
      name = value.product.name
      code = value?.product?.code
    }
    if (type === 'Strength') {
      name = `${value.product.name} ${value.strength.name}`
      code = value?.strength?.code
    }
    if (type === 'Quantity') {
      name = `${value.product.name} ${value.strength.name} ${value.quantity.name}units`
      code = value?.quantity?.code
    }
    setTableLoading(true)
    const res = await addDiscount(
      type,
      code,
      name,
      discount,
      description,
      link,
      eligibility,
      howDoYouGetIt,
      conditions,
      otherTerms,
      addPassword
    )
    setLoader({ ...loader, new: false })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Discount Added',
        })
        setType(null)
        setValue(null)
        setDiscount(0)
        setAddPassword('')
        setDiscounts([res.data, ...discounts])
        setEligibility('')
        setHowDoYouGetIT('')
        setConditions('')
        setOtherTerms('')
        setDescription('')
        setLink('')
      }
    }
  }

  const handelDeleteDiscount = async () => {
    setLoader({ ...loader, delete: true })
    const res = await deleteDiscount(deleteData.id, password)
    setLoader({ ...loader, delete: false })
    if (res && res.status === 200) {
      hideDeleteDiscountDialog()
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Discount Deleted',
      })
      setDiscounts(discounts.filter((val) => val.id !== deleteData.id))
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error || 'Something went wrong',
      })
    }
  }

  const handelUpdateDiscount = async () => {
    setLoader({ ...loader, edit: true })
    const res = await updateDiscount(editData.id, editData.discount)
    setLoader({ ...loader, edit: false })

    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Discount Updated',
      })
      setEditData(null)
      setDiscounts(
        discounts.map((val) => {
          if (val.id === editData.id) {
            val.discount = editData.discount
          }
          return val
        })
      )
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error || 'Something went wrong',
      })
    }
  }

  const hideDeleteDiscountDialog = () => {
    setDeleteData({})
    setDeleteDialog(false)
    setPassword('')
  }

  const fetchProducts = async () => {
    const res = await getProductNames()
    if (res && res.status === 200) {
      setProducts(
        res.data.map((product) => ({
          name: product.productName,
          code: product.productCode,
        }))
      )
    }
  }

  const fetchStrength = async (code) => {
    if (!code) return
    const res = await searchStrength(code)
    if (res && res.status === 200) {
      setStrength(
        res.data.map((strength) => ({
          name: strength.strengthName,
          code: strength.strengthCode,
        }))
      )
    }
  }

  const fetchQuantity = async (code) => {
    if (!code) return
    const res = await searchQuantity(code)
    if (res && res.status === 200) {
      setQuantity(
        res.data.map((quantity) => ({
          name: quantity.quantity,
          code: quantity.quantityCode,
        }))
      )
    }
  }

  const handelTypeChange = (e) => {
    setType(e.value)
    switch (e.value) {
      case 'All':
        setValue(null)
        break
      case 'Category':
        setValue({
          id: null,
          name: null,
        })
        break
      case 'Product':
        fetchProducts()
        setValue({
          product: {
            name: null,
            code: null,
          },
        })
        break
      case 'Strength':
        fetchProducts()
        setValue({
          product: {
            name: null,
            code: null,
          },
          strength: {
            name: null,
            code: null,
          },
        })
        break
      case 'Quantity':
        fetchProducts()
        setValue({
          product: {
            name: null,
            code: null,
          },
          strength: {
            name: null,
            code: null,
          },
          quantity: {
            name: null,
            code: null,
          },
        })
        break
      default:
        break
    }
  }

  const checkValid = useCallback(() => {
    if (!type) {
      return false
    } else if (!discount) {
      return false
    } else if (type === 'Category' && (!value || !value.name)) {
      return false
    } else if (type === 'Product' && (!value.product.code || !value.product.name)) {
      return false
    } else if (
      type === 'Strength' &&
      (!value.product.code || !value.product.name || !value.strength.code || !value.strength.name)
    ) {
      return false
    } else if (
      type === 'Quantity' &&
      (!value.product.code ||
        !value.product.name ||
        !value.strength.code ||
        !value.strength.name ||
        !value.quantity.code ||
        !value.quantity.name)
    ) {
      return false
    }

    return true
  }, [type, value, discount])

  useEffect(() => setValid(checkValid()), [checkValid])

  const Product = () => {
    return (
      <div className="p-fluid p-field ">
        <label className="p-col-fixed">Product</label>
        <Dropdown
          value={value?.product}
          optionLabel="name"
          filter
          filterBy="name"
          filterPlaceholder="Search..."
          options={products}
          className=""
          placeholder="Select Product"
          onChange={(e) => {
            setValue((ps) => ({ ...ps, product: e.value }))
            fetchStrength(e.value.code)
          }}
        />
      </div>
    )
  }
  const Strength = () => {
    return (
      <div className="p-fluid p-field ">
        <label className="p-col-fixed">Strength</label>
        <Dropdown
          value={value?.strength}
          options={strength}
          optionLabel="name"
          className=""
          placeholder="Select Strength"
          onChange={(e) => {
            setValue((ps) => ({ ...ps, strength: e.value }))
            fetchQuantity(e.value.code)
          }}
        />
      </div>
    )
  }
  const Quantity = () => {
    return (
      <div className="p-fluid p-field ">
        <label className="p-col-fixed">Quantity </label>
        <Dropdown
          value={value?.quantity}
          options={quantity}
          optionLabel="name"
          className=""
          placeholder="Select Quantity"
          onChange={(e) => {
            setValue((ps) => ({ ...ps, quantity: e.value }))
          }}
        />
      </div>
    )
  }

  /* BODIES */
  const idBody = (rowData) => {
    return <div className="">{rowData?.id}</div>
  }
  const typeBody = (rowData) => {
    return <div className="">{rowData?.type}</div>
  }
  const nameBody = (rowData) => {
    return <div className="">{rowData?.name}</div>
  }
  const createdByBody = (rowData) => {
    return <div className="">{rowData?.createdBy}</div>
  }

  const discountByBody = (rowData) => {
    return (
      <div className="">
        {editData?.id === rowData.id ? (
          <span className="p-inputnumber p-component p-inputwrapper ">
            <InputNumber
              value={editData?.discount}
              onChange={(e) => setEditData((ps) => ({ ...ps, discount: e.value }))}
              suffix="%"
              inputId="horizontal"
              showButtons
              buttonLayout="horizontal"
              step={5}
              maxFractionDigits={2}
              minFractionDigits={2}
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
          </span>
        ) : (
          <>{rowData?.discount}%</>
        )}
      </div>
    )
  }
  const actionBody = (rowData) => {
    return (
      <div className="p-d-flex p-jc-center p-align-center gap-1by2">
        {editData?.id === rowData.id ? (
          <Button
            icon={classNames('pi', {
              'pi-save': !loader.edit,
              'pi-spin pi-spinner': loader.edit,
            })}
            tooltip="Save"
            tooltipOptions={{ position: 'top' }}
            className="p-button"
            onClick={() => {
              handelUpdateDiscount()
            }}
          />
        ) : (
          <Button
            icon="pi pi-pencil"
            tooltip="Edit"
            tooltipOptions={{ position: 'top' }}
            className="p-button"
            onClick={() => {
              setEditData(rowData)
            }}
          />
        )}
        {editData?.id === rowData.id ? (
          <Button
            icon="pi pi-times"
            tooltip="Cancel"
            tooltipOptions={{ position: 'top' }}
            className="p-button p-button-danger"
            onClick={() => {
              setEditData(null)
            }}
          />
        ) : (
          <Button
            icon="pi pi-trash"
            tooltip="Delete"
            tooltipOptions={{ position: 'top' }}
            className="p-button p-button-danger"
            onClick={() => {
              setDeleteData(rowData)
              setDeleteDialog(true)
            }}
          />
        )}
        <Link to={`?history=${rowData.id}`}>
          <Button
            tooltip="History"
            tooltipOptions={{ position: 'bottom' }}
            icon="pi pi-clock"
            className="p-button p-button-help"
          />
        </Link>
      </div>
    )
  }
  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <>
        <Toast ref={toast} />
        <ConfirmDialogWithPassword
          onHide={hideDeleteDiscountDialog}
          onDelete={handelDeleteDiscount}
          visible={deleteDialog}
          deleting={loader.delete}
          password={password}
          setPassword={setPassword}
          headerText="Delete Discount"
          mainText={`Are you sure you want to delete discount for ${deleteData.type} item ${deleteData.name}?`}
        />

        <div className="card ">
          <TabView>
            <TabPanel header="Discounts">
              <Toolbar
                className="p-toolbar"
                left={<h4 className="p-m-0">Discounts</h4>}
                right={
                  <div className="p-d-flex gap-1">
                    <Button
                      className=""
                      icon="pi pi-refresh"
                      tooltip="Refresh Companies"
                      tooltipOptions={{ position: 'top' }}
                      onClick={fetchDiscounts}
                    />
                    <ExportButton datatable_ref={dt} />
                  </div>
                }
              ></Toolbar>
              <div className="">
                <DataTable
                  exportFilename={`Discounts_${dtFilenameDate}`}
                  ref={dt}
                  value={discounts}
                  filters={filters}
                  showGridlines
                  dataKey="id"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  className="datatable-responsive"
                  columnResizeMode="fit"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} discounts"
                  emptyMessage="No discount found."
                  loading={tableLoading}
                  rowHover
                  header={
                    <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                      <span style={{ width: '200px' }} className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                          type="search"
                          value={filters.global.value}
                          onInput={(e) => {
                            let _filters = { ...filters }
                            _filters.global.value = e.target.value
                            setFilters(_filters)
                          }}
                          placeholder="Search..."
                        />
                      </span>
                    </div>
                  }
                >
                  <Column header="id" field="id" sortable body={idBody} style={{ width: '1%' }} />
                  <Column
                    header="Type"
                    field="type"
                    sortable
                    body={typeBody}
                    style={{ width: '20%' }}
                  />
                  <Column
                    header="Name"
                    field="name"
                    sortable
                    body={nameBody}
                    style={{ width: '30%' }}
                  />
                  <Column
                    header="Created By"
                    field="createdBy"
                    sortable
                    body={createdByBody}
                    style={{ width: '20%' }}
                  />
                  <Column
                    header="Discount"
                    field="discount"
                    sortable
                    body={discountByBody}
                    style={{ width: '1%' }}
                  />

                  <Column
                    hidden={!['superadmin', 'admin', 'manager', 'operations'].includes(user?.role)}
                    header="Action"
                    body={actionBody}
                    style={{ width: '1%' }}
                  />
                </DataTable>
              </div>
            </TabPanel>

            {['superadmin', 'admin', 'manager', 'operations'].includes(user?.role) && (
              <TabPanel header="Add Discount">
                <div className="p-fluid p-formgrid p-grid">
                  <div className="p-col-12 p-lg-6">
                    <div className="p-fluid p-field ">
                      <label htmlFor="" className="p-col-fixed"></label>

                      <label className="p-mb-2">
                        Type <RequiredMessage value={type} />
                      </label>
                      <Dropdown
                        value={type}
                        placeholder="Select Discount Type"
                        options={['All', 'Category', 'Product', 'Strength', 'Quantity']}
                        onChange={handelTypeChange}
                      />
                    </div>
                    {type === 'Category' && (
                      <div className="p-fluid p-field ">
                        <label htmlFor="" className="p-col-fixed"></label>
                        <Dropdown
                          value={value}
                          optionLabel="name"
                          filter
                          filterBy="name"
                          filterPlaceholder="Search..."
                          options={productCategoriesWithIds}
                          className=""
                          placeholder="Select Category"
                          onChange={(e) => {
                            setValue(e.value)
                          }}
                        />
                      </div>
                    )}
                    {type === 'Product' && <Product />}
                    {type === 'Strength' && (
                      <>
                        <Product />
                        <Strength />
                      </>
                    )}
                    {type === 'Quantity' && (
                      <>
                        <Product />
                        <Strength />
                        <Quantity />
                      </>
                    )}
                  </div>
                  <div className="p-col-6 p-lg-6">
                    <div className="p-fluid p-field ">
                      <label htmlFor="" className="p-col-fixed">
                        Discount <RequiredMessage value={discount} />
                      </label>

                      <InputNumber
                        value={discount}
                        onValueChange={(e) => setDiscount(e.value)}
                        suffix="%"
                        inputId="horizontal"
                        showButtons
                        buttonLayout="horizontal"
                        step={0.5}
                        maxFractionDigits={2}
                        minFractionDigits={2}
                        incrementButtonIcon="pi pi-plus"
                        decrementButtonIcon="pi pi-minus"
                      />
                    </div>
                    <div className="p-fluid p-field ">
                      <label htmlFor="" className="p-col-fixed">
                        Description <RequiredMessage value={description} />
                      </label>

                      <InputTextarea
                        rows={5}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="p-fluid p-field">
                      <label htmlFor="" className="p-col-fixed">
                        Link
                      </label>

                      <InputTextarea
                        rows={3}
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="p-fluid p-field p-col-12 p-lg-6">
                    <label htmlFor="" className="p-col-fixed">
                      Eligibility
                    </label>

                    <ReactQuill
                      onChange={setEligibility}
                      placeholder="Eligibility"
                      value={eligibility}
                    />
                  </div>
                  <div className="p-fluid p-field p-col-12 p-lg-6">
                    <label htmlFor="" className="p-col-fixed">
                      How do you get it
                    </label>

                    <ReactQuill
                      onChange={setHowDoYouGetIT}
                      placeholder="How do you get it"
                      value={howDoYouGetIt}
                    />
                  </div>
                  <div className="p-fluid p-field p-col-12 p-lg-6">
                    <label htmlFor="" className="p-col-fixed">
                      Conditions in case of cancellation
                    </label>

                    <ReactQuill
                      onChange={setConditions}
                      placeholder="Conditions in case of cancellation"
                      value={conditions}
                    />
                  </div>
                  <div className="p-fluid p-field p-col-12 p-lg-6">
                    <label htmlFor="" className="p-col-fixed">
                      Other terms and condition
                    </label>

                    <ReactQuill
                      onChange={setOtherTerms}
                      placeholder="Other terms and condition"
                      value={otherTerms}
                    />
                  </div>

                  <div className="p-fluid p-field p-col-12 p-lg-6">
                    <label htmlFor="" className="p-col-fixed">
                      {' '}
                      Password <RequiredMessage value={addPassword} />
                    </label>

                    <InputText
                      placeholder="Enter password"
                      value={addPassword}
                      onChange={(e) => setAddPassword(e.target.value)}
                      type="password"
                    />
                  </div>
                  <div className="p-field p-col-12">
                    <Button
                      label={loader.new ? 'Saving' : 'Save Discount'}
                      icon={classNames('pi', {
                        'pi-save': !loader.new,
                        'pi-spin pi-spinner': loader.new,
                      })}
                      disabled={!valid || loader.new}
                      onClick={saveDiscount}
                    />
                  </div>
                </div>
              </TabPanel>
            )}
          </TabView>
        </div>
        <ChangeHistorySidebar
          setTableLoading={setTableLoading}
          header="Discount Change History"
          historyType="discount"
        />
      </>
    </PageAllowedToRoles>
  )
}
