import axios from 'axios'
import endpoints from './endpoints'

const postCoupon = async (couponData) => {
  try {
    const res = await axios.post(endpoints.coupon.index, couponData)
    return res
  } catch (error) {
    return error.response
  }
}

const getCoupons = async () => {
  try {
    const res = await axios.get(endpoints.coupon.index)
    return res
  } catch (error) {
    return error.response
  }
}

const getCouponById = async (id) => {
  try {
    const res = await axios.get(endpoints.coupon.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

const patchCoupon = async (id, couponData) => {
  try {
    const res = await axios.patch(endpoints.coupon.index + id, couponData)
    return res
  } catch (error) {
    return error.response
  }
}

const deleteCoupon = async (id, password) => {
  try {
    const res = await axios.delete(endpoints.coupon.index + id, { data: { password } })
    return res
  } catch (error) {
    return error.response
  }
}

export { postCoupon, getCoupons, getCouponById, patchCoupon, deleteCoupon }
