let hostname = window.location.hostname
let baseURL = ''
if (hostname === 'localhost') {
  baseURL = process.env.REACT_APP_LOCAL_SERVER
} else {
  baseURL = process.env.REACT_APP_LIVE_SERVER
}
baseURL += 'api/v1'

export default baseURL
