import React from 'react'
import { Redirect } from 'react-router-dom'
import { useGlobalContext } from '../../context'

const PageAllowedToRoles = ({ allowedRoles = [], children }) => {
  const { user } = useGlobalContext()
  return (
    <>
      {allowedRoles.includes(user.role) || allowedRoles.length === 0 || !user?.role ? (
        children
      ) : (
        <Redirect to="/access" />
      )}
    </>
  )
}

export default PageAllowedToRoles
