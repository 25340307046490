import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Badge } from 'primereact/badge'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { handleObjChange } from '../../../functions/setter'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { getComplaintById, patchCloseComplaint, postReopenComplaintReq } from '../../../api'
import useQuery from '../../../hooks/useQuery'
import { Toast } from 'primereact/toast'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useGlobalContext } from '../../../context'
import { Editor } from '@tinymce/tinymce-react'
import ConfirmationDialogWithInput from '../Dialog/ConfirmationDialogWithInput'
import HideDetails from '../../mini/HideDetails'
import ReactQuill from 'react-quill'

export default function ComplaintDetailsDialog({
  setLoading,
  loading,
  setOpenIssuesTableLoading,
  setClosedIssuesTableLoading,
  allOpenComplaints,
  allClosedComplaints,
  setAllOpenComplaints,
  setAllClosedComplaints,
  complaintCloserCategories,
}) {
  //* Used in multiple places
  const history = useHistory()
  const toast = useRef(null)
  const query = useQuery()
  const id = parseInt(query.get('id'))
  const { colorScheme, user, agents } = useGlobalContext()

  //* Empty objects
  const emptyManualComplaint = {
    orderDetails: '',
    customerName: '',
    issueCategory: '',
    issueSubCategory: '',
    openingNote: '',
    assignedToId: '',
    reopenApproval: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  const emptyCloseComplaint = {
    closingCategory: '',
    closingNote: '',
  }

  const emptyReopenReason = {
    reopeningNote: '',
    assignTo: '',
  }
  //* States start
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [issueData, setIssueData] = useState(emptyManualComplaint)
  const [closeComplaintToggle, setCloseComplaintToggle] = useState(false)
  const [closeComplaintData, setCloseComplaintData] = useState(emptyCloseComplaint)
  const [detailsDialog, setDetailsDialog] = useState(false)
  const [reasonToReopen, setReasonToReopen] = useState(emptyReopenReason)
  const [reopenComplaintDialogVisible, setReopenComplaintDialogVisible] = useState(false)
  //* States end
  //* Function to fetch all details for complaint using it's id
  const fetchComplaintById = useCallback(async () => {
    if (!id) return
    setOpenIssuesTableLoading(true)
    setClosedIssuesTableLoading(true)
    const res = await getComplaintById(id)
    if (res) {
      setOpenIssuesTableLoading(false)
      setClosedIssuesTableLoading(false)
      if (res.status === 404 || res.status === 400) {
        hideComplaintDialog()
      }
      if (res.status === 200) {
        setIssueData(res.data[0])
        setDetailsDialog(true)
      }
    }
  }, [id])
  useEffect(() => {
    fetchComplaintById()
  }, [fetchComplaintById])

  //* Function to toggle the complaint details dialog off
  const hideComplaintDialog = () => {
    setLoading(false)
    setDetailsDialog(false)
    setCloseComplaintToggle(false)
    setIssueData(emptyManualComplaint)
    setCloseComplaintData(emptyCloseComplaint)
    setNewRecordError(emptyErr)
    history.push({
      pathname: '/issues/cac',
    })
  }

  //* Function to close a complaint
  const closeComplaint = async () => {
    const err = []
    if (!closeComplaintData.closingCategory) {
      err.push('You need to give complaint closing category')
    }
    if (!closeComplaintData?.closingNote?.trim()) {
      err.push('You need to add a closing note')
    }
    if (err.length) {
      setNewRecordError({
        state: true,
        errors: err,
      })
      return
    }
    setLoading(true)
    const res = await patchCloseComplaint(id, closeComplaintData)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        let filteredClosedComplaints = allOpenComplaints.filter((complaint) => {
          if (complaint.id === id) {
            complaint.closedBy = res.data.closedByName
            return complaint
          }
        })

        let filteredOpenComplaints = allOpenComplaints.filter((complaint) => complaint.id !== id)
        setAllOpenComplaints(filteredOpenComplaints)
        setAllClosedComplaints((prevSate) => [...filteredClosedComplaints, ...prevSate])
        setIssueData(emptyManualComplaint)
        setCloseComplaintData(emptyCloseComplaint)
        hideComplaintDialog()
      }
    }
  }

  const hideReopenDialog = () => {
    setReasonToReopen(emptyReopenReason)
    setReopenComplaintDialogVisible(false)
  }

  const reopenComplaintRequest = async () => {
    const err = []
    if (!reasonToReopen?.reopeningNote?.trim()) {
      err.push('Reason cannot be empty')
    }
    if (!reasonToReopen?.assignTo) {
      err.push('You need to assign the issue to an agent')
    }
    if (err.length) {
      setNewRecordError({
        state: true,
        errors: err,
      })
      return
    }
    setLoading(true)
    const res = await postReopenComplaintReq(id, reasonToReopen)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        let filterCloseComplaints = allClosedComplaints.filter((complaint) => {
          if (complaint.id === id) {
            complaint.reopenApproval = 'pending'
          }
          return complaint
        })
        setAllClosedComplaints(filterCloseComplaints)
        hideReopenDialog()
        hideComplaintDialog()
      }
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })

        let filterCloseComplaint = allClosedComplaints.filter((complaint) => complaint.id === id)
        let filterCloseComplaints = allClosedComplaints.filter((complaint) => complaint.id !== id)
        filterCloseComplaint[0].assignedToId = reasonToReopen.assignTo
        let moveToOpen = [...filterCloseComplaint, ...allOpenComplaints]
        setAllClosedComplaints(filterCloseComplaints)
        setAllOpenComplaints(moveToOpen)
        hideReopenDialog()
        hideComplaintDialog()
      }
    }
  }

  //* Toggle between the color of open and close badge
  const badgeRed =
    colorScheme === 'dark'
      ? { color: `#FF7070`, backgroundColor: 'rgba(86, 51, 51, 1)' }
      : { color: `#b91c1c`, backgroundColor: '#FEE2E0' }
  const badgeGreen =
    colorScheme === 'dark'
      ? { color: `#06CF51`, backgroundColor: 'rgba(58, 106, 69, 0.6)' }
      : { color: `rgb(48 175 95)`, backgroundColor: 'rgb(204 237 216 / 100%)' }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        style={{ width: '90%', maxWidth: '800px' }}
        visible={detailsDialog}
        footer={
          issueData.status === 'close' ? (
            <div>
              <Button
                label="Reopen"
                disabled={
                  issueData.reopenApproval === 'pending' ||
                  (['teamlead', 'manager'].includes(user.role) &&
                    !user.members?.map((u) => u.id).includes(issueData.assignedToId) &&
                    !user.members?.map((u) => u.id).includes(issueData.closedById)) ||
                  (user.role === 'agent' &&
                    issueData.assignedToId !== user.id &&
                    issueData.closedById !== user.id &&
                    user.id !== 150)
                }
                className="p-button-text p-button-danger"
                icon="pi pi-undo"
                onClick={() => {
                  if (user.role === 'agent') {
                    handleObjChange(setReasonToReopen, 'assignTo', user.id)
                  }
                  setReopenComplaintDialogVisible(true)
                }}
              />
            </div>
          ) : (
            <div></div>
          )
        }
        onHide={hideComplaintDialog}
        header={
          <div className="p-text-capitalize">
            <Badge
              className="p-text-bold"
              value={issueData.status === 'close' ? issueData.status + 'd' : issueData.status}
              style={issueData.status === 'close' ? badgeGreen : badgeRed}
            ></Badge>
          </div>
        }
      >
        {!closeComplaintToggle ? (
          issueData && (
            <div className="p-grid">
              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Case ID
                </span>
                <h6 className="p-mt-2">{issueData.id}</h6>
              </div>
              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Invoice ID
                </span>
                <h6 className="p-mt-2">
                  {issueData.invoiceId != 'NA' ? (
                    <Link target={'_blank'} to={`/orders?invId=${issueData.invoiceId}`}>
                      {issueData.invoiceId}
                    </Link>
                  ) : (
                    'NA'
                  )}
                </h6>
              </div>

              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Order Agent
                </span>
                <h6 className="p-mt-2">{issueData?.username ? issueData.username : 'NA'}</h6>
              </div>

              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Handling By
                </span>
                <h6 className="p-mt-2">{issueData.agent}</h6>
              </div>
              <div className="p-col-12 p-lg-3">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Customer Name
                </span>
                <h6 className="p-mt-2">{issueData.customerName}</h6>
              </div>
              <div className="p-col-12 p-lg-4">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Issue Category
                </span>
                <h6 className="p-mt-2">{issueData.issueType}</h6>
              </div>
              <div className="p-col-12 p-lg-5">
                <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                  Issue Sub-Category
                </span>
                <h6 className="p-mt-2">{issueData.issueSub}</h6>
              </div>
              {issueData.orderDetails != '' && (
                <>
                  <div className="p-col-12 p-lg-4">
                    <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                      Email
                    </span>
                    <h6 className="p-mt-2">
                      <HideDetails id={'email'} value={issueData?.email} type="text" />
                    </h6>
                  </div>
                  <div className="p-col-12 p-lg-4">
                    <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                      Phone
                    </span>
                    <h6 className="p-mt-2">
                      <HideDetails
                        id={'phone'}
                        value={issueData?.phone}
                        type="text"
                        copyRegexReplace={/[^0-9]/g}
                      />
                    </h6>
                  </div>
                </>
              )}

              <div className="p-col-12 p-px-0">
                {issueData.orderDetails != '' && (
                  <>
                    <span className="p-text-bold" style={{ color: '#ABABAB' }}>
                      Order Details
                    </span>
                    <div className="p-mt-2">
                      {/* <Editor
                        className="editor"
                        style={{ width: '100%' }}
                        tinymceScriptSrc="https://cdn.tiny.cloud/1/crhihg018llbh8k3e3x0c5e5l8ewun4d1xr6c6buyzkpqwvb/tinymce/5/tinymce.min.js"
                        value={issueData.orderDetails}
                        disabled={true}
                        init={{
                          skin: colorScheme === 'light' ? 'oxide' : 'oxide-dark',
                          content_css: colorScheme === 'light' ? 'default' : 'dark',
                          menubar: false,
                          toolbar: false,
                          height: 400,
                          plugins: [
                            'advlist media autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen table',
                            'importcss insertdatetime media table paste code help wordcount template',
                          ],
                        }}
                      /> */}
                      <ReactQuill
                        readOnly
                        value={issueData.orderDetails}
                        // onChange={(value) =>
                        //   handleObjChange(setNewManualComplaint, 'orderDetails', value)
                        // }
                      />
                    </div>
                  </>
                )}
              </div>
              <div
                className="p-d-flex gap-1 p-col-12 p-flex-col p-pt-3"
                style={{ borderTop: '1px dashed #0066FF' }}
              >
                <h6
                  style={{
                    color: '#0066FF',
                    fontWeight: 500,
                    fontSize: '16px',
                  }}
                >
                  <strong>Q : </strong>
                  {issueData.note}
                </h6>
              </div>
              {(issueData?.assignedToId === user.id ||
                ['superadmin', 'admin', 'operations'].includes(user.role) ||
                (['teamlead', 'manager'].includes(user.role) &&
                  user.members.map((u) => u.id).includes(issueData?.assignedToId)) ||
                user.id === 150) &&
                (issueData.status === 'open' || issueData.status === 'reopen') && (
                  <Button
                    onClick={() => setCloseComplaintToggle(true)}
                    style={{
                      margin: 'auto',
                      position: 'absolute',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      bottom: '-16px',
                    }}
                  >
                    Close Issue
                  </Button>
                )}
            </div>
          )
        ) : (
          <div>
            <div className="p-fluid">
              <div className="field p-col-4">
                <label htmlFor="closingCategory">
                  Issue Closer Category <sup style={{ color: 'red' }}>*</sup>
                </label>
                <Dropdown
                  value={closeComplaintData.closingCategory}
                  onChange={(e) => {
                    handleObjChange(setCloseComplaintData, 'closingCategory', e.target.value)
                  }}
                  className="p-mt-3"
                  id="closingCategory"
                  options={complaintCloserCategories}
                />
              </div>
              <div className="field p-col">
                <label htmlFor="closingNote">
                  Closer Note <sup style={{ color: 'red' }}>*</sup>
                </label>
                <InputTextarea
                  value={closeComplaintData.closingNote}
                  onChange={(e) => {
                    handleObjChange(setCloseComplaintData, 'closingNote', e.target.value)
                  }}
                  className="p-mt-3"
                  id="closingNote"
                  rows={5}
                  cols={30}
                />
              </div>
            </div>
            {newRecordError.state && (
              <>
                {newRecordError.errors.map((err, idx) => {
                  return (
                    <div key={idx} className="p-fluid p-filed p-col-12">
                      <Message text={err} severity="warn" sticky={true} />
                    </div>
                  )
                })}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    type="button"
                    onClick={() => {
                      setNewRecordError(emptyErr)
                    }}
                    icon="pi pi-times"
                    label="Clear Warnings"
                    className="p-button-secondary"
                  ></Button>
                </div>
              </>
            )}
            <div className="p-col field">
              <Button loading={loading} label="Submit" onClick={closeComplaint}></Button>
            </div>
          </div>
        )}
        <ConfirmationDialogWithInput
          onHide={() => {
            hideReopenDialog()
          }}
          header={'Reopen complaint'}
          onSubmit={reopenComplaintRequest}
          visible={reopenComplaintDialogVisible}
          inputLabel={'Enter Reason...'}
          customInputs={true}
          // loading={loading}
        >
          <div className="p-field">
            <label htmlFor="password" className="">
              Reason to reopen <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputTextarea
              rows={4}
              cols={30}
              value={reasonToReopen.reopeningNote}
              onChange={(e) => handleObjChange(setReasonToReopen, 'reopeningNote', e.target.value)}
              className="w-full p-mt-1"
              placeholder="Start typing here..."
            />
          </div>
          {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
            <div className="p-field p-fluid p-col-12 p-lg-6">
              <label className="p-d-block">
                Assign To <sup style={{ color: 'red' }}>*</sup>
              </label>
              <Dropdown
                placeholder="User"
                options={user.members}
                disabled={user.role === 'agent'}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                value={reasonToReopen.assignTo}
                onChange={(e) => {
                  handleObjChange(setReasonToReopen, 'assignTo', e.value)
                }}
              />
            </div>
          )}
          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </ConfirmationDialogWithInput>
      </Dialog>
    </div>
  )
}
