import classNames from 'classnames'
import { subDays } from 'date-fns'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { InputSwitch } from 'primereact/inputswitch'
import { InputText } from 'primereact/inputtext'
import { MultiSelect } from 'primereact/multiselect'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { useGlobalContext } from '../../../context'
import { handleObjChange } from '../../../functions/setter'
import TargetBreakdown from './TargetBreakdown'
import { RadioButton } from 'primereact/radiobutton'

export default function TargetToMembers({ callBackFn }) {
  const { agents, user, teams } = useGlobalContext()
  const [optionAgents, setOptionAgents] = useState([])
  const toast = useRef(null)
  const emptyRecord = {
    name: '',
    groupName: '',
    managerReward: null,
    teamLeadReward: null,
    teamId: null,
    members: [],
    levels: null,
    membersLevelsBreakdown: [],
    teamOrGroupLevelsBreakdown: [],
    weekRange: [],
    targetBased: 'Amount',
    targetAmountBased: true,
    targetCountBased: false,
    targetConversionRateBased: false,
    settingSaved: false,
    rewardType: null,
    active: true,
  }
  const level_empty = {
    targetAmount: null,
    targetOrder: null,
    conversionRate: null,
    rewardAmount: null,
    remark: '',
  }

  const [record, setRecord] = useState(emptyRecord)

  const confirmationCallbackFn = async () => {
    callBackFn && callBackFn()
    setRecord(emptyRecord)
  }
  useEffect(() => {
    if (['teamlead'].includes(user.role)) {
      const _myMembers = teams
        .find((team) => team.teamID === user?.teams[0]?.id)
        ?.teamMembers.map((m) => m.userId)
      const _agents = agents.filter((agent) => _myMembers?.includes(agent.id))
      return setOptionAgents(_agents)
    }
    if (record.teamId) {
      const _myMembers = teams.find((team) => team.teamID === record.teamId)
      const _agents = _myMembers.teamMembers.map((i) => ({
        username: i.username,
        id: i.userId,
      }))
      return setOptionAgents(_agents)
    }

    const _agents = agents.filter((agent) => agent.role === 'agent' || agent.role === 'teamlead')
    setOptionAgents(_agents)
  }, [user, agents, teams, record.teamId])
  return (
    <>
      <Toast ref={toast} />
      <div className="card">
        <div className="p-grid">
          <div className="p-field p-fluid p-col-12 p-md-4">
            <label className="p-d-block">Target Name</label>
            <InputText
              placeholder="Enter Target Name"
              value={record.name}
              disabled={record.settingSaved}
              onChange={(e) => {
                handleObjChange(setRecord, 'name', e.target.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-md-4">
            <label htmlFor="" className="p-d-block">
              Agents
            </label>
            <MultiSelect
              placeholder="Agents"
              options={optionAgents}
              filterPlaceholder="Search Agents"
              optionLabel="username"
              optionValue="id"
              showClear
              filter
              filterBy="username"
              disabled={record.settingSaved}
              value={record.members}
              onChange={(e) => {
                handleObjChange(setRecord, 'members', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-md-4">
            <label className="p-d-block">Levels</label>
            <Dropdown
              options={[
                {
                  name: 'Level 1',
                  value: 1,
                },
                {
                  name: 'Level 2',
                  value: 2,
                },
                {
                  name: 'Level 3',
                  value: 3,
                },
                {
                  name: 'Level 4',
                  value: 4,
                },
              ]}
              optionLabel="name"
              optionValue="value"
              placeholder={'Select Levels'}
              disabled={record.settingSaved}
              value={record.levels}
              showClear
              onChange={(e) => {
                handleObjChange(setRecord, 'levels', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-md-4">
            <label htmlFor="" className="p-d-block">
              Dates
            </label>
            <Calendar
              placeholder="Select Timeframe"
              disabled={record.settingSaved}
              selectionMode="range"
              value={record.weekRange}
              onChange={(e) => {
                handleObjChange(setRecord, 'weekRange', e.value || [])
              }}
              minDate={subDays(new Date(), 7)}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-md-4">
            <label className="p-d-block">Reward Type</label>
            <Dropdown
              placeholder="Select Reward Type"
              options={[
                { name: 'Fixed', value: 'Fixed' },
                {
                  name: 'Incentive',
                  value: 'Incentive',
                },
              ]}
              optionLabel="name"
              optionValue="value"
              value={record.rewardType}
              disabled={record.settingSaved}
              onChange={(e) => {
                handleObjChange(setRecord, 'rewardType', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-md-4">
            <label className="p-d-block">Based On</label>
            <div className="p-d-flex p-flex-wrap gap-1 p-ai-end p-mt-3">
              <RadioButton
                name="targetBased"
                value="Amount"
                inputId="targetAmountBased"
                onChange={(e) => {
                  handleObjChange(setRecord, 'targetBased', e.value)
                  handleObjChange(setRecord, 'targetAmountBased', true)
                  handleObjChange(setRecord, 'targetCountBased', false)
                }}
                checked={record.targetAmountBased}
                disabled={record.settingSaved}
              />
              <label htmlFor="targetAmountBased">Amount</label>

              <RadioButton
                name="targetBased"
                value="Count"
                inputId="targetCountBased"
                onChange={(e) => {
                  handleObjChange(setRecord, 'targetBased', e.value)
                  handleObjChange(setRecord, 'targetAmountBased', false)
                  handleObjChange(setRecord, 'targetCountBased', true)
                }}
                checked={record.targetCountBased}
                disabled={record.settingSaved}
              />
              <label htmlFor="targetCountBased">Count</label>
              {/* <Checkbox
                  inputId="targetConversionRateBased"
                  onChange={(e) =>
                    handleObjChange(
                      setRecord,
                      'targetConversionRateBased',
                      e.checked
                    )
                  }
                  checked={record.targetConversionRateBased}
                  disabled={record.settingSaved}
                />
                <label htmlFor="targetConversionRateBased">
                  Conversion Rate (optional)
                </label> */}
              <InputSwitch
                checked={record.active}
                disabled={record.settingSaved}
                onChange={(e) => {
                  handleObjChange(setRecord, 'active', e.value)
                }}
              />
              <label htmlFor="targetAmountBased">{record.active ? 'Active' : 'Inactive'}</label>
            </div>
          </div>

          <div className="p-field p-fluid p-col-12">
            <Button
              label={record.settingSaved ? 'Reset' : 'Apply'}
              icon={classNames('pi', {
                'pi-check': !record.settingSaved,
                'pi-replay': record.settingSaved,
              })}
              className={classNames({
                'p-button-danger': record.settingSaved,
              })}
              disabled={
                !record.settingSaved &&
                (!record.weekRange[0] ||
                  !record.weekRange[1] ||
                  (!record.targetAmountBased && !record.targetCountBased) ||
                  !record.levels ||
                  !record.name ||
                  !record.rewardType ||
                  !record?.members?.length)
              }
              onClick={() => {
                const val = !record.settingSaved

                const _breakDown = record.members.map(() => {
                  return [...Array(record.levels).keys()].map(() => ({
                    ...level_empty,
                  }))
                })
                handleObjChange(setRecord, 'membersLevelsBreakdown', _breakDown)
                handleObjChange(setRecord, 'settingSaved', val)
              }}
            />
          </div>
        </div>
      </div>
      {record.settingSaved && (
        <div className="card">
          <TargetBreakdown
            record={record}
            setRecord={setRecord}
            confirmationCallbackFn={confirmationCallbackFn}
            targetType="I"
            toast={toast}
          />
        </div>
      )}
    </>
  )
}
