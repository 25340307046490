import axios from 'axios'
import endpoints from './endpoints'
import { format } from 'date-fns'

export const getTargets = async ({ dateFrom, dateTo }) => {
  try {
    const res = await axios.get(endpoints.targetManagement.index, {
      params: {
        dateFrom: dateFrom && format(new Date(dateFrom), 'yyyy-MM-dd'),
        dateTo: dateTo && format(new Date(dateTo), 'yyyy-MM-dd'),
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getTargetById = async (id) => {
  try {
    const res = await axios.get(endpoints.targetManagement.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const getPendingTargets = async () => {
  try {
    const res = await axios.get(endpoints.targetManagement.pending)
    return res
  } catch (error) {
    return error.response
  }
}

export const getPendingTargetById = async (id) => {
  try {
    const res = await axios.get(endpoints.targetManagement.pending + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const postTarget = async ({
  targetType,
  active,
  teamId,
  name,
  groupName,
  managerReward,
  teamLeadReward,
  dateFrom,
  dateTo,
  rewardType,
  targetAmountBased,
  targetCountBased,
  targetConversionRateBased,
  members,
  membersLevelsBreakdown,
  teamOrGroupLevelsBreakdown,
}) => {
  try {
    const res = await axios.post(endpoints.targetManagement.index, {
      targetType,
      active,
      teamId,
      name,
      groupName,
      managerReward,
      teamLeadReward,
      dateFrom: format(new Date(dateFrom), 'yyyy-MM-dd'),
      dateTo: format(new Date(dateTo), 'yyyy-MM-dd'),
      rewardType,
      targetAmountBased,
      targetCountBased,
      targetConversionRateBased,
      members,
      membersLevelsBreakdown,
      teamOrGroupLevelsBreakdown,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchTargetById = async (id, { breakDown, members, active }) => {
  try {
    const res = await axios.patch(endpoints.targetManagement.index + id, {
      breakDown,
      members,
      active,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteTargetByid = async (id, { password }) => {
  try {
    const data = JSON.stringify({ password })
    const res = await axios.delete(endpoints.targetManagement.index + id, {
      data,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const putTargetById = async (id, { active }) => {
  try {
    const res = await axios.put(endpoints.targetManagement.index + id, {
      active,
    })
    return res
  } catch (error) {
    return error.response
  }
}
