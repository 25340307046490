import { Button } from 'primereact/button'
import React from 'react'
import { useGlobalContext } from '../../context'
import { useHistory } from 'react-router-dom'

function BackToHome() {
  const {user} = useGlobalContext()
  const history = useHistory()

  const goDashboard = () => {
    history.push('/')
  }
  const goOrdersPage = () => {
    history.push('/orders')
  }
  return (
    user &&
    <Button
    type="button"
    icon={`pi ${ user.role === "operations" ? "pi-inbox" : "pi-home" }`}
    label={`Open ${ user.role === "operations" ? 'Orders' : 'Dashboard' }`}
    onClick={ user.role === "operations" ? goOrdersPage : goDashboard }
  ></Button>
  )
}

export default BackToHome