import { InputText } from 'primereact/inputtext'
import React, { useRef } from 'react'
import copy from 'clipboard-copy'
import hideDetails from '../../functions/hideDetails'
import { Toast } from 'primereact/toast'
import { useGlobalContext } from '../../context'

function HideDetails({ value, id, style, type, className, copyRegexReplace }) {
  const { toast, user } = useGlobalContext()

  const handleCopyClick = () => {
    if (!value) return
    let _value = value
    if (copyRegexReplace) {
      _value = _value.replace(copyRegexReplace, '')
    }
    copy(_value)
    toast.current.show({
      severity: 'success',
      summary: 'Successfully copied',
    })
  }

  return (
    <div className={`relative ${type === 'text' ? 'p-d-inline' : ''}`}>
      <Toast ref={toast} />
      {type === 'text' ? (
        hideDetails(value)
      ) : (
        <InputText
          id={id}
          value={hideDetails(value)}
          disabled
          style={style}
          className={className}
        />
      )}
      {user?.accessToCopy
        ? value &&
          value.length > 3 && (
            <i
              className={`pi pi-copy ${
                type === 'input' ? 'absolute right-5-px top-10-px' : 'p-pl-2'
              }`}
              style={{ cursor: 'pointer' }}
              onClick={handleCopyClick}
            />
          )
        : ''}
    </div>
  )
}

export default HideDetails
