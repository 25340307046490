import axios from 'axios'
import endpoints from './endpoints'
import { urlBase64ToUint8Array } from '../functions/notificationUtil'

const serverKey = process.env.REACT_APP_WEBPUSH_PUBLIC_VAPID_KEY

export const subscribeToNotification = async (serviceWorkerReg) => {
  try {
    let subscription = await serviceWorkerReg.pushManager.getSubscription()
    if (subscription === null) {
      subscription = await serviceWorkerReg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(serverKey),
      })
      await axios.post(endpoints.notification.subscribe, subscription)
      console.log('Notification subscribed')
    }
  } catch (error) {
    return error.response
  }
}

export const unsubscribeToNotification = async (serviceWorkerReg) => {
  try {
    let subscription = await serviceWorkerReg.pushManager.getSubscription()
    if (subscription) {
      await axios.post(endpoints.notification.unsubscribe, subscription)
      subscription.unsubscribe()
      console.log('Notification unsubscribed')
    }
  } catch (error) {
    return error.response
  }
}

export const putUserNotificationTopics = async ({ topics, type, value = false }) => {
  try {
    const res = await axios.put(endpoints.notification.topics, {
      topics,
      type,
      value,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getUserNotificationTopics = async () => {
  try {
    const res = await axios.get(endpoints.notification.topics)
    return res
  } catch (error) {
    return error.response
  }
}

export const getUserNotificationHistory = async () => {
  try {
    const res = await axios.get(endpoints.notification.history)
    return res
  } catch (error) {
    return error.response
  }
}
