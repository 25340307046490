import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React from 'react'
// ConfirmationDialogWithInput component to manage the confirmation dialog with input
export default function ConfirmationDialogWithInput({
  children,
  loading,
  visible,
  onHide,
  header,
  onSubmit,
  submitLabel = 'Submit',
  submitLabelLoading = 'Submiting...',
  customInputs = false,
  inputPlaceholder,
  inputLabel,
  confirmationText,
  inputValue,
  setInputValue,
}) {
  /**
   * children @type {object} - jsx/html to be rendered
   * loading @type {boolean} - boolean to check loading
   * visible @type {boolean} - boolean to check visible
   * onHide @type {function} - function to handle hide
   * header @type {string} - header conponent
   * onSubmit @type {function} - function to handle submit
   * submitLabel @type {string} - string to check submit label
   * submitLabelLoading @type {string} - string to check submit label loading
   * customInputs @type {boolean} - boolean to check custom inputs i.e input from children
   * inputPlaceholder @type {string} - string to check input placeholder
   * inputLabel @type {string} - string to check input label
   * confirmationText @type {string} - string to check confirmation text
   * inputValue @type {string} - string to check input value
   * setInputValue @type {function} - function to handle set input value
   **/
  return (
    <Dialog
      onHide={onHide}
      visible={visible}
      autoFocus
      style={{ width: '450px' }}
      modal
      className="p-fluid"
      footer={() => (
        <>
          <Button
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            onClick={onHide}
          />
          <Button
            loading={loading}
            disabled={loading || (!customInputs && !inputValue)}
            label={loading ? submitLabelLoading : submitLabel}
            icon="pi pi-check"
            className="p-button"
            onClick={onSubmit}
          />
        </>
      )}
      header={<>{header}</>}
    >
      <div>
        {customInputs ? (
          <>{children}</>
        ) : (
          <>
            <div className="confirmation-content  p-d-flex p-ai-center">
              <i
                className="pi pi-exclamation-triangle p-mr-4"
                style={{ fontSize: '2rem' }}
              />
              <div className="w-full">
                <p>{confirmationText}</p>
                <div className="p-field">
                  <label htmlFor="default-input" className="">
                    {inputLabel}
                  </label>
                  <InputText
                    autoFocus
                    id="default-input"
                    placeholder={inputPlaceholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  )
}
