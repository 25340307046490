import axios from 'axios'
import endpoints from './endpoints'

export const getOffers = async () => {
  try {
    return await axios.get(endpoints.offer.index)
  } catch (error) {
    return error.response
  }
}

export const postOffer = async (name, slug, status, products, productNames) => {
  try {
    return await axios.post(endpoints.offer.index, {
      name,
      slug,
      status,
      products,
      productNames,
    })
  } catch (error) {
    return error.response
  }
}
export const patchOffer = async (
  id,
  name,
  slug,
  status,
  products,
  productNames,
) => {
  try {
    return await axios.patch(endpoints.offer.index + id, {
      name,
      slug,
      status,
      products,
      productNames,
    })
  } catch (error) {
    return error.response
  }
}

export const deleteOffer = async (id, password) => {
  try {
    const data = JSON.stringify({ password })
    return await axios.delete(endpoints.offer.index + id, { data })
  } catch (error) {
    return error.response
  }
}
