import axios from 'axios'
import endpoints from './endpoints'

export const postNewDACIssue = async (payload) => {
  const {
    invoiceId,
    issueType,
    issueCategory,
    customerName,
    customerId,
    issueSubCategory,
    description,
    assignTo,
  } = payload
  try {
    const res = await axios.post(endpoints.issues.dac.index, {
      invoiceId,
      issueType,
      issueCategory,
      customerName,
      customerId,
      issueSubCategory,
      description,
      assignTo,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getAllDACIssues = async ({ status, dateFrom, dateTo }) => {
  try {
    const res = await axios.get(endpoints.issues.dac.index, {
      params: { status, dateFrom, dateTo },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getDACNotesById = async (noteId) => {
  try {
    const res = await axios.get(endpoints.issues.dac.notes + noteId)
    return res
  } catch (error) {
    return error.response
  }
}

export const postNewDACIssueNote = async (generalNote, noteId) => {
  try {
    const res = await axios.post(endpoints.issues.dac.notes + noteId, {
      generalNote,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getDACIssueById = async (id) => {
  try {
    const res = await axios.get(endpoints.issues.dac.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const patchDACCloseIssue = async (complaintId, payload) => {
  try {
    const res = await axios.patch(
      endpoints.issues.dac.index + complaintId,
      payload
    )
    return res
  } catch (error) {
    return error.response
  }
}

export const putDACAgentById = async (updateAgentDetails, password) => {
  const { complaintId, agentId } = updateAgentDetails

  try {
    const res = await axios.put(endpoints.issues.dac.index, {
      password,
      complaintId,
      agentId,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getReopenIssueRequestById = async (reopenId) => {
  try {
    const res = await axios.get(endpoints.issues.dac.reopen + reopenId)
    return res
  } catch (error) {
    return error.response
  }
}

export const postReopenDACIssueReq = async (id, reasonToReopen) => {
  const { reopeningNote, assignTo } = reasonToReopen
  try {
    const res = await axios.post(endpoints.issues.dac.reopen + id, {
      reopeningNote,
      assignTo,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const patchIssueRequestStatus = async (reopenId, status, password) => {
  try {
    const res = await axios.patch(endpoints.issues.dac.reopen + reopenId, {
      password,
      status,
    })
    return res
  } catch (error) {
    return error.response
  }
}
