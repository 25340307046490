import { InputNumber } from 'primereact/inputnumber'
import React, { useState } from 'react'
import { handleObjChange } from '../../../functions/setter'
import { InputText } from 'primereact/inputtext'
import { Timeline } from 'primereact/timeline'
import { Message } from 'primereact/message'
import { Button } from 'primereact/button'
import { useGlobalContext } from '../../../context'
import TargetConfirmationDialog from './TargetConfirmationDialog'
import classNames from 'classnames'
export default function TargetBreakdown({
  record,
  setRecord,
  confirmationCallbackFn,
  targetType,
  toast,
  isTeamPendingBreakdown = false,
  teamPendingBreakdownId = null,
}) {
  const { agents, teams } = useGlobalContext()
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [error, setError] = useState(emptyErr)
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const validateNewRecord = () => {
    if (!record || !targetType) {
      return ['Invalid record']
    }
    let groupAlias = ''
    if (targetType === 'G') {
      groupAlias = 'Group'
    } else if (targetType === 'T') {
      groupAlias = 'Team'
    } else if (targetType === 'TM') {
      groupAlias = 'Team'
    } else if (targetType === 'OT') {
      groupAlias = 'Only Team'
    }
    const err = []
    if (['G', 'TM', 'I'].includes(targetType)) {
      record.membersLevelsBreakdown.forEach((levels, idx) => {
        levels.forEach((input, idx1) => {
          const agentName = agents.find((agent) => agent.id === record.members[idx])?.username
          const level = idx1 + 1
          if (!input.rewardAmount) {
            err.push(`Reward is invalid for ${agentName} at level ${level}`)
          }
          if (record.targetAmountBased && !input.targetAmount > 0) {
            err.push(`Amount is invalid for ${agentName} at level ${level}`)
          }
          if (record.targetCountBased && !input.targetOrder > 0) {
            err.push(`Order count is invalid for ${agentName} at level ${level}`)
          }
          if (record.targetConversionRateBased && !input.conversionRate > 0) {
            err.push(`Conversion rate is invalid for ${agentName} at level ${level}`)
          }
        })
      })
    }
    if (['G', 'T', 'TM', 'OT'].includes(targetType)) {
      record.teamOrGroupLevelsBreakdown.forEach((input, idx) => {
        const level = idx + 1
        if (!input.rewardAmount) {
          err.push(`Reward is invalid for ${groupAlias} at level ${level}`)
        }
        if (record.targetAmountBased && !input.targetAmount > 0) {
          err.push(`Target amount is invalid for ${groupAlias} at level ${level}`)
        }
        if (record.targetCountBased && !input.targetOrder > 0) {
          err.push(`Order count is invalid for ${groupAlias} at level ${level}`)
        }
        if (record.targetConversionRateBased && !input.conversionRate > 0) {
          err.push(`Conversion rate is invalid for ${groupAlias} at level ${level}`)
        }
        if (['G', 'TM'].includes(targetType)) {
          let group = {
            reward:
              record.rewardType === 'Incentive'
                ? input.rewardAmount * input.targetAmount
                : input.rewardAmount || 0,
            amount: input.targetAmount || 0,
            count: input.targetOrder || 0,
          }
          let member = {
            reward: 0,
            amount: 0,
            count: 0,
          }
          member = record.membersLevelsBreakdown.reduce((acc, curr) => {
            if (record.rewardType === 'Incentive' && record.targetAmountBased) {
              acc.reward += curr[idx].rewardAmount * curr[idx].targetAmount
            } else if (record.rewardType === 'Fixed') {
              acc.reward += curr[idx].rewardAmount
            }
            acc.amount += curr[idx].targetAmount
            acc.count += curr[idx].targetOrder
            return acc
          }, member)
          // toFixed(2) is used to round off the number to 2 decimal places

          member.reward = parseFloat(member.reward).toFixed(2)
          member.amount = parseFloat(member.amount).toFixed(2)
          member.count = parseFloat(member.count).toFixed(2)
          group.reward = parseFloat(group.reward).toFixed(2)
          group.amount = parseFloat(group.amount).toFixed(2)
          group.count = parseFloat(group.count).toFixed(2)
          if (
            record.rewardType === 'Fixed' ||
            (record.rewardType === 'Incentive' && record.targetAmountBased)
          ) {
            if (group.reward !== member.reward) {
              err.push(
                `${groupAlias} reward and total members reward are not equal at level ${
                  idx + 1
                } by ₹${group.reward - member.reward}`
              )
            }
          }
          if (group.amount !== member.amount) {
            err.push(
              `${groupAlias} amount and total members amount are not equal at level ${
                idx + 1
              } by $${group.amount - member.amount}`
            )
          }
          if (group.count !== member.count) {
            err.push(
              `${groupAlias} count and total members count are not equal at level ${idx + 1} by ${
                group.count - member.count
              } orders`
            )
          }
        }
      })
    }

    if (err.length) {
      setError({
        state: true,
        errors: err,
      })
      return
    }
    setError(emptyErr)
    setConfirmationDialog(true)
  }

  return (
    <div>
      <div className="">
        {/* group targets */}

        <div className="p-mb-2">
          <h6 className="p-ml-2">
            {targetType === 'G' && record.groupName}
            {['T', 'TM', 'OT'].includes(targetType) &&
              teams[teams.findIndex((team) => team.teamID === record.teamId)]?.teamName}
          </h6>

          {['G', 'TM', 'T', 'OT'].includes(targetType) &&
            record.teamOrGroupLevelsBreakdown.map((level, idx) => {
              return (
                <div
                  key={idx}
                  className="p-formgrid p-grid p-mx-auto p-text-center p-ai-center gap-1by2"
                >
                  <div
                    className="p-field p-col-12 p-lg-1 p-p-2 p-shadow-2"
                    style={{
                      border: '1px solid #1F6AE3',
                      borderRadius: '10px',
                    }}
                  >
                    <label className="p-m-0">{`Level ${idx + 1}`}</label>
                  </div>
                  {record.targetAmountBased && (
                    <div className="p-field p-fluid p-col-12 p-lg-2">
                      <InputNumber
                        disabled={isTeamPendingBreakdown}
                        mode="currency"
                        currency="USD"
                        name="Target Amount"
                        placeholder="Enter Target Amount"
                        value={level.targetAmount}
                        onChange={(e) => {
                          let _newLevelRecord = [...record.teamOrGroupLevelsBreakdown]
                          _newLevelRecord[idx].targetAmount = e.value
                          handleObjChange(setRecord, 'teamOrGroupLevelsBreakdown', _newLevelRecord)
                        }}
                      />
                    </div>
                  )}
                  {record.targetCountBased && (
                    <div className="p-field p-fluid p-col-12 p-lg-2">
                      <InputNumber
                        disabled={isTeamPendingBreakdown}
                        name="Target Orders"
                        placeholder="Enter Target Orders"
                        suffix=" Orders"
                        value={level.targetOrder}
                        onChange={(e) => {
                          let _newLevelRecord = [...record.teamOrGroupLevelsBreakdown]
                          _newLevelRecord[idx].targetOrder = e.value
                          handleObjChange(setRecord, 'teamOrGroupLevelsBreakdown', _newLevelRecord)
                        }}
                      />
                    </div>
                  )}
                  {record.targetConversionRateBased && (
                    <div className="p-field p-fluid p-col-12 p-lg-2">
                      <InputNumber
                        disabled={isTeamPendingBreakdown}
                        suffix="%"
                        name="Conversion Rate"
                        placeholder="Enter Conversion Rate"
                        value={level.conversionRate}
                        onChange={(e) => {
                          let _newLevelRecord = [...record.teamOrGroupLevelsBreakdown]
                          _newLevelRecord[idx].conversionRate = e.value
                          handleObjChange(setRecord, 'teamOrGroupLevelsBreakdown', _newLevelRecord)
                        }}
                      />
                    </div>
                  )}
                  {record.rewardType === 'Incentive' && (
                    <div className="p-field p-fluid p-col-12 p-lg-2">
                      <InputNumber
                        disabled={isTeamPendingBreakdown}
                        tooltip={`₹${
                          level?.targetAmount
                            ? (level?.targetAmount * level?.rewardAmount).toFixed(2)
                            : 0
                        }`}
                        mode="currency"
                        currency="INR"
                        suffix="/$"
                        name="Reward Amount"
                        placeholder="Enter Reward Amount"
                        value={level.rewardAmount}
                        onChange={(e) => {
                          let _newLevelRecord = [...record.teamOrGroupLevelsBreakdown]
                          _newLevelRecord[idx].rewardAmount = e.value
                          handleObjChange(setRecord, 'teamOrGroupLevelsBreakdown', _newLevelRecord)
                        }}
                      />
                    </div>
                  )}
                  {record.rewardType === 'Fixed' && (
                    <div className="p-field p-fluid p-col-12 p-lg-2">
                      <InputNumber
                        disabled={isTeamPendingBreakdown}
                        mode="currency"
                        currency="INR"
                        name="Reward Amount"
                        placeholder="Enter Reward Amount"
                        value={level.rewardAmount}
                        onChange={(e) => {
                          let _newLevelRecord = [...record.teamOrGroupLevelsBreakdown]
                          _newLevelRecord[idx].rewardAmount = e.value
                          handleObjChange(setRecord, 'teamOrGroupLevelsBreakdown', _newLevelRecord)
                        }}
                      />
                    </div>
                  )}
                  <div className="p-field p-fluid p-col">
                    <InputText
                      disabled={isTeamPendingBreakdown}
                      name="Remarks"
                      placeholder="Enter Remarks"
                      value={level.remark}
                      onChange={(e) => {
                        let _newLevelRecord = [...record.teamOrGroupLevelsBreakdown]
                        _newLevelRecord[idx].remark = e.target.value
                        handleObjChange(setRecord, 'teamOrGroupLevelsBreakdown', _newLevelRecord)
                      }}
                    />
                  </div>
                </div>
              )
            })}
        </div>

        {/* memeber targets */}
        {['G', 'TM', 'I'].includes(targetType) && (
          <div className="widget-timeline">
            <div className="timeline-content">
              <Timeline
                value={record.membersLevelsBreakdown}
                className="custimized-timeline"
                content={(targetsArr, idx) => {
                  return (
                    <>
                      <div className="p-mb-3">
                        <h6>
                          {agents.find((agent) => agent.id === record.members[idx])?.username}
                        </h6>
                      </div>
                      {targetsArr.map((targets, id) => {
                        return (
                          <div
                            key={id}
                            className="p-formgrid p-grid p-mx-auto p-text-center p-ai-center gap-1by2"
                          >
                            <div
                              className="p-field p-col-12 p-lg-1 p-p-2 p-shadow-2"
                              style={{
                                border: '1px solid #1F6AE3',
                                borderRadius: '10px',
                              }}
                            >
                              <label className="p-m-0">{`Level ${id + 1}`}</label>
                            </div>
                            {record.targetAmountBased && (
                              <div className="p-field p-fluid p-col-12 p-lg-2">
                                <InputNumber
                                  mode="currency"
                                  currency="USD"
                                  name="Target Amount"
                                  placeholder="Enter Target Amount"
                                  value={targets.targetAmount}
                                  onChange={(e) => {
                                    let _newLevelRecord = [...record.membersLevelsBreakdown]

                                    _newLevelRecord[idx][id].targetAmount = e.value
                                    handleObjChange(
                                      setRecord,
                                      'membersLevelsBreakdown',
                                      _newLevelRecord
                                    )
                                  }}
                                />
                              </div>
                            )}
                            {record.targetCountBased && (
                              <div className="p-field p-fluid p-col-12 p-lg-2">
                                <InputNumber
                                  name="Target Orders"
                                  placeholder="Enter Target Orders"
                                  suffix=" Orders"
                                  value={targets.targetOrder}
                                  onChange={(e) => {
                                    let _newLevelRecord = [...record.membersLevelsBreakdown]

                                    _newLevelRecord[idx][id].targetOrder = e.value

                                    handleObjChange(
                                      setRecord,
                                      'membersLevelsBreakdown',
                                      _newLevelRecord
                                    )
                                  }}
                                />
                              </div>
                            )}
                            {record.targetConversionRateBased && (
                              <div className="p-field p-fluid p-col-12 p-lg-2">
                                <InputNumber
                                  suffix="%"
                                  name="Conversion Rate"
                                  placeholder="Enter Conversion Rate"
                                  value={targets.conversionRate}
                                  onChange={(e) => {
                                    let _newLevelRecord = [...record.membersLevelsBreakdown]
                                    _newLevelRecord[idx][id].conversionRate = e.value
                                    handleObjChange(
                                      setRecord,
                                      'membersLevelsBreakdown',
                                      _newLevelRecord
                                    )
                                  }}
                                />
                              </div>
                            )}
                            {record.rewardType === 'Incentive' && (
                              <div className="p-field p-fluid p-col-12 p-lg-2">
                                <InputNumber
                                  tooltip={`₹${
                                    targets?.targetAmount
                                      ? (targets?.targetAmount * targets?.rewardAmount).toFixed(2)
                                      : 0
                                  }`}
                                  mode="currency"
                                  currency="INR"
                                  suffix="/$"
                                  name="Reward Amount"
                                  placeholder="Enter Reward Amount"
                                  value={targets.rewardAmount}
                                  onChange={(e) => {
                                    let _newLevelRecord = [...record.membersLevelsBreakdown]

                                    _newLevelRecord[idx][id].rewardAmount = e.value

                                    handleObjChange(
                                      setRecord,
                                      'membersLevelsBreakdown',
                                      _newLevelRecord
                                    )
                                  }}
                                />
                              </div>
                            )}
                            {record.rewardType === 'Fixed' && (
                              <div className="p-field p-fluid p-col-12 p-lg-2">
                                <InputNumber
                                  mode="currency"
                                  currency="INR"
                                  name="Reward Amount"
                                  placeholder="Enter Reward Amount"
                                  value={targets.rewardAmount}
                                  onChange={(e) => {
                                    let _newLevelRecord = [...record.membersLevelsBreakdown]

                                    _newLevelRecord[idx][id].rewardAmount = e.value

                                    handleObjChange(
                                      setRecord,
                                      'membersLevelsBreakdown',
                                      _newLevelRecord
                                    )
                                  }}
                                />
                              </div>
                            )}
                            <div className="p-field p-fluid p-col">
                              <InputText
                                name="Remarks"
                                placeholder="Enter Remarks"
                                value={targets.remark}
                                onChange={(e) => {
                                  let _newLevelRecord = [...record.membersLevelsBreakdown]

                                  _newLevelRecord[idx][id].remark = e.target.value

                                  handleObjChange(
                                    setRecord,
                                    'membersLevelsBreakdown',
                                    _newLevelRecord
                                  )
                                }}
                              />
                            </div>
                          </div>
                        )
                      })}
                    </>
                  )
                }}
              />
            </div>
          </div>
        )}
        {/* errors */}
        <div className="p-fluid p-grid p-formgrid p-mt-3">
          {/* Showing errors that occur if the field isn't filled properly */}
          {record.rewardType === 'Incentive' &&
            record.targetCountBased &&
            !record.targetAmountBased && (
              <div className="p-field p-fluid p-col-12">
                <Message
                  text="Note: Incentive validation does not work for target count based targets"
                  severity="info"
                  sticky={true}
                />
              </div>
            )}
          {error.state && (
            <>
              {error.errors.map((err, idx) => (
                <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                  <Message text={err} severity="warn" sticky={true} />
                </div>
              ))}
              <div className="p-d-flex p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => setError(emptyErr)}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                />
              </div>
            </>
          )}
        </div>
        <div className="p-field p-fluid p-col-12 p-p-0 ">
          <Button
            label="Submit"
            icon="pi pi-check"
            onClick={() => {
              validateNewRecord()
            }}
          />
        </div>
      </div>
      <TargetConfirmationDialog
        targetType={targetType}
        confirmationDialogVisible={confirmationDialog}
        setConfirmationDialogVisible={setConfirmationDialog}
        name={record.name}
        active={record.active}
        teamId={record.teamId}
        groupName={record.groupName}
        managerReward={record.managerReward}
        teamLeadReward={record.teamLeadReward}
        weekRange={record.weekRange}
        targetAmountBased={record.targetAmountBased}
        targetCountBased={record.targetCountBased}
        targetConversionRateBased={record.targetConversionRateBased}
        rewardType={record.rewardType}
        members={record.members}
        membersLevelsBreakdown={record.membersLevelsBreakdown}
        teamOrGroupLevelsBreakdown={record.teamOrGroupLevelsBreakdown}
        callBackFn={confirmationCallbackFn}
        toast={toast}
        isTeamPendingBreakdown={isTeamPendingBreakdown}
        teamPendingBreakdownId={teamPendingBreakdownId}
      />
    </div>
  )
}
