import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../context'
import { serialize } from '../../functions/serializeUrl'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
export default function CustomerAffiliate() {
  const { agents, user, leadSource, toast } = useGlobalContext()
  const [url, setUrl] = useState('')
  const [affiliateUrl, setAffiliateUrl] = useState('')
  const [leadSrc, setLeadSrc] = useState('')
  const [userRef, setUserRef] = useState('')

  useEffect(() => setUserRef(user.id), [user.id])

  useEffect(() => {
    if (!url) {
      return setAffiliateUrl('')
    }
    const a_url =
      url +
      '?' +
      serialize({
        utm_source: leadSrc,
        u_ref: userRef,
      })
    setAffiliateUrl(a_url)
  }, [leadSrc, url, userRef])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'teamlead', 'agent']}>
      <div className="card">
        <h4 className="p-m-0">Customer Affiliate</h4>
        <hr />
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12">
            <div className="p-fluid p-field ">
              <label htmlFor="" className="p-col-fixed"></label>
              <label className="p-mb-2">URL</label>
              <InputText
                showClear
                value={url}
                placeholder="Enter URL"
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div className="p-fluid p-field ">
              <label htmlFor="" className="p-col-fixed"></label>
              <label className="p-mb-2">Lead Source</label>
              <Dropdown
                showClear
                optionLabel="name"
                optionValue="value"
                options={leadSource}
                placeholder="Select lead source"
                value={leadSrc}
                onChange={(e) => setLeadSrc(e.value)}
              />
            </div>
            <div className="p-fluid p-field ">
              <label htmlFor="" className="p-col-fixed"></label>
              <label className="p-mb-2">User Reference</label>
              <Dropdown
                showClear
                optionLabel="username"
                optionValue="id"
                filter
                filterBy="username"
                filterPlaceholder="Search..."
                options={agents}
                placeholder="Select user"
                value={userRef}
                onChange={(e) => setUserRef(e.value)}
              />
            </div>
            <div className="p-fluid p-field ">
              <label htmlFor="" className="p-col-fixed"></label>
              <label className="p-mb-2">Your Affiliate URL</label>
              <div className="p-d-flex p-ai-center gap-1">
                <span className="flex-1">
                  <InputText value={affiliateUrl} readOnly placeholder="Your affiliate URL" />
                </span>
                <span>
                  <Button
                    icon="pi pi-copy"
                    className="p-button-secondary"
                    tooltip="Copy"
                    onClick={() => {
                      navigator.clipboard?.writeText(affiliateUrl)
                      toast.current.show({ severity: 'success', summary: 'Copied', detail: 'Affiliate link copied'})
                    }}
                    disabled={!affiliateUrl}
                  />
                </span>
                <span>
                  <Button
                    icon="pi pi-external-link"
                    className="p-button-secondary"
                    tooltip="Open"
                    onClick={() => {
                      window.open(affiliateUrl)
                    }}
                    disabled={!affiliateUrl}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageAllowedToRoles>
  )
}
