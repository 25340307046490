import adminMenu from './menu/adminMenu'
import agentMenu from './menu/agentMenu'
import managerMenu from './menu/managerMenu'
import operationsMenu from './menu/operationsMenu'
import defaultMenu from './menu/defaultMenu'
import superAdminMenu from './menu/superAdminMenu'
import teamLeadMenu from './menu/teamLeadMenu'
// this decides which menu items to show based on the user role
const menu = (user) => {
  let menuItems = defaultMenu
  if (user.role === 'superadmin') {
    menuItems = superAdminMenu
  } else if (user.role === 'admin') {
    menuItems = adminMenu
  } else if (user.role === 'manager') {
    menuItems = managerMenu
  } else if (user.role === 'operations') {
    menuItems = operationsMenu
  } else if (user.role === 'agent') {
    menuItems = agentMenu
  } else if (user.role === 'teamlead') {
    menuItems = teamLeadMenu
  }

  return menuItems
}
export default menu
