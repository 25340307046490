import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Toolbar } from 'primereact/toolbar'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import {
  addMultipleProductToShipper,
  changeMultipleChargesFromProduct,
  getAllProductsShipping,
  getShippingCharges,
  removeMultipleChargesFromProduct,
} from '../../../api'
import { Toast } from 'primereact/toast'
import { useGlobalContext } from '../../../context'
import { Column } from 'primereact/column'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Dialog } from 'primereact/dialog'
import ViewProductChargesDialog from './ViewProductChargesDialog'
export default function ProductLogistic({
  companies,
  fetchData,
  unassignedShippings,
  assignedShippings,
  selectedUnassignedProducts,
  setSelectedUnassignedProducts,
  selectedAssignedProducts,
  setSelectedAssignedProducts,
  tableProductLoading,
}) {
  const { shippingTags, productCategories } = useGlobalContext()
  const toast = useRef(null)
  const dt = useRef(null)
  const defaultViewShipperDetails = {
    visible: false,
    product: {},
    provider: {
      companyTag: '',
      companyName: '',
      companyProvider: '',
    },
    chargesByAll: [],
    chargesByShipper: [],
    chargesByProduct: [],
  }
  const [shipperForUnassigned, setShipperForUnassigned] = useState({
    value: '',
    adding: false,
  })
  const handelAddMultipleProductToShipper = async () => {
    setShipperForUnassigned((ps) => ({ ...ps, adding: true }))
    const ids = selectedUnassignedProducts.map((item) => item.productId)
    const res = await addMultipleProductToShipper(ids, shipperForUnassigned.value)
    setShipperForUnassigned((ps) => ({ ...ps, adding: false }))

    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Added Shipping Charge',
        detail: 'Shipping charge added successfully',
      })
      fetchData()
      setShipperForUnassigned({
        value: '',
        adding: false,
      })
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error || 'Something went wrong',
      })
    }
  }
  const [viewShipperDetails, setViewShipperDetails] = useState(defaultViewShipperDetails)
  // const [tableProductLoading, setTableProductLoading] = useState(false)
  const [shipper, setShipper] = useState('')
  const [assignedFilter, setAssignedFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCategory: { value: null, matchMode: FilterMatchMode.CONTAINS },
    shippingCompany: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [unassignedFilter, setUnassignedFilter] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productCategory: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const handelChangingMultipleShippers = async () => {
    const ids = selectedAssignedProducts.map((item) => item.productId)
    const res = await changeMultipleChargesFromProduct(ids, shipper)
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Shippers changed successfully',
      })
      fetchData()
      setShipper('')
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error || 'Something went wrong',
      })
    }
  }
  const handelRemovingMultipleShippers = async () => {
    const ids = selectedAssignedProducts.map((item) => item.productId)
    const res = await removeMultipleChargesFromProduct(ids)
    if (res && res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Shippers removed successfully',
      })
      fetchData()
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: res.data.error || 'Something went wrong',
      })
    }
  }
  const hideShipperDetails = () => {
    setViewShipperDetails(defaultViewShipperDetails)
  }
  const tooltipAssignedLeft = () => {
    return (
      <div className="p-d-flex gap-1 p-flex-wrap">
        <span className="p-d-flex p-ai-center">
          <Button
            label="Remove"
            tooltip="Remove Shippers From Selected Products"
            icon="pi pi-info-circle"
            tooltipOptions={{
              position: 'top',
            }}
            disabled={assignedShippings.length === 0 || selectedAssignedProducts.length === 0}
            className="p-button-icon p-button-success"
            onClick={handelRemovingMultipleShippers}
          />
        </span>
        <span className="p-d-flex p-ai-center">
          <Button
            label="Change"
            tooltip="Change Shippers From Selected Products"
            icon="pi pi-info-circle"
            tooltipOptions={{
              position: 'top',
            }}
            disabled={selectedAssignedProducts.length === 0 || !shipper}
            className="p-button-icon p-button-warning"
            onClick={handelChangingMultipleShippers}
          />
        </span>

        <span className="">
          <Dropdown
            placeholder="Select New Shipper"
            showClear
            options={shippingTags}
            value={shipper}
            onChange={(e) => {
              setShipper(e.value)
            }}
          />
        </span>
      </div>
    )
  }
  const tooltipAssignedRight = () => {
    return (
      <p className="p-d-flex gap-1">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search Global"
            value={assignedFilter.global.value}
            onChange={(e) => {
              setAssignedFilter({
                ...assignedFilter,
                global: {
                  value: e.target.value,
                  matchMode: FilterMatchMode.CONTAINS,
                },
              })
            }}
          />
        </span>
        {/* <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search Product"
            value={assignedFilter.productName.value}
            onChange={(e) => {
              setAssignedFilter({
                ...assignedFilter,
                productName: {
                  value: e.target.value,
                  matchMode: FilterMatchMode.CONTAINS,
                },
              })
            }}
          />
        </span>{' '}
        <span className="p-input-icon-left">
          <Dropdown
            placeholder="Select Category"
            showClear
            options={productCategories}
            value={assignedFilter.productCategory.value}
            onChange={(e) => {
              setAssignedFilter({
                ...assignedFilter,
                productCategory: {
                  value: e.target.value,
                  matchMode: FilterMatchMode.CONTAINS,
                },
              })
            }}
          />
        </span> */}
        {/* <span className="p-input-icon-left">
          <Dropdown
            placeholder="Select Shipper"
            showClear
            options={shippingTags}
            value={assignedFilter.shippingCompany.value}
            onChange={(e) => {
              setAssignedFilter({
                ...assignedFilter,
                shippingCompany: {
                  value: e.target.value,
                  matchMode: FilterMatchMode.CONTAINS,
                },
              })
            }}
          />
        </span> */}
      </p>
    )
  }

  const tooltipUnassignedLeft = () => {
    return (
      <span className="p-d-flex p-ai-center">
        <Button
          label=""
          tooltip="Refetch Products"
          icon="pi pi-refresh"
          className="p-button-icon p-button  p-mr-2"
          onClick={fetchData}
        />
      </span>
    )
  }
  const tooltipUnassignedRight = () => {
    return (
      <>
        <p className="p-d-flex gap-1">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Global"
              value={unassignedFilter.global.value}
              onChange={(e) => {
                setUnassignedFilter({
                  ...unassignedFilter,
                  global: {
                    value: e.target.value,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                })
              }}
            />
          </span>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              placeholder="Product"
              value={unassignedFilter.productName.value}
              onChange={(e) => {
                setUnassignedFilter({
                  ...unassignedFilter,
                  productName: {
                    value: e.target.value,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                })
              }}
            />
          </span>
          <span className="p-input-icon-left">
            <Dropdown
              placeholder="Select Category"
              showClear
              options={productCategories}
              value={unassignedFilter.productCategory.value}
              onChange={(e) => {
                setUnassignedFilter({
                  ...unassignedFilter,
                  productCategory: {
                    value: e.target.value,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                })
              }}
            />
          </span>
        </p>
      </>
    )
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="p-grid">
        <div className="p-col-12 p-lg-7">
          <div className="card p-shadow-3">
            <Toolbar
              className="p-toolbar p-mb-3 p-d-flex p-ai-center"
              left={tooltipAssignedLeft}
              right={tooltipAssignedRight}
            ></Toolbar>
            <DataTable
              filters={assignedFilter}
              selection={selectedAssignedProducts}
              onSelectionChange={(e) => setSelectedAssignedProducts(e.value)}
              dataKey="productId"
              ref={dt}
              value={assignedShippings}
              loading={tableProductLoading}
              showGridlines
              paginator
              rows={10}
              header="Assigned Products"
              rowsPerPageOptions={[10, 50, 100, 500, 1000]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              emptyMessage="No assigned products found."
              rowHover
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
              <Column field="productId" header="ID" sortable />
              <Column field="productName" header="Name" sortable />
              <Column field="productType" header="Packing" sortable />
              <Column field="shippingCompany" header="Shipper" sortable />
              <Column
                header="Actions"
                body={(rd) => {
                  return (
                    <Link to={`logistics?view_product_code=${rd.productCode}`}>
                      <Button tooltip="View Charges" className="" icon="pi pi-eye" />
                    </Link>
                  )
                }}
              />
            </DataTable>
          </div>
        </div>
        <div className=" p-col-12 p-lg-5">
          <div className="card p-shadow-3">
            <Toolbar
              className="p-toolbar p-mb-3 p-d-flex p-ai-center"
              left={tooltipUnassignedLeft}
              right={tooltipUnassignedRight}
            ></Toolbar>
            <DataTable
              loading={tableProductLoading}
              filters={unassignedFilter}
              selection={selectedUnassignedProducts}
              onSelectionChange={(e) => setSelectedUnassignedProducts(e.value)}
              dataKey="productId"
              ref={dt}
              value={unassignedShippings}
              showGridlines
              paginator
              rows={10}
              header={() => {
                return (
                  <div className="p-d-flex p-jc-between p-ai-end">
                    <div className="">Unassigned Products</div>
                    <div className="p-d-flex gap-1">
                      <Dropdown
                        placeholder="Select Shipper To Be Assigned"
                        options={shippingTags}
                        value={shipperForUnassigned.value}
                        showClear
                        onChange={(e) =>
                          setShipperForUnassigned((ps) => ({
                            ...ps,
                            value: e.value,
                          }))
                        }
                      />
                      {shipperForUnassigned.value && (
                        <div className="">
                          <Button
                            onClick={handelAddMultipleProductToShipper}
                            label={shipperForUnassigned.adding ? 'Adding...' : 'Add Shipper'}
                            icon="pi pi-plus"
                            loading={shipperForUnassigned.adding}
                            disabled={
                              selectedUnassignedProducts.length === 0 ||
                              !shipperForUnassigned.value ||
                              shipperForUnassigned.adding
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              }}
              rowsPerPageOptions={[10, 50, 100, 500, 1000]}
              className="datatable-responsive"
              columnResizeMode="fit"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              emptyMessage="No unassigned products found."
              rowHover
            >
              <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
              <Column field="productId" header="ID" sortable />
              <Column field="productName" header="Name" sortable />
              <Column field="productCategory" header="Category" sortable />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}
