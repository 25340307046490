import axios from 'axios'
import endpoints from './endpoints'

export const loginApi = async (email, password) => {
  try {
    const res = await axios.post(endpoints.auth.login, {
      email,
      password,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const logoutApi = async () => {
  try {
    const res = await axios.post(endpoints.auth.logout)
    return res
  } catch (error) {
    return error.response
  }
}

export const loginOtpApi = async (otp, id) => {
  try {
    const res = await axios.post(endpoints.auth.verify, {
      otp,
      id,
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getMyself = async () => {
  try {
    const res = await axios.get(endpoints.auth.myself)
    return res
  } catch (error) {
    return error.response
  }
}

export const resetUserPassword = async (stage, payload) => {
  try {
    return await axios.post(endpoints.auth.resetPassword + stage, payload)
  } catch (error) {
    return error.response
  }
}
