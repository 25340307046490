import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import { requestOtp, validateOtp } from '../../api'
import { useGlobalContext } from '../../context'

export default function ExportButton({ datatable_ref }) {
  const { user, toast } = useGlobalContext()
  const [showOtpDialog, setShowOtpDialog] = useState(false)

  const empty_otp = {
    value: '',
    requesting: false,
    validaing: false,
    expireTime: null,
  }
  const [otp, setOtp] = useState(empty_otp)
  const [otpCoolDown, setOtpCoolDown] = useState(0)

  const exportData = () => {
    datatable_ref?.current?.exportCSV()
  }

  const handelRequestOtp = async () => {
    setOtp({ ...otp, requesting: true })
    const res = await requestOtp()
    if (res) {
      setOtp({ ...otp, requesting: false })
      if (res.status === 200) {
        toast?.current?.show({
          severity: 'success',
          summary: 'OTP sent',
          detail: 'OTP sent to your email',
        })
        let time = new Date().getTime()
        setOtp({ ...otp, expireTime: time + 30 * 1000 })
      }
    }
  }

  const handleValidateOtp = async () => {
    setOtp({ ...otp, validaing: true })
    const res = await validateOtp(otp.value)
    if (res) {
      setOtp({ ...otp, validaing: false })
      if (res.status === 200) {
        exportData()
        closeRequestOtpDialog()
      }
    }
  }

  useEffect(() => {
    if (otp.expireTime) {
      const interval = setInterval(() => {
        if (new Date().getTime() < otp.expireTime) {
          setOtpCoolDown((p) => p + 1)
        } else {
          setOtpCoolDown(0)
          setOtp((p) => ({ ...p, expireTime: null }))
          return () => clearInterval(interval)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [otp.expireTime])

  const openRequestOtpDialog = () => {
    setShowOtpDialog(true)
    setOtp(empty_otp)
    setOtpCoolDown(0)
  }

  const closeRequestOtpDialog = () => {
    setShowOtpDialog(false)
    setOtp(empty_otp)
    setOtpCoolDown(0)
  }

  return (
    <>
      <Dialog
        className="w-20"
        visible={showOtpDialog}
        onHide={closeRequestOtpDialog}
        header="Validate Export"
        footer={
          <>
            <Button label="Cancel" className="p-button-text" onClick={closeRequestOtpDialog} />
          </>
        }
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="otp">OTP</label>
            <InputText
              value={otp.value}
              onChange={(e) => {
                if (e.target.value.length > 4) return
                setOtp({ ...otp, value: e.target.value })
              }}
              placeholder="Enter your OTP"
              id="otp"
              className="p-d-block"
              style={{ width: '100%' }}
            />
          </div>
          <div className="p-field">
            <Button
              label={otp.validaing ? 'Submitting' : 'Submit'}
              className="p-button"
              onClick={handleValidateOtp}
              loading={otp.validaing}
              disabled={!otp.value || otp.value.length !== 4 || otp.validaing}
            />
          </div>

          {otp.expireTime ? (
            <div className="p-field">
              <Button
                label={`Request OTP again in ${30 - otpCoolDown} sec`}
                className="p-button-text"
                disabled
              />
            </div>
          ) : (
            <div className="p-field">
              <Button
                label={otp.requesting ? 'Requesting...' : 'Request OTP'}
                className="p-button-text"
                onClick={handelRequestOtp}
                loading={otp.requesting}
              />
            </div>
          )}
        </div>
      </Dialog>
      {['superadmin'].includes(user.role) && (
        <Button
          label="Export"
          icon="pi pi-download"
          className="p-button-help "
          onClick={openRequestOtpDialog}
        />
      )}
    </>
  )
}
