import { TabPanel, TabView } from 'primereact/tabview'
import React, { useCallback, useEffect, useState } from 'react'
import { getTargets } from '../../api'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import TargetDetailsDialog from '../../components/mycomponents/TargetManagement/TargetDetailsDialog'
import TargetTeamAssignBreakdownDialog from '../../components/mycomponents/TargetManagement/TargetTeamAssignBreakdownDialog'
import TargetToGroup from '../../components/mycomponents/TargetManagement/TargetToGroup'
import TargetToManager from '../../components/mycomponents/TargetManagement/TargetToManager'
import TargetToMembers from '../../components/mycomponents/TargetManagement/TargetToMembers'
import TargetToTeam from '../../components/mycomponents/TargetManagement/TargetToTeam'
import TargetsDataTable from '../../components/mycomponents/TargetManagement/TargetsDataTable'
import { assignDates, dateFilters } from '../../functions/myDateFns'
import { useGlobalContext } from '../../context'

const pathname = '/targets-admin'
export default function AdminTargetsPage() {
  const { user } = useGlobalContext()
  // States
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))
  const [showTargets, setShowTargets] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)

  //* Handler to fetch all the target records from the database
  const fetchTargets = useCallback(async () => {
    setTableLoading(true)
    const res = await getTargets({
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
    })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setShowTargets(res.data)
      }
    }
  }, [dateFilter.dateFrom, dateFilter.dateTo])
  useEffect(() => fetchTargets(), [fetchTargets])

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager']}>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setActiveIndex(e.index)
        }}
      >
        <TabPanel header="Assign Target To Team" activeIndex={activeIndex}>
          <TargetToTeam callBackFn={fetchTargets} />
        </TabPanel>
        {['superadmin', 'admin'].includes(user.role) && (
          <TabPanel header="Assign Target To Manager">
            <TargetToManager callBackFn={fetchTargets} />
          </TabPanel>
        )}
        <TabPanel header="Assign Target To Agents">
          <TargetToMembers callBackFn={fetchTargets} />
        </TabPanel>
        <TabPanel header="Assign Target To Group">
          <TargetToGroup callBackFn={fetchTargets} />
        </TabPanel>
        <TabPanel header="Target History">
          <div className="">
            <TargetsDataTable
              records={showTargets}
              tableLoading={tableLoading}
              setRecords={setShowTargets}
              setTableLoading={setTableLoading}
              fetchRecords={fetchTargets}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
            />
          </div>
        </TabPanel>
      </TabView>
      <TargetTeamAssignBreakdownDialog callBackFn={fetchTargets} />
      <TargetDetailsDialog />
    </PageAllowedToRoles>
  )
}
