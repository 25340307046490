import React, { useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { updateOrderProductShipmentDelays } from '../../../api'
import { useGlobalContext } from '../../../context'
export default function OrderProductINSDSwitch({
  disabled = true,
  orderProductId,
  value,
  callback,
}) {
  const { toast } = useGlobalContext()
  const [checked, setChecked] = useState(value)
  const updateOrderProductShippmentTracking = async (e) => {
    const res = await updateOrderProductShipmentDelays({
      id: orderProductId,
      trackingStatus: e.value,
    })
    if (res) {
      if (res.status === 200) {
        setChecked(e.value)
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        callback && callback()
      }
    }
  }
  return (
    <InputSwitch
      checked={checked}
      disabled={disabled}
      tooltip="Shipment	 tracking"
      tooltipOptions={{ position: 'top' }}
      onChange={(e) => {
        updateOrderProductShippmentTracking(e)
      }}
    />
  )
}
