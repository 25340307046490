import classNames from 'classnames'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import validator from 'validator'
import { resetUserPassword } from '../../api'

export default function ResetPasswordPage(props) {
  const toast = useRef(null)
  const [stage, setStage] = useState('email')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [requestLoading, setRequestLoading] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)
  const emptyError = {
    email: null,
    password: null,
    confirmPassword: null,
    otp: null,
  }
  const [otpExpireTime, setOtpExpireTime] = useState(0)
  const [errors, setErrors] = useState(emptyError)
  const [otpCoolDown, setOtpCoolDown] = useState(0)

  const validateFields = (...fields) => {
    const err = {}
    if (fields.includes('email')) {
      if (!email) {
        err.email = 'Email is empty'
      } else if (!validator.isEmail(email)) {
        err.email = 'Email is invalid'
      } else {
        err.email = null
      }
    }
    if (fields.includes('otp')) {
      if (!otp) {
        err.otp = 'OTP is empty'
      } else {
        err.otp = null
      }
    }
    if (fields.includes('password')) {
      if (!password) {
        err.password = 'Password is empty'
      } else {
        err.password = null
      }
    }
    if (fields.includes('confirmPassword')) {
      if (!confirmPassword) {
        err.confirmPassword = 'Confirm password is empty'
      } else if (confirmPassword !== password)
        err.confirmPassword = 'Passwords do not match'
      else {
        err.confirmPassword = null
      }
    }
    setErrors((ps) => ({ ...ps, ...err }))
    return err
  }
  const requestOtp = async () => {
    setRequestLoading(true)
    const vdata = validateFields('email')
    if (!Object.values(vdata).every((i) => i === null)) {
      return setRequestLoading(false)
    }
    const res = await resetUserPassword('request-otp', { email })
    if (res) {
      setRequestLoading(false)

      if (res.status === 200) {
        setStage('otp')
        let time = new Date().getTime()
        setOtpExpireTime(time + 30 * 1000)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
      }
    }
  }

  const verifyOtp = async () => {
    setVerifyLoading(true)
    const vdata = validateFields('email', 'otp', 'password', 'confirmPassword')
    if (!Object.values(vdata).every((i) => i === null)) {
      return setVerifyLoading(false)
    }

    const res = await resetUserPassword('verify-otp', { email, otp, password })
    if (res) {
      setVerifyLoading(false)
      if (res.status === 200) {
        setStage('success')
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
      }
    }
  }

  useEffect(() => {
    if (otpExpireTime) {
      const interval = setInterval(() => {
        if (new Date().getTime() < otpExpireTime) {
          setOtpCoolDown((p) => p + 1)
        } else {
          setOtpCoolDown(0)
          setOtpExpireTime(null)
          return () => clearInterval(interval)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [otpExpireTime])
  return (
    <div className="login-body">
      <Toast ref={toast} />

      <div className="w-full">
        <div className="p-d-flex h-full p-ai-center p-jc-center">
          <div className="card max-w-30 p-m-4">
            <div className="p-d-flex p-jc-between p-ai-center">
              <h3 className="p-m-0">Reset Password</h3>
              <div className="p-d-flex p-ai-center">
                <img
                  src={`assets/layout/images/logo-${
                    props.colorScheme === 'light' ? 'dark' : 'light'
                  }.png`}
                  className="login-logo p-mr-2"
                  alt="login-logo"
                  height="25px"
                />
                <img
                  src={`assets/layout/images/appname-${
                    props.colorScheme === 'light' ? 'dark' : 'light'
                  }.png`}
                  className="login-appname"
                  alt="login-appname"
                  height="25px"
                />
              </div>
            </div>
            <hr />

            <div className="p-formgrid">
              {stage === 'email' && (
                <>
                  <div className="p-field p-fuild">
                    <label>Email</label>
                    <InputText
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter Email"
                      className={classNames('w-full', {
                        'p-invalid': errors?.email,
                      })}
                    />
                    {errors?.email && (
                      <small className="p-error block">{errors?.email}</small>
                    )}
                  </div>
                  <Link to="/login" className="p-d-flex p-link p-mb-3">
                    Open Login?
                  </Link>
                  <Button
                    label={requestLoading ? 'Submitting' : 'Submit'}
                    loading={requestLoading}
                    disabled={requestLoading}
                    className="w-full"
                    icon="pi pi-check"
                    onClick={() => requestOtp()}
                  />
                </>
              )}
              {stage === 'otp' && (
                <>
                  <p className="opacity-50">Email: {email}</p>
                  <div className="p-field p-fuild">
                    <label>OTP</label>
                    <InputText
                      type="password"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      className={classNames('w-full', {
                        'p-invalid': errors?.otp,
                      })}
                    />
                    {errors?.otp && (
                      <small className="p-error block">{errors?.otp}</small>
                    )}
                  </div>
                  <div className="p-field p-fuild">
                    <label>New Password</label>
                    <InputText
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter New Password"
                      className={classNames('w-full', {
                        'p-invalid': errors?.password,
                      })}
                    />
                    {errors?.password && (
                      <small className="p-error block">
                        {errors?.password}
                      </small>
                    )}
                  </div>
                  <div className="p-field p-fuild">
                    <label>Confirm Password</label>
                    <InputText
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      placeholder="Confirm Password"
                      className={classNames('w-full', {
                        'p-invalid': errors?.confirmPassword,
                      })}
                    />
                    {errors?.confirmPassword && (
                      <small className="p-error block">
                        {errors?.confirmPassword}
                      </small>
                    )}
                  </div>
                  {otpExpireTime ? (
                    <div className="p-field">
                      <Button
                        label={`Request OTP again in ${30 - otpCoolDown} sec`}
                        className="p-button-text"
                        disabled
                      />
                    </div>
                  ) : (
                    <div className="p-field">
                      <Button
                        label={otp.requesting ? 'Requesting' : 'Request OTP'}
                        className="p-button-text"
                        onClick={requestOtp}
                        loading={otp.requesting}
                      />
                    </div>
                  )}
                  <Button
                    label={verifyLoading ? 'Submitting' : 'Submit'}
                    loading={verifyLoading}
                    disabled={verifyLoading}
                    icon="pi pi-check"
                    className="w-full"
                    onClick={verifyOtp}
                  />
                </>
              )}
              {stage === 'success' && (
                <>
                  <p>
                    <li className="pi pi-check p-mr-2" />
                    Password Reset Successful
                    <br />
                    <br />
                    Your password has been successfully reset. You can now log
                    in to your account using your new password.
                  </p>
                  <Link to="/login">
                    <Button
                      label="Open Login"
                      icon="pi pi-sign-in"
                      className="p-button-text"
                    />
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
