import axios from 'axios'
import endpoints from './endpoints'

export const getTrackingIds = async ({ orderId, orderProductId }) => {
  try {
    const res = await axios.get(endpoints.tracking.index, {
      params: {
        orderId,
        orderProductId,
      },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const addTrackinIds = async (orderId, payload) => {
  try {
    const res = await axios.post(endpoints.tracking.index + orderId, {
      trackingDetailsAry: [...payload],
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const removeTrackingId = async (
  orderId,
  id,
  trackingid,
  trackinglink
) => {
  try {
    var data = JSON.stringify({
      id,
      trackingid,
      trackinglink,
    })
    const res = await axios.delete(endpoints.tracking.index + orderId, { data })
    return res
  } catch (error) {
    return error.response
  }
}

export const resendTrackingId = async (productId) => {
  try {
    const res = await axios.put(endpoints.tracking.index + productId)
    return res
  } catch (error) {
    return error.response
  }
}
export const reshipTrackingId = async (id) => {
  try {
    const res = await axios.put(endpoints.tracking.reship + id)
    return res
  } catch (error) {
    return error.response
  }
}
