import React, { useRef, useState, useEffect } from 'react'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { AutoComplete } from 'primereact/autocomplete'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { useGlobalContext } from '../../../context'
import { handleObjChange } from '../../../functions/setter'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getAllCategoryDetails, postNewDACIssue, searchByOrderOrName } from '../../../api'

export default function AddDACRecord({ dialogVisible, setDialogVisible, setAllOpenDACIssues }) {
  const toast = useRef(null)
  const { user, agents } = useGlobalContext()

  const emptyDACRecord = {
    invoiceId: '',
    issueType: '',
    issueCategory: '',
    customerName: '',
    customerId: '',
    issueSubCategory: '',
    description: '',
    orderAgent: '',
    assignTo: '',
  }

  const emptyErr = {
    state: false,
    errors: [],
  }

  //* States Start
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [newDACRecord, setNewDACRecord] = useState(emptyDACRecord)
  const [issuesCategories, setIssuesCategories] = useState([])
  const [searchAddressKeyword, setSearchAddressKeyword] = useState('')
  const [avaliableAddressInDb, setAvaliableAddressInDb] = useState([])
  const [filteredAddressInDbDisplayAry, setFilteredAddressInDbDisplayAry] = useState([])
  const [loading, setLoading] = useState(false)
  //* States End

  //* Function For Autocomplete
  const searchOrderAddressOnInput = async (event) => {
    setTimeout(async () => {
      let _filteredOptions
      if (!event.query.trim().length) {
        _filteredOptions = [...avaliableAddressInDb]
      } else {
        let dbEData = await getAvailableAddress()
        _filteredOptions = dbEData.map((item) => {
          item.option = `${
            item.orderid
          } | ${item.fname.trim()} ${item.lname.trim()} | ${item.addressline1.trim()} | ${
            item.country
          }`
          return item
        })
      }
      setFilteredAddressInDbDisplayAry(_filteredOptions)
    }, 500)
  }

  //* Handler to fetch the available addresses(Used by searchOrderAddressOnInput function above)
  const getAvailableAddress = async () => {
    const res = await searchByOrderOrName(searchAddressKeyword)
    if (res && res.status === 200) {
      let data = res.data || []
      setAvaliableAddressInDb(data)
      return data
    } else {
      return []
    }
  }

  //* Function to setValues when you select an address
  const onAddressSelect = (value) => {
    handleObjChange(setNewDACRecord, 'invoiceId', value.orderid)
    if (value.masterid) {
      handleObjChange(setNewDACRecord, 'orderAgent', value.masterid)
      if (['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role)) {
        handleObjChange(setNewDACRecord, 'assignTo', value.masterid)
      }
    }
    if (user.role === 'agent') {
      handleObjChange(setNewDACRecord, 'assignTo', user.id)
    }
    if (value.custid && Number(value.custid)) {
      handleObjChange(setNewDACRecord, 'customerId', value.custid)
    }
    let userName = `${value.fname.trim()} ${value.lname.trim()}`
    handleObjChange(setNewDACRecord, 'customerName', userName)
    setSearchAddressKeyword(value.orderid)
  }

  const fetchMainCategories = async () => {
    const res = await getAllCategoryDetails()
    if (res && res.status === 200) {
      setIssuesCategories(res.data)
    }
  }
  useEffect(() => {
    fetchMainCategories()
  }, [])

  const validateNewRecord = () => {
    const err = []
    const {
      invoiceId,
      issueType,
      issueCategory,
      issueSubCategory,
      description,
      assignTo,
      customerName,
    } = newDACRecord

    if (!invoiceId) {
      err.push('You need to select an order')
    }
    if (!issueType) {
      err.push('You need to select an issue type')
    }
    if (!issueCategory) {
      err.push('Selecting Issue Category is mandatory')
    }
    if (!issueSubCategory) {
      err.push('Selecting Issue Sub Category is manadatory')
    }
    if (!description?.trim()) {
      err.push('Description is invalid')
    }
    if (!customerName) {
      err.push('Customer name is invalid')
    }
    if (!assignTo) {
      err.push('You need to assign the issue to an agent')
    }
    return err
  }

  //* Function to create new DAC Issue
  const createNewDACIssue = async () => {
    const errs = validateNewRecord()
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading(true)
    const res = await postNewDACIssue(newDACRecord)
    if (res) {
      setLoading(false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.data.message,
        })
        onHideIssue()
        setAllOpenDACIssues((prevSate) => [res.data.record[0], ...prevSate])
      }
    }
  }

  const onHideIssue = () => {
    setSearchAddressKeyword('')
    setNewDACRecord(emptyDACRecord)
    setNewRecordError(emptyErr)
    setDialogVisible(false)
    setLoading(false)
  }

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Report New Issue"
        visible={dialogVisible}
        style={{ width: '40%', minWidth: '400px' }}
        footer={
          <div>
            <Button
              loading={loading}
              label="Submit"
              className="p-button-submit"
              icon="pi pi-check"
              onClick={() => {
                createNewDACIssue()
              }}
            />
            <Button
              label="Cancel"
              className="p-button-text"
              icon="pi pi-times"
              onClick={() => {
                onHideIssue()
              }}
            />
          </div>
        }
        onHide={() => {
          onHideIssue()
        }}
      >
        <div className="p-grid gap-1">
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block">
              Enter Invoice Number <sup style={{ color: 'red' }}>*</sup>
            </label>
            <AutoComplete
              placeholder="Enter Invoice Number/First Name"
              autoHighlight
              value={searchAddressKeyword}
              field="option"
              optionLabel="orderid"
              suggestions={filteredAddressInDbDisplayAry}
              completeMethod={searchOrderAddressOnInput}
              onSelect={(e) => {
                handleObjChange(setNewDACRecord, 'orderAgent', '')
                handleObjChange(setNewDACRecord, 'assignTo', '')
                onAddressSelect(e.value)
              }}
              onChange={(e) => setSearchAddressKeyword(e.value)}
              delay={500}
              panelStyle={{
                width: '30%',
                minWidth: '400px',
              }}
            />
            {newDACRecord.invoiceId && (
              <p className="p-mt-2">
                Click on the invoice to view order details
                <Link
                  to={`/orders?invId=${newDACRecord.invoiceId}`}
                  target="_blank"
                  className="p-ml-2"
                >
                  <span>{newDACRecord.invoiceId}</span>
                </Link>
              </p>
            )}
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block">
              Issue Type <sup style={{ color: 'red' }}>*</sup>
            </label>
            <Dropdown
              options={[
                {
                  issueType: 'Dispute',
                },
                {
                  issueType: 'Chargeback',
                },
              ]}
              optionLabel="issueType"
              optionValue="issueType"
              placeholder="Select Issue Type"
              value={newDACRecord.issueType}
              onChange={(e) => {
                handleObjChange(setNewDACRecord, 'issueType', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block">
              Issue Category <sup style={{ color: 'red' }}>*</sup>
            </label>
            <Dropdown
              options={issuesCategories}
              optionLabel="mainIssue"
              optionValue="mainIssue"
              placeholder="Select Issue Category"
              value={newDACRecord.issueCategory}
              onChange={(e) => {
                handleObjChange(setNewDACRecord, 'issueCategory', e.value)
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block">
              Issue Sub-Category <sup style={{ color: 'red' }}>*</sup>
            </label>
            <Dropdown
              options={
                newDACRecord.issueCategory
                  ? issuesCategories[
                      issuesCategories.findIndex(
                        (val) => val.mainIssue === newDACRecord.issueCategory
                      )
                    ]?.subIssues
                  : []
              }
              optionLabel="subIssue"
              optionValue="subIssue"
              placeholder="Select Issue Sub-Category"
              value={newDACRecord.issueSubCategory}
              onChange={(e) => {
                handleObjChange(setNewDACRecord, 'issueSubCategory', e.value)
              }}
              panelStyle={{
                width: '30%',
                minWidth: '400px',
              }}
            />
          </div>
          <div className="p-field p-fluid p-col-12 p-mb-0">
            <label className="p-d-block">
              Description <sup style={{ color: 'red' }}>*</sup>
            </label>
            <InputTextarea
              rows={5}
              cols={30}
              placeholder="Describe your problem here..."
              value={newDACRecord.description}
              onChange={(e) => {
                handleObjChange(setNewDACRecord, 'description', e.target.value)
              }}
            />
          </div>
          {newDACRecord.orderAgent && (
            <div className="p-field p-fluid p-col-12 p-md-5 p-mb-0">
              <label className="p-d-block">Order Agent</label>
              <h6 className="p-mt-3">
                {agents.find((agent) => agent.id === newDACRecord.orderAgent)?.username}
              </h6>
            </div>
          )}
          {(['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) ||
            user.id === 150) && (
            <div className="p-field p-fluid p-col-12 p-md-5 p-mb-0">
              <label className="p-d-block">
                Assign To <sup style={{ color: 'red' }}>*</sup>
              </label>
              <Dropdown
                placeholder="Agent"
                options={user.members}
                // disabled={user.role === 'agent'}
                optionLabel="username"
                optionValue="id"
                showClear
                filter
                filterBy="username"
                value={newDACRecord.assignTo}
                onChange={(e) => {
                  handleObjChange(setNewDACRecord, 'assignTo', e.value)
                }}
              />
            </div>
          )}

          {newRecordError.state && (
            <>
              {newRecordError.errors.map((err, idx) => {
                return (
                  <div key={idx} className="p-fluid p-filed p-col-12">
                    <Message text={err} severity="warn" sticky={true} />
                  </div>
                )
              })}
              <div className="p-fluid p-field p-col-12">
                <Button
                  type="button"
                  onClick={() => {
                    setNewRecordError(emptyErr)
                  }}
                  icon="pi pi-times"
                  label="Clear Warnings"
                  className="p-button-secondary"
                ></Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </>
  )
}
