import { format, subDays } from 'date-fns'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { Checkbox } from 'primereact/checkbox'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import React, { useState } from 'react'
import { postWebsiteBanner, uploadFileToS3 } from '../../../api'
import { useGlobalContext } from '../../../context'
import { handleObjChange } from '../../../functions/setter'
import UploadImage from '../../mini/UploadImage'

const emptyErr = {
  state: false,
  errors: [],
}

const emptyImageState = {
  url: '',
  file: null,
}

const emptyBannerObj = {
  bannerName: '',
  ctaLink: '',
  validFrom: '',
  validTo: '',
  bannerImage: '',
  active: false,
}

export default function AddBannerDialog({
  newBannerDialogVisible,
  setNewBannerDialogVisible,
  setBannerRecords,
  setTableLoading,
}) {
  const { toast } = useGlobalContext()
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const [banner, setBanner] = useState(emptyBannerObj)
  const [bannerImage, setBannerImage] = useState(emptyImageState)
  const [loading, setLoading] = useState(false)

  const validateNewRecord = () => {
    const errs = []
    const { bannerName, ctaLink, validFrom, validTo } = banner
    if (!bannerName) {
      errs.push('Banner name is mandatory')
    }
    if (!ctaLink) {
      errs.push('Please enter a link')
    }
    if (!validFrom) {
      errs.push('Valid from cannot be empty')
    }
    if (!validTo) {
      errs.push('Valid to cannot be empty')
    }
    if (!bannerImage.url) {
      errs.push('Please select a banner')
    }
    return errs
  }

  const createNewBanner = async () => {
    try {
      const errs = validateNewRecord()
      if (errs.length > 0) {
        setLoading(false)
        setNewRecordError({ state: true, errors: errs })
        return
      }
      setLoading(true)
      const uploadPromises = []
      const data = banner
      if (bannerImage.file) {
        uploadPromises.push(
          uploadFileToS3({ file: bannerImage.file }).then(
            (res) => (data.bannerImage = res.data.filePath)
          )
        )
      }
      await Promise.all(uploadPromises)
      setTableLoading(true)
      const res = await postWebsiteBanner(data)
      if (res) {
        setTableLoading(false)
        setLoading(false)
        if (res.status === 201) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
            life: 3000,
          })
          setBannerRecords((ps) => [res.data.record, ...ps])
          onHide()
        }
      }
    } catch (error) {
      setLoading(false)
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: error.message,
        life: 3000,
      })
    }
  }

  const onHide = () => {
    setNewBannerDialogVisible(false)
    setBanner(emptyBannerObj)
    setBannerImage(emptyImageState)
    setNewRecordError(emptyErr)
  }

  const footer = () => {
    return (
      <div>
        <Button
          label="Cancel"
          icon="pi pi-times"
          onClick={() => onHide()}
          className="p-button-secondary"
        />
        <Button
          label="Submit"
          icon="pi pi-save"
          className="p-button-primary"
          onClick={createNewBanner}
          loading={loading}
        />
      </div>
    )
  }

  return (
    <Dialog
      header="Add Banner"
      visible={newBannerDialogVisible}
      style={{ width: '50vw' }}
      onHide={onHide}
      footer={footer}
    >
      <div className="p-grid p-formgrid p-ai-center p-col-12">
        <div className="p-field p-fluid p-col-12">
          <label htmlFor="name">Name</label>
          <InputText
            id="name"
            placeholder="Enter Banner Name"
            value={banner.bannerName}
            onChange={(e) => {
              handleObjChange(setBanner, 'bannerName', e.target.value)
            }}
          />
        </div>
        <div className="p-field p-fluid p-col-12">
          <label htmlFor="name">CTA Link</label>
          <InputText
            id="ctaLink"
            placeholder="Add Link"
            value={banner.slug}
            onChange={(e) => handleObjChange(setBanner, 'ctaLink', e.target.value)}
          />
        </div>
        <div className="p-field p-fluid p-col-12 p-lg-6">
          <label className="p-d-block" htmlFor="validFrom">
            Valid From
          </label>
          <Calendar
            id="validFrom"
            placeholder="Valid From"
            minDate={subDays(new Date(), 0)}
            value={banner.validFrom}
            onChange={(e) => {
              const date = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : ''
              handleObjChange(setBanner, 'validFrom', date)
            }}
          />
        </div>
        <div className="p-field p-fluid p-col-12 p-lg-6 ">
          <label className="p-d-block" htmlFor="ValidTo">
            Valid To
          </label>
          <Calendar
            id="validTo"
            placeholder="Valid To"
            minDate={subDays(new Date(), 0)}
            value={banner.validTo}
            onChange={(e) => {
              const date = e.value ? format(new Date(e.value), 'yyyy-MM-dd') : ''
              handleObjChange(setBanner, 'validTo', date)
            }}
          />
        </div>
        <div className="p-field p-fluid p-col-12 p-lg-6">
          <label htmlFor="image" className="p-d-block">
            Banner
          </label>
          <UploadImage imageState={bannerImage} setImageState={setBannerImage} />
        </div>
        <div className="p-field p-fluid p-col-12 p-lg-6">
          <label
            className="p-d-none p-d-lg-inline"
            htmlFor="active"
            style={{ color: 'transparent' }}
          >
            Active
          </label>
          <div className="p-d-flex p-ai-center">
            <label className="p-d-block p-mr-2" htmlFor="active">
              Active :
            </label>
            <Checkbox
              onChange={(e) => handleObjChange(setBanner, 'active', e.checked)}
              checked={banner.active}
            ></Checkbox>
          </div>
        </div>
      </div>
      {newRecordError.state && (
        <>
          {newRecordError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setNewRecordError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
    </Dialog>
  )
}
