/*eslint-disable */
export const urlBase64ToUint8Array = (base64String) => {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const checkIfSubscriptionExistsSw = async () => {
  const serviceWorkerReg = await navigator.serviceWorker.ready
  console.log(serviceWorkerReg)
  let subscription = await serviceWorkerReg.pushManager.getSubscription()
  let result = !subscription ? false : true
  return result
}

export const registerNotificationSw = async () => {
  if ('serviceWorker' in navigator) {
    let url = process.env.PUBLIC_URL + '/sw.js'
    const reg = await navigator.serviceWorker.register(url, { scope: '/' })
    console.log('Notification registered')
    return reg
  }
  throw Error('serviceworker not supported')
}
