import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Message } from 'primereact/message'
import React, { useCallback, useEffect, useState } from 'react'
import { useGlobalContext } from '../../../context'
import useQuery from '../../../hooks/useQuery'
import { getShippingCompany, updateShippingCompany } from '../../../api'
import { InputText } from 'primereact/inputtext'
import { handleObjChange } from '../../../functions/setter'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { InputNumber } from 'primereact/inputnumber'

const EditShippingCompanyDetailsDialog = ({ fetchRecords }) => {
  const { toast } = useGlobalContext()
  const history = useHistory()
  const query = useQuery()
  const code = query.get('edit_st_company')
  // empty objects for state
  const emptyRecord = {
    id: '',
    companyTag: '',
    companyName: '',
    companyProvider: '',
    companyLink: '',
    createdAt: '',
    createdBy: '',
    expectedMinDays: 0,
    expectedMaxDays: 0,
    arrivalMinDays: 0,
    arrivalMaxDays: 0,
  }
  const emptyErr = {
    state: false,
    errors: [],
  }
  // empty objects for state
  const [record, setRecord] = useState(emptyRecord)
  const [newRecordError, setNewRecordError] = useState([])
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchRecord = useCallback(async () => {
    if (!code) return
    const res = await getShippingCompany(code)
    if (res && res.status === 200) {
      setRecord(res.data)
      setVisible(true)
    }
  }, [code])
  useEffect(() => {
    fetchRecord()
  }, [fetchRecord])

  const validateUpdateRecord = () => {
    const err = []
    const {
      companyProvider,
      companyName,
      companyLink,
      expectedMinDays,
      expectedMaxDays,
      arrivalMinDays,
      arrivalMaxDays,
    } = record
    if (!companyProvider) {
      err.push('Shipping company cannot be empty')
    }
    if (!companyName) {
      err.push('Shipper name cannot be empty')
    }
    if (!companyLink) {
      err.push('Shipping link cannot be empty')
    }
    if (!expectedMinDays) {
      err.push('Expected min days cannot be empty')
    }
    if (!expectedMaxDays) {
      err.push('Expected max days cannot be empty')
    }
    if (!arrivalMinDays) {
      err.push('Arrival max days cannot be empty')
    }
    if (!arrivalMaxDays) {
      err.push('Arrival min days cannot be empty')
    }
    return err
  }

  const updateRecord = async () => {
    const errs = validateUpdateRecord()
    if (errs.length) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      return
    }
    setLoading(true)
    const res = await updateShippingCompany(record)
    if (res) {
      setLoading(false)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Updated Successfully',
          detail: res.data.message,
        })
        fetchRecords()
        hideDialog()
      }
    }
  }

  const hideDialog = () => {
    history.push('/website/logistics')
    setRecord(emptyRecord)
    setNewRecordError(emptyErr)
    setLoading(false)
    setVisible(false)
  }

  return (
    <Dialog
      header={
        <div>
          <h5 style={{ color: '#4B8AF4' }}>{'Edit Shipping Company ' + code}</h5>
        </div>
      }
      visible={visible}
      onHide={hideDialog}
      className="w-full max-w-40"
      footer={
        <div>
          <Button
            label="Cancel"
            className="p-button-text"
            disabled={loading}
            icon="pi pi-times"
            onClick={() => hideDialog()}
          />
          <Button
            label={loading ? 'Saving' : 'Save Changes'}
            loading={loading}
            icon="pi pi-save"
            onClick={() => updateRecord()}
          />
        </div>
      }
    >
      {/* <ScrollPanel style={{ width: '100%', height: '310px' }}> */}
      <div className="p-grid">
        <div className="p-fluid p-field p-col-12">
          <label className="p-d-block" htmlFor="companyProvider">
            Shipping Company
          </label>
          <InputText
            id="companyProvider"
            placeholder="Shipping Company"
            value={record.companyProvider}
            onChange={(e) => {
              const { value } = e.target
              handleObjChange(setRecord, 'companyProvider', value)
            }}
          />
        </div>
        <div className="p-fluid p-field p-col-12">
          <label className="p-d-block" htmlFor="companyName">
            Shippers Name
          </label>
          <InputText
            id="companyName"
            placeholder="Shipper Name"
            value={record.companyName}
            onChange={(e) => {
              const { value } = e.target
              handleObjChange(setRecord, 'companyName', value)
            }}
          />
        </div>
        <div className="p-fluid p-field p-col-12">
          <label className="p-d-block" htmlFor="companyLink">
            Shipper Link
          </label>
          <InputText
            id="companyLink"
            placeholder="Shippers Link"
            value={record.companyLink}
            onChange={(e) => {
              const { value } = e.target
              handleObjChange(setRecord, 'companyLink', value)
            }}
          />
        </div>
        <div className="p-fluid p-field p-col-6">
          <p className="p-mb-2">Arrival Min Days</p>{' '}
          <InputNumber
            placeholder="Enter minimum arrival days"
            value={record.arrivalMinDays}
            onChange={(e) => handleObjChange(setRecord, 'arrivalMinDays', e.value)}
          />
        </div>
        <div className="p-fluid p-field p-col-6">
          <p className="p-mb-2">Arrival Max Days</p>{' '}
          <InputNumber
            placeholder="Enter maximum arrival days"
            value={record.arrivalMaxDays}
            onChange={(e) => handleObjChange(setRecord, 'arrivalMaxDays', e.value)}
          />
        </div>
        <div className="p-fluid p-field p-col-6">
          <p className="p-mb-2">Expected Min Days</p>{' '}
          <InputNumber
            placeholder="Enter expected minimum days"
            value={record.expectedMinDays}
            onChange={(e) => handleObjChange(setRecord, 'expectedMinDays', e.value)}
          />
        </div>
        <div className="p-fluid p-field p-col-6">
          <p className="p-mb-2">Expected Max Days</p>{' '}
          <InputNumber
            placeholder="Enter expected maximum days"
            value={record.expectedMaxDays}
            onChange={(e) => handleObjChange(setRecord, 'expectedMaxDays', e.value)}
          />
        </div>
      </div>
      {newRecordError.state && (
        <>
          {newRecordError.errors.map((err, idx) => {
            return (
              <div key={idx} className="p-fluid p-filed p-col-12">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            )
          })}
          <div className="p-fluid p-field p-col-12">
            <Button
              type="button"
              onClick={() => {
                setNewRecordError(emptyErr)
              }}
              icon="pi pi-times"
              label="Clear Warnings"
              className="p-button-secondary"
            ></Button>
          </div>
        </>
      )}
      {/* </ScrollPanel> */}
    </Dialog>
  )
}

export default EditShippingCompanyDetailsDialog
