import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'

import React, { useState } from 'react'
import { assignDates, dateFilters } from '../../functions/myDateFns'
// MyDatesPicker component to manage the date picker
export default function MyDatesPicker({
  code,
  setCode,
  rangeDates,
  setRangeDates,
  setFilterDates,
  disabled,
}) {
  /**
   * code @type {string} - string to check code
   * setCode @type {function} - function to set code, code defined in assignDates function
   * rangeDates @type {array} - array to check range dates
   * setRangeDates @type {function} - function to set range dates [dateFrom, dateTo]
   * setFilterDates @type {function} - function to set filter dates {dateFrom, dateTo}
   **/

  const [monthDates, setMonthDates] = useState(null)

  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
        disabled={disabled}
      />
    )
  }

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
        disabled={disabled}
      />
    )
  }

  return (
    <div className="w-full p-d-flex gap-1by2">
      <Dropdown
        placeholder="Select Date"
        options={dateFilters}
        value={code}
        optionLabel="name"
        optionValue="code"
        onChange={(e) => {
          setCode(e.value)
          const dts = assignDates(e.value)
          if (dts) {
            setFilterDates(dts)
          }
        }}
        className="flex-1"
        disabled={disabled}
      />
      {code === 'daterange' && (
        <div className="p-d-flex gap-1by2 p-ai-center">
          <label htmlFor="drc" className="p-text-bold p-mr-2">
            Date Range
          </label>
          <Calendar
            id="drc"
            placeholder="Date Range"
            dateFormat="dd/mm/yy"
            value={rangeDates}
            onChange={(e) => {
              let val = e.value
              setRangeDates(val)
              if (val[0] && val[1]) {
                const dateFrom = startOfDay(val[0]),
                  dateTo = endOfDay(val[1])
                setFilterDates({
                  dateFrom,
                  dateTo,
                })
              }
            }}
            selectionMode="range"
            showClear
            readOnlyInput
            showIcon
            monthNavigator
            yearNavigator
            yearRange="2015:2030"
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            className="flex-1"
            disabled={disabled}
          />
        </div>
      )}
      {code === 'monthyear' && (
        <div className="p-d-flex p-ai-center p-flex-wrap">
          <label htmlFor="myc" className="p-text-bold p-mr-2">
            Month & Year
          </label>
          <Calendar
            id="myc"
            placeholder="Month & Year"
            value={monthDates}
            onChange={(e) => {
              let val = e.value
              setMonthDates(val)
              if (val) {
                const dateFrom = startOfMonth(val),
                  dateTo = endOfMonth(val)

                setRangeDates([dateFrom, dateTo])
                setFilterDates({
                  dateFrom,
                  dateTo,
                })
              }
            }}
            view="month"
            dateFormat="MM/yy"
            className="flex-1"
            disabled={disabled}
          />
        </div>
      )}
    </div>
  )
}
