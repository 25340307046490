import { format, isValid } from 'date-fns'
import React from 'react'

export const CustomerDetails = ({ enquiryData }) => {
  return (
    <div className="customerDetails ">
      <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
        <p className="highlight-dot relative p-pl-4 ">
          Customer Name : <span className="text-light">{enquiryData?.enquiry?.name}</span>
        </p>
        <p className="highlight-dot red relative p-pl-4 ">
          Email : <span className="text-light">{enquiryData?.enquiry?.email}</span>
        </p>
        <p className="highlight-dot mar relative p-pl-4 ">
          Phone : <span>{enquiryData?.enquiry?.phone}</span>
        </p>
        <p className="highlight-dot relative p-pl-4 ">
          Country : <span>{enquiryData?.enquiry?.country}</span>
        </p>
        <p className="highlight-dot org relative p-pl-4 ">
          Source : <span>{enquiryData?.enquiry?.web}</span>
        </p>
        <p className="highlight-dot navi relative p-pl-4 ">
          Date :{' '}
          <span>
            {isValid(new Date(enquiryData?.enquiry?.date)) &&
              format(new Date(enquiryData?.enquiry?.date), 'PPp')}
          </span>
        </p>
        <p className="highlight-dot pur relative p-pl-4 ">
          Page : <span>{enquiryData?.enquiry?.page}</span>
        </p>
      </div>
      <p>
        Message : <span>{enquiryData?.enquiry?.message}</span>
      </p>
      <p>
        Description :{' '}
        <span dangerouslySetInnerHTML={{ __html: enquiryData?.enquiry?.description }}></span>
      </p>
    </div>
  )
}
