import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import BackToHome from '../../components/mini/BackToHome'

export default function ErrorPage(props) {
  const history = useHistory()

  const goDashboard = () => {
    history.push('/')
  }

  return (
    <div className="exception-body error">
      <div className="exception-panel">
        <h1>ERROR</h1>
        <h3>something's went wrong</h3>
        <BackToHome />
        {/* <Button type="button" label="Go back to home" onClick={user.role === "operations" ? goOrdersPage : goDashboard}></Button> */}
      </div>
      <div className="exception-footer">
        <img
          src={`assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`}
          className="exception-logo"
          alt="exception-logo"
        />
        <img
          src={`assets/layout/images/appname-${
            props.colorScheme === 'light' ? 'dark' : 'light'
          }.png`}
          className="exception-appname"
          alt="exception-appname"
        />
      </div>
    </div>
  )
}
