import React, { useCallback, useState, useEffect } from 'react'
import OrdersDataTable from '../../components/mycomponents/OrdersDataTable'
import { getAllOrder } from '../../api'
import { assignDates, dateFilters } from '../../functions/myDateFns'
const orderStatusOptions = ['Bin']
export default function BinOrdersPage() {
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[0].code))
  const [source, setSource] = useState(null)
  const [orders, setOrders] = useState(null)
  const [ordersMeta, setOrdersMeta] = useState({})

  const [tableLoading, setTableLoading] = useState(false)

  const getOrders = useCallback(async () => {
    setTableLoading(true)
    const res = await getAllOrder({ status: orderStatusOptions, ...dateFilter, source })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        const data = await res.data.orders
        setOrders(data)
        setOrdersMeta((ps) => ({
          ...ps,
          totalSales: res.data.totalSales,
          totalOrders: res.data.totalOrders,
        }))
      }
    }
  }, [dateFilter, source])

  useEffect(() => {
    getOrders()
  }, [getOrders])
  return (
    <OrdersDataTable
      dateFilter={dateFilter}
      setDateFilter={setDateFilter}
      source={source}
      setSource={setSource}
      ordersMeta={ordersMeta}
      pageUrl={'/orders/bin'}
      orders={orders}
      setOrders={setOrders}
      getOrders={getOrders}
      tableLoading={tableLoading}
      setTableLoading={setTableLoading}
      orderStatusOptions={orderStatusOptions}
    />
  )
}
