import axios from 'axios'
import { endOfDay, endOfToday, startOfDay, subDays } from 'date-fns'
import endpoints from './endpoints'

const getOrderExcel = async (params) => {
  try {
    let myParams = { ...params } || {}

    /* DATE FORMATING */
    let dateFrom = params.dateFrom
    let dateTo = params.dateTo
    let status = params.status || ''
    let source = params.source || ''
    let website = params.website || ''

    dateFrom = dateFrom ? startOfDay(dateFrom).toISOString() : null
    dateTo = dateTo ? endOfDay(dateTo).toISOString() : null

    const res = await axios.get(endpoints.excel.order, {
      params: {
        ...myParams,
        dateFrom,
        dateTo,
      },
    })
    /* CREATING BLOB FROM RES */
    const blob = new Blob([res.data], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url

    let filename = 'orders_'
    if (status) {
      filename = filename + status + '_'
    }
    if (source) {
      filename = filename + source + '_'
    }
    if (website) {
      filename = filename + website + '_'
    }
    filename = filename + `${dateFrom.slice(0, 10)}-${dateTo.slice(0, 10)}.csv`
    link.download = filename.toLowerCase()
    link.click()
    window.URL.revokeObjectURL(url)
    return true
  } catch (error) {
    return error.response
  }
}

const getOrderProductExcel = async (params) => {
  try {
    let myParams = { ...params } || {}

    /* DATE FORMATING */
    let dateFrom = params.dateFrom
    let dateTo = params.dateTo
    let status = params.status || ''
    let source = params.source || ''
    let website = params.website || ''

    dateFrom = dateFrom ? startOfDay(dateFrom).toISOString() : null
    dateTo = dateTo ? endOfDay(dateTo).toISOString() : null

    const res = await axios.get(endpoints.excel.orderProduct, {
      params: {
        ...myParams,
        dateFrom,
        dateTo,
      },
    })

    /* CREATING BLOB FROM RES */
    const blob = new Blob([res.data], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url

    let filename = 'order_products_'
    if (status) {
      filename = filename + status + '_'
    }
    if (source) {
      filename = filename + source + '_'
    }
    if (website) {
      filename = filename + website + '_'
    }
    filename = filename + `${dateFrom.slice(0, 10)}-${dateTo.slice(0, 10)}.csv`
    link.download = filename.toLowerCase()
    link.click()
    window.URL.revokeObjectURL(url)
    return true
  } catch (error) {
    return error.response
  }
}

export { getOrderExcel, getOrderProductExcel }
