import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { format, startOfDay, subDays, subMonths, subYears } from 'date-fns'
import { Column } from 'jspdf-autotable'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import { Message } from 'primereact/message'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  addReview,
  deleteReview,
  deleteReviewImage,
  getReview,
  getReviews,
  updateReview,
} from '../../api'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import ExportButton from '../../components/mini/ExportButton'
import RatingStars from '../../components/mini/RatingStars'
import RequiredMessage from '../../components/mini/RequiredMessage'
import ChangeHistorySidebar from '../../components/mycomponents/ChangeHistorySidebar'
import { useGlobalContext } from '../../context'
import us_states from '../../data/us_states'
import { dtFilenameDate } from '../../functions/myDateFns'
import { handleObjChange } from '../../functions/setter'
import generateS3Path from '../../functions/generateS3Path'

export default function ReviewsPage() {
  const dt = useRef(null)
  const { user } = useGlobalContext()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    reviewType: { value: null, matchMode: FilterMatchMode.EQUALS },
    rating: { value: null, matchMode: FilterMatchMode.EQUALS },
    productName: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    // source: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const rImg1 = useRef(null)
  const rImg2 = useRef(null)
  const rImg3 = useRef(null)
  const rImg4 = useRef(null)

  const reImg1 = useRef(null)
  const reImg2 = useRef(null)
  const reImg3 = useRef(null)
  const reImg4 = useRef(null)
  // const sourceTypeOptions = ['GEN', 'MAN']
  const userTypeOptions = ['Existing', 'New']
  const countryOptions = ['U.S.A', 'U.K']

  const ratingOptions = [
    { name: '1 Star', value: 1 },
    { name: '2 Stars', value: 2 },
    { name: '3 Stars', value: 3 },
    { name: '4 Stars', value: 4 },
    { name: '5 Stars', value: 5 },
  ]

  const sortByOptions = [
    'Top Reviews',
    'Recent Reviews',
    'Reviews With Images',
    'Oldest Reviews',
    'Highest Reviews',
    'Lowest Reviews',
    'Reviews With Comment',
  ]

  const dateOptions = [
    { name: 'Today', code: 'D1' },
    { name: '1 Week', code: 'W1' },
    { name: '2 Weeks', code: 'W2' },
    { name: '1 Month', code: 'M1' },
    { name: '2 Months', code: 'M2' },
    { name: '3 Months', code: 'M3' },
    { name: '6 Months', code: 'M6' },
    { name: '1 Year', code: 'Y1' },
    { name: '2 Years', code: 'Y2' },
    { name: '3 Years', code: 'Y3' },
  ]

  const reviewTypeOptions = ['Website Review', 'Service Review', 'Overall review', 'Product Review']
  const statusOptions = ['Approved', 'Disapproved']
  const emptyNewReviewImages = [
    {
      file: null,
      url: '',
      ref: rImg1,
    },
    {
      file: null,
      url: '',
      ref: rImg2,
    },
    {
      file: null,
      url: '',
      ref: rImg3,
    },
    {
      file: null,
      url: '',
      ref: rImg4,
    },
  ]
  const emptyEditReviewImages = [
    {
      file: null,
      url: '',
      ref: reImg1,
      data: null,
    },
    {
      file: null,
      url: '',
      ref: reImg2,
      data: null,
    },
    {
      file: null,
      url: '',
      ref: reImg3,
      data: null,
    },
    {
      file: null,
      url: '',
      ref: reImg4,
      data: null,
    },
  ]
  const emptyNewReview = {
    username: '',
    userType: userTypeOptions[0],
    country: countryOptions[0],
    state: '',
    showDate: '',
    date: '',
    rating: ratingOptions[2].value,
    review: '',
    reviewType: reviewTypeOptions[0],
    productCode: '',
    loading: false,
  }
  const emptyReview = {
    username: '',
    userType: '',
    country: '',
    state: '',
    date: '',
    rating: '',
    review: '',
    reviewType: '',
    productCode: '',
    loading: false,
    images: [],
  }

  const emptyErr = {
    state: false,
    errors: [],
  }
  const emptyDelete = {
    id: null,
    state: false,
  }

  const [newReviewError, setNewReviewError] = useState(emptyErr)
  const [editReviewError, setEditReviewError] = useState(emptyErr)

  const [filters, setFilters] = useState(emptyFilterObject)
  const [reviews, setReviews] = useState([])
  const [deleteReviewLoading, setDeleteReviewLoading] = useState(emptyDelete)
  const [deleteReviewImageLoading, setDeleteReviewImageLoading] = useState(emptyDelete)

  const [newReviewImages, setNewReviewImages] = useState(emptyNewReviewImages)
  const [editReviewImages, setEditReviewImages] = useState(emptyEditReviewImages)

  const [limit, setLimit] = useState(10)
  const [sortBy, setSortBy] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)
  const [reviewVisible, setReviewVisible] = useState(false)
  const [reviewEditEnable, setReviewEditEnable] = useState(false)

  const { productNames, toast } = useGlobalContext()

  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [newReviewData, setNewReviewData] = useState(emptyNewReview)
  const [editReviewData, setEditReviewData] = useState(emptyReview)

  const [reviewData, setReviewData] = useState(emptyReview)

  const resetReview = () => {
    setNewReviewData(emptyNewReview)
    setNewReviewImages(emptyNewReviewImages)
  }
  const onChangeFilter = (value, target) => {
    let _filters = { ...filters }
    _filters[target].value = value
    setFilters(_filters)
  }

  // const handleNewReviewChange = (target, value) => {
  //   setNewReviewData((ps) => ({ ...ps, [target]: value }))
  // }
  // const handleEditReviewChange = (target, value) => {
  //   setEditReviewData((ps) => ({ ...ps, [target]: value }))
  // }
  // const handleNewReviewImagesAdd = (e, idx) => {
  //   const files = e.target.files

  //   let file = files.item(0)
  //   const img = {
  //     file: file,
  //     url: URL.createObjectURL(file),
  //   }

  //   setNewReviewImages((ps) => [
  //     ...ps.map((val, i) => {
  //       if (i === idx) {
  //         return img
  //       }
  //       return val
  //     }),
  //   ])
  // }

  // const handleEditReviewImagesAdd = (e, idx) => {
  //   const files = e.target.files

  //   let file = files.item(0)
  //   const img = {
  //     file: file,
  //     url: URL.createObjectURL(file),
  //   }

  //   setEditReviewImages((ps) => [
  //     ...ps.map((val, i) => {
  //       if (i === idx) {
  //         return img
  //       }
  //       return val
  //     }),
  //   ])
  // }

  const handleReviewImagesAdd = (setter, e, idx) => {
    const files = e.target.files

    let file = files.item(0)
    const img = {
      file: file,
      url: URL.createObjectURL(file),
    }

    setter((ps) => [
      ...ps.map((val, i) => {
        if (i === idx) {
          return img
        }
        return val
      }),
    ])
  }

  const handleReviewImagesRemove = (idx) => {
    let ref = null
    switch (idx) {
      case 0:
        ref = rImg1
        break
      case 1:
        ref = rImg2
        break
      case 2:
        ref = rImg3
        break
      case 3:
        ref = rImg4
        break
      default:
        return
    }

    setNewReviewImages((ps) => [
      ...ps.map((val, i) => {
        if (i === idx) {
          return { file: null, url: '', ref }
        }
        return val
      }),
    ])
  }

  const handleEditReviewImagesRemove = (idx) => {
    let ref = null
    switch (idx) {
      case 0:
        ref = reImg1
        break
      case 1:
        ref = reImg2
        break
      case 2:
        ref = reImg3
        break
      case 3:
        ref = reImg4
        break
      default:
        return
    }

    setEditReviewImages((ps) => [
      ...ps.map((val, i) => {
        if (i === idx) {
          return { file: null, url: '', ref }
        }
        return val
      }),
    ])
  }

  const saveReview = async () => {
    handleObjChange(setNewReviewData, 'loading', true)
    const _reviewImages = newReviewImages.map((i) => i.file).filter((i) => i)
    const _reviewData = { ...newReviewData }
    delete _reviewData.loading
    delete _reviewData.showDate
    const res = await addReview(_reviewData, _reviewImages)
    if (res) {
      handleObjChange(setNewReviewData, 'loading', false)
      if (res.status === 201) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successful',
          detail: res.data.message,
        })
        setReviews((ps) => [res.data.review, ...ps])
        resetReview()
      }
    }
  }

  const handleReviewEditEnable = () => {
    setReviewEditEnable(true)
    setEditReviewData({ ...reviewData })
    let _editReviewImages = [...editReviewImages]
    const imgs = reviewData.images
    imgs.forEach((img, idx) => {
      _editReviewImages[idx].data = img
    })
    setEditReviewImages(_editReviewImages)
  }

  const handleDiscardReviewEdit = () => {
    setReviewEditEnable(false)
    setEditReviewData(emptyReview)
    setEditReviewImages(emptyEditReviewImages)
  }

  const handleSaveReviewEdit = async () => {
    const result = checkValid(editReviewData)
    if (result.length > 0) {
      setEditReviewError({
        state: true,
        errors: result,
      })
    } else {
      handleObjChange(setEditReviewData, 'loading', true)
      setEditReviewError(emptyErr)
      const _images = editReviewImages.filter((i) => i.file).map((i) => i.file)
      const res = await updateReview(id, _images, editReviewData, null)
      if (res) {
        handleObjChange(setEditReviewData, 'loading', false)
        if (res.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Operation Successful',
            detail: res.data.message,
          })
          res.data.review.date = new Date(res.data.review.date)
          setReviewData(res.data.review)
          setReviews((ps) =>
            [...ps].map((i) => {
              if (i.id === res.data.review.id) {
                return res.data.review
              }
              return i
            })
          )
          handleDiscardReviewEdit()
        }
      }
    }
  }

  const handleSaveReviewStatusEdit = async (rid, status) => {
    const res = await updateReview(rid, null, null, status)
    if (res.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Operation Successful',
        detail: res.data.message,
      })
      setReviews((ps) =>
        [...ps].map((i) => {
          if (i.id === res.data.review.id) {
            return res.data.review
          }
          return i
        })
      )
    }
  }

  const checkValid = (reviewToValidate) => {
    const errs = []
    const { username, userType, country, state, date, rating, review, reviewType, productCode } =
      reviewToValidate

    if (!username) {
      errs.push('Username is invalid')
    }
    if (!userType) {
      errs.push('UserType is invalid')
    }
    if (!country) {
      errs.push('Country is invalid')
    }
    if (!state) {
      errs.push('State is invalid')
    }
    if (!date) {
      errs.push('Date is invalid')
    }
    if (!rating) {
      errs.push('Rating is invalid')
    }
    if (!review) {
      errs.push('Review is invalid')
    }
    if (!reviewType) {
      errs.push('Review-Type is invalid')
    }
    if (reviewType === 'Product Review' && !productCode) {
      errs.push('Product is invalid')
    }

    return errs
  }

  const handleSubmit = () => {
    const result = checkValid(newReviewData)
    if (result.length > 0) {
      setNewReviewError({
        state: true,
        errors: result,
      })
    } else {
      setNewReviewError(emptyErr)
      saveReview()
    }
  }

  const fetchData = useCallback(async () => {
    setTableLoading(true)
    const res = await getReviews(limit, sortBy)
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setReviews(res.data)
      } else {
        setReviews([])
      }
    }
  }, [limit, sortBy])

  useEffect(() => fetchData(), [fetchData])

  const handleDeleteReviewImage = async (id, idx) => {
    setDeleteReviewImageLoading({
      id,
      state: true,
    })
    const res = await deleteReviewImage(id)
    if (res) {
      setDeleteReviewImageLoading(emptyDelete)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successfull',
          detail: res.data.message,
        })
        const _images = [...reviewData.images].filter((item) => item.id !== id)
        setReviewData((ps) => ({ ...ps, images: _images }))
        setEditReviewImages((ps) =>
          [...ps].map((item, item_idx) => {
            if (item_idx === idx) {
              return emptyEditReviewImages[idx]
            }
            return item
          })
        )
      }
    }
  }
  const handleReviewDelete = async (id) => {
    setDeleteReviewLoading({
      id,
      state: true,
    })
    const res = await deleteReview(id)
    if (res) {
      setDeleteReviewLoading(emptyDelete)
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Operation Successfull',
          detail: res.data.message,
        })
        const _reviews = [...reviews].filter((item) => item.id !== id)
        setReviews(_reviews)
      }
    }
  }
  const hideReview = () => {
    setReviewData(emptyReview)
    setReviewVisible(false)
    setReviewEditEnable(false)
    history.push({
      pathname: '/website/reviews',
    })
  }

  const fetchReview = useCallback(async () => {
    if (!id) return
    const res = await getReview(id)
    if (res) {
      if (res.status === 404) {
        hideReview()
      }
      if (res.status === 200) {
        setReviewData((ps) => ({
          ...ps,
          ...res.data,
          date: new Date(res.data.date),
        }))
        setReviewVisible(true)
      }
    }
  }, [id])
  useEffect(() => fetchReview(), [fetchReview])

  const assignReviewDate = (code) => {
    let codes = dateOptions.map((i) => i.code)
    if (!codes.includes(code)) return null
    let reviewDate = new Date()
    switch (code) {
      case 'D1':
        reviewDate
        break
      case 'W1':
        reviewDate = subDays(reviewDate, 7)
        break
      case 'W2':
        reviewDate = subDays(reviewDate, 14)
        break
      case 'M1':
        reviewDate = subMonths(reviewDate, 1)
        break
      case 'M2':
        reviewDate = subMonths(reviewDate, 2)
        break
      case 'M3':
        reviewDate = subMonths(reviewDate, 3)
        break
      case 'M6':
        reviewDate = subMonths(reviewDate, 6)
        break
      case 'Y1':
        reviewDate = subYears(reviewDate, 1)
        break
      case 'Y2':
        reviewDate = subYears(reviewDate, 2)
        break
      case 'Y3':
        reviewDate = subYears(reviewDate, 3)
        break
      default:
        return null
    }
    reviewDate = startOfDay(reviewDate)

    return { reviewDate }
  }

  return (
    <PageAllowedToRoles allowedRoles={['superadmin', 'admin', 'manager', 'operations']}>
      <>
        <Toast ref={toast} />
        <div className="card ">
          <TabView>
            <TabPanel header="Reviews">
              <Toolbar
                className="p-toolbar p-flex-wrap gap-1"
                left={
                  <div>
                    <h4 className="p-m-0">Reviews</h4>
                  </div>
                }
                right={
                  <div className="p-d-flex p-jc-end gap-1by2">
                    <Button icon="pi pi-refresh" onClick={fetchData} />
                    <ExportButton datatable_ref={dt} />
                  </div>
                }
              />

              <DataTable
                exportFilename={`Reviews_${dtFilenameDate}`}
                loading={tableLoading}
                value={reviews}
                ref={dt}
                filters={filters}
                showGridlines
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                className="datatable-responsive"
                columnResizeMode="fit"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reviews"
                rowHover
                emptyMessage="No reviews found."
                header={
                  <div className="p-d-flex p-flex-wrap gap-1by2 p-jc-end">
                    <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Sort By</p>
                      <Dropdown
                        options={sortByOptions}
                        placeholder="Sort BY"
                        value={sortBy}
                        onChange={(e) => {
                          setSortBy(e.target.value)
                        }}
                        showClear
                      />
                    </div>
                    <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Rating</p>
                      <Dropdown
                        options={ratingOptions}
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Rating"
                        value={filters.rating.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'rating')
                        }}
                        showClear
                      />
                    </div>
                    <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Filter By</p>
                      <Dropdown
                        filter
                        options={productNames}
                        placeholder="Product"
                        optionLabel={'productName'}
                        optionValue="productName"
                        value={filters.productName.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'productName')
                        }}
                        showClear
                      />
                    </div>
                    {/* <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Review Type</p>
                      <Dropdown
                        options={reviewTypeOptions}
                        placeholder="Review Type"
                        value={filters.reviewType.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'reviewType')
                        }}
                        showClear
                      />
                    </div> */}
                    <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Status</p>
                      <Dropdown
                        options={statusOptions}
                        placeholder="Status"
                        value={filters.status.value}
                        onChange={(e) => {
                          onChangeFilter(e.target.value, 'status')
                        }}
                        showClear
                      />
                    </div>
                    <div className=" p-d-flex p-ai-center">
                      <p className="p-m-0 p-pr-2">Limit</p>
                      <Dropdown
                        placeholder="Limit"
                        options={[10, 50, 100, 500, 'All']}
                        onChange={(e) => {
                          setLimit(e.value)
                        }}
                        value={limit}
                      />
                    </div>

                    <span className="p-input-icon-left">
                      <i className="pi pi-search" />
                      <InputText
                        type="search"
                        value={globalFilterValue}
                        onChange={(e) => {
                          setGlobalFilterValue(e.target.value)
                          onChangeFilter(e.target.value, 'global')
                        }}
                        placeholder="Search..."
                      />
                    </span>
                  </div>
                }
              >
                <Column style={{ width: '0%' }} header="Id" field="id" sortable />
                <Column style={{ width: '2%' }} header="Username" field="username" sortable />
                <Column style={{ width: '1%' }} header="User Type" field="userType" />
                {/* <Column style={{ width: '1%' }} header="Source" field="source" /> */}
                <Column style={{ width: '1%' }} header="Country" field="country" />
                <Column
                  style={{ width: '5%' }}
                  header="Rating"
                  field="rating"
                  body={(rd) => <RatingStars rating={rd.rating} />}
                  sortable
                />
                <Column
                  style={{ width: '10%' }}
                  header="Review"
                  field="review"
                  body={(rd) => (
                    <InputText
                      tooltip={rd.review}
                      value={rd.review}
                      readOnly
                      className="p-text-truncate p-p-0"
                      style={{
                        background: 'none',
                        border: 0,
                        // maxWidth: '100px',
                      }}
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                    />
                  )}
                />
                <Column style={{ width: '2%' }} header="Review Type" field="reviewType" />

                <Column style={{ width: '2%' }} header="Product" field="productName" sortable />
                <Column
                  style={{ width: '4%' }}
                  header="Date"
                  field="date"
                  body={(rd) => <div>{format(new Date(rd.date), 'PPp')}</div>}
                />

                <Column
                  style={{ width: '2%' }}
                  header="Status"
                  sortable
                  field="status"
                  body={(rd) => (
                    <Dropdown
                      options={statusOptions}
                      value={rd.status}
                      placeholder="Select review status"
                      onChange={(e) => {
                        handleSaveReviewStatusEdit(rd.id, e.value)
                      }}
                    />
                  )}
                />
                <Column
                  style={{ width: '0%' }}
                  header="Action"
                  body={(rd) => (
                    <div className="p-d-flex gap-1by2">
                      <Link to={`?id=${rd?.id}`}>
                        <Button icon="pi pi-eye" />
                      </Link>
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger"
                        loading={rd.id === deleteReviewLoading.id && deleteReviewLoading.state}
                        disabled={rd.id === deleteReviewLoading.id && deleteReviewLoading.state}
                        onClick={() => handleReviewDelete(rd.id)}
                      />
                      <Link to={`?history=${rd.id}`}>
                        <Button
                          tooltip="History"
                          tooltipOptions={{ position: 'bottom' }}
                          icon="pi pi-clock"
                          className="p-button p-button-help"
                        />
                      </Link>
                    </div>
                  )}
                />
              </DataTable>
            </TabPanel>

            <TabPanel header="Add Review">
              <div className="p-fluid p-formgrid p-grid">
                <div className="p-fluid p-field p-col-12 p-lg-6">
                  <label htmlFor="codeid" className="p-col-fixed">
                    Username <RequiredMessage value={newReviewData.username} />
                  </label>
                  <InputText
                    placeholder="Enter the username"
                    value={newReviewData.username}
                    onChange={(e) => handleObjChange(setNewReviewData, 'username', e.target.value)}
                  />
                </div>
                <div className="p-fluid p-field p-col-12 p-lg-6">
                  <label htmlFor="codeid" className="p-col-fixed">
                    User Type <RequiredMessage value={newReviewData.userType} />
                  </label>
                  <Dropdown
                    placeholder="Select a user type"
                    value={newReviewData.userType}
                    onChange={(e) => handleObjChange(setNewReviewData, 'userType', e.target.value)}
                    options={userTypeOptions}
                  />
                </div>
                <div className="p-fluid p-field p-col-12 p-lg-6">
                  <label htmlFor="codeid" className="p-col-fixed">
                    Country <RequiredMessage value={newReviewData.country} />
                  </label>
                  <InputText
                    placeholder="Enter the country"
                    value={newReviewData.country}
                    onChange={(e) => handleObjChange(setNewReviewData, 'country', e.target.value)}
                  />
                </div>
                <div className="p-fluid p-field p-col-12 p-lg-6">
                  <label htmlFor="codeid" className="p-col-fixed">
                    State <RequiredMessage value={newReviewData.state} />
                  </label>
                  <Dropdown
                    options={us_states}
                    optionLabel="name"
                    optionValue="name"
                    filter
                    filterBy="name"
                    filterPlaceholder="Search states"
                    placeholder="Enter the state"
                    value={newReviewData.state}
                    onChange={(e) => handleObjChange(setNewReviewData, 'state', e.target.value)}
                  />
                </div>
                <div className="p-fluid p-field p-col-12 p-lg-6">
                  <label htmlFor="codeid" className="p-col-fixed">
                    Date <RequiredMessage value={newReviewData.date} />
                  </label>
                  <Dropdown
                    placeholder="Select a date"
                    options={dateOptions}
                    value={newReviewData.showDate}
                    optionLabel="name"
                    optionValue="code"
                    onChange={(e) => {
                      handleObjChange(setNewReviewData, 'showDate', e.value)
                      const { reviewDate } = assignReviewDate(e.value)
                      handleObjChange(setNewReviewData, 'date', reviewDate)
                    }}
                    className="flex-1"
                  />
                </div>
                <div className="p-fluid p-field p-col-12 p-lg-6">
                  <label htmlFor="codeid" className="p-col-fixed">
                    Rating <RequiredMessage value={newReviewData.rating} />
                  </label>
                  <Dropdown
                    value={newReviewData.rating}
                    onChange={(e) => handleObjChange(setNewReviewData, 'rating', e.value)}
                    placeholder="Select a rating"
                    options={ratingOptions}
                    optionValue="value"
                    optionLabel="name"
                  />
                </div>
                <div className="p-fluid p-field p-col-12">
                  <label htmlFor="codeid" className="p-col-fixed">
                    Review <RequiredMessage value={newReviewData.review} />
                  </label>
                  <InputTextarea
                    placeholder="Enter the review"
                    autoResize
                    value={newReviewData.review}
                    onChange={(e) => handleObjChange(setNewReviewData, 'review', e.target.value)}
                  />
                </div>
                <div className="p-fluid p-field p-col-12 p-lg-6">
                  <label htmlFor="codeid" className="p-col-fixed">
                    Review Type <RequiredMessage value={newReviewData.reviewType} />
                  </label>
                  <Dropdown
                    placeholder="Select a review type"
                    value={newReviewData.reviewType}
                    onChange={(e) =>
                      handleObjChange(setNewReviewData, 'reviewType', e.target.value)
                    }
                    options={reviewTypeOptions}
                  />
                </div>
                {newReviewData.reviewType === 'Product Review' && (
                  <div className="p-fluid p-field p-col-12 p-lg-6">
                    <label htmlFor="codeid" className="p-col-fixed">
                      Product <RequiredMessage value={newReviewData.productCode} />
                    </label>
                    <Dropdown
                      placeholder="Select a product"
                      value={newReviewData.productCode}
                      onChange={(e) => handleObjChange(setNewReviewData, 'productCode', e.value)}
                      options={productNames}
                      optionLabel="productName"
                      optionValue="productCode"
                      filter
                      filterPlaceholder="Search product..."
                    />
                  </div>
                )}
                {newReviewError.state && (
                  <>
                    {newReviewError.errors.map((err, idx) => (
                      <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                        <Message text={err} severity="warn" sticky={true} />
                      </div>
                    ))}
                    <div className="p-fluid p-field p-col-12">
                      <Button
                        type="button"
                        onClick={() => setNewReviewError(emptyErr)}
                        icon="pi pi-times"
                        label="Clear Warnings"
                        className="p-button-secondary"
                      />
                    </div>
                  </>
                )}
                <div className="p-fluid p-field p-col-12">
                  <Button
                    label={newReviewData.loading ? 'Submitting' : 'Submit'}
                    loading={newReviewData.loading}
                    disabled={newReviewData.loading}
                    icon="pi pi-check"
                    onClick={handleSubmit}
                  />
                </div>
                <div className="p-fluid p-field p-col-12">
                  <label htmlFor="codeid" className="p-col-fixed">
                    Images
                  </label>
                  <div className="p-d-flex p-flex-wrap gap-1 p-m-1">
                    {newReviewImages.map((rImg, idx) => {
                      return (
                        <div className="flex-1 card p-m-0" key={'review-image-' + idx}>
                          {rImg.file ? (
                            <div className="">
                              <img className="product-image" src={rImg.url} alt={rImg.file.name} />
                              <div className="w-full">
                                <Button
                                  label="Clear Image"
                                  icon="pi pi-trash"
                                  className="p-button-danger"
                                  onClick={() => handleReviewImagesRemove(idx)}
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="">
                              <input
                                multiple
                                type="file"
                                name=""
                                className="p-hidden"
                                accept="image/*"
                                ref={rImg.ref}
                                onChange={(e) => handleReviewImagesAdd(setNewReviewImages, e, idx)}
                              />
                              <Button
                                label="Select Image"
                                icon="pi pi-image"
                                className="p-button-secondary"
                                onClick={() => rImg.ref.current.click()}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>

        <Dialog
          visible={reviewVisible}
          header={`Review #${id}`}
          className="w-full"
          style={{
            maxWidth: '800px',
          }}
          onHide={hideReview}
          footer={
            <div>
              {reviewEditEnable ? (
                <>
                  <Button
                    label="Discard Edit"
                    className="p-button-text"
                    icon="pi pi-trash"
                    onClick={() => handleDiscardReviewEdit()}
                  />
                  <Button
                    disabled={editReviewData.loading}
                    loading={editReviewData.loading}
                    label={editReviewData.loading ? 'Saving...' : 'Save'}
                    icon="pi pi-save"
                    className=""
                    onClick={() => handleSaveReviewEdit()}
                  />
                </>
              ) : (
                <>
                  <Button
                    label="Cancel"
                    className="p-button-text"
                    icon="pi pi-times"
                    onClick={() => hideReview()}
                  />
                  <Button
                    label="Edit"
                    icon="pi pi-pencil"
                    className=""
                    onClick={() => handleReviewEditEnable()}
                  />
                </>
              )}
            </div>
          }
        >
          {reviewEditEnable ? (
            <div className="p-grid p-my-2">
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Username</p>
                <p className="p-col-8">
                  <InputText
                    className="w-full"
                    placeholder="Enter the username"
                    value={editReviewData.username}
                    onChange={(e) => handleObjChange(setEditReviewData, 'username', e.target.value)}
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">User Type</p>
                <p className="p-col-8">
                  <Dropdown
                    className="w-full"
                    placeholder="Select a user type"
                    value={editReviewData.userType}
                    onChange={(e) => handleObjChange(setEditReviewData, 'userType', e.target.value)}
                    options={userTypeOptions}
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Country</p>
                <p className="p-col-8">
                  <InputText
                    className="w-full"
                    placeholder="Enter the country"
                    value={editReviewData.country}
                    onChange={(e) => handleObjChange(setEditReviewData, 'country', e.target.value)}
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">State</p>
                <p className="p-col-8">
                  <InputText
                    className="w-full"
                    placeholder="Enter the state"
                    value={editReviewData.state}
                    onChange={(e) => handleObjChange(setEditReviewData, 'state', e.target.value)}
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Rating</p>
                <p className="p-col-8">
                  <Dropdown
                    value={editReviewData.rating}
                    onChange={(e) => handleObjChange(setEditReviewData, 'rating', e.value)}
                    placeholder="Select a rating"
                    options={ratingOptions}
                    optionValue="value"
                    optionLabel="name"
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Review</p>
                <p className="p-col-8">
                  <InputTextarea
                    className="w-full"
                    placeholder="Enter the review"
                    autoResize
                    value={editReviewData.review}
                    onChange={(e) => handleObjChange(setEditReviewData, 'review', e.target.value)}
                  />
                </p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Review Type</p>
                <p className="p-col-8">
                  <Dropdown
                    className="w-full"
                    placeholder="Select a review type"
                    value={editReviewData.reviewType}
                    onChange={(e) =>
                      handleObjChange(setEditReviewData, 'reviewType', e.target.value)
                    }
                    options={reviewTypeOptions}
                  />
                </p>
              </div>
              {editReviewData.reviewType === 'Product Review' && (
                <div className="p-col-12 p-grid">
                  <p className="p-col-4">Product Name</p>
                  <p className="p-col-8">
                    <Dropdown
                      className="w-full"
                      placeholder="Select a product"
                      value={editReviewData.productCode}
                      onChange={(e) => handleObjChange(setEditReviewData, 'productCode', e.value)}
                      options={productNames}
                      optionLabel="productName"
                      optionValue="productCode"
                      filter
                      filterPlaceholder="Search product..."
                    />
                  </p>
                </div>
              )}
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Date</p>
                <p className="p-col-8">
                  <Calendar
                    className="w-full"
                    placeholder="Select a Date"
                    value={editReviewData.date}
                    onChange={(e) => handleObjChange(setEditReviewData, 'date', e.target.value)}
                  />
                </p>
              </div>
              <div className="p-col-12 ">
                <p className="">Images</p>
                <div className="p-d-flex p-flex-wrap gap-1 p-m-1">
                  {editReviewImages.map((reImg, idx) => {
                    if (reImg.file && reImg.url) {
                      return (
                        <div className="flex-1 card p-m-0" key={'review-image-' + idx}>
                          <div className="">
                            <img className="product-image" src={reImg.url} alt={reImg.file.name} />
                            <div className="w-full">
                              <Button
                                label="Clear Image"
                                icon="pi pi-trash"
                                className="p-button-danger w-full"
                                onClick={() => handleEditReviewImagesRemove(idx)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }

                    if (reImg.data) {
                      return (
                        <div className="flex-1 card p-m-0" key={'review-image-' + idx}>
                          <div className="">
                            <img
                              className="product-image"
                              src={process.env.REACT_APP_IMAGE_BASE + reImg.data.path}
                              alt="review_images"
                            />
                            <div className="w-full">
                              <Button
                                loading={
                                  reImg.data.id === deleteReviewImageLoading.id &&
                                  deleteReviewImageLoading.state
                                }
                                disabled={
                                  reImg.data.id === deleteReviewImageLoading.id &&
                                  deleteReviewImageLoading.state
                                }
                                label={
                                  reImg.data.id === deleteReviewImageLoading.id &&
                                  deleteReviewImageLoading.state
                                    ? 'Deleting...'
                                    : 'Delete Image'
                                }
                                icon="pi pi-trash"
                                className="p-button-danger w-full"
                                onClick={() => handleDeleteReviewImage(reImg.data.id, idx)}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    }

                    return (
                      <div className="flex-1 card p-m-0" key={'review-image-' + idx}>
                        <div className="">
                          <input
                            multiple
                            type="file"
                            name=""
                            className="p-hidden"
                            accept="image/*"
                            ref={reImg.ref}
                            onChange={(e) => handleReviewImagesAdd(setEditReviewImages, e, idx)}
                          />
                          <Button
                            label="Select Image"
                            icon="pi pi-image"
                            className="p-button-secondary w-full"
                            onClick={() => reImg.ref.current.click()}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="p-col-12">
                {editReviewError.state && (
                  <>
                    {editReviewError.errors.map((err, idx) => (
                      <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                        <Message text={err} severity="warn" sticky={true} />
                      </div>
                    ))}
                    <div className="p-fluid p-field p-col-12">
                      <Button
                        type="button"
                        onClick={() => setEditReviewError(emptyErr)}
                        icon="pi pi-times"
                        label="Clear Warnings"
                        className="p-button-secondary"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="p-grid p-my-2">
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Username</p>
                <p className="p-col-8">{reviewData?.username}</p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">User Type</p>
                <p className="p-col-8">{reviewData?.userType}</p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Country</p>
                <p className="p-col-8">{reviewData?.country}</p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">State</p>
                <p className="p-col-8">{reviewData?.state}</p>
              </div>
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Rating</p>
                <p className="p-col-8">
                  <RatingStars rating={reviewData?.rating} />
                </p>
              </div>
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Review</p>
                <p className="p-col-8">{reviewData?.review}</p>
              </div>

              <div className="p-col-12 p-grid">
                <p className="p-col-4">Review Type</p>
                <p className="p-col-8">{reviewData?.reviewType}</p>
              </div>
              {reviewData.reviewType === 'Product Review' && (
                <div className="p-col-12 p-grid">
                  <p className="p-col-4">Product Name</p>
                  <p className="p-col-8">{reviewData?.productName}</p>
                </div>
              )}
              <div className="p-col-12 p-grid">
                <p className="p-col-4">Date</p>
                <p className="p-col-8">{reviewData?.date && format(reviewData?.date, 'PPp')}</p>
              </div>

              {reviewData.images && reviewData.images.length > 0 && (
                <div className="p-col-12">
                  <p>Images</p>
                  <div className="p-d-flex p-flex-wrap gap-1 p-m-1">
                    {reviewData?.images.map((image, idx) => {
                      return (
                        <div className="flex-1 card p-m-0" key={idx}>
                          <img
                            className="product-image"
                            src={generateS3Path(image?.path)}
                            alt="Review_image"
                          />
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </Dialog>
        <ChangeHistorySidebar
          setTableLoading={setTableLoading}
          header="Review Change History"
          historyType="review"
        />
      </>
    </PageAllowedToRoles>
  )
}
