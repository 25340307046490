import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import { handleObjChange } from '../../../functions/setter'
import { set } from 'lodash'
import validator from 'validator'
import { addUser } from '../../../api'
import { useGlobalContext } from '../../../context'
import { Dropdown } from 'primereact/dropdown'
import { Message } from 'primereact/message'
import { Password } from 'primereact/password'
import { InputText } from 'primereact/inputtext'

export default function AddUserDialog({
  dialogVisible,
  setRecords,
  setDialogVisible,
  roleOptions = [],
}) {
  const { toast, user, userOptions } = useGlobalContext()
  const defaultAgent = {
    visible: false,
    loading: false,
    email: '',
    username: '',
    adminPassword: '',
    role: '',
  }
  const [newRecord, setNewRecord] = useState({ ...defaultAgent })
  const emptyErr = {
    state: false,
    errors: [],
  }
  const [newRecordError, setNewRecordError] = useState(emptyErr)
  const validateNewRecord = (item) => {
    const { username, email, role, adminPassword } = item
    const err = []

    if (!username) {
      err.push('Username is invalid')
    }
    if (!email || !validator.isEmail(email)) {
      err.push('Email is invalid')
    }
    if (!role) {
      err.push('Role is invalid')
    }
    if (!adminPassword) {
      err.push('Your password is invalid')
    }

    return err
  }
  const hideDialog = () => {
    setNewRecord({ ...defaultAgent, loading: false })
    setDialogVisible(false)
  }
  const handelAddAgent = async () => {
    handleObjChange(setNewRecord, 'loading', true)
    const errs = validateNewRecord(newRecord)
    if (errs.length > 0) {
      setNewRecordError({
        state: true,
        errors: errs,
      })
      handleObjChange(setNewRecord, 'loading', false)
    } else {
      setNewRecordError(emptyErr)
      const res = await addUser(
        newRecord.username,
        newRecord.email,
        newRecord.adminPassword,
        newRecord.role
      )
      if (res) {
        if (res.status === 201) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.data.message,
          })
          setRecords((ps) => [res.data.record, ...ps])
          hideDialog()
        } else {
          setNewRecord((ps) => ({ ...ps, visible: true, loading: false }))
        }
      }
    }
  }
  return (
    <Dialog
      header="Add User"
      visible={dialogVisible}
      onHide={hideDialog}
      breakpoints={{ '960px': '75vw' }}
      style={{ width: '50vw' }}
      footer={() => {
        return (
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={hideDialog}
              className="p-button-text"
            />
            <Button
              loading={newRecord.loading}
              label={newRecord.loading ? 'Submitting' : 'Submit'}
              icon="pi pi-save"
              onClick={() => handelAddAgent()}
              disabled={newRecord.loading}
              autoFocus
            />
          </div>
        )
      }}
    >
      <div className="p-fluid">
        <div className="p-field p-grid">
          <label className="p-col-12 p-mb-2">Role</label>
          <div className="p-col-12 md:p-col-10">
            <Dropdown
              placeholder="Select role"
              options={roleOptions}
              optionLabel="name"
              optionValue="value"
              value={newRecord.role}
              onChange={(e) => handleObjChange(setNewRecord, 'role', e.value)}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-12 p-mb-2">Name</label>
          <div className="p-col-12 md:p-col-10">
            <InputText
              placeholder="Enter username"
              value={newRecord.username}
              onChange={(e) => handleObjChange(setNewRecord, 'username', e.target.value)}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-12 p-mb-2">Email</label>
          <div className="p-col-12 md:p-col-10">
            <InputText
              placeholder="Enter email"
              value={newRecord.email}
              onChange={(e) => handleObjChange(setNewRecord, 'email', e.target.value)}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-12 p-mb-2">Your Password</label>
          <div className="p-col-12 md:p-col-10">
            <Password
              placeholder="Enter your password"
              type="password"
              toggleMask
              feedback={false}
              value={newRecord.adminPassword}
              onChange={(e) => handleObjChange(setNewRecord, 'adminPassword', e.target.value)}
            />
          </div>
        </div>
        {newRecordError.state && (
          <div className="p-field p-grid">
            {newRecordError.errors.map((err, idx) => (
              <div key={idx} className="p-fluid p-field p-col-12 p-lg-6">
                <Message text={err} severity="warn" sticky={true} />
              </div>
            ))}
            <div className="p-fluid p-field p-col-12">
              <Button
                type="button"
                onClick={() => setNewRecordError(emptyErr)}
                icon="pi pi-times"
                label="Clear Warnings"
                className="p-button-secondary"
              />
            </div>
          </div>
        )}
      </div>
    </Dialog>
  )
}
