import axios from 'axios'
import endpoints from './endpoints'

export const addReview = async (review, reviewImages) => {
  try {
    const formData = new FormData()
    review = JSON.stringify(review)
    formData.append('reviewData', review)
    reviewImages.forEach((image) => {
      formData.append('reviewImage', image)
    })
    const res = await axios.post(endpoints.review.index, formData)
    return res
  } catch (error) {
    return error.response
  }
}

export const getReviews = async (limit, sortBy) => {
  try {
    const res = await axios.get(endpoints.review.index, {
      params: { limit, sortBy },
    })
    return res
  } catch (error) {
    return error.response
  }
}

export const getReview = async (id) => {
  try {
    const res = await axios.get(endpoints.review.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const updateReview = async (id, reviewImages, reviewData, status) => {
  try {
    let data
    if (status) {
      data = { status }
    } else {
      data = new FormData()
      reviewData = JSON.stringify(reviewData)
      data.append('reviewData', reviewData)
      reviewImages.forEach((image) => {
        data.append('reviewImage', image)
      })
    }
    const res = await axios.patch(endpoints.review.index + id, data)
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteReview = async (id) => {
  try {
    const res = await axios.delete(endpoints.review.index + id)
    return res
  } catch (error) {
    return error.response
  }
}

export const deleteReviewImage = async (id) => {
  try {
    const res = await axios.delete(endpoints.review.image + id)
    return res
  } catch (error) {
    return error.response
  }
}
