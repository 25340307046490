import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { Chip } from 'primereact/chip'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { onChangeFilter } from '../../../functions/setter'

import { useGlobalContext } from '../../../context'
import { Dropdown } from 'primereact/dropdown'
import MyDatesPicker from '../../mini/MyDatesPicker'
import { InputText } from 'primereact/inputtext'
import { dtFilenameDate } from '../../../functions/myDateFns'

const UserTicketsDataTable = ({
  dtRef,
  exportFilename,
  type,
  dateCode,
  setDateCode,
  dateRange,
  setDateRange,
  userTickets,
  dataTableLoading,
  dateFilter,
  setDateFilter,
  filters,
  setFilters,
}) => {
  //ticket statuses
  const ticketStatus = [
    { label: 'Open', value: 'open' },
    { label: 'Close', value: 'close' },
  ]

  //ticket type
  const ticketType = [
    { label: 'Technical', value: 'Technical' },
    { label: 'Non Technical', value: 'Non Technical' },
  ]

  const { agents, user, teams } = useGlobalContext()

  // global filter search onchange
  const onGlobalFilterChange = (event, filter, setFilter) => {
    const value = event.target.value
    let _filters = { ...filter }

    _filters['global'].value = value

    setFilter(_filters)
  }

  const header = () => (
    <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
      {['superadmin', 'admin', 'manager', 'teamlead', 'operations'].includes(user.role) && (
        <>
          <div>
            <Dropdown
              value={filters.assignedById.value}
              placeholder="Assigned By Agent"
              options={user.members}
              optionLabel="username"
              optionValue="id"
              showClear
              filter
              filterBy="username"
              onChange={(e) => {
                onChangeFilter(setFilters, e.value, 'assignedById')
              }}
            ></Dropdown>
          </div>
          <div>
            <Dropdown
              value={filters.assignedToTeamIds.value}
              placeholder="Assigned To Team"
              options={teams}
              optionLabel="teamName"
              optionValue="teamID"
              showClear
              filter
              filterBy="teamName"
              onChange={(e) => {
                onChangeFilter(setFilters, e.value, 'assignedToTeamIds')
              }}
            ></Dropdown>
          </div>
          <div>
            <Dropdown
              value={filters.assignedToUserIds.value}
              placeholder="Assigned To"
              options={user.members}
              optionLabel="username"
              optionValue="id"
              showClear
              filter
              filterBy="username"
              onChange={(e) => {
                onChangeFilter(setFilters, e.value, 'assignedToUserIds')
              }}
            ></Dropdown>
          </div>
        </>
      )}
      <div>
        <Dropdown
          value={filters.type.value}
          placeholder="Ticket Type"
          options={ticketType}
          optionLabel="label"
          optionValue="value"
          showClear
          onChange={(e) => {
            onChangeFilter(setFilters, e.value, 'type')
          }}
        ></Dropdown>
      </div>
      {type === 'all' && (
        <div>
          <Dropdown
            value={filters.status.value}
            placeholder="Ticket Status"
            options={ticketStatus}
            optionLabel="label"
            optionValue="value"
            showClear
            onChange={(e) => {
              onChangeFilter(setFilters, e.value, 'status')
            }}
          ></Dropdown>
        </div>
      )}
      <div className="">
        <MyDatesPicker
          code={dateCode}
          setCode={setDateCode}
          rangeDates={dateRange}
          setRangeDates={setDateRange}
          filterDates={dateFilter}
          setFilterDates={setDateFilter}
        />
      </div>
      <div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={filters['global'] ? filters['global'].value : '' || ''}
            onChange={(e) => onGlobalFilterChange(e, filters, setFilters)}
            placeholder="Global Search"
          />
        </span>
      </div>
    </div>
  )

  return (
    <>
      <DataTable
        ref={dtRef}
        exportFilename={exportFilename}
        removableSort
        header={header}
        value={userTickets}
        showGridlines
        filters={filters}
        loading={dataTableLoading}
        dataKey="id"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      >
        <Column
          field="id"
          header="Id"
          sortable
          style={{
            width: '5%',
          }}
        />
        <Column field="assignedBy" header="Assigned By" style={{ width: '10%' }} />
        <Column
          field="type"
          header="Ticket Type"
          style={{
            width: '10%',
          }}
        />
        <Column
          field="assignedTo"
          header="Assigned To"
          style={{
            width: '15%',
          }}
        />
        <Column
          field="subject"
          header="Subject"
          style={{
            width: `${type === 'closed' ? '20%' : '25%'}`,
          }}
        />
        <Column
          field="openDate"
          header="Open Date"
          style={{
            width: '17%',
          }}
          body={(rd) => {
            return format(new Date(rd.openDate), 'PP, p')
          }}
        />
        {type === 'closed' && (
          <Column
            field="closingTimestamp"
            header="Close Date"
            style={{
              width: '17%',
            }}
            body={(rd) => {
              return format(new Date(rd.closingTimestamp), 'PP, p')
            }}
          />
        )}
        <Column
          field="status"
          header="Status"
          style={{
            width: '5%',
            textAlign: 'center',
          }}
          body={(rd) => {
            return (
              <Chip
                label={rd.status === 'Open' ? rd.status : rd.status + 'd'}
                className={`${
                  rd.status === 'Open' ? 'tm-status-Unachieved' : 'tm-status-Achieved'
                } p-text-bold bg-opacity-50`}
              />
            )
          }}
        />
        <Column
          field="Action"
          header="Action"
          style={{
            width: '15%',
          }}
          body={(rd) => {
            return (
              <div className="p-d-flex gap-1by2 p-jc-start">
                {rd.status !== 'Close' ? (
                  <Link to={`?id=${rd?.id}`}>
                    <Button
                      disabled={rd.status === 'Close'}
                      icon="pi pi-pencil"
                      tooltip="Edit"
                      className="p-button-info"
                      tooltipOptions={{
                        position: 'bottom',
                      }}
                    />
                  </Link>
                ) : (
                  <Button
                    disabled={rd.status === 'Close'}
                    icon="pi pi-pencil"
                    tooltip="Edit"
                    className="p-button-info"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                  />
                )}

                <Link to={`?noteId=${rd?.id}`}>
                  <Button
                    icon="pi pi-copy"
                    tooltip="Open Notes"
                    className="p-button-success"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                  />
                </Link>
                <Link to={`?viewId=${rd?.id}`}>
                  <Button
                    icon="pi pi-eye"
                    tooltip="View"
                    tooltipOptions={{
                      position: 'bottom',
                    }}
                  />
                </Link>
                <Link to={`?history=${rd.id}`}>
                  <Button
                    tooltip="History"
                    tooltipOptions={{ position: 'bottom' }}
                    icon="pi pi-clock"
                    className="p-button p-button-help"
                  />
                </Link>
              </div>
            )
          }}
        />
      </DataTable>
    </>
  )
}

export default UserTicketsDataTable
