import { Column } from 'jspdf-autotable'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { onChangeFilter } from '../../functions/setter'
import { useGlobalContext } from '../../context'

import { Toast } from 'primereact/toast'
import { getUserNotificationTopics, putUserNotificationTopics } from '../../api'
import { TreeTable } from 'primereact/treetable'

import { DataTable } from 'primereact/datatable'
import { checkIfSubscriptionExistsSw } from '../../functions/notificationUtil'
import { TabPanel, TabView } from 'primereact/tabview'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { getUserNotificationHistory } from '../../api/notification'
import { FilterMatchMode } from 'primereact/api'
import { format, isValid } from 'date-fns'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import notificationTopics from '../../data/notifcatificationTopics'

export default function MyNotificationsPage() {
  const emptyFilterObject = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    topic: { value: null, matchMode: FilterMatchMode.EQUALS },
  }
  const npDtRef = useRef(null)
  const { toast, user } = useGlobalContext()
  const [notificationActive, setNotificationActive] = useState(false)
  const [preferencesLoading, setPreferencesLoading] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  const [notificationState, setNotificationState] = useState([])

  const [expandedRows, setExpandedRows] = useState(null)
  const [notificationHistory, setNotificationHistory] = useState(notificationTopics)
  const [filters, setFilters] = useState(emptyFilterObject)
  useEffect(() => {
    const fn = async () => {
      let res = await checkIfSubscriptionExistsSw()
      setNotificationActive(res)
    }
    fn()
  }, [])

  const handleNotificationToggle = async (topics, type, value) => {
    const res = await putUserNotificationTopics({ topics, type, value })
    if (res) {
      if (res.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Successful',
          detail: res.data.message,
        })
      }
    }
  }
  const fetchNotifcations = useCallback(async () => {
    setPreferencesLoading(true)
    const res = await getUserNotificationTopics()
    if (res) {
      if (res.status === 200) {
        const data = res?.data?.topics ?? []
        let prevData = [...notificationTopics]
        prevData = prevData
          .filter((val) => val.roles.includes(user.role))
          .map((val) => {
            val.items = val.items.filter((val) => val.roles.includes(user.role))
            return val
          })
        prevData.forEach((val) => {
          const { items } = val
          items.forEach((item) => {
            const findData = data.find((val) => val.topic === item.topic)
            if (findData) {
              item.isPushNotification = !!findData.isPushNotification
              item.isEmailNotification = !!findData.isEmailNotification
            }
          })
          // check if all the children are checked
          let groupIsPushNotification = true,
            groupIsEmailNotification = true
          items.forEach((item) => {
            if (!item.isPushNotification) {
              groupIsPushNotification = false
            }
            if (!item.isEmailNotification) {
              groupIsEmailNotification = false
            }
          })
          val.isPushNotification = groupIsPushNotification
          val.isEmailNotification = groupIsEmailNotification
        })

        setNotificationState(prevData)
      }
    }
    setPreferencesLoading(false)
  }, [user.role])
  useEffect(() => {
    fetchNotifcations()
  }, [fetchNotifcations])

  const fetchNotifcationHistory = async () => {
    setHistoryLoading(true)
    const res = await getUserNotificationHistory()

    if (res) {
      setHistoryLoading(false)
      if (res.status === 200) {
        setNotificationHistory(res.data.history)
      }
    }
  }
  useEffect(() => {
    fetchNotifcationHistory()
  }, [])

  const handleGroupNotificationToggle = async ({ index, type, value }) => {
    const _notificationTopics = [...notificationState]
    const groupTopics = notificationState[index].items
    const topics = []
    notificationState[index].items = groupTopics.map((val) => {
      val[type] = value
      topics.push(val.topic)
      return val
    })
    _notificationTopics[index][type] = value
    setNotificationState(_notificationTopics)
    await handleNotificationToggle(topics, type, value)
  }

  const handleSingleNotificationToggle = async ({ group, topic, type, value }) => {
    const _notificationTopics = [...notificationState]
    const groupIndex = _notificationTopics.findIndex((val) => val.topic === group)
    const groupTopics = _notificationTopics[groupIndex].items
    const topicIndex = groupTopics.findIndex((val) => val.topic === topic)
    _notificationTopics[groupIndex].items[topicIndex][type] = value
    // sync group toggle state
    const result = groupTopics.every((val) => val[type] === value)
    _notificationTopics[groupIndex][type] = result && value ? true : false
    setNotificationState(_notificationTopics)
    await handleNotificationToggle([topic], type, value)
  }

  const rowExpansionTemplate = (data) => {
    return (
      <TreeTable
        value={data.items}
        responsiveLayout="scroll"
        style={{
          marginTop: '-1.7rem',
        }}
      >
        <Column style={{ width: '3rem' }} />

        <Column
          field="topic"
          body={(rd) => {
            return rd.label
          }}
        />
        <Column
          field="isPushNotification"
          style={{ width: '25%' }}
          body={(rd, index) => {
            return (
              <InputSwitch
                disabled={!notificationActive}
                checked={rd.isPushNotification}
                onChange={(e) => {
                  handleSingleNotificationToggle({
                    group: data.topic,
                    topic: rd.topic,
                    type: 'isPushNotification',
                    value: e.value,
                  })
                }}
              />
            )
          }}
        />
        <Column
          field="isEmailNotification"
          style={{ width: '25%' }}
          body={(rd, index) => {
            return (
              <InputSwitch
                checked={rd.isEmailNotification}
                onChange={(e) => {
                  handleSingleNotificationToggle({
                    group: data.topic,
                    topic: rd.topic,
                    type: 'isEmailNotification',
                    value: e.value,
                  })
                }}
              />
            )
          }}
        />
      </TreeTable>
    )
  }

  const notificationHistoryHeader = () => {
    return (
      <div className="p-d-flex p-jc-end p-flex-wrap gap-1by2 p-ai-center p-pb-2">
        <div>
          <Dropdown
            options={notificationState.filter((topics) => topics.roles.includes(user.role))}
            optionLabel="label"
            optionValue="topic"
            showClear
            filter
            filterBy="label"
            placeholder="Filter by topic"
            value={filters.topic.value}
            optionGroupLabel="label"
            optionGroupChildren="items"
            onChange={(e) => {
              onChangeFilter(setFilters, e.value, 'topic')
            }}
          />
        </div>
        <div>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              value={filters['global'] ? filters['global'].value : '' || ''}
              onChange={(e) => {
                onChangeFilter(setFilters, e.target.value, 'global')
              }}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Toast ref={toast} />
      <TabView className="card">
        <TabPanel header="History">
          <Toolbar
            className="p-toolbar p-mb-3 p-flex-wrap gap-1"
            left={
              <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                <h4 className="p-m-0">Notification Hisotry</h4>
              </div>
            }
            right={
              <div>
                <div className="p-d-flex p-flex-wrap gap-1">
                  <Button
                    icon="pi pi-refresh"
                    onClick={() => {
                      fetchNotifcationHistory()
                    }}
                  />
                </div>
              </div>
            }
          ></Toolbar>
          <DataTable
            exportFilename={`Notification History`}
            value={notificationHistory}
            showGridlines
            filters={filters}
            dataKey="id"
            paginator
            rows={10}
            header={notificationHistoryHeader}
            rowsPerPageOptions={[10, 25, 50, 100]}
            className="datatable-responsive"
            columnResizeMode="fit"
            responsiveLayout="scroll"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} activities"
            emptyMessage="No activities found."
            loading={historyLoading}
            removableSort
          >
            <Column field="id" header="ID" />
            <Column field="title" header="Title" />

            <Column field="body" header="Body" />
            <Column
              field="createdAt"
              header="Created At"
              body={(rd) => {
                const date = new Date(rd.createdAt)
                return isValid(date) && format(date, 'Pp')
              }}
            />
            {/* <Column field="User" />
            <Column field="masterRole" /> */}
          </DataTable>
        </TabPanel>
        <TabPanel header="Preferences">
          <Toolbar
            className="p-toolbar p-mb-3 p-flex-wrap gap-1"
            left={
              <div className="p-d-flex p-flex-wrap gap-1 p-ai-center">
                <h4 className="p-m-0">Notification Preferences</h4>
              </div>
            }
            right={
              <div>
                <div className="p-d-flex p-flex-wrap gap-1">
                  <Button
                    icon="pi pi-refresh"
                    onClick={() => {
                      fetchNotifcations()
                    }}
                  />
                </div>
              </div>
            }
          ></Toolbar>
          <div>
            <DataTable
              ref={npDtRef}
              value={notificationState}
              expandedRows={expandedRows}
              loading={preferencesLoading}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
            >
              <Column expander style={{ width: '1rem' }} />
              <Column
                field="topic"
                header="Topic"
                body={(rd) => {
                  return <>{rd.label}</>
                }}
              />
              <Column
                field="isPushNotification"
                header="Web Push Notif"
                style={{ width: '25%' }}
                body={(rd, index) => {
                  return (
                    <InputSwitch
                      disabled={!notificationActive}
                      checked={rd.isPushNotification}
                      onChange={(e) => {
                        handleGroupNotificationToggle({
                          index: index.rowIndex,
                          type: 'isPushNotification',
                          value: e.value,
                        })
                      }}
                    />
                  )
                }}
              />
              <Column
                field="isEmailNotification"
                header="Email Notif"
                style={{ width: '25%' }}
                body={(rd, index) => {
                  return (
                    <InputSwitch
                      checked={rd.isEmailNotification}
                      onChange={(e) => {
                        handleGroupNotificationToggle({
                          index: index.rowIndex,
                          type: 'isEmailNotification',
                          value: e.value,
                        })
                      }}
                    />
                  )
                }}
              />
            </DataTable>
          </div>
        </TabPanel>
      </TabView>
    </div>
  )
}
