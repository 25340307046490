import React, { useCallback, useState, useEffect } from 'react'
import OrdersDataTable from '../../components/mycomponents/OrdersDataTable'
import { getAllOrder } from '../../api'
import { assignDates, dateFilters } from '../../functions/myDateFns'
const orderStatusOptions = [
  'Pending',
  'Processed',
  'Shipped',
  'Tracking',
  'Tracking-Live',
  'Delivered',
  'Undelivered',
  'Partial',
  'Cancelled',
  'Ineligible',
  'Draft',
  'Ineligi-Cancel',
]
export default function OrdersPage() {
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[0].code))
  const [tableLoading, setTableLoading] = useState(false)
  const [source, setSource] = useState(null)
  const [orders, setOrders] = useState(null)
  const [ordersMeta, setOrdersMeta] = useState({})

  const getOrders = useCallback(async () => {
    setTableLoading(true)
    const res = await getAllOrder({
      ...dateFilter,
      source,
      status: orderStatusOptions
    })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        const data = await res.data.orders
        setOrders(data)
        setOrdersMeta((ps) => ({
          ...ps,
          totalSales: res.data.totalSales,
          totalOrders: res.data.totalOrders,
        }))
      }
    }
  }, [dateFilter, source])

  useEffect(() => {
    getOrders()
  }, [getOrders])
  return (
    <OrdersDataTable
      dateFilter={dateFilter}
      setDateFilter={setDateFilter}
      source={source}
      ordersMeta={ordersMeta}
      setSource={setSource}
      orders={orders}
      setOrders={setOrders}
      pageUrl={'/orders'}
      getOrders={getOrders}
      tableLoading={tableLoading}
      setTableLoading={setTableLoading}
      orderStatusOptions={orderStatusOptions}
    />
  )
}
