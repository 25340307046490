import axios from 'axios'
import endpoints from './endpoints'

export const getOrderRequests = async (params) => {
  try {
    return await axios.get(endpoints.order.request, { params })
  } catch (error) {
    return error.response
  }
}

export const orderRequestReviewed = async (id, gateway,status) => {
  try {
    return await axios.patch(endpoints.order.request + id, { status,gateway })
  } catch (error) {
    return error.response
  }
}
