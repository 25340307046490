import { searchQuantity, searchStrength } from "../api"

const fetchStrength = async (code, setStrength) => {
    if (!code) return
    const res = await searchStrength(code)
    if (res && res.status === 200) {
      setStrength(res.data.filter((strength) => strength.strengthStatus === 'active'))
    }
  }

  // fetch quantities
  const fetchQuantity = async (code, setQuantity) => {
    if (!code) return
    const res = await searchQuantity(code)
    if (res && res.status === 200) {
      setQuantity(res.data.filter((quantity) => quantity.qtyStatus === 'active'))
    }
  }

    export { fetchStrength, fetchQuantity }