import React, { useCallback, useEffect, useState } from 'react'
import { getTargets } from '../../api'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
import TargetDetailsDialog from '../../components/mycomponents/TargetManagement/TargetDetailsDialog'
import TargetsDataTable from '../../components/mycomponents/TargetManagement/TargetsDataTable'
import { assignDates, dateFilters } from '../../functions/myDateFns'

const pathname = '/targets-agent'
export default function AgentTargetsPage() {
  const [showTargets, setShowTargets] = useState([])
  const [tableLoading, setTableLoading] = useState(false)
  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))

  //* Handler to fetch all the target records from the database
  const fetchTargets = useCallback(async () => {
    setTableLoading(true)
    const res = await getTargets({
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
    })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setShowTargets(res.data)
      }
    }
  }, [dateFilter.dateFrom, dateFilter.dateTo])
  useEffect(() => fetchTargets(), [fetchTargets])

  return (
    <PageAllowedToRoles allowedRoles={['agent']}>
      <TargetsDataTable
        records={showTargets}
        tableLoading={tableLoading}
        setRecords={setShowTargets}
        setTableLoading={setTableLoading}
        fetchRecords={fetchTargets}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
      />
      <TargetDetailsDialog />
    </PageAllowedToRoles>
  )
}
