import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React from 'react'
import { CustomerDetails } from './CustomerDetails'
import { Timeline } from 'primereact/timeline'

export const CustomerCaseViewDialog = ({
  id,
  enquiryData,
  hideEnquiry,
  customizedMarker,
  customizedContent,
}) => {
  return (
    <Dialog
      visible={enquiryData?.visible}
      onHide={hideEnquiry}
      footer={
        <div>
          <Button
            label="Cancel"
            className="p-button-text"
            onClick={hideEnquiry}
            icon="pi pi-times"
          />
        </div>
      }
      style={{ width: '60%' }}
      header={`Customer Enquiry #${id}`}
    >
      <div>
        {/* customer details  */}
        <CustomerDetails enquiryData={enquiryData} />
        {/* customer entered Details  */}
        <h5 className="p-mt-5">Additional Details</h5>
        <div className="p-d-flex p-flex-wrap gap-2 p-my-4">
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot relative p-pl-4">
              Customer Name : <span className="text-light">{enquiryData?.enquiry?.name}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot red relative p-pl-4">
              Source : <span className="text-light">{enquiryData?.enquiry?.web}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot mar relative p-pl-4">
              Shifts : <span className="text-light">{enquiryData?.enquiry?.shiftNames}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot org relative p-pl-4">
              Lead Type : <span className="text-light">{enquiryData?.enquiry?.leadType}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot navi relative p-pl-4">
              Agents : <span className="text-light">{enquiryData?.enquiry?.agentNames}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot pur relative p-pl-4">
              Enquiry Score :{' '}
              <span className="text-light">{enquiryData?.enquiry?.enquiryScore}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot red relative p-pl-4">
              Disposition : <span className="text-light">{enquiryData?.enquiry?.disposal}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot org relative p-pl-4">
              Age : <span className="text-light">{enquiryData?.enquiry?.age}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot pur relative p-pl-4">
              Budget : <span className="text-light">{enquiryData?.enquiry?.budget}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot navi relative p-pl-4">
              Quantity : <span className="text-light">{enquiryData?.enquiry?.quantity}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot relative p-pl-4">
              Product : <span className="text-light">{enquiryData?.enquiry?.product}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot mar relative p-pl-4">
              Category : <span className="text-light">{enquiryData?.enquiry?.category}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot red relative p-pl-4">
              Sub Category : <span className="text-light">{enquiryData?.enquiry?.subCategory}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot navi relative p-pl-4">
              Country : <span className="text-light">{enquiryData?.enquiry?.country}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot pur relative p-pl-4">
              Gender : <span className="text-light">{enquiryData?.enquiry?.gender}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot org relative p-pl-4">
              Checks : <span className="text-light">{enquiryData?.enquiry?.checks}</span>
            </p>
          </div>
          <div className="p-d-flex p-flex-wrap gap-2 p-mb-4">
            <p className="highlight-dot mar relative p-pl-4">
              Acceptability :{' '}
              <span className="text-light">{enquiryData?.enquiry?.acceptibility}</span>
            </p>
          </div>
        </div>
        <h5>Feedbacks</h5>
        {enquiryData?.feedbacks && (
          <div className="widget-timeline">
            <div className="timeline-content">
              <Timeline
                value={enquiryData?.feedbacks}
                marker={customizedMarker}
                content={customizedContent}
                className="custimized-timeline"
              />
            </div>
          </div>
        )}
        {/* customer entered Details  */}
      </div>
    </Dialog>
  )
}
