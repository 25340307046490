import { format } from 'date-fns'
import { Tooltip } from 'primereact/tooltip'
import React from 'react'
import { MdOutlineNotificationsOff } from 'react-icons/md'

export default function CustomTooltip({ data }) {
  return (
    <>
      <Tooltip
        target={`.dnd-tt-${data.orderid}`}
        content={`DND till ${
          data.dndTillDate ? format(new Date(data.dndTillDate), 'PP') : 'Forever'
        }, Note: ${data.dndNote || 'NA'}`}
      />
      <span className={`dnd-tt-${data.orderid}`}>
        <MdOutlineNotificationsOff fontSize={'18px'} />
      </span>
    </>
  )
}
