import { format } from 'date-fns'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useCallback, useEffect, useState } from 'react'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { TbTargetArrow } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { getPendingTargets, getTargets } from '../../api'
import TargetDetailsDialog from '../../components/mycomponents/TargetManagement/TargetDetailsDialog'
import TargetTeamAssignBreakdownDialog from '../../components/mycomponents/TargetManagement/TargetTeamAssignBreakdownDialog'
import TargetToGroup from '../../components/mycomponents/TargetManagement/TargetToGroup'
import TargetToMembers from '../../components/mycomponents/TargetManagement/TargetToMembers'
import TargetToTeam from '../../components/mycomponents/TargetManagement/TargetToTeam'
import TargetsDataTable from '../../components/mycomponents/TargetManagement/TargetsDataTable'
import { assignDates, dateFilters } from '../../functions/myDateFns'
import PageAllowedToRoles from '../../app/wrappers/PageAllowedToRoles'
const pathname = '/targets-manager'
export default function TeamTargetsPage() {
  // States
  const [showTargets, setShowTargets] = useState([])
  const [showPendingTargets, setShowPendingTargets] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [tableLoading, setTableLoading] = useState(false)

  const [dateFilter, setDateFilter] = useState(assignDates(dateFilters[1].code))

  //* Handler to fetch all the target records from the database
  const fetchTargets = useCallback(async () => {
    setTableLoading(true)
    const res = await getTargets({
      dateFrom: dateFilter.dateFrom,
      dateTo: dateFilter.dateTo,
    })
    if (res) {
      setTableLoading(false)
      if (res.status === 200) {
        setShowTargets(res.data)
      }
    }
  }, [dateFilter.dateFrom, dateFilter.dateTo])
  useEffect(() => fetchTargets(), [fetchTargets])

  const fetchPendingTargets = async () => {
    const res = await getPendingTargets()
    setShowPendingTargets(res.data)
  }
  useEffect(() => fetchPendingTargets(), [])

  return (
    <PageAllowedToRoles allowedRoles={['teamlead']}>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setActiveIndex(e.index)
        }}
      >
        <TabPanel header="Target History">
          <TargetsDataTable
            records={showTargets}
            tableLoading={tableLoading}
            setRecords={setShowTargets}
            setTableLoading={setTableLoading}
            fetchRecords={fetchTargets}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
        </TabPanel>
        <TabPanel header="Pending Targets">
          <div className="p-grid">
            {showPendingTargets.map((items, idx) => {
              return (
                <div key={idx} className="p-col-12 p-lg-6">
                  <div
                    className="card"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div className="p-grid p-formgrid p-mb-3 p-jc-center">
                      {items.levelDetails.map((singleLevel, id) => {
                        return (
                          <div key={idx} className="p-col-6 p-md-4">
                            <div
                              className="card"
                              style={{
                                borderRadius: '20px',
                                border: 'solid rgba(250, 250, 250, 0.2) 0.5px',
                                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                              }}
                            >
                              <div className="p-d-flex p-jc-between p-ai-start p-mb-2">
                                <label className="p-m-0 p-text-bold">{`Lvl. ${id + 1}`}</label>
                                <span>
                                  <label className="p-m-0 p-text-bold">{`₹${Number(
                                    singleLevel.reward
                                  ).toLocaleString('en-US')}${
                                    items.rewardType === 'Incentive' ? '/$' : ''
                                  }`}</label>
                                </span>
                              </div>
                              <div
                                className="p-mx-auto p-d-flex p-ai-center p-jc-center p-mb-3"
                                style={{
                                  width: '105px',
                                  height: '105px',
                                  border: 'solid #BCBCBC 2px',
                                  borderRadius: '50%',
                                }}
                              >
                                <span className="p-text-center">
                                  <h6
                                    className="p-m-0 p-d-flex p-ai-center"
                                    // style={{ color: '#898888' }}
                                  >
                                    <TbTargetArrow
                                      className="p-mr-1"
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        color: '#7DAEFF',
                                      }}
                                    />
                                    Target
                                  </h6>
                                  <h5 className="p-m-0" style={{ color: '#7DAEFF' }}>
                                    {items.isAmountBased === 1 &&
                                      `$${Number(singleLevel.orderAmount).toLocaleString('en-US')}`}
                                  </h5>
                                  <h5 className="p-m-0" style={{ color: '#7DAEFF' }}>
                                    {items.isCountBased === 1 &&
                                      `${Number(singleLevel.orderCount).toLocaleString('en-US')}`}
                                  </h5>
                                  <h5 className="p-m-0" style={{ color: '#7DAEFF' }}>
                                    {items.isConversionRateBased === 1 &&
                                      `${singleLevel.orderConversionRate}%`}
                                  </h5>
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="p-grid p-formgrid p-pl-2">
                      <div
                        className="p-col-12 p-lg-4 p-mb-3"
                        style={{ borderLeft: 'solid #0066FF 2px' }}
                      >
                        <label style={{ color: 'var(--text-color-secondary)' }}>Target Name</label>
                        <h6 className="p-mb-0 p-mt-2">{`${items.targetName}`}</h6>
                      </div>
                      <div
                        className="p-col-12 p-lg-4 p-mb-3"
                        style={{ borderLeft: 'solid #0066FF 2px' }}
                      >
                        <label style={{ color: 'var(--text-color-secondary)' }}>Reward Type</label>
                        <h6 className="p-mb-0 p-mt-2">{`${items.rewardType}`}</h6>
                      </div>
                      <div
                        className="p-col-12 p-lg-4 p-mb-3"
                        style={{ borderLeft: 'solid #0066FF 2px' }}
                      >
                        <label style={{ color: 'var(--text-color-secondary)' }}>Created At</label>
                        <h6 className="p-mb-0 p-mt-2">
                          {items.targetName
                            ? `${format(new Date(items?.createdAt), 'd-MMM-yyyy')}`
                            : ''}
                        </h6>
                      </div>
                      <div
                        className="p-col-12 p-lg-4 p-mb-3"
                        style={{ borderLeft: 'solid #0066FF 2px' }}
                      >
                        <label style={{ color: 'var(--text-color-secondary)' }}>Target Date</label>
                        <h6 className="p-mb-0 p-mt-2">
                          {items.targetName
                            ? `${format(new Date(items?.dateFrom), 'P')} To ${format(
                                new Date(items?.dateTo),
                                'P'
                              )}`
                            : ''}
                        </h6>
                      </div>

                      <div
                        className="p-col-12 p-lg-4 p-mb-3"
                        style={{ borderLeft: 'solid #0066FF 2px' }}
                      >
                        <label style={{ color: 'var(--text-color-secondary)' }}>Target Type</label>
                        <h6 className="p-mb-0 p-mt-2">
                          {items.isAmountBased === 1 && 'Amount'}
                          {items.isCountBased === 1 && 'Order'}
                          {items.isConversionRateBased === 1 && 'Conversion'}
                        </h6>
                      </div>
                      <div
                        className="p-col-12 p-lg-4 p-mb-3"
                        style={{ borderLeft: 'solid #0066FF 2px' }}
                      >
                        <label style={{ color: 'var(--text-color-secondary)' }}>Created By</label>
                        <h6 className="p-mb-0 p-mt-2">{items.createdBy}</h6>
                      </div>
                    </div>
                    <Link to={`?at_id=${items.id}`}>
                      <div className="w-full p-d-flex p-jc-center">
                        <Button
                          style={{
                            position: 'absolute',
                            bottom: '-17px',
                            borderRadius: '10px',
                          }}
                          className="p-px-3 p-py-2 "
                          label="Assign"
                        >
                          <HiArrowNarrowRight
                            style={{
                              width: '20px',
                              height: '20px',
                            }}
                            className="p-ml-1"
                          />
                        </Button>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </TabPanel>
        <TabPanel header="Assign New Target">
          <TargetToMembers
            callBackFn={() => {
              fetchTargets()
            }}
          />
        </TabPanel>
        <TabPanel header="Assign Target To Team" activeIndex={activeIndex}>
          <TargetToTeam callBackFn={fetchTargets} />
        </TabPanel>
        <TabPanel header="Assign Target To Group">
          <TargetToGroup callBackFn={fetchTargets} />
        </TabPanel>
      </TabView>
      <TargetTeamAssignBreakdownDialog
        callBackFn={() => {
          fetchPendingTargets()
          fetchTargets()
        }}
      />
      <TargetDetailsDialog />
    </PageAllowedToRoles>
  )
}
