import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { requestOtp, resetPassword } from '../../api'
// ChangePassword component to manage the change password
export default function ChangePassword({ showToaster }) {
  /**
   * showToaster @type {function} - function to show toaster
   **/

  const toast = useRef(null)
  const defaultOtp = {
    value: '',
    requesting: false,
    loading: false,
    expireTime: null,
  }
  const [otp, setOtp] = useState(defaultOtp)
  const [otpCoolDown, setOtpCoolDown] = useState(0)
  const defaultReset = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  const [reset, setReset] = useState(defaultReset)

  const handelPasswordChange = async () => {
    setOtp({ ...otp, loading: true })
    const res = await resetPassword(reset.password, otp.value, reset.newPassword)
    setOtp({ ...otp, loading: false })

    if (res.status === 200) {
      showToaster('success', 'Password Changed', 'Your password has been changed successfully')
      setReset(defaultReset)
      setOtp(defaultOtp)
    } else {
      showToaster('error', 'Password Change Failed', res.data.error || 'Something went wrong')
    }
  }

  const handelRequestOtp = async () => {
    setOtp({ ...otp, requesting: true })
    const res = await requestOtp()
    setOtp({ ...otp, requesting: false })
    if (res && res.status === 200) {
      showToaster('success', 'OTP sent', 'OTP sent to your email')
      let time = new Date().getTime()
      setOtp({ ...otp, expireTime: time + 30 * 1000 })
    } else {
      showToaster('error', 'Error', res.data.error || 'Something went wrong')
    }
  }

  const handelResetChange = (target, value) => {
    setReset({ ...reset, [target]: value })
  }
  const passwordHeader = <h6>Pick a password</h6>
  const passwordFooter = (
    <>
      <Divider />
      <p className="mt-2">Suggestions</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </>
  )

  useEffect(() => {
    if (otp.expireTime) {
      const interval = setInterval(() => {
        if (new Date().getTime() < otp.expireTime) {
          setOtpCoolDown((p) => p + 1)
        } else {
          setOtpCoolDown(0)
          setOtp((p) => ({ ...p, expireTime: null }))
          return () => clearInterval(interval)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [otp.expireTime])
  return (
    <>
      <Toast ref={toast} />
      <div className="daily-plan-wrapper p-mt-3">
        <span className="p-text-bold">Change Credentials</span>

        <div className="p-mt-3 p-fluid">
          <div className="p-field">
            <label htmlFor="password">Current Password</label>
            <div className="">
              <InputText
                placeholder="Enter current password"
                value={reset.password}
                onChange={(e) => handelResetChange('password', e.target.value)}
                id="password"
                type="password"
                className=""
              />
            </div>
          </div>

          <div className="p-field">
            <label htmlFor="npassword">New Password</label>
            <Password
              feedback={false}
              toggleMask
              header={passwordHeader}
              footer={passwordFooter}
              value={reset.newPassword}
              onChange={(e) => handelResetChange('newPassword', e.target.value)}
              placeholder="Enter new password"
              id="npassword"
              className="p-d-block"
              style={{ width: '100%' }}
            />
          </div>

          <div className="p-field">
            <label htmlFor="cpassword">Confirm Password</label>
            <Password
              feedback={false}
              toggleMask
              header={passwordHeader}
              footer={passwordFooter}
              value={reset.confirmPassword}
              onChange={(e) => handelResetChange('confirmPassword', e.target.value)}
              placeholder="Confirm password"
              id="cpassword"
              className="p-d-block"
              style={{ width: '100%' }}
            />
          </div>

          <div className="p-field">
            <label htmlFor="otp">OTP</label>
            <InputText
              value={otp.value}
              onChange={(e) => {
                if (e.target.value.length > 4) return
                setOtp({ ...otp, value: e.target.value })
              }}
              placeholder="Enter your OTP"
              id="otp"
              className="p-d-block"
              style={{ width: '100%' }}
            />
          </div>
          <div className="p-field">
            <Button
              label={otp.loading ? 'Submitting' : 'Submit'}
              className="p-button"
              onClick={handelPasswordChange}
              loading={otp.loading}
              disabled={
                !otp.value ||
                otp.value.length !== 4 ||
                otp.loading ||
                Object.values(reset).some((p) => !p)
              }
            />
          </div>

          {otp.expireTime ? (
            <div className="p-field">
              <Button
                label={`Request OTP again in ${30 - otpCoolDown} sec`}
                className="p-button-text"
                disabled
              />
            </div>
          ) : (
            <div className="p-field">
              <Button
                label={otp.requesting ? 'Requesting...' : 'Request OTP'}
                className="p-button-text"
                onClick={handelRequestOtp}
                loading={otp.requesting}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
