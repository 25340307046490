import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import { logoutApi } from '../../api'
import BackToHome from '../../components/mini/BackToHome'

export default function AccessPage(props) {
  const history = useHistory()

  const goLogin = () => {
    history.push('/login')
  }

  const clearCacheAndLogout = async () => {
    const res = await logoutApi()
    if (res) {
      if (res.status === 200) {
        localStorage.clear()
        sessionStorage.clear()
      }
      goLogin()
    }
  }

  return (
    <div className="exception-body accessdenied">
      <div className="exception-panel">
        <h1>ACCESS</h1>
        <h3>denied</h3>
        <p>You are not allowed to view this page.</p>

        <div className="p-d-flex gap-1 p-flex-wrap p-jc-center p-ai-center">
          <BackToHome />
          {/* <Button
            type="button"
            icon="pi pi-home"
            label={`Open ${user && user.role === "operations" ? 'Orders' : 'Dashboard'}`}
            onClick={user && user.role === "operations" ? goOrdersPage : goDashboard}
          ></Button> */}
          <Button
            type="button"
            icon="pi pi-unlock"
            label="Open Login Page"
            onClick={goLogin}
          ></Button>
          {localStorage.getItem('token') && (
            <Button
              type="button"
              icon="pi pi-trash"
              className="p-button-danger"
              label="Logout And Clear Cache"
              onClick={clearCacheAndLogout}
            ></Button>
          )}
        </div>
      </div>
      <div className="exception-footer">
        <img
          src={`/assets/layout/images/logo-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`}
          className="exception-logo"
          alt="exception-logo"
        />
      </div>
    </div>
  )
}
